import React, { useState } from "react";
import { Button, Grid, InputBase, Menu, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  white: {
    backgroundColor: theme.palette.common.white,
  },
  fullWidth: {
    justifyContent: "space-between",
  },
});

const FilterMenuGroups = ({
  handleFilters,
  fullWidth,
  color = "default",
  storesList,
  selectedStore,
  setSelectedStore,
}) => {
  const styles = useStyles();
  const [anchorElThemes, setAnchorElThemes] = useState(null);
  const [filterActive, setFilterActive] = useState("Store/Store group");

  const handleClickSorting = event => {
    setAnchorElThemes(event.currentTarget);
  };
  const handleCloseSorting = () => {
    setAnchorElThemes(null);
  };
  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        size="small"
        className={classNames({
          [styles.white]: color === "white",
          [styles.fullWidth]: fullWidth,
        })}
        endIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
        onClick={handleClickSorting}
      >
        {selectedStore && selectedStore.name ? selectedStore.name : "Store/Store group"}
      </Button>
      <Menu
        id="simple-menu"
        elevation={0}
        anchorEl={anchorElThemes}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorElThemes)}
        onClose={handleCloseSorting}
      >
        <MenuItem value="search" button={false}>
          <InputBase placeholder="Search store name or ID" fullWidth />
        </MenuItem>
        <MenuItem
          value=""
          onClick={e => {
            handleCloseSorting();
            handleFilters(e);
            setSelectedStore(null);
            setFilterActive(null);
          }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs="auto">
              <Typography variant="body1">{"Clear"}</Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" color="secondary">
                {"Selection"}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        {storesList &&
          storesList.map(item => (
            <MenuItem
              key={item._id}
              data-name="theme"
              data-value={null}
              onClick={e => {
                handleCloseSorting();
                handleFilters(e);
                setSelectedStore(item);
                setFilterActive(item._id);
              }}
              selected={filterActive === item._id}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs="auto">
                  <Typography variant="body1">{item.name}</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="body1" color="secondary">
                    {item.ident}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default FilterMenuGroups;

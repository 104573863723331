import React, { useState, useEffect, useMemo, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import CampaignService from "services/CampaignService";
import { Box, Button, Collapse, Container, Fab, Fade, Grid, IconButton, Typography, Snackbar } from "@material-ui/core";
import Loader from "../loader/Loader";
import Heading from "../headings/Heading";
import theme from "AppTheme";
import FilterMenuSorting from "../filters/FilterMenuSorting";
import FilterBulkSelect from "../filters/FilterBulckSelect";
import FilterSelectAll from "../filters/FilterSelectAll";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import VfAlert from "../vf/VfAlert";
import SearchBarWrapper from "../searchbar/SearchBarWrapper";
import VfSvgIcon from "../icons/VfSvgIcon";
import FilterPager from "../filters/FilterPager";
import FilterMenuGroups from "../filters/FilterMenuGroups";
import FilterDateInput from "../filters/FilterDateInput";
import FilterTextField from "../filters/FilterTextField";
import CardCampaign from "./cards/CardCampaign";
import DialogCampaign from "./dialogs/DialogCampaign";
import { CSVLink } from "react-csv";
import { fetchCampaigns, bulkDeleteCampaigns, removeError } from "actions/campaignActions";
import { fetchAllGalleries } from "actions/galleryActions";
import { fetchAllStoresAndDevices } from "actions/storeActions";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 100,
    },
  },
  fab: {
    position: "absolute",
    right: theme.spacing(3),
    top: -theme.spacing(7),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
});

const Header = ({locale, bulkSelect, campaigns, selectAll, startDate, endDate, priority, sortOptions, showDialogDelete, select, selectedStore, storesList, setSort, setSelectedStore, setStartDate, setStartCheck, setEndDate, setEndCheck, setPriority, setSelect, setSelectAll, setShowDialogDelete, setBulkSelect, handleDelete}) => (
  <Heading title="Campaigns" backUrl={`/${locale}/landing`}>
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4}>
            <FilterMenuSorting options={sortOptions} handleFilters={sortValue => setSort(sortValue)} fullWidth />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FilterMenuGroups
              setSelectedStore={setSelectedStore}
              selectedStore={selectedStore}
              storesList={storesList}
              fullWidth
              handleFilters={() => {}}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FilterBulkSelect
              fullWidth
              value={bulkSelect}
              action={() => {
                setSelect(campaigns.map(() => false));
                setSelectAll(false);
                setBulkSelect(!bulkSelect);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FilterDateInput
              selectedDate={startDate}
              setSelectedDate={setStartDate}
              setChecked={setStartCheck}
              fullWidth
              action={() => {}}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FilterDateInput
              selectedDate={endDate}
              setSelectedDate={setEndDate}
              setChecked={setEndCheck}
              fullWidth
              action={() => {}}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FilterTextField fullWidth type="number" label="Priority" value={priority} action={setPriority} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={3} wrap="nowrap" justify="flex-end" alignItems="center">
          <Fade in={bulkSelect}>
            <Grid item sm={6}>
              <FilterSelectAll
                fullWidth
                value={selectAll}
                action={() => {
                  setSelect(campaigns.map(() => !selectAll));
                  setSelectAll((value) => !value);
                }}
              />
            </Grid>
          </Fade>
          <Fade in={bulkSelect}>
            <Grid item xs="auto">
              <Button size="small" onClick={() => setShowDialogDelete(true)}>
                Delete selected
              </Button>
              <VfDialogConfirm
                openDialog={showDialogDelete}
                maxWidth="xs"
                buttonCancelText="Cancel"
                buttonCancelAction={() => setShowDialogDelete(false)}
                buttonConfirmText="Delete"
                buttonConfirmAction={handleDelete}
              >
                <Typography variant="body1" align="center">
                  Are you sure you want to delete from menu
                  <br />
                  <strong>{select.filter(x => x).length + " Campaigns"}</strong>
                  <br />
                  permanently?
                </Typography>
              </VfDialogConfirm>
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    </Grid>
  </Heading>
);

const Campaigns = () => {
  const styles = useStyles();
  const { locale } = useParams();
  const [openSearch, setOpenSearch] = useState(false);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [sort, setSort] = useState("title");
  const [search, setSearch] = useState("");
  const [priority, setPriority] = useState("");
  const [pager, setPager] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const csvLink = useRef();
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogCampaign, setShowDialogCampaign] = useState(false);
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;
  const { campaigns, campaignItems, loading, error } = useSelector(state => state.campaignReducer, shallowEqual);
  const { allStores, allStoreGroups } = useSelector(state => state.storeReducer, shallowEqual);
  const [startDate, setStartDate] = useState(null);
  const [startCheck, setStartCheck] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [endCheck, setEndCheck] = useState(false);
  const [storesList, setStoresList] = useState([]);
  const [selectedStore, setSelectedStore] = useState({});
  const [select, setSelect] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const dispatch = useDispatch();
  let calling = false;

  useEffect(() => {
    dispatch(fetchAllGalleries({ access_token, id_token }));
    dispatch(fetchAllStoresAndDevices({ access_token, id_token }));
  }, []);

  useEffect(() => {
    const storeGroupsArray = allStoreGroups
      ? allStoreGroups.map(x => ({
          name: x.name,
          _id: x._id,
          type: "storeGroup",
          ident: "",
        }))
      : [];
    const storeArray = allStores
      ? allStores.map(x => ({
          name: x.name,
          _id: x._id,
          type: "store",
          ident: x.storeId,
        }))
      : [];

    setStoresList(storeGroupsArray.concat(storeArray));
  }, [allStores, allStoreGroups]);

  useEffect(() => {
    if (loading) {
      return;
    }
    dispatch(
      fetchCampaigns({
        search,
        page: pager ? pager : 1,
        pageSize,
        sort,
        priority: priority,
        dateFrom: startDate,
        dateTo: endDate,
        selectedStore: selectedStore?._id,
        selectedStoreType: selectedStore?.type,
        access_token,
        id_token,
      })
    );
  }, [loading, pager, pageSize, search, sort, priority, startDate, endDate, selectedStore]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (pager === 1) {
      dispatch(
        fetchCampaigns({
          search,
          page: pager ? pager : 1,
          pageSize,
          sort,
          priority: priority,
          access_token,
          id_token,
        })
      );
    }
    setPager(1);
  }, [search]);

  useEffect(() => {
    setSelectAll(select.every((value) => value));
  }, [select]);

  const handleDownload = () => {
    if (calling) return;
    if (!calling) {
      calling = true;
      CampaignService.export({
        search,
        sort,
        priority: priority,
        dateFrom: startDate,
        dateTo: endDate,
        selectedStore: selectedStore?._id,
        selectedStoreType: selectedStore?.type,
        access_token,
        id_token,
      })
        .then(({ campaigns }) => {
          calling = false;
          setDownloadData(
            campaigns.map(x => ({
              _id: x?._id,
              title: x?.title,
              startDate: x?.validFor?.start,
              endDate: x.validFor?.end,
              priority: x.priority,
              gallery: x?.galery?._id,
              stores: x?.stores?.map(x => x._id).join(","),
              devices: x?.devices?.map(x => x._id).join(","),
              storeGroups: x?.storeGroups?.map(x => x._id).join(","),
            }))
          );
          csvLink.current.link.click();
          calling = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const campaignPages = useMemo(() => Math.ceil(campaignItems / pageSize), [campaignItems, pageSize]);

  const sortOptions = [
    { title: "Sort by name", value: "title" },
    { title: "Sort by Id", value: "_id" },
  ];

  const selectAction = index => {
    setSelect(
      select.map((x, y) => {
        if (y !== index) return x;
        return !x;
      })
    );
  };

  const handleDelete = () => {
    const campaignsList = select
      .map((item, index) => {
        if (item) return campaigns[index]._id;
        return null;
      })
      .filter(x => x);
    setBulkSelect(false);
    setSelectAll(false);
    dispatch(bulkDeleteCampaigns({ access_token, id_token, campaigns: campaignsList }));
    setShowDialogDelete(false);
  };

  const onChangePageSize = (number) => {
    setPageSize(number);
    setPager(1);
  };

  return (
    <main className={styles.root}>
      <Header
        locale={locale}
        bulkSelect={bulkSelect}
        campaigns={campaigns}
        selectAll={selectAll}
        startDate={startDate}
        endDate={endDate}
        priority={priority}
        sortOptions={sortOptions}
        showDialogDelete={showDialogDelete}
        select={select}
        selectedStore={selectedStore}
        storesList={storesList}
        setSort={setSort}
        setSelectedStore={setSelectedStore}
        setStartDate={setStartDate}
        setStartCheck={setStartCheck}
        setEndDate={setEndDate}
        setEndCheck={setEndCheck}
        setPriority={setPriority}
        setSelect={setSelect}
        setSelectAll={setSelectAll}
        setShowDialogDelete={setShowDialogDelete}
        setBulkSelect={setBulkSelect}
        handleDelete={handleDelete}
      />
      <Box pt={8} pb={4}>
        <Container className={styles.container} maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Collapse in={!bulkSelect}>
                <SearchBarWrapper setSearchText={setSearch} expanded={openSearch} setExpanded={setOpenSearch}>
                  <Grid container alignItems="center" justify="flex-end">
                    <Grid item xs="auto">
                      <IconButton onClick={handleDownload}>
                        <VfSvgIcon icon="download" />
                      </IconButton>
                    </Grid>
                    <Grid item xs="auto">
                      <IconButton
                        onClick={() => {
                          setOpenSearch(true);
                        }}
                      >
                        <VfSvgIcon icon="search" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </SearchBarWrapper>
              </Collapse>
            </Grid>
            {!loading ? (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {campaigns &&
                    campaigns.map((campaign, index) => (
                      <Grid item xs={12} key={campaign?._id}>
                        <CardCampaign
                          campaign={campaign}
                          title={campaign?.title}
                          gallery={campaign?.gallery?.title}
                          date={campaign?.validFor?.start}
                          priority={campaign?.priority}
                          bulkSelect={bulkSelect}
                          selected={select[index]}
                          moreActions
                          action={() => {
                            // eslint-disable-next-line no-unused-expressions
                            if (bulkSelect) {
                              selectAction(index);
                            } else {
                              return null; //setShowDialogCampaign(true);
                            }
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}

            {!loading && (
              <Fade in={!bulkSelect}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item xs="auto">
                      <FilterPager
                        count={campaignPages ? campaignPages : 1}
                        page={pager}
                        pageSize={pageSize}
                        action={setPager}
                        actionPageSize={onChangePageSize}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
          <Box className={styles.fab}>
            <Fade in={!bulkSelect}>
              <Grid container spacing={3}>
                <Grid item xs="auto">
                  <Fab color="secondary" onClick={() => setShowDialogCampaign(true)}>
                    <VfSvgIcon icon="plus" viewBox={24} />
                  </Fab>
                </Grid>
              </Grid>
            </Fade>
          </Box>
          {showDialogCampaign && <DialogCampaign open={showDialogCampaign} setOpen={setShowDialogCampaign} />}
        </Container>
        <CSVLink
          data={downloadData}
          separator={";"}
          filename="campaigns.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        ></CSVLink>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(error)}
        onClose={() => {
          dispatch(removeError());
        }}
      >
        <VfAlert severity={0} message={error} />
      </Snackbar>
    </main>
  );
};

export default Campaigns;

/* eslint-disable camelcase */
import axios from "axios";

class StoreService {
  fetch = async ({ access_token, id_token, search, page, pageSize, sort }) => {
    const params = {
      search,
      page,
      pageSize,
      sort,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/store/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in fetch stores service:", err);
      return err;
    }
  };

  fetchAll = async ({ access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/store/all`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch all stores and devices service:", err);
      return err;
    }
  };

  add = async ({ access_token, id_token, name, storeId, storeGroup }) => {
    const params = { storeId, name, storeGroup };
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/portal/store/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: params,
      });
      return data;
    } catch (err) {
      console.log("Error in add store service:", err);
      return err;
    }
  };

  import = async ({ payload, access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/portal/store/import`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: payload,
      });
      return data;
    } catch (err) {
      console.log("Error in add many stores service:", err);
      return err;
    }
  };

  update = async ({ access_token, id_token, name, storeId, storeGroup, _id }) => {
    const params = { storeId, name, storeGroup };
    try {
      const { data } = await axios({
        method: "patch",
        url: `/api/appbuilder/portal/store/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: params,
      });
      return data;
    } catch (err) {
      console.log("Error in update store service:", err);
      return err;
    }
  };

  delete = async ({ access_token, id_token, _id }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/store/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        }
      });
      return data;
    } catch (err) {
      console.log("Error in delete store service:", err);
      return err;
    }
  };

  bulkDelete = async ({ access_token, id_token, stores }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/store/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: stores,
      });
      return data;
    } catch (err) {
      console.log("Error in Bulk delete stores service:", err);
      return err;
    }
  };

  export = async ({ access_token, id_token, search, sort }) => {
    const params = {
      search,
      sort,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/store/export`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in export stores service:", err);
      return err;
    }
  };
}

const inst = new StoreService();
export default inst;

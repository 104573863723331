import {
  RESET_MEDIA,
  CLEAR_MEDIA_ERROR,
  MEDIA_REQUEST,
  MEDIA_REQUEST_SUCCESS,
  MEDIA_REQUEST_FAIL,
  FILTER_MEDIA_REQUEST,
  FILTER_MEDIA_REQUEST_SUCCESS,
  FILTER_MEDIA_REQUEST_FAIL,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_REQUEST_SUCCESS,
  UPLOAD_MEDIA_REQUEST_FAIL,
  UPDATE_MEDIA_REQUEST,
  UPDATE_MEDIA_REQUEST_SUCCESS,
  UPDATE_MEDIA_REQUEST_FAIL,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_REQUEST_SUCCESS,
  DELETE_MEDIA_REQUEST_FAIL,
} from "actions/mediaActions";

const initialState = {
  page: 0,
  limit: 10,
  hasMore: false,
  list: [],
  scrollLoading: false,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case RESET_MEDIA:
      return initialState;
    case CLEAR_MEDIA_ERROR:
      return {
        ...state,
        error: null,
      };
    case FILTER_MEDIA_REQUEST:
    case UPLOAD_MEDIA_REQUEST:
    case UPDATE_MEDIA_REQUEST:
    case DELETE_MEDIA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MEDIA_REQUEST:
      return {
        ...state,
        scrollLoading: true,
      };
    case MEDIA_REQUEST_SUCCESS:
      return {
        ...state,
        hasMore: payload.data.length === state.limit,
        page: payload.page + 1,
        list: [...payload.data, ...state.list].sort((a, b) => {
          const x = a.updatedAt;
          const y = b.updatedAt;
          if (x < y) {
            return 1;
          }
          if (x > y) {
            return -1;
          }
          return 0;
        }),
        loading: false,
        scrollLoading: false,
        error: null,
      };
    case MEDIA_REQUEST_FAIL:
      return {
        ...state,
        list: [],
        loading: false,
        scrollLoading: false,
        error,
      };
    case FILTER_MEDIA_REQUEST_SUCCESS:
      return {
        ...state,
        hasMore: payload.data.length === state.limit,
        page: payload.page + 1,
        list: payload.data,
        loading: false,
        scrollLoading: false,
        error: null,
      };
    case FILTER_MEDIA_REQUEST_FAIL:
      return {
        ...state,
        list: [],
        loading: false,
        scrollLoading: false,
        error,
      };
    case UPLOAD_MEDIA_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPLOAD_MEDIA_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error,
      };
    case UPDATE_MEDIA_REQUEST_SUCCESS:
      return {
        ...state,
        list: [payload, ...state.list.filter(item => item.key !== payload.key)],
        loading: false,
        error: null,
      };
    case UPDATE_MEDIA_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error,
      };
    case DELETE_MEDIA_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_MEDIA_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid, IconButton } from "@material-ui/core";
import FilterPager from "../filters/FilterPager";
import SearchBarWrapper from "../searchbar/SearchBarWrapper";
import CardPasscode from "./cards/CardPasscode";
import { fetchPasscodes } from "actions/passcodeActions";
import Heading from "../headings/Heading";
import Loader from "../loader/Loader";
import theme from "../../AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  container: {
    position: "relative",
  }
});

const Passcodes = () => {
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [fetch, setFetch] = useState(true);
  const { locale } = useParams();
  const styles = useStyles();

  const auth = useSelector(state => state.authentication, shallowEqual);
  const { stores, storeItems, loading } = useSelector(state => state.passcodeReducer, shallowEqual);
  const dispatch = useDispatch();

  const { access_token, id_token } = auth;

  const storePages = useMemo(() => Math.ceil(storeItems / pageSize), [storeItems, pageSize]);

  useEffect(() => {
    if (fetch) {
      dispatch(
        fetchPasscodes({
          access_token,
          id_token,
          search,
          page,
          pageSize,
        })
      );
      setFetch(false);
    }
  }, [fetch, search, page, pageSize]);

  useEffect(() => {
    if (search) {
      setPage(1);
    }
    setFetch(true);
  }, [search]);

  const onChangePage = number => {
    setPage(number);
    setFetch(true);
  };

  const onChangePageSize = number => {
    setPageSize(number);
    setPage(1);
    setFetch(true);
  };

  return (
    <main className={styles.root}>
      <Heading
        title={
          <>
            <Container className={styles.container} maxWidth="md">
              <Grid container>
                <Grid item xs={6}>
                  Passcodes
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </Container>
          </>
        }
        backUrl={`/${locale}/landing`}
      />
      <Box pt={8} pb={4}>
        <Container className={styles.container} maxWidth="md">
          <Grid item xs={12}>
            <Box pb={4}>
              <SearchBarWrapper setSearchText={setSearch} expanded={openSearch} setExpanded={setOpenSearch}>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item xs="auto">
                    <IconButton onClick={() => setOpenSearch(true)}>
                      <VfSvgIcon icon="search" />
                    </IconButton>
                  </Grid>
                </Grid>
              </SearchBarWrapper>
            </Box>
          </Grid>
          {
            loading ? <Loader size={theme.spacing(12)} /> :
            <>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {stores.map(({_id, name, storeId, devices}) => (
                    <Grid item xs={12} key={_id}>
                      <CardPasscode storeId={storeId} name={name} devices={devices} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Grid item xs="auto">
                    <FilterPager
                      count={storePages ?? 1}
                      page={page}
                      pageSize={pageSize}
                      action={onChangePage}
                      actionPageSize={onChangePageSize}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
        </Container>
      </Box>
    </main>
  );
};

export default Passcodes;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React, { useState, version } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import {
  fetchAppVersions,
  setVersionTo,
  setVersionFrom,
  fetchAppVersionsDetails,
} from "actions/compareAppVersionsActions";
import {
  Typography,
  Grid,
  TextField,
  Tabs,
  Tab,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import ApiTab from "./ApiTab";
import PackageTab from "./PackageTab";
import SnippetTab from "./SnippetTab";
import FlowTab from "./FlowTab";

import VfDialog from "../../vf/VfDialogFull";
import theme from "../../../AppTheme";

const useStyles = makeStyles({
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  dif: {
    maxHeight: "50vh",
    overflowY: "auto",
    width: "100%",
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(4),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  tabs: {
    "& .MuiTab-labelIcon": {
      minHeight: "auto",
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      "& .MuiTab-wrapper": {
        flexDirection: "row",
        "& .MuiSvgIcon-root": {
          marginBottom: 0,
          marginRight: theme.spacing(1),
        },
      },
    },
  },
  inputSelect: {
    "& .MuiSelect-select.MuiSelect-selectMenu": {
      paddingTop: 10,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.secondary.main,
    },
  },
  dialogContent: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
  },
});

const CompareVersionsMenuItem = ({ onClick, app }) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { dialogOpen, versions, versionFrom, versionTo, data } = useSelector(
    state => state.compareAppVersions,
    shallowEqual
  );
  const [tab, setTab] = useState("apis");
  const classes = useStyles();
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;
  return (
    <>
      <MenuItem
        onClick={() => {
          onClick();
          setShowDialog(true);
          dispatch(fetchAppVersions({ app, access_token, id_token }));
        }}
      >
        Comprare versions
      </MenuItem>

      <VfDialog
        openDialog={showDialog}
        buttonCloseAction={() => {
          setTab("apis");

          setShowDialog(false);
        }}
        buttonCloseText="Close"
        appBar={
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h3">
                  {app.title}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    },
                  }}
                  onChange={e => {
                    dispatch(
                      fetchAppVersionsDetails({
                        versionFrom: e.target.value._id,
                        versionTo: versionTo._id,
                        access_token,
                        id_token,
                      })
                    );
                    dispatch(setVersionFrom(e.target.value));
                  }}
                  value={versionFrom || ""}
                  variant="filled"
                  color="secondary"
                  fullWidth
                  className={classes.inputSelect}
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {version &&
                    versions.map(ver => (
                      <MenuItem key={ver._id} value={ver}>
                        {`v${ver.version}`}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2}>
                <TextField
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    },
                  }}
                  onChange={e => {
                    dispatch(
                      fetchAppVersionsDetails({
                        versionFrom: versionFrom._id,
                        versionTo: e.target.value._id,
                        access_token,
                        id_token,
                      })
                    );
                    dispatch(setVersionTo(e.target.value));
                  }}
                  value={versionTo || ""}
                  variant="filled"
                  color="secondary"
                  fullWidth
                  className={classes.inputSelect}
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {version &&
                    versions.map(ver => (
                      <MenuItem key={ver._id} value={ver}>
                        {`v${ver.version}`}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          </>
        }
        classDialogContent={classes.dialogContent}
      >
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          value={tab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
        >
          <Tab
            /* eslint-disable */
            label={
              <Typography variant="body2" color="textPrimary">
                APIS
              </Typography>
            }
            /* eslint-disable no-underscore-dangle */
            value="apis"
          />
          <Tab
            /* eslint-disable */
            label={
              <Typography variant="body2" color="textPrimary">
                FLOWS
              </Typography>
            }
            /* eslint-enable */
            /* eslint-disable no-underscore-dangle */
            value="flows"
          />
          <Tab
            /* eslint-disable */
            label={
              <Typography variant="body2" color="textPrimary">
                PCKGS
              </Typography>
            }
            /* eslint-enable */
            /* eslint-disable no-underscore-dangle */
            value="packages"
          />
          <Tab
            /* eslint-disable */
            label={
              <Typography variant="body2" color="textPrimary">
                SNIPS
              </Typography>
            }
            /* eslint-enable */
            /* eslint-disable no-underscore-dangle */
            value="snippets"
          />
        </Tabs>
        {data?.packages && tab === "packages" && (
          <PackageTab packages={data?.packages} />
        )}
        {data?.apis && tab === "apis" && (
          <ApiTab apis={data.apis} classes={classes} />
        )}
        {data?.flows && tab === "flows" && <FlowTab flows={data?.flows} />}
        {data?.snippets && tab === "snippets" && (
          <SnippetTab snippets={data.snippets} />
        )}
      </VfDialog>
    </>
  );
};

export default CompareVersionsMenuItem;

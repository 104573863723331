import React, { useState } from "react";
import { Card, CardActions, CardContent, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import VfSvgIcon from "../../icons/VfSvgIcon";
import theme from "AppTheme";
import VfDialogConfirm from "../../vf/VfDialogConfirm";

const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiCardActions-root": {
      padding: 0,
      opacity: 0,
      transition: theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.standard,
      }),
    },
    "&:hover": {
      "& .MuiCardActions-root": {
        opacity: 1,
      },
    },
  },
});

const CardStoreList = ({ name, code, deleteAction }) => {
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const classes = useStyles();

  return (
    <Card
      elevation={0}
      className={classNames(classes.card, {
        "MuiCard-grey": true,
        "MuiCard-rounded": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-grey": true,
      })}
    >
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs="auto">
            <Typography variant="body1">{name}</Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="body1" color="secondary">
              {code}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <IconButton onClick={() => setShowDialogDelete(true)}>
          <VfSvgIcon icon="delete" />
        </IconButton>
      </CardActions>
      <VfDialogConfirm
        openDialog={showDialogDelete}
        maxWidth="xs"
        buttonCancelText="Cancel"
        buttonCancelAction={() => setShowDialogDelete(false)}
        buttonConfirmText="Delete"
        buttonConfirmAction={() => {
          setShowDialogDelete(false);
          deleteAction(code);
        }}
      >
        <Typography variant="body1" align="center">
          Are you sure you want to delete from menu
          <br />
          <strong>{name}</strong>
          <br />
          permanently?
        </Typography>
      </VfDialogConfirm>
    </Card>
  );
};

export default CardStoreList;

/* eslint-disable camelcase */
import axios from "axios";

class HistoryService {
  fetchConfigHistory = async ({
    application,
    componentId,
    id_token,
    access_token,
    envID,
  }) => {
    const params = {
      application,
      componentId,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/history",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget list service:", err);
      return err;
    }
  };
}

const inst = new HistoryService();
export default inst;

import {
  LOGGED_IN,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_MESSAGE,
  CLEAR_LOGOUT_MESSAGE,
  SET_SELECTED_MARKET,
  UPDATE_AUTH_ID_TOKEN,
} from "actions/authenticationActions";

const initialState = {};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case CLEAR_LOGOUT_MESSAGE:
    case LOGOUT: {
      localStorage.removeItem("auth");
      localStorage.removeItem("permissions");
      return {};
    }
    case LOGOUT_MESSAGE: {
      localStorage.removeItem("auth");
      return { error: payload };
    }
    case LOGIN_ERROR: {
      return error;
    }
    case LOGGED_IN: {
      localStorage.setItem("auth", JSON.stringify(action.payload));
      return payload;
    }
    case SET_SELECTED_MARKET: {
      return { ...state, ctry: payload.code.toLowerCase(), selectedMarket: { ...payload } };
    }
    case UPDATE_AUTH_ID_TOKEN: {
      const updatedAuth = { ...state, id_token: payload.id_token, ctry: payload.ctry };
      localStorage.setItem("auth", JSON.stringify(updatedAuth));
      return updatedAuth;
    }
    default:
      return state;
  }
};

export default reducer;

import {
  GET_GALLERY_MEDIA_REQUEST_SUCCESS,
  UPLOAD_GALLERY_MEDIA_REQUEST,
  UPLOAD_GALLERY_MEDIA_REQUEST_SUCCESS,
  UPLOAD_GALLERY_MEDIA_REQUEST_FAIL,
  DELETE_GALLERY_MEDIA_REQUEST,
  DELETE_GALLERY_MEDIA_REQUEST_SUCCESS,
  DELETE_GALLERY_MEDIA_REQUEST_FAIL,
} from "actions/galleryMediaActions";
  
const initialState = {
  loading: false,
  media: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GALLERY_MEDIA_REQUEST_SUCCESS: {
      return {
        ...state,
        ...{
          media: payload ? payload : [],
        },
      };
    }
    case DELETE_GALLERY_MEDIA_REQUEST: {
      return { ...state, ...{ loading: true } };
    }
    case DELETE_GALLERY_MEDIA_REQUEST_SUCCESS: {
      return { ...state, ...{ loading: false } };
    }
    case DELETE_GALLERY_MEDIA_REQUEST_FAIL: {
      return { ...state, ...{ loading: false } };
    }
    case UPLOAD_GALLERY_MEDIA_REQUEST: {
      return { ...state, ...{ loading: true } };
    }
    case UPLOAD_GALLERY_MEDIA_REQUEST_SUCCESS: {
      return { ...state, ...{ loading: false } };
    }
    case UPLOAD_GALLERY_MEDIA_REQUEST_FAIL: {
      return { ...state, ...{ loading: false } };
    }
    default:
      return state;
  }
};

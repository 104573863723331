/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import theme from "AppTheme";
import { DialogActions, FormControl, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import CardMedia from "@material-ui/core/CardMedia";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getAppLatestVersion } from "../../actions/applicationsActions";
import AppSummary from "./common/AppSummary";
import VfSvgIcon from "../icons/VfSvgIcon";
import {
  getVersionValidationMsg,
  getSuggestedVersion,
} from "../../utils/app-version-utils";
import { fetchTenants } from "../../actions/tenantActions";

const useStyles = makeStyles({
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalContent: {
    minHeight: "30vh",
  },
  gridDivider: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
  dialogActions: {
    paddingBottom: theme.spacing(4),
    justifyContent: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      "& .MuiButton-root": {
        width: "100%",
      },
      "& > :not(:first-child)": {
        marginLeft: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
});

const NewVersionDialog = ({
  isOpen,
  application,
  onCancel,
  onConfirm,
  auth,
  latestVersion,
  localeId,
  tenants,
  getApplicationLatestVersion,
  getTenants,
}) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const ref = useRef(null);
  const descriptionRef = useRef(null);

  const [suggestedVersion, setSuggestedVersion] = useState("");
  const [validationMessage, setValidationMessage] = useState(null);
  const [
    descriptionValidationMessage,
    setDescriptionValidationMessage,
  ] = useState(null);
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);

  const { access_token, id_token } = auth;

  const validateVersion = (latestVer, inputVer) => {
    setValidationMessage(
      getVersionValidationMsg(latestVer, inputVer, suggestedVersion)
    );
  };

  const validateDescription = (appDescription, inputDescription) => {
    if (!isDescriptionRequired) {
      setDescriptionValidationMessage(null);
    } else if (!inputDescription.trim()) {
      setDescriptionValidationMessage("Release notes are required.");
    } else if (appDescription === inputDescription.trim()) {
      setDescriptionValidationMessage("You must update the release notes.");
    } else {
      setDescriptionValidationMessage(null);
    }
  };

  useEffect(() => {
    setSuggestedVersion(getSuggestedVersion(latestVersion));
  }, [latestVersion]);

  useEffect(() => {
    getApplicationLatestVersion({
      ...auth,
      // eslint-disable-next-line no-underscore-dangle
      applicationId: application._id,
      sourceAppId: application.sourceAppId,
    });
    if (!tenants || !tenants.length) {
      getTenants({ access_token, id_token });
    } else {
      const tenant = tenants.find(t => t._id === localeId);
      if (tenant) {
        setIsDescriptionRequired(tenant.locale.requiredVersionDescription);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    validateDescription(application.description, application.description);
  }, [application, isDescriptionRequired]);

  return !isOpen ? null : (
    <Dialog className={classes.root} fullScreen={fullScreen} open maxWidth="xs">
      <DialogTitle disableTypography className={classes.modalTitle}>
        <Grid container alignItems="center" justify="space-between" spacing={1}>
          <Grid item xs="auto">
            {application.iconBase64 && (
              <CardMedia image={application.appInfo.iconBase64} />
            )}
            {!application.iconBase64 && (
              <VfSvgIcon icon="vodafone" viewBox={24} />
            )}
          </Grid>
          <Grid item xs="auto" className={classes.gridDivider}>
            <Divider orientation="vertical" />
          </Grid>
          <AppSummary
            includeLogo
            appTitle={application.title}
            appType={application.appType}
            appPlatform={application.platform}
            appTheme={application.theming}
            appTags={application.tags}
            appVersion={application.version}
            unmanaged={application.unmanaged}
            hasAuth={application.hasAuth}
          />
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Grid container spacing={3} justify="center" alignContent="center">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                inputProps={{
                  ref,
                }}
                name="latestVersion"
                variant="outlined"
                label="New version"
                type="text"
                defaultValue={suggestedVersion}
                onChange={e => {
                  validateVersion(latestVersion, e.target.value);
                }}
                error={validationMessage}
                helperText={validationMessage}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                inputProps={{
                  ref: descriptionRef,
                }}
                name="versionDescription"
                variant="outlined"
                label="Release notes"
                type="text"
                defaultValue={application.description}
                onChange={e => {
                  validateDescription(application.description, e.target.value);
                }}
                error={descriptionValidationMessage}
                helperText={descriptionValidationMessage}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!!(validationMessage || descriptionValidationMessage)}
          onClick={() => {
            onConfirm(ref.current.value, descriptionRef.current.value);
          }}
        >
          Create version
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    latestVersion: state.applications.current.latestVersion,
    localeId: (state.envs?.list || [])[0]?.locale,
    tenants: state.tenants.list,
  };
};

const mapDispatchToProps = dispatch => ({
  getApplicationLatestVersion: params => dispatch(getAppLatestVersion(params)),
  getTenants: params => dispatch(fetchTenants(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionDialog);

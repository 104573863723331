/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { MenuItem, Grid, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Collapse from "@material-ui/core/Collapse";
import theme from "../../../../AppTheme";
import AppDialogFullHeader from "../../common/AppDialogFullHeader";
import DeployAppResources from "./DeployResources";
import {
  clearEnvURLs,
  fetchEnvURLsAndResources,
} from "../../../../actions/envsActions";
import CardPlain from "../../../roles/CardPlain";
import SecurityGroupsSelector from "../SecurityGroups/SecurityGroupsSelector";
import { deployApplication } from "../../../../actions/applicationsActions";
import FilterMenuEnvironments from "../../../filters/FilterMenuEnvironments";
import NoResourcesDialog from "./NoResourcesDialog";
import { hasPermissions } from "../../../../utils/permissions-utils";

const useStyles = makeStyles({
  modalContent: {
    minHeight: "50vh",
  },
  alignRight: {
    textAlign: "right",
  },
  buttonText: {
    backgroundColor: "none",
    fontSize: theme.typography.caption.fontSize,
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
});

const DeployMenuItem = ({
  application,
  environment,
  originEnvironment,
  onPress,
  auth,
  urlList,
  ramList,
  cpuList,
  resourcesLoading,
  permissions,
  applyPermissions,
  getDeploymentResources,
  clearDeploymentResources,
  deploy,
}) => {
  const styles = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState("settings");

  const [selectedURL, setSelectedURL] = useState(urlList ? urlList[0] : "");
  const [selectedRam, setSelectedRam] = useState(ramList ? ramList[0] : "");
  const [selectedCpu, setSelectedCpu] = useState(cpuList ? cpuList[0] : "");
  const [selectedSecurityGroups, setSelectedSecurityGroups] = useState(
    application.securityGroups || []
  );

  const { ctry, access_token, id_token } = auth;

  useEffect(() => {
    if (environment && environment.locale && environment._id && openDialog) {
      getDeploymentResources({
        localeID: environment.locale,
        environmentID: environment._id,
        access_token,
        id_token,
      });
    }
  }, [environment, openDialog]);

  useEffect(() => {
    if (urlList && urlList.length) {
      setSelectedURL(urlList[0]._id);
    }
  }, [urlList]);

  useEffect(() => {
    if (ramList && ramList.length) {
      setSelectedRam(ramList[0].toString());
    }
  }, [ramList]);

  useEffect(() => {
    if (cpuList && cpuList.length) {
      setSelectedCpu(cpuList[0].toString());
    }
  }, [cpuList]);

  const hasResources = () =>
    urlList &&
    cpuList &&
    ramList &&
    urlList.length &&
    cpuList.length &&
    ramList.length;

  const securityGroupsVisible = () => {
    return (
      application.hasAuth &&
      hasPermissions(
        permissions || [],
        ["view_security_groups"],
        applyPermissions
      )
    );
  };

  const securityGroupsValid = () => {
    if (!application.hasAuth) {
      return true;
    }

    return selectedSecurityGroups.length;
  };

  const isInvalid = () =>
    !selectedURL || !selectedRam || !selectedCpu || !securityGroupsValid();

  const onCancel = () => {
    setOpenDialog(false);
    setSelectedURL("");
    setSelectedRam("");
    setSelectedCpu("");
    clearDeploymentResources();
  };

  const onDeploy = () => {
    if (isInvalid()) {
      return;
    }

    deploy({
      access_token,
      id_token,
      locale: ctry.toLowerCase(),
      body: {
        originEnvId: originEnvironment.id,
        environmentId: environment._id,
        application: {
          ...application,
          securityGroups: application.hasAuth ? selectedSecurityGroups : [],
        },
        url: selectedURL,
        cpu: selectedCpu,
        ram: selectedRam,
      },
    });
    onCancel();
  };

  const ActionButtons = () => {
    return (
      <>
        <Grid item xs="auto">
          <Button
            autoFocus
            color="default"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button
            autoFocus
            color="secondary"
            variant="contained"
            disabled={isInvalid()}
            onClick={onDeploy}
          >
            Deploy
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <>
      <MenuItem
        className={`MuiListItem-${environment.title.toLowerCase()}`}
        onClick={() => {
          onPress();
          setOpenDialog(true);
        }}
      >
        <ListItemText secondary={`Promote to ${environment.title}`} />
      </MenuItem>

      <NoResourcesDialog
        environment={environment}
        isOpen={openDialog && !hasResources() && !resourcesLoading}
        onConfirm={() => setOpenDialog(false)}
      />

      <Dialog
        fullScreen={fullScreen}
        open={openDialog && hasResources() && !resourcesLoading}
        maxWidth="md"
      >
        <AppDialogFullHeader
          application={application}
          includeLogo
          buttons={<ActionButtons />}
        >
          <FilterMenuEnvironments
            envs={{ list: [environment] }}
            activeEnv={environment ? environment.title : ""}
            clearEnvironment={() => {}}
            setEnvironment={() => {}}
            skipAllOption
            isDisabled
          />
        </AppDialogFullHeader>
        <DialogContent className={styles.modalContent}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
              <Tabs
                value={selectedTab}
                onChange={(e, value) => setSelectedTab(value)}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Settings" value="settings" />

                {securityGroupsVisible() && (
                  <Tab label="Security Groups" value="securityGroups" />
                )}
              </Tabs>
              <CardPlain
                title={
                  selectedTab === "settings"
                    ? "Fill in the following fields"
                    : ""
                }
                isList
              >
                <Grid item xs={12}>
                  <Collapse
                    in={selectedTab === "settings"}
                    key="settings-tab-content"
                  >
                    <DeployAppResources
                      selectedUrl={selectedURL}
                      selectedRam={selectedRam}
                      selectedCpu={selectedCpu}
                      onSelectUrl={value => setSelectedURL(value)}
                      onSelectRam={value => setSelectedRam(value)}
                      onSelectCpu={value => setSelectedCpu(value)}
                      urlList={urlList || []}
                      ramList={ramList || []}
                      cpuList={cpuList || []}
                    />
                  </Collapse>
                  <Collapse
                    in={selectedTab === "securityGroups"}
                    key="securityGroups-tab-content"
                  >
                    <SecurityGroupsSelector
                      environment={environment}
                      selectedSecurityGroups={selectedSecurityGroups}
                      onSelectSecurityGroups={value =>
                        setSelectedSecurityGroups(value)
                      }
                      onResetSecurityGroups={() =>
                        setSelectedSecurityGroups(application.securityGroups)
                      }
                    />
                  </Collapse>
                </Grid>
              </CardPlain>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    urlList:
      state.envs.envUrls?.urls?.filter(({ available }) => available) || null,
    ramList: state.envs.envUrls?.availableResources?.ram || null,
    cpuList: state.envs.envUrls?.availableResources?.cpu || null,
    resourcesLoading: state.envs.loading,
    permissions: state.userPermissions.permissions,
    applyPermissions: state.userPermissions.enablePermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  getDeploymentResources: payload =>
    dispatch(fetchEnvURLsAndResources(payload)),
  clearDeploymentResources: () => dispatch(clearEnvURLs()),
  deploy: payload => dispatch(deployApplication(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeployMenuItem);

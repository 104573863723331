import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import VfSvgIcon from "../../icons/VfSvgIcon";
import VfDialogConfirm from "../../vf/VfDialogConfirm";
import theme from "AppTheme";
import DialogDevice from "../dialogs/DialogDevice";
import { deleteDevice } from "actions/deviceActions";

const useStyles = makeStyles({
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  gridDivider: {
    marginTop: -theme.spacing(2),
    marginBottom: -theme.spacing(2),
    alignSelf: "stretch",
  },
});

const CardDevice = ({ device, action, moreActions, bulkSelect, selected }) => {
  const [openMenuMore, setOpenMenuMore] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDevice, setOpenDialogDevice] = useState(false);

  const auth = useSelector(state => state.authentication, shallowEqual);
  const dispatch = useDispatch();
  const styles = useStyles();
  const title = device.name;
  const icon = device.type;
  const id = device.code;
  const { access_token, id_token } = auth;

  const handleDelete = () => {
    dispatch(deleteDevice({ access_token, id_token, _id: device._id }));
    setOpenDialogDelete(false);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-white": true,
        "MuiCard-outlined": selected && bulkSelect,
        "MuiCard-outlined-primary": selected && bulkSelect,
        "Mui-selected": selected && bulkSelect,
        animate: true,
      })}
    >
      <Grid container wrap="nowrap" alignItems="center" justify="space-between">
        <Grid item xs>
          <CardActionArea onClick={() => action && action()}>
            <CardContent className={styles.cardContent}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs="auto">
                  <VfSvgIcon icon={icon} viewBox="56" fontSize="large" />
                </Grid>
                <Grid item xs="auto" className={styles.gridDivider}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs="auto">
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1">{title}</Typography>
                        </Grid>
                        {id && (
                          <Grid item xs="auto">
                            <Typography variant="body1" color="secondary">
                              {id}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {device.store && (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          Store:
                          {device.store.name}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Grid>
        {moreActions && (
          <Fade in={!bulkSelect}>
            <Grid item xs="auto">
              <CardActions disableSpacing>
                <IconButton
                  aria-label="add to favorites"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={e => setOpenMenuMore(e.currentTarget)}
                >
                  <VfSvgIcon icon="more" viewBox={24} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  elevation={0}
                  anchorEl={openMenuMore}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  open={Boolean(openMenuMore)}
                  onClose={() => setOpenMenuMore(null)}
                >
                  <MenuItem
                    button
                    onClick={() => {
                      setOpenDialogDevice(true);
                      setOpenMenuMore(null);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    button
                    onClick={() => {
                      setOpenDialogDelete(true);
                      setOpenMenuMore(null);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <DialogDevice
                  open={openDialogDevice}
                  device={device}
                  store={device.store}
                  setOpen={setOpenDialogDevice}
                />
                <VfDialogConfirm
                  openDialog={openDialogDelete}
                  maxWidth="xs"
                  buttonCancelText="Cancel"
                  buttonCancelAction={() => setOpenDialogDelete(false)}
                  buttonConfirmText="Delete"
                  buttonConfirmAction={() => handleDelete()}
                >
                  <Typography variant="body1" align="center">
                    Are you sure you want to delete from menu
                    <br />
                    <strong>{title}</strong>
                    <br />
                    permanently?
                  </Typography>
                </VfDialogConfirm>
              </CardActions>
            </Grid>
          </Fade>
        )}
      </Grid>
    </Card>
  );
};

export default CardDevice;

const GET_GALLERIES_REQUEST = "GET_GALLERIES_REQUEST";
const GET_GALLERIES_REQUEST_SUCCESS = "GET_GALLERIES_REQUEST_SUCCESS";
const GET_GALLERIES_REQUEST_FAIL = "GET_GALLERIES_REQUEST_FAIL";
const GET_ALL_GALLERIES_REQUEST = "GET_ALL_GALLERIES_REQUEST";
const GET_ALL_GALLERIES_REQUEST_SUCCESS = "GET_ALL_GALLERIES_REQUEST_SUCCESS";
const GET_ALL_GALLERIES_REQUEST_FAIL = "GET_ALL_GALLERIES_REQUEST_FAIL";
const ADD_GALLERIES_REQUEST = "ADD_GALLERIES_REQUEST";
const ADD_GALLERIES_REQUEST_SUCCESS = "ADD_GALLERIES_REQUEST_SUCCESS";
const ADD_GALLERIES_REQUEST_FAIL = "ADD_GALLERIES_REQUEST_FAIL";
const UPDATE_GALLERIES_REQUEST = "UPDATE_GALLERIES_REQUEST";
const UPDATE_GALLERIES_REQUEST_SUCCESS = "UPDATE_GALLERIES_REQUEST_SUCCESS";
const UPDATE_GALLERIES_REQUEST_FAIL = "UPDATE_GALLERIES_REQUEST_FAIL";
const DELETE_GALLERIES_REQUEST = "DELETE_GALLERIES_REQUEST";
const DELETE_GALLERIES_REQUEST_SUCCESS = "DELETE_GALLERIES_REQUEST_SUCCESS";
const DELETE_GALLERIES_REQUEST_FAIL = "DELETE_GALLERIES_REQUEST_FAIL";
const BULK_DELETE_GALLERIES_REQUEST = "BULK_DELETE_GALLERIES_REQUEST";
const BULK_DELETE_GALLERIES_REQUEST_SUCCESS = "BULK_DELETE_GALLERIES_REQUEST_SUCCESS";
const BULK_DELETE_GALLERIES_REQUEST_FAIL = "BULK_DELETE_GALLERIES_REQUEST_FAIL";
const REMOVE_ERROR = "REMOVE_ERROR";

const fetchGalleries = payload => ({
  type: GET_GALLERIES_REQUEST,
  payload,
});

const fetchGalleriesSuccess = payload => ({
  type: GET_GALLERIES_REQUEST_SUCCESS,
  payload,
});

const fetchGalleriesFail = payload => ({
  type: GET_GALLERIES_REQUEST_FAIL,
  error: payload
});

const fetchAllGalleries = payload => ({
  type: GET_ALL_GALLERIES_REQUEST,
  payload,
});

const fetchAllGalleriesSuccess = payload => ({
  type: GET_ALL_GALLERIES_REQUEST_SUCCESS,
  payload,
});

const fetchAllGalleriesFail = payload => ({
  type: GET_ALL_GALLERIES_REQUEST_FAIL,
  error: payload
});

const addGallery = payload => ({
  type: ADD_GALLERIES_REQUEST,
  payload,
});

const addGallerySuccess = payload => ({
  type: ADD_GALLERIES_REQUEST_SUCCESS,
  payload,
});

const addGalleryFail = payload => ({
  type: ADD_GALLERIES_REQUEST_FAIL,
  error: payload,
});

const updateGallery = payload => ({
  type: UPDATE_GALLERIES_REQUEST,
  payload,
});

const updateGallerySuccess = payload => ({
  type: UPDATE_GALLERIES_REQUEST_SUCCESS,
  payload,
});

const updateGalleryFail = payload => ({
  type: UPDATE_GALLERIES_REQUEST_FAIL,
  error: payload,
});

const deleteGallery = payload => ({
  type: DELETE_GALLERIES_REQUEST,
  payload,
});

const deleteGallerySuccess = payload => ({
  type: DELETE_GALLERIES_REQUEST_SUCCESS,
  payload,
});

const deleteGalleryFail = payload => ({
  type: DELETE_GALLERIES_REQUEST_FAIL,
  error: payload,
});

const bulkDeleteGalleries = payload => ({
  type: BULK_DELETE_GALLERIES_REQUEST,
  payload,
});

const bulkDeleteGalleriesSuccess = payload => ({
  type: BULK_DELETE_GALLERIES_REQUEST_SUCCESS,
  payload,
});

const bulkDeleteGalleriesFail = payload => ({
  type: BULK_DELETE_GALLERIES_REQUEST_FAIL,
  error: payload,
});

const removeError = () => ({
  type: REMOVE_ERROR,
});

export {
	GET_GALLERIES_REQUEST,
	GET_GALLERIES_REQUEST_SUCCESS,
  GET_GALLERIES_REQUEST_FAIL,
  GET_ALL_GALLERIES_REQUEST,
  GET_ALL_GALLERIES_REQUEST_SUCCESS,
  GET_ALL_GALLERIES_REQUEST_FAIL,
  ADD_GALLERIES_REQUEST,
  ADD_GALLERIES_REQUEST_FAIL,
  ADD_GALLERIES_REQUEST_SUCCESS,
  UPDATE_GALLERIES_REQUEST,
	UPDATE_GALLERIES_REQUEST_SUCCESS,
  UPDATE_GALLERIES_REQUEST_FAIL,
  DELETE_GALLERIES_REQUEST,
	DELETE_GALLERIES_REQUEST_SUCCESS,
  DELETE_GALLERIES_REQUEST_FAIL,
  BULK_DELETE_GALLERIES_REQUEST,
  BULK_DELETE_GALLERIES_REQUEST_SUCCESS,
  BULK_DELETE_GALLERIES_REQUEST_FAIL,
  REMOVE_ERROR,
	fetchGalleries,
  fetchGalleriesSuccess,
  fetchGalleriesFail,
	fetchAllGalleries,
  fetchAllGalleriesSuccess,
  fetchAllGalleriesFail,
	addGallery,
  addGallerySuccess,
  addGalleryFail,
	updateGallery,
  updateGallerySuccess,
  updateGalleryFail,
	deleteGallery,
  deleteGallerySuccess,
  deleteGalleryFail,
  bulkDeleteGalleries,
  bulkDeleteGalleriesSuccess,
  bulkDeleteGalleriesFail,
  removeError,
};

import React, { useEffect, useState } from "react";
import { withTheme } from "react-jsonschema-form";
import { cloneDeep } from "lodash";
import FormTheme from "components/custom-mui-components/FormTheme";
import HasPermission from "components/has-permissions/HasPermissions";
import createInputForRole from "components/input/customInputForPropertyRole";

const jp = require("jsonpath");

const Form = withTheme(FormTheme);

const ConfigForm = ({
  configForm,
  updateConfigData,
  envID,
  isGlobal,
  isTenant,
  currentTenant,
  maximumRequestBodySize,
}) => {
  const data = jp.query(configForm.data, configForm.selectedTreeItem.id)[0];
  const { schema } = configForm.selectedTreeItem;
  const [localSchema, setlocalSchema] = useState(schema);
  const uiSchemaEntries = Object.entries(schema.properties)
    // eslint-disable-next-line no-unused-vars,no-underscore-dangle
    .filter(([_, prop]) => prop.type === "string" && prop.__role)
    .map(([field, prop]) => [
      field,
      {
        // eslint-disable-next-line no-underscore-dangle
        "ui:widget": createInputForRole(prop.__role, maximumRequestBodySize),
      },
    ]);
  let uiSchema = Object.fromEntries(uiSchemaEntries);
  if (isTenant) {
    uiSchema = {
      cpu: {
        "ui:widget": "RangeWidget",
      },
      ram: {
        "ui:widget": "RangeWidget",
      },
    };
  }
  uiSchema = {
    ...uiSchema,
    allowedMarkets: {
      "ui:widget": "FreeTextSelectorWidget",
    },
  };
  useEffect(() => {
    setlocalSchema(schema);
    if (isTenant && currentTenant) {
      if (
        !jp.query(currentTenant, configForm.selectedTreeItem.id).length &&
        configForm.selectedTreeItem.id.includes("environments") &&
        configForm.selectedTreeItem.id.match(/\./g)?.length === 1
      )
        setlocalSchema({
          ...schema,
          properties: {
            ...schema.properties,
            type: {
              ...schema.properties.type,
              readOnly: false,
            },
          },
        });
      if (
        configForm.selectedTreeItem.id.includes("environments") &&
        configForm.selectedTreeItem.id.includes("deploymentInformation") &&
        configForm.selectedTreeItem.id.match(/\./g)?.length === 2
      ) {
        const envTenant = jp.query(
          currentTenant,
          configForm.selectedTreeItem.id.substring(
            0,
            configForm.selectedTreeItem.id.lastIndexOf(".")
          )
        )[0];
        setlocalSchema({
          ...schema,
          properties: {
            ...schema.properties,
            namespace: {
              ...schema.properties.namespace,
              readOnly: envTenant?.hasDeployedApps || false,
            },
            cluster: {
              ...schema.properties.cluster,
              readOnly: envTenant?.hasDeployedApps || false,
            },
          },
        });
      }
    }
    const cloneData = cloneDeep(configForm.data);
    if (!jp.query(cloneData, configForm.selectedTreeItem.id).length) {
      const splitPath = configForm.selectedTreeItem.id.split(".");
      jp.apply(
        cloneData,
        splitPath.slice(0, splitPath.length - 1).join("."),
        value => {
          return {
            ...value,
            [splitPath[splitPath.length - 1]]: {},
          };
        }
      );
      updateConfigData(cloneData);
    }
  }, [configForm.selectedTreeItem.id]);

  return (
    <HasPermission
      permissionKey={
        isTenant ? "edit_tenant" : `edit_configurations_env_${envID}`
      }
      disabled
      isGlobal={isGlobal}
    >
      <Form
        schema={localSchema}
        uiSchema={uiSchema}
        formData={data}
        showErrorList={false}
        onChange={formData => {
          if (configForm.selectedTreeItem.id.length > 2) {
            const cloneData = cloneDeep(configForm.data);
            jp.apply(cloneData, configForm.selectedTreeItem.id, () => {
              return formData.formData;
            });
            updateConfigData(cloneData);
          } else {
            updateConfigData(formData.formData);
          }
        }}
      >
        <span />
      </Form>
    </HasPermission>
  );
};

export default ConfigForm;

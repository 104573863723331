// eslint-disable-next-line import/prefer-default-export
export const handleErrorResponse = (responsePayload, defaultMessage) => {
  if (
    responsePayload &&
    responsePayload.response &&
    responsePayload.response.data &&
    responsePayload.response.data.message
  ) {
    const retError = { message: responsePayload.response.data.message };
    throw retError;
  }

  const returnError = {
    message: responsePayload.message || defaultMessage,
  };
  throw returnError;
};

import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "reducers";
import rootSaga from "sagas/rootSaga";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const initialState = {};

const configItem = localStorage.getItem("settings");
if (configItem) {
  initialState.settings = JSON.parse(configItem);
}

const authItem = localStorage.getItem("auth");
if (authItem) {
  initialState.authentication = JSON.parse(authItem);
}

const permissionsItem = localStorage.getItem("permissions");
if (permissionsItem) {
  initialState.userPermissions = JSON.parse(permissionsItem);
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;

import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  white: {
    backgroundColor: theme.palette.common.white,
  },
  fullWidth: {
    justifyContent: "space-between",
  },
});

const FilterMenuSorting = ({ handleFilters, fullWidth, color = "default", options }) => {
  const styles = useStyles();
  const [anchorElThemes, setAnchorElThemes] = useState(null);
  const [filterActive, setFilterActive] = useState("Sort by name");

  const handleClickSorting = event => {
    setAnchorElThemes(event.currentTarget);
  };
  const handleCloseSorting = () => {
    setAnchorElThemes(null);
  };
  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        size="small"
        className={classNames({
          [styles.white]: color === "white",
          [styles.fullWidth]: fullWidth,
        })}
        endIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
        onClick={handleClickSorting}
      >
        {filterActive}
      </Button>
      <Menu
        id="simple-menu"
        elevation={0}
        anchorEl={anchorElThemes}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorElThemes)}
        onClose={handleCloseSorting}
      >
        {options &&
          options.map(option => (
            <MenuItem
              data-name="theme"
              key={option.title}
              data-value={null}
              onClick={e => {
                handleCloseSorting();
                handleFilters(option.value);
                setFilterActive(option.title);
              }}
              selected={filterActive === option.title}
            >
              {option.title}
            </MenuItem>
          ))}
        {/* <MenuItem
          data-name="theme"
          data-value={null}
          onClick={e => {
            handleCloseSorting();
            handleFilters("name");
            setFilterActive("Sort by name");
          }}
          selected={filterActive === "Sort by name"}
        >
          Sort by name
        </MenuItem>
        <MenuItem
          selected={filterActive === "Sort by ID"}
          data-name="theme"
          data-value="MVA10"
          onClick={e => {
            handleCloseSorting();
            handleFilters("id");
            setFilterActive("Sort by ID");
          }}
        >
          Sort by ID
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default FilterMenuSorting;

import React, { useRef, useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { TextField, Paper, Popper, Fade, ClickAwayListener, Box, Typography, makeStyles } from "@material-ui/core";

import VfSvgIcon from "../icons/VfSvgIcon";
import { globalReduxSchema } from "./schema";
import { schemaToTreeForBpmn, constructConfigActionSchema, constructWidgetsReduxSlices } from "./ElementsUtils";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  popperPaper: {
    padding: theme.spacing(0, 2),
    maxHeight: theme.spacing(28),
    overflowY: "auto",
  },
});

const SelectTreeViewField = ({ element, selectedEffect, setSelectedEffect }) => {
  const selectRef = useRef();
  const styles = useStyles();
  const [expanded, setExpanded] = useState(["$.state"]);
  const [treeData, setTreeData] = useState([]);
  const [searchTreeData, setSearchTreeData] = useState([]);
  const [value, setValue] = useState("");
  const moveFocusToInput = () => {
    selectRef.current.focus();
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const search = (items, term) => {
    return items.reduce((acc, item) => {
      if (item.id.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
        const newExpaned = expanded;
        newExpaned.unshift(item.id);
        setExpanded(newExpaned);
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        const newItems = search(item.children, term);
        if (newItems && newItems.length > 0) {
          const newExpaned = expanded;
          newExpaned.unshift(item.id);
          setExpanded(newExpaned);
          acc.push({
            id: item.id,
            name: item.name,
            children: newItems,
          });
        }
      }
      return acc;
    }, []);
  };

  const appWidgets = useSelector(state => state.applications.current.appInfo.widgets, shallowEqual);

  const handleInputChange = e => {
    const term = e.target.value;
    if (!term) setSearchTreeData(treeData);
    setValue(term);
    const r = search(treeData, term);
    setSearchTreeData(r);
  };

  const handleClickNode = id => {
    setValue(id.substring(2));
  };

  const renderTree = node => {
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        label={<Typography variant="body2">{node.name}</Typography>}
        onClick={() => {
          handleClickNode(node.id);
          moveFocusToInput();
        }}
      >
        {Array.isArray(node.children) ? node.children.map(n => renderTree(n)) : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    if (selectedEffect?.effect === "select")
      setSelectedEffect(e => ({
        ...e,
        pickExpression: value,
      }));
  }, [value, selectedEffect?.effect]);

  useEffect(() => {
    if (!element) return;
    if (selectedEffect.effect === "select" && !treeData.length) {
      globalReduxSchema.properties = {
        ...globalReduxSchema.properties,
        ...constructWidgetsReduxSlices(element.widgetList, appWidgets),
      };
      globalReduxSchema.properties.config.properties.data.properties.widgets.properties = constructConfigActionSchema(
        element.widgetList,
        appWidgets
      );

      const tree = schemaToTreeForBpmn(globalReduxSchema, "$.state");
      setTreeData([tree]);
      setSearchTreeData([tree]);
      setValue(selectedEffect.pickExpression ? `state.${selectedEffect.pickExpression}` : "");
    }
  }, [element, selectedEffect]);
  return (
    <>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Box position="relative" zIndex={10}>
          <TextField
            label="Select"
            type="text"
            inputRef={selectRef}
            value={value}
            onChange={handleInputChange}
            variant="outlined"
            color="primary"
            fullWidth
            InputProps={{
              // onKeyDown: event => setAnchorEl(event.currentTarget),
              onFocus: event => setAnchorEl(event.currentTarget),
              // onBlur: () => setAnchorEl(null),
            }}
          />
          <Popper
            open={Boolean(anchorEl)}
            placement="bottom"
            disablePortal
            transition
            style={{
              left: 0,
              right: 0,
              position: "absolute",
              top: "100%",
              zIndex: 1,
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={3} className={styles.popperPaper}>
                  <TreeView
                    defaultCollapseIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
                    defaultExpandIcon={<VfSvgIcon icon="chevronRight" fontSize="small" />}
                    expanded={expanded}
                    onNodeToggle={(e, newValue) => setExpanded(newValue)}
                  >
                    {Array.isArray(treeData) ? searchTreeData.map(node => renderTree(node)) : null}
                  </TreeView>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default SelectTreeViewField;

/* eslint-disable camelcase */
import axios from "axios";

class PackagesService {
  fetchPackages = async ({ access_token, id_token, searchText }) => {
    const params = {
      searchText,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/packages`,
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch packages service:", err);
      throw err;
    }
  };
}
const inst = new PackagesService();
export default inst;

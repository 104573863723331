const GET_STOREGROUPS_REQUEST = "GET_STOREGROUPS_REQUEST";
const GET_STOREGROUPS_REQUEST_SUCCESS = "GET_STOREGROUPS_REQUEST_SUCCESS";
const GET_STOREGROUPS_REQUEST_FAIL = "GET_STOREGROUPS_REQUEST_FAIL";
const ADD_STOREGROUP_REQUEST = "ADD_STOREGROUP_REQUEST";
const ADD_STOREGROUP_REQUEST_SUCCESS = "ADD_STOREGROUP_REQUEST_SUCCESS";
const ADD_STOREGROUP_REQUEST_FAIL = "ADD_STOREGROUP_REQUEST_FAIL";
const UPDATE_STOREGROUP_REQUEST = "UPDATE_STOREGROUP_REQUEST";
const UPDATE_STOREGROUP_REQUEST_SUCCESS = "UPDATE_STOREGROUP_REQUEST_SUCCESS";
const UPDATE_STOREGROUP_REQUEST_FAIL = "UPDATE_STOREGROUP_REQUEST_FAIL";
const DELETE_STOREGROUP_REQUEST = "DELETE_STOREGROUP_REQUEST";
const DELETE_STOREGROUP_REQUEST_SUCCESS = "DELETE_STOREGROUP_REQUEST_SUCCESS";
const DELETE_STOREGROUP_REQUEST_FAIL = "DELETE_STOREGROUP_REQUEST_FAIL";
const BULK_DELETE_STOREGROUPS_REQUEST = "BULK_DELETE_STOREGROUPS_REQUEST";
const BULK_DELETE_STOREGROUPS_REQUEST_SUCCESS = "BULK_DELETE_STOREGROUPS_REQUEST_SUCCESS";
const BULK_DELETE_STOREGROUPS_REQUEST_FAIL = "BULK_DELETE_STOREGROUPS_REQUEST_FAIL";
const EXPORT_STOREGROUPS_REQUEST = "EXPORT_STOREGROUPS_REQUEST";
const EXPORT_STOREGROUPS_REQUEST_SUCCESS = "EXPORT_STOREGROUPS_REQUEST_SUCCESS";
const EXPORT_STOREGROUPS_REQUEST_FAIL = "EXPORT_STOREGROUPS_REQUEST_FAIL";
const REMOVE_ERROR = "REMOVE_ERROR";

const fetchStoreGroups = payload => ({
  type: GET_STOREGROUPS_REQUEST,
  payload,
});

const fetchStoreGroupsSuccess = payload => ({
  type: GET_STOREGROUPS_REQUEST_SUCCESS,
  payload,
});

const fetchStoreGroupsFail = error => ({
  type: GET_STOREGROUPS_REQUEST_FAIL,
  error,
});

const addStoreGroup = payload => ({
  type: ADD_STOREGROUP_REQUEST,
  payload,
});

const addStoreGroupSuccess = payload => ({
  type: ADD_STOREGROUP_REQUEST_SUCCESS,
  payload,
});

const addStoreGroupFail = error => ({
  type: ADD_STOREGROUP_REQUEST_FAIL,
  error,
});

const updateStoreGroup = payload => ({
  type: UPDATE_STOREGROUP_REQUEST,
  payload,
});

const updateStoreGroupSuccess = payload => ({
  type: UPDATE_STOREGROUP_REQUEST_SUCCESS,
  payload,
});

const updateStoreGroupFail = error => ({
  type: UPDATE_STOREGROUP_REQUEST_FAIL,
  error,
});

const deleteStoreGroup = payload => ({
  type: DELETE_STOREGROUP_REQUEST,
  payload,
});

const deleteStoreGroupSuccess = payload => ({
  type: DELETE_STOREGROUP_REQUEST_SUCCESS,
  payload,
});

const deleteStoreGroupFail = error => ({
  type: DELETE_STOREGROUP_REQUEST_FAIL,
  error,
});

const bulkDeleteStoreGroups = payload => ({
  type: BULK_DELETE_STOREGROUPS_REQUEST,
  payload,
});

const bulkDeleteStoreGroupsSuccess = payload => ({
  type: BULK_DELETE_STOREGROUPS_REQUEST_SUCCESS,
  payload,
});

const bulkDeleteStoreGroupsFail = error => ({
  type: BULK_DELETE_STOREGROUPS_REQUEST_FAIL,
  error,
});

const removeError = () => ({
  type: REMOVE_ERROR,
});

export {
  GET_STOREGROUPS_REQUEST,
  GET_STOREGROUPS_REQUEST_FAIL,
  GET_STOREGROUPS_REQUEST_SUCCESS,
  ADD_STOREGROUP_REQUEST,
  ADD_STOREGROUP_REQUEST_FAIL,
  ADD_STOREGROUP_REQUEST_SUCCESS,
  UPDATE_STOREGROUP_REQUEST,
  UPDATE_STOREGROUP_REQUEST_FAIL,
  UPDATE_STOREGROUP_REQUEST_SUCCESS,
  DELETE_STOREGROUP_REQUEST,
  DELETE_STOREGROUP_REQUEST_FAIL,
  DELETE_STOREGROUP_REQUEST_SUCCESS,
  BULK_DELETE_STOREGROUPS_REQUEST,
  BULK_DELETE_STOREGROUPS_REQUEST_FAIL,
  BULK_DELETE_STOREGROUPS_REQUEST_SUCCESS,
  EXPORT_STOREGROUPS_REQUEST,
  EXPORT_STOREGROUPS_REQUEST_FAIL,
  EXPORT_STOREGROUPS_REQUEST_SUCCESS,
  REMOVE_ERROR,
  fetchStoreGroups,
  fetchStoreGroupsSuccess,
  fetchStoreGroupsFail,
  addStoreGroup,
  addStoreGroupSuccess,
  addStoreGroupFail,
  updateStoreGroup,
  updateStoreGroupSuccess,
  updateStoreGroupFail,
  deleteStoreGroup,
  deleteStoreGroupSuccess,
  deleteStoreGroupFail,
  bulkDeleteStoreGroups,
  bulkDeleteStoreGroupsSuccess,
  bulkDeleteStoreGroupsFail,
  removeError,
};

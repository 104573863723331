/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_GALLERIES_REQUEST,
  GET_ALL_GALLERIES_REQUEST,
  ADD_GALLERIES_REQUEST,
  UPDATE_GALLERIES_REQUEST,
  DELETE_GALLERIES_REQUEST,
  BULK_DELETE_GALLERIES_REQUEST,
  fetchGalleriesSuccess,
  fetchGalleriesFail,
  fetchAllGalleriesSuccess,
  fetchAllGalleriesFail,
  addGallerySuccess,
  addGalleryFail,
  updateGallerySuccess,
  updateGalleryFail,
  deleteGallerySuccess,
  deleteGalleryFail,
  bulkDeleteGalleriesSuccess,
  bulkDeleteGalleriesFail,
} from "actions/galleryActions";
import GalleryService from "services/GalleryService";

function* getGalleriesWorker({ payload }) {
  try {
    const data = yield call(GalleryService.fetch, payload);
    yield put(fetchGalleriesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(fetchGalleriesFail(error));
  }
}

function* getGalleriesWatcher() {
  yield takeLatest(GET_GALLERIES_REQUEST, getGalleriesWorker);
}

function* getAllGalleriesWorker({ payload }) {
  try {
    const data = yield call(GalleryService.fetchAll, payload);
    yield put(fetchAllGalleriesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(fetchAllGalleriesFail(error));
  }
}

function* getAllGalleriesWatcher() {
  yield takeLatest(GET_ALL_GALLERIES_REQUEST, getAllGalleriesWorker);
}

function* addGalleriesWorker({ payload }) {
  try {
    const data = yield call(GalleryService.add, payload);
    yield put(addGallerySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addGalleryFail(error));
  }
}

function* addGalleriesWatcher() {
  yield takeLatest(ADD_GALLERIES_REQUEST, addGalleriesWorker);
}

function* updateGalleriesWorker({ payload }) {
  try {
    const data = yield call(GalleryService.update, payload);
    yield put(updateGallerySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(updateGalleryFail(error));
  }
}

function* updateGalleriesWatcher() {
  yield takeLatest(UPDATE_GALLERIES_REQUEST, updateGalleriesWorker);
}

function* deleteGalleriesWorker({ payload }) {
  try {
    const data = yield call(GalleryService.delete, payload);
    yield put(deleteGallerySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(deleteGalleryFail(error));
  }
}

function* deleteGalleriesWatcher() {
  yield takeLatest(DELETE_GALLERIES_REQUEST, deleteGalleriesWorker);
}

function* bulkDeleteGalleriesWorker({ payload }) {
  try {
    const data = yield call(GalleryService.bulkDelete, payload);
    yield put(bulkDeleteGalleriesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(bulkDeleteGalleriesFail(error));
  }
}

function* bulkDeleteGalleriesWatcher() {
  yield takeLatest(BULK_DELETE_GALLERIES_REQUEST, bulkDeleteGalleriesWorker);
}

const gallerySaga = [
  getGalleriesWatcher(),
  getAllGalleriesWatcher(),
  addGalleriesWatcher(),
  updateGalleriesWatcher(),
  deleteGalleriesWatcher(),
  bulkDeleteGalleriesWatcher(),
];

export default gallerySaga;

import {
  GET_PASSCODES_REQUEST,
  GET_PASSCODES_REQUEST_SUCCESS
} from "actions/passcodeActions";

const initialState = {
  loading: false,
  stores: [],
  storeItems: 1
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PASSCODES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PASSCODES_REQUEST_SUCCESS: {
      return {
        ...state,
        stores: payload.stores,
        storeItems: payload.count,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const NEXUS_WIDGETS_REQUEST = "NEXUS_WIDGETS_REQUEST";
const NEXUS_WIDGETS_REQUEST_SUCCESS = "NEXUS_WIDGETS_REQUEST_SUCCESS";
const NEXUS_WIDGETS_REQUEST_FAIL = "NEXUS_WIDGETS_REQUEST_FAIL";
const SET_NEXUS_WIDGET = "SET_NEXUS_WIDGETS";
const CLEAR_NEXUS_WIDGET = "CLEAR_NEXUS_WIDGET";
const CLEAR_NEXUS_WIDGETS_LIST = "CLEAR_NEXUS_WIDGETS_LIST";
const SET_NEXUS_WIDGETS_VIEW = "SET_NEXUS_WIDGETS_VIEW";
const NEXUS_WIDGETS_CATEGORIES_REQUEST = "NEXUS_WIDGETS_CATEGORIES_REQUEST";
const NEXUS_WIDGETS_CATEGORIES_REQUEST_SUCCESS =
  "NEXUS_WIDGETS_CATEGORIES_REQUEST_SUCCESS";
const NEXUS_WIDGETS_CATEGORIES_REQUEST_FAIL =
  "NEXUS_WIDGETS_CATEGORIES_REQUEST_FAIL";
const NEXUS_WIDGETS_VERSIONS_REQUEST = "NEXUS_WIDGETS_VERSIONS_REQUEST";
const NEXUS_WIDGETS_VERSIONS_REQUEST_SUCCESS =
  "NEXUS_WIDGETS_VERSIONS_REQUEST_SUCCESS";
const NEXUS_WIDGETS_VERSIONS_REQUEST_FAIL =
  "NEXUS_WIDGETS_VERSIONS_REQUEST_FAIL";
const CLEAR_NEXUS_WIDGET_VERSIONS = "CLEAR_NEXUS_WIDGET_VERSIONS";
const GET_NEXUS_WIDGET_BY_ID_REQUEST = "GET_NEXUS_WIDGET_BY_ID_REQUEST";
const GET_NEXUS_WIDGET_BY_ID_REQUEST_SUCCESS =
  "GET_NEXUS_WIDGET_BY_ID_REQUEST_SUCCESS";
const GET_NEXUS_WIDGET_BY_ID_REQUEST_FAIL =
  "GET_NEXUS_WIDGET_BY_ID_REQUEST_FAIL";
const UPDATE_NEXUS_WIDGET_REQUEST = "UPDATE_NEXUS_WIDGET_REQUEST";
const UPDATE_NEXUS_WIDGET_REQUEST_SUCCESS =
  "UPDATE_NEXUS_WIDGET_REQUEST_SUCCESS";
const UPDATE_NEXUS_WIDGET_REQUEST_FAIL = "UPDATE_NEXUS_WIDGET_REQUEST_FAIL";

const fetchNexusListWidgets = payload => ({
  type: NEXUS_WIDGETS_REQUEST,
  payload,
});

const setNexusWidget = payload => ({
  type: SET_NEXUS_WIDGET,
  payload,
});

const setNexusWidgetView = payload => ({
  type: SET_NEXUS_WIDGETS_VIEW,
  payload,
});

const clearNexusWidget = () => ({
  type: CLEAR_NEXUS_WIDGET,
});

const clearNexusWidgetsList = () => ({
  type: CLEAR_NEXUS_WIDGETS_LIST,
});

const fetchNexusWidgetsCategories = payload => ({
  type: NEXUS_WIDGETS_CATEGORIES_REQUEST,
  payload,
});

const fetchNexusWidgetsVersions = payload => ({
  type: NEXUS_WIDGETS_VERSIONS_REQUEST,
  payload,
});

const clearNexusWidgetsVersions = () => ({
  type: CLEAR_NEXUS_WIDGET_VERSIONS,
});

const fetchNexusWidgetById = payload => ({
  type: GET_NEXUS_WIDGET_BY_ID_REQUEST,
  payload,
});

const updateNexusWidget = payload => ({
  type: UPDATE_NEXUS_WIDGET_REQUEST,
  payload,
});

export {
  NEXUS_WIDGETS_REQUEST,
  NEXUS_WIDGETS_REQUEST_SUCCESS,
  NEXUS_WIDGETS_REQUEST_FAIL,
  SET_NEXUS_WIDGET,
  CLEAR_NEXUS_WIDGET,
  CLEAR_NEXUS_WIDGETS_LIST,
  SET_NEXUS_WIDGETS_VIEW,
  NEXUS_WIDGETS_CATEGORIES_REQUEST,
  NEXUS_WIDGETS_CATEGORIES_REQUEST_SUCCESS,
  NEXUS_WIDGETS_CATEGORIES_REQUEST_FAIL,
  NEXUS_WIDGETS_VERSIONS_REQUEST,
  NEXUS_WIDGETS_VERSIONS_REQUEST_SUCCESS,
  NEXUS_WIDGETS_VERSIONS_REQUEST_FAIL,
  CLEAR_NEXUS_WIDGET_VERSIONS,
  GET_NEXUS_WIDGET_BY_ID_REQUEST,
  GET_NEXUS_WIDGET_BY_ID_REQUEST_SUCCESS,
  GET_NEXUS_WIDGET_BY_ID_REQUEST_FAIL,
  UPDATE_NEXUS_WIDGET_REQUEST,
  UPDATE_NEXUS_WIDGET_REQUEST_SUCCESS,
  UPDATE_NEXUS_WIDGET_REQUEST_FAIL,
  fetchNexusListWidgets,
  setNexusWidget,
  clearNexusWidget,
  clearNexusWidgetsList,
  setNexusWidgetView,
  fetchNexusWidgetsCategories,
  fetchNexusWidgetsVersions,
  clearNexusWidgetsVersions,
  fetchNexusWidgetById,
  updateNexusWidget,
};

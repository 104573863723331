import axios from "axios";

export default {
  setupInterceptors: store => {
    // Add a response interceptor
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          // handle error: inform user, go to login, etc
          store.dispatch({ type: "LOGOUT" });
        }
        if (error.response.status === 403) {
          // handle error: inform user, go to login, etc
          store.dispatch({ type: "LOGOUT" });
          store.dispatch({
            type: "LOGOUT_MESSAGE",
            payload: "Requisite permissions are missing",
          });
        }
        return Promise.reject(error);
      }
    );
  },
};

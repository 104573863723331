import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { IconButton } from "@material-ui/core";
import VfSvgIcon from "../../icons/VfSvgIcon";
import VfTooltip from "../../vf/VfTooltip";
import HasPermission from "../../has-permissions/HasPermissions";

const AppSummary = ({
  appTitle,
  appType,
  appPlatform,
  appTheme,
  appTags,
  appVersion,
  unmanaged,
  hasAuth,
  onEditApp,
}) => {
  const AppDescription = () => {
    return (
      <Grid item xs="auto">
        <Typography variant="body2" color="textSecondary">
          App Description
        </Typography>
      </Grid>
    );
  };

  const AppType = () => {
    return (
      <Grid item xs="auto">
        <VfSvgIcon
          icon={appType}
          viewBox={24}
          className="MuiSvgIcon-fontSizeXSmall"
        />
      </Grid>
    );
  };

  const AppPlatform = () => {
    return (
      <Grid item xs="auto">
        <VfSvgIcon
          icon={appPlatform}
          viewBox={24}
          className="MuiSvgIcon-fontSizeXSmall"
        />
      </Grid>
    );
  };

  const AppAuth = () => {
    return (
      <Grid item xs="auto">
        <VfTooltip title="Azure authentication">
          <VfSvgIcon
            icon="lock"
            viewBox={24}
            className="MuiSvgIcon-fontSizeXSmall"
          />
        </VfTooltip>
      </Grid>
    );
  };

  const AppTheme = () => {
    return (
      <Grid item xs="auto">
        <Typography variant="body2" color="textSecondary">
          {appTheme === "MVA10" ? "WS10" : appTheme}
        </Typography>
      </Grid>
    );
  };

  const AppUnmanaged = () => {
    return (
      <Grid item xs="auto">
        <Typography variant="body2" color="secondary">
          Unmanaged
        </Typography>
      </Grid>
    );
  };

  const AppTags = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {appTags ? appTags.join(", ") : ""}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const AppVersion = () => {
    if (!appVersion) {
      return null;
    }
    return (
      <Grid item xs="auto">
        <Typography variant="body1" component="h5" color="secondary">
          {appVersion}
        </Typography>
      </Grid>
    );
  };

  const AppTitle = () => {
    return (
      <Grid item xs="auto">
        <Typography variant="body1" component="h5">
          {appTitle || "App Name"}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item xs>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <AppTitle />
            <AppVersion />
            {onEditApp && (
              <HasPermission permissionKey="edit_application">
                <Grid item>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={onEditApp}
                  >
                    <VfSvgIcon icon="edit" fontSize="small" viewBox={24} />
                  </IconButton>
                </Grid>
              </HasPermission>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            {!appType && <AppDescription />}
            {appType && <AppType />}
            {appPlatform && <AppPlatform />}
            {hasAuth && <AppAuth />}
            {appTheme && <AppTheme />}
            {unmanaged && <AppUnmanaged />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {appTags.length > 0 && <AppTags />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppSummary;

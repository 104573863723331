/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from "react";
import { Grid, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";

const tags = ["tag 1", "tag 2", "tag 3"];

const AppsPageAdd = ({ setPageValues, page, application }) => {
  const { appInfo } = application;

  const onTagsChange = (event, values) => {
    setPageValues({ ...page, tags: values });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setPageValues({ ...page, [name]: value });
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setPageValues({ ...page, [name]: checked });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          name="title"
          label="Name"
          fullWidth
          variant="outlined"
          onChange={handleInputChange}
          value={page.title}
        />
      </Grid>
      {appInfo.hasAuth && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="private"
                onChange={handleCheckboxChange}
                checked={page.private}
              />
            }
            label="Authenticated"
            labelPlacement="end"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              name="isMicroFrontend"
              color="primary"
              checked={page.isMicroFrontend}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="Is Micro Frontend Page"
          labelPlacement="end"
        />
      </Grid>
      {page.isMicroFrontend && (
        <Grid item xs={12}>
          <TextField
            name="baseRoute"
            label="* /baseRoute"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
            value={page.baseRoute}
            helperText="The URL path for this page, should be unique and follow the pattern /[a-zA-Z0-9]+ Ex: '/example'"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          name="description"
          label="Description (Optional)"
          fullWidth
          variant="outlined"
          onChange={handleInputChange}
          value={page.description}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          freeSolo
          id="tags-outlined"
          onChange={onTagsChange}
          options={tags}
          value={page.tags}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Tags (Optional)"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AppsPageAdd;

import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  white: {
    backgroundColor: theme.palette.common.white,
  },
  fullWidth: {
    justifyContent: "space-between",
  },
});

const FilterTextField = ({ value, action, fullWidth, type, label }) => {
  const styles = useStyles();
  return (
    <TextField
      label={label}
      type={type}
      fullWidth={fullWidth}
      onChange={e => action(e.target.value)}
      variant="outlined"
      color="primary"
      className={classNames({
        [styles.fullWidth]: fullWidth,
      })}
      value={value}
      size="small"
    />
  );
};

export default FilterTextField;

/* eslint-disable camelcase */
import axios from "axios";

class StoreGroupService {
  fetch = async ({ access_token, id_token, search, page, pageSize, sort }) => {
    const params = {
      search,
      page,
      pageSize,
      sort,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/storeGroup/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in fetch stores groups service:", err);
      return err;
    }
  };

  add = async ({ access_token, id_token, name, stores }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/portal/storeGroup/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: { name, stores },
      });
      return data;
    } catch (err) {
      console.log("Error in add store group service:", err);
      return err;
    }
  };

  update = async ({ access_token, id_token, name, stores, _id }) => {
    try {
      const { data } = await axios({
        method: "patch",
        url: `/api/appbuilder/portal/storeGroup/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: { name, stores },
      });
      return data;
    } catch (err) {
      console.log("Error in update store group service:", err);
      return err;
    }
  };

  delete = async ({ access_token, id_token, _id }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/storeGroup/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in delete store group service:", err);
      return err;
    }
  };

  bulkDelete = async ({ access_token, id_token, groups }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/storeGroup/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: groups,
      });
      return data;
    } catch (err) {
      console.log("Error in bulk delete store groups service:", err);
      return err;
    }
  };

  export = async ({ access_token, id_token, search, sort }) => {
    const params = {
      search,
      sort,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/storeGroup/export`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in export stores groups service:", err);
      return err;
    }
  };
}

export default new StoreGroupService();

import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const FreeTextSelectorWidget = ({ schema, label, disabled, onChange, value: val }) => {
  const [value, setValue] = useState(val ? val.split(",") : []);
  useEffect(() => onChange(value.toString()), [value]);
  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        value={value}
        options={[]}
        onChange={(e, newValue) => setValue([...newValue])}
        filterSelectedOptions
        freeSolo
        renderInput={params => (
          <TextField {...params} variant="outlined" label={label || schema.title} placeholder={schema.placeholder} />
        )}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default FreeTextSelectorWidget;

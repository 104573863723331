import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Box, Button, Card, CardContent, Container, Divider, Grid, InputBase, TextField, Typography, MenuItem, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import VfDialogFull from "../../vf/VfDialogFull";
import theme from "../../../AppTheme";
import CardStoreList from "../cards/CardStoreList";
import VfSvgIcon from "../../icons/VfSvgIcon";
import { addCampaign, updateCampaigns } from "actions/campaignActions";

const useStyles = makeStyles({
  cardContent: {
    padding: theme.spacing(3),
  },
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "none",
      position: "relative",
    },
  },
});

const AppBar = ({campaign}) => {
  const styles = useStyles();

  return (
    <Grid container alignItems="center" spacing={6} justify="space-between">
      <Grid item xs="auto">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">{campaign?.title}</Typography>
          </Grid>
          {campaign?.gallery && (
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Gallery:
                {campaign?.gallery?.title}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs="auto">
            <Typography variant="body2">{campaign?.date}</Typography>
          </Grid>
          <Grid item xs="auto">
            {campaign?.priority && (
              <Badge
                className={styles.badge}
                badgeContent={campaign?.priority}
                color={campaign?.priority === 1 ? "secondary" : "default"}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const GalleryPanel = ({selectedGallery, allGalleries, setSelectedGallery}) => {
  const styles = useStyles();

  return (
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardContent className={styles.cardContent}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">Select gallery</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Gallery name"
                variant="filled"
                value={selectedGallery}
                color="primary"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  className: classNames("MuiFilledInput-disableUnderline"),
                }}
              >
                <MenuItem value="search" button={false}>
                  <InputBase placeholder="Search gallery name" fullWidth />
                </MenuItem>
                <Divider orientation="horizontal" />
                {allGalleries &&
                  allGalleries.map(gallery => (
                    <MenuItem
                      key={gallery._id}
                      value={gallery}
                      onClick={() => {
                        setSelectedGallery(gallery);
                      }}
                      key={gallery._id}
                    >
                      <Typography variant="body1">{gallery.title}</Typography>
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const DistributionPanel = ({distArray, everythingArray, selectedDistribution, setDistArray, setSelectedDistribution}) => {
  const styles = useStyles();
  const filterEverythingArray = everythingArray?.filter(x => !distArray.find(y => y._id === x._id)) ?? [];

  return (
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardContent className={styles.cardContent}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">Set distribution</Typography>
            </Grid>
            {distArray &&
              distArray.map(dist => (
                <Grid key={dist._id} item xs={12}>
                  <CardStoreList
                    name={dist.name}
                    code=""
                    deleteAction={() => {
                      setDistArray(distArray.filter(x => x._id !== dist._id));
                    }}
                  />
                </Grid>
              ))}

            <Grid item xs={12}>
              <TextField
                select
                label="Select store"
                variant="filled"
                color="primary"
                value={filterEverythingArray?.find(({_id}) => _id === selectedDistribution?._id) ?? ''}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  className: classNames("MuiFilledInput-disableUnderline"),
                }}
              >
                <MenuItem value="search" button={false}>
                  <InputBase placeholder="Search store name or ID" fullWidth />
                </MenuItem>
                <Divider orientation="horizontal" />
                {filterEverythingArray &&
                  filterEverythingArray
                    .map(item => (
                      <MenuItem key={item._id} value={item} onClick={() => setSelectedDistribution(item)}>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justify="space-between"
                          wrap="nowrap"
                        >
                          <Grid item xs="auto">
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs="auto">
                                <Typography variant="body1">{item.name}</Typography>
                              </Grid>
                              <Grid item xs="auto">
                                <Typography variant="body1" color="secondary">
                                  {item?.ident}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                disabled={!selectedDistribution?.name}
                onClick={() => setDistArray(distArray.concat([selectedDistribution]))}
              >
                Add item
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const DialogCampaign = ({ open, setOpen, campaign }) => {
  const { allGalleries } = useSelector(state => state.galleryReducer, shallowEqual);
  const { allStores, allDevices, allStoreGroups } = useSelector(state => state.storeReducer, shallowEqual);

  const [selectedGallery, setSelectedGallery] = useState(
    campaign && allGalleries.filter(x => campaign?.gallery?._id === x._id).length === 1
      ? allGalleries.filter(x => campaign?.gallery?._id === x._id)[0]
      : {}
  );

  const [title, setTitle] = useState(campaign ? campaign.title : "");
  const [priority, setPriority] = useState(campaign ? campaign.priority : 0);
  const dispatch = useDispatch();
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    campaign && campaign.validFor && campaign?.validFor?.end ? campaign.validFor.end : null
  );
  const styles = useStyles();
  const [everythingArray, setEverythingArray] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    campaign && campaign.validFor && campaign?.validFor?.start ? campaign.validFor.start : new Date()
  );
  const [selectedDistribution, setSelectedDistribution] = useState({});
  const [distArray, setDistArray] = useState([]);
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;

  useEffect(() => {
    const distArray = allDevices
      .map(x => ({ name: x.name, _id: x._id, type: "device", ident: x.code }))
      ?.concat(
        allStores.map(x => ({
          name: x.name,
          _id: x._id,
          type: "store",
          ident: x.storeId,
        }))
      )
      ?.concat(
        allStoreGroups.map(x => ({
          name: x.name,
          _id: x._id,
          type: "storeGroup",
        }))
      );
    setEverythingArray(distArray);
  }, [allDevices, allStores, allStoreGroups]);

  useEffect(() => {
    if (open) {
      if (!campaign?._id) return;
      const storeArray =
        campaign && campaign.stores
          ? campaign.stores.map(x => ({
              name: x.name,
              _id: x._id,
              ident: x.storeId,
              type: "store",
            }))
          : [];
      const devicesArray =
        campaign && campaign.devices
          ? campaign.devices.map(x => ({
              name: x.name,
              ident: x.code,
              _id: x._id,
              type: "device",
            }))
          : [];
      const storeGroupsArray =
        campaign && campaign.storeGroups
          ? campaign.storeGroups.map(x => ({
              name: x.name,
              _id: x._id,
              type: "storeGroup",
            }))
          : [];
      setTitle(campaign ? campaign.title : "");
      setPriority(campaign?.priority);
      setSelectedGallery(
        campaign && allGalleries.filter(x => campaign?.gallery?._id === x?._id).length === 1
          ? allGalleries.filter(x => campaign.gallery._id === x._id)[0]
          : {}
      );
      setDistArray(storeGroupsArray.concat(storeArray).concat(devicesArray));
    }
  }, [open]);

  const clearAllAndClose = () => {
    setDistArray([]);
    setTitle("");
    setPriority();
    setSelectedGallery({});
    setSelectedDate(new Date());
    setSelectedDateEnd(null);
    setSelectedDistribution({});
    setOpen(false);
  };

  const confirmAction = () => {
    if (campaign?._id) {
      dispatch(
        updateCampaigns({
          access_token,
          id_token,
          campaign: {
            _id: campaign._id,
            title,
            priority,
            gallery: selectedGallery,
            validFor: {
              start: selectedDate,
              end: selectedDateEnd,
            },
            stores: distArray.filter(x => x.type === "store"),
            devices: distArray.filter(x => x.type === "device"),
            storeGroups: distArray.filter(x => x.type === "storeGroup"),
          },
        })
      );
    } else {
      dispatch(
        addCampaign({
          access_token,
          id_token,
          campaign: {
            title,
            priority,
            gallery: selectedGallery,
            validFor: {
              start: selectedDate,
              end: selectedDateEnd,
            },
            stores: distArray.filter(x => x.type === "store"),
            devices: distArray.filter(x => x.type === "device"),
            storeGroups: distArray.filter(x => x.type === "storeGroup"),
          },
        })
      );
    }
  };

  return (
    <VfDialogFull
      title="Dialog"
      openDialog={open}
      buttonCloseAction={clearAllAndClose}
      buttonConfirmAction={clearAllAndClose}
      buttonConfirmText="Save"
      saveAction={confirmAction}
      buttonConfirmDisabled={!title || !selectedDate || !selectedGallery?._id || distArray.length === 0}
      /* eslint-disable */
      appBar={<AppBar campaign={campaign} />}
      /* eslint-enable */
    >
      <Box py={6}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Card elevation={0}>
                <CardContent className={styles.cardContent}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1">Enter campaign name</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={title}
                        label="Name"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onChange={e => {
                          setTitle(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Select dates</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label="Dates"
                          format="dd/MM/yyyy"
                          value={selectedDate}
                          InputAdornmentProps={{ position: "end" }}
                          keyboardIcon={<VfSvgIcon icon="calendar" />}
                          leftArrowIcon={<VfSvgIcon icon="chevronLeft" />}
                          rightArrowIcon={<VfSvgIcon icon="chevronRight" />}
                          onChange={date => setSelectedDate(date)}
                          disableToolbar
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label="Dates"
                          format="dd/MM/yyyy"
                          value={selectedDateEnd}
                          InputAdornmentProps={{ position: "end" }}
                          keyboardIcon={<VfSvgIcon icon="calendar" />}
                          leftArrowIcon={<VfSvgIcon icon="chevronLeft" />}
                          rightArrowIcon={<VfSvgIcon icon="chevronRight" />}
                          onChange={date => setSelectedDateEnd(date)}
                          disableToolbar
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Set priority</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Priority"
                        value={priority}
                        type="number"
                        variant="outlined"
                        color="primary"
                        onChange={e => setPriority(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container spacing={3}>
                <GalleryPanel
                  selectedGallery={selectedGallery}
                  allGalleries={allGalleries}
                  setSelectedGallery={setSelectedGallery}
                />
                <DistributionPanel
                  distArray={distArray}
                  everythingArray={everythingArray}
                  selectedDistribution={selectedDistribution}
                  setDistArray={setDistArray}
                  setSelectedDistribution={setSelectedDistribution}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </VfDialogFull>
  );
};

export default DialogCampaign;

import React from "react";
import { Card, CardActionArea, Avatar, CardContent, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import theme from "../../../AppTheme";
import VfSvgIcon from "../../icons/VfSvgIcon";

const useStyles = makeStyles({
  avatar: {
    marginBottom: theme.spacing(1),
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.grey[300],
    "& .MuiSvgIcon-root": {
      marginBottom: "0 !important",
    },
  },
});

const CardMediaAdd = ({ addAction }) => {
  const styles = useStyles();

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-dropzone": true,
        "MuiCard-media": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-rounded": true,
      })}
    >
      <CardActionArea onClick={() => addAction()}>
        <CardContent>
          <Avatar className={styles.avatar}>
            <VfSvgIcon color="secondary" icon="plus" fontSize="large" />
          </Avatar>
          <Typography variant="body2">Add files</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardMediaAdd;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { uniqBy } from "lodash";

import ShowSwaggerDialog from "./ShowSwaggerDialog";
import theme from "../../AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  box: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  card: {},
  list: {
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    backgroundColor: "#f0f0f0",
    color: "#f00e0e",
  },
  notAdded: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    backgroundColor: "#fbfbfb",
  },
  secondaryAction: {
    right: 0,
  },
});

export default function SwaggerCard({
  env,
  setShowAddSwaggerDialog,
  setSelectedEnv,
  deleteVersion,
  dispatch,
  files,
  apiInfo,
  auth,
}) {
  const classes = useStyles();
  const [displaySwaggerFile, setDisplaySwaggerFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const uniqueFileIds = uniqBy(apiInfo.files, "version").map(
    file => file.version
  );

  return (
    <>
      <Card
        elevation={0}
        className={classNames(
          {
            "MuiCard-form": true,
            "MuiCard-white": true,
            "MuiCard-outlined": false,
            "MuiCard-rounded": true,
          },
          classes.card
        )}
      >
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Box>
                <Box className={classes.box}>
                  <VfSvgIcon
                    className={classes.icon}
                    icon={env.title.toLowerCase()}
                    viewBox={24}
                    fontSize="large"
                  />
                  <Typography variant="body1" component="h5">
                    {env.title}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setShowAddSwaggerDialog(true);
                    setSelectedEnv(env);
                  }}
                >
                  <VfSvgIcon icon="plus" viewBox={24} />
                </IconButton>
              </Box>
            </Grid>
          }
        />
        <CardContent>
          {apiInfo.files.length ? (
            <List dense className={classes.list}>
              {uniqueFileIds.map(version => {
                const found = files.find(file => file.version === version);
                return found ? (
                  <ListItem
                    key={found.fileId}
                    button
                    className={classes.listItem}
                    onClick={() => {
                      setSelectedFile(found);
                      setDisplaySwaggerFile(true);
                    }}
                  >
                    <ListItemText primary={found.fileId} />
                    <ListItemSecondaryAction
                      className={classes.secondaryAction}
                    >
                      <IconButton
                        onClick={() => {
                          return found.unsaved
                            ? dispatch({
                                type: "removeFile",
                                payload: {
                                  fileId: found.fileId,
                                  envId: env._id,
                                },
                              })
                            : deleteVersion({
                                access_token: auth?.accessToken,
                                id_token: auth?.idToken,
                                apiId: apiInfo?._id,
                                envId: env?._id,
                                versionName: found.version,
                              });
                        }}
                      >
                        <VfSvgIcon
                          icon="delete"
                          viewBox={24}
                          fontSize="small"
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : (
                  <ListItem key={version} button className={classes.notAdded}>
                    <ListItemText primary={`v${version} is not added yet`} />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Typography variant="body2" component="h6">
              No swagger is added yet
            </Typography>
          )}
        </CardContent>
      </Card>
      {displaySwaggerFile && (
        <ShowSwaggerDialog
          show={displaySwaggerFile}
          setShow={setDisplaySwaggerFile}
          env={env}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}
    </>
  );
}

const GET_PASSCODES_REQUEST = "GET_PASSCODES_REQUEST";
const GET_PASSCODES_REQUEST_SUCCESS = "GET_PASSCODES_REQUEST_SUCCESS";
const GET_PASSCODES_REQUEST_FAIL = "GET_PASSCODES_REQUEST_FAIL";

const fetchPasscodes = payload => ({
  type: GET_PASSCODES_REQUEST,
  payload,
});

const fetchPasscodesSuccess = payload => ({
  type: GET_PASSCODES_REQUEST_SUCCESS,
  payload,
});

const fetchPasscodesFail = error => ({
  type: GET_PASSCODES_REQUEST_FAIL,
  error,
});

export {
  GET_PASSCODES_REQUEST,
  GET_PASSCODES_REQUEST_SUCCESS,
  GET_PASSCODES_REQUEST_FAIL,
  fetchPasscodes,
  fetchPasscodesSuccess,
  fetchPasscodesFail,
};

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Container, Fab, Snackbar } from "@material-ui/core";

import {
  fetchApis,
  showCreateApiDialog,
  deleteApi,
  fetchApiDetails,
  toggleDeleteApiIsUsedPopUp,
} from "actions/apisActions";
import SearchBar from "components/searchbar/SearchBar";
import Heading from "../headings/Heading";
import CardApi from "./CardApi";
import AddApiDialog from "./AddApiDialog";
import Loader from "../loader/Loader";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfAlert from "../vf/VfAlert";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 100,
    },
  },
  fab: {
    position: "absolute",
    top: -theme.spacing(8),
    right: theme.spacing(3),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
  search: {
    paddingBottom: theme.spacing(4),
  },
});

export default function Apis() {
  const classes = useStyles();

  const { locale } = useParams();

  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;
  const {
    loading,
    show,
    list: apisList,
    error,
    deleteApiFailData,
  } = useSelector(state => state.apis, shallowEqual);
  const envs = useSelector(state => state.envs.list, shallowEqual);

  const dispatch = useDispatch();
  const getApis = params => dispatch(fetchApis(params));
  const removeApi = params => dispatch(deleteApi(params));
  const getApiDetails = params => dispatch(fetchApiDetails(params));
  const showApisDialog = payload => dispatch(showCreateApiDialog(payload));
  const togglePopUp = () => dispatch(toggleDeleteApiIsUsedPopUp());

  useEffect(() => {
    getApis({ access_token, id_token });
  }, []);

  return (
    <main className={classes.root}>
      <Heading title="Api Management" backUrl={`/${locale}/landing`} />
      <Box pt={8} pb={4}>
        <Container className={classes.container} maxWidth="md">
          <Grid item xs={12} className={classes.search}>
            <SearchBar
              callback={getApis}
              callbackParams={{
                access_token,
                id_token,
              }}
            />
          </Grid>
          <Grid container spacing={2}>
            {!loading ? (
              apisList.map(api => (
                <Grid item xs={12} key={api._id}>
                  <CardApi
                    api={api}
                    auth={auth}
                    deleteApiFailData={deleteApiFailData}
                    removeApi={removeApi}
                    getApiDetails={getApiDetails}
                    toggleDeleteApiIsUsedPopUp={togglePopUp}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
          </Grid>
          {/* <HasPermission permissionKey="create_applications"> */}
          <Fab
            color="secondary"
            className={classes.fab}
            onClick={() => showApisDialog(true)}
          >
            <VfSvgIcon icon="plus" viewBox={24} />
          </Fab>
          {/* </HasPermission> */}
          {error && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={error}
              onClose={() => {}}
            >
              <VfAlert severity="error" message={error.message} />
            </Snackbar>
          )}
        </Container>
      </Box>
      {show && (
        <AddApiDialog show={show} showDialog={showApisDialog} envs={envs} />
      )}
    </main>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React from "react";

import { MenuItem, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import tenantTypes from "enums/tenantTypes";
import { deployApplication } from "../../../../actions/applicationsActions";
import { deploySpainApplication } from "../../../../actions/spainIntegrationActions";

const DeployUnmanagedMenuItem = ({
  application,
  environment,
  originEnvironment,
  onPress,
  auth,
  deploy,
  tenantType,
}) => {
  const { ctry, access_token, id_token } = auth;

  const onDeploy = () => {
    onPress();

    deploy({
      access_token,
      id_token,
      locale: ctry.toLowerCase(),
      body: {
        originEnvId: originEnvironment.id,
        environmentId: environment._id,
        application,
      },
      tenantType,
    });
  };

  return (
    <MenuItem
      className={`MuiListItem-${environment.title.toLowerCase()}`}
      onClick={onDeploy}
    >
      <ListItemText secondary={`Promote to ${environment.title}`} />
    </MenuItem>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    tenantType: state.tenants?.current?.type,
  };
};

const mapDispatchToProps = dispatch => ({
  deploy: payload =>
    payload.tenantType === tenantTypes.SPAIN
      ? dispatch(deploySpainApplication(payload))
      : dispatch(deployApplication(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployUnmanagedMenuItem);

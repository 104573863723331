/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import VfSvgIcon from "../icons/VfSvgIcon";
import image from "../../assets/images/bck.jpg";
import VfDialog from "../vf/VfDialog";
import WidgetHistory from "./WidgetsHistory";

const CardWidgetGrid = ({
  widget,
  cardAction,
  moreActions,
  isConfig,
  fetchHistory,
  clearHistory,
  sourceEnvId,
  envs,
}) => {
  const widgetImage = widget.image || image;
  const [openDialogNotes, setOpenDialogNotes] = useState(false);
  const [openDialogHistory, setOpenDialogHistory] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleOpenDialogNotes = () => {
    setOpenDialogNotes(!openDialogNotes);
  };

  const handleOpenDialogHistory = () => {
    if (!openDialogHistory) fetchHistory();
    else clearHistory();
    setOpenDialogHistory(!openDialogHistory);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-widget": true,
        "MuiCard-grid": true,
        "MuiCard-white": true,
        "MuiCard-shadowHover": true,
        "MuiCard-outlined": widget.isSaved,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": widget.isSaved,
      })}
    >
      <CardActionArea onClick={cardAction}>
        <CardMedia
          className={classNames({ "CardMedia-imagePreview": moreActions })}
          image={widgetImage}
          title={widget.title}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {isConfig && widget.componentId && (
                  <Grid item xs="auto">
                    <Typography
                      variant="body1"
                      component="span"
                      color="secondary"
                    >
                      {widget.widgetInstanceName || widget.widgetInstanceId}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs="auto">
                  <Typography variant="body1" component="h5">
                    {!isConfig
                      ? widget.description
                      : !widget.componentId
                      ? "Global Settings"
                      : widget.description}
                  </Typography>
                </Grid>
                {isConfig && widget.componentId && (
                  <Grid item xs="auto">
                    <Typography
                      variant="body1"
                      component="span"
                      color="secondary"
                    >
                      {widget.version}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {widget.updatedAt && widget.user && (
                <Typography variant="body2">
                  {`Last edit: ${widget.updatedAt} - ${widget.user}`}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      {moreActions && (
        <CardActions disableSpacing>
          <IconButton
            className="MuiIconButton-colorWhite"
            title="View readme file"
            onClick={handleClickMenu}
          >
            <VfSvgIcon icon="more" />
          </IconButton>
          <Menu
            id="simple-menu"
            elevation={0}
            anchorEl={anchorElMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorElMenu)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={() => {
                handleOpenDialogNotes();
                handleCloseMenu();
              }}
            >
              See notes
            </MenuItem>
            {isConfig && (
              <MenuItem
                onClick={() => {
                  handleOpenDialogHistory();
                  handleCloseMenu();
                }}
              >
                History
              </MenuItem>
            )}
            {envs
              .filter(({ _id, isGlobal }) => !isGlobal && _id !== sourceEnvId)
              .sort((a, b) => (a.order < b.order ? -1 : 1))
              .map(({ title }) => (
                <MenuItem
                  key={title}
                  onClick={() => {
                    handleCloseMenu();
                  }}
                  className={`MuiListItem-${title.toLowerCase()}`}
                >
                  <ListItemText secondary={`Promote to ${title}`} />
                </MenuItem>
              ))}
          </Menu>
          <VfDialog
            openDialog={openDialogNotes}
            buttonCloseAction={handleOpenDialogNotes}
            title="Readme file"
            maxWidth="md"
            classDialogContent="MuiDialogContent-git"
          >
            <ReactMarkdown source={widget.readme} />
          </VfDialog>
          {isConfig && (
            <VfDialog
              openDialog={openDialogHistory}
              buttonCloseAction={handleOpenDialogHistory}
              title="History"
              maxWidth="md"
            >
              <WidgetHistory />
            </VfDialog>
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default CardWidgetGrid;

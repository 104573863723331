import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { get, set } from "lodash";
import TextField from "@material-ui/core/TextField";
import HasPermission from "components/has-permissions/HasPermissions";
import {
  setSelectedTreeItem as settingSelectedTreeItem,
  updateTranslationData as updTranslationData,
} from "actions/translationsActions";

const useStyles = makeStyles({
  root: {},
});

const TranslationsForm = ({
  language,
  languageCode,
  selectedTreeItem,
  translations,
  setSelectedTreeItem,
  updateTranslationData,
  envID,
}) => {
  const classes = useStyles();
  /* eslint-disable no-nested-ternary */
  const path =
    selectedTreeItem && selectedTreeItem.path
      ? selectedTreeItem.path === "$."
        ? "data"
        : `data.${selectedTreeItem.path}`.replace("$.", "")
      : "";
  const [translated, setTranslated] = React.useState(
    get(
      translations.find(l => l.language === languageCode),
      path,
      {}
    )
  );
  const original = get(
    translations.find(l => l.language === "en"),
    path,
    {}
  );
  const setValue = (k, v) => {
    translations.map(t => {
      if (t.language !== languageCode) return t;
      set(t, `${path}.${k}`, v);
      setTranslated(get(t, path, {}));
      return t;
    });
    updateTranslationData(translations);
    setSelectedTreeItem({
      ...selectedTreeItem,
    });
  };

  React.useEffect(() => {
    setTranslated(
      get(
        translations.find(l => l.language === languageCode),
        path,
        {}
      )
    );
  });

  if (!original) return null;

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h5" component="h5">
                Original
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="h5">
                {`${language} `}
                Translation
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {Object.keys(original).map(
          k =>
            typeof original[k] !== "object" && (
              <Grid item xs={12} key={k}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      label={k}
                      variant="outlined"
                      value={original[k]}
                      rowsMax="3"
                      rows="1"
                      multiline
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HasPermission
                      permissionKey={`edit_translations_env_${envID}`}
                      disabled
                    >
                      <TextField
                        variant="outlined"
                        rowsMax="3"
                        rows="1"
                        onChange={e => setValue(k, e.target.value)}
                        value={translated && translated[k]}
                        multiline
                        fullWidth
                      />
                    </HasPermission>
                  </Grid>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    translations: state.translations.translations,
    selectedTreeItem: state.translations.selectedTreeItem,
  };
};
const mapDispatchToProps = dispatch => ({
  setSelectedTreeItem: data => dispatch(settingSelectedTreeItem(data)),
  updateTranslationData: data => dispatch(updTranslationData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationsForm);

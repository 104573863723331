import {
  NEXUS_WIDGETS_REQUEST,
  NEXUS_WIDGETS_REQUEST_SUCCESS,
  NEXUS_WIDGETS_REQUEST_FAIL,
  SET_NEXUS_WIDGET,
  CLEAR_NEXUS_WIDGET,
  CLEAR_NEXUS_WIDGETS_LIST,
  SET_NEXUS_WIDGETS_VIEW,
  NEXUS_WIDGETS_CATEGORIES_REQUEST_SUCCESS,
  NEXUS_WIDGETS_CATEGORIES_REQUEST_FAIL,
  NEXUS_WIDGETS_VERSIONS_REQUEST,
  NEXUS_WIDGETS_VERSIONS_REQUEST_SUCCESS,
  NEXUS_WIDGETS_VERSIONS_REQUEST_FAIL,
  CLEAR_NEXUS_WIDGET_VERSIONS,
  GET_NEXUS_WIDGET_BY_ID_REQUEST_SUCCESS,
  GET_NEXUS_WIDGET_BY_ID_REQUEST_FAIL,
  UPDATE_NEXUS_WIDGET_REQUEST,
  UPDATE_NEXUS_WIDGET_REQUEST_SUCCESS,
  UPDATE_NEXUS_WIDGET_REQUEST_FAIL,
} from "actions/nexusWidgetsActions";

const initialState = {
  current: "",
  data: [],
  error: null,
  view: "list",
  searchData: [],
  categories: [],
  versions: [],
  loading: false,
  updating: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_NEXUS_WIDGET:
      return {
        ...state,
        current: payload,
      };
    case CLEAR_NEXUS_WIDGET:
      return {
        ...state,
        current: "",
      };
    case CLEAR_NEXUS_WIDGETS_LIST:
      return {
        ...state,
        data: state.data.filter(
          ({ _id }) =>
            _id === "Protons" ||
            _id === "Atoms" ||
            _id === "Molecules" ||
            _id === "Organisms" ||
            _id === "Custom Widgets" ||
            _id === "Layout"
        ),
      };
    case SET_NEXUS_WIDGETS_VIEW:
      return {
        ...state,
        view: payload,
      };
    case NEXUS_WIDGETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEXUS_WIDGETS_REQUEST_SUCCESS:
      return {
        ...state,
        data: [...state.data.filter(x => x.type === "SYSTEM"), ...payload.data],
        loading: false,
      };
    case NEXUS_WIDGETS_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    case NEXUS_WIDGETS_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        categories: payload,
      };
    case NEXUS_WIDGETS_CATEGORIES_REQUEST_FAIL:
      return {
        ...state,
        ...error,
      };
    case NEXUS_WIDGETS_VERSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEXUS_WIDGETS_VERSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        versions: payload,
      };
    case NEXUS_WIDGETS_VERSIONS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        ...error,
      };
    case GET_NEXUS_WIDGET_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        current: payload,
      };
    case GET_NEXUS_WIDGET_BY_ID_REQUEST_FAIL:
      return {
        ...state,
        ...error,
      };
    case CLEAR_NEXUS_WIDGET_VERSIONS:
      return {
        ...state,
        versions: [],
      };

    case UPDATE_NEXUS_WIDGET_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_NEXUS_WIDGET_REQUEST_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case UPDATE_NEXUS_WIDGET_REQUEST_FAIL:
      return {
        ...state,
        updating: true,
      };
    default:
      return state;
  }
};

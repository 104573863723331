/* eslint-disable no-nested-ternary */
import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDowIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import nodeName from "utils/form-tree-node-name";
import HasPermission from "components/has-permissions/HasPermissions";

const EditorFormOrderItem = ({
  index,
  item,
  swap,
  remove,
  itemsLength,
  envID,
  isTenant,
  isGlobal
}) => {
  return (
    <Card
      className="MuiCard-shadow MuiCard-white MuiCard-ordering MuiCard-shadow animate"
      elevation={0}
    >
      <Grid container alignItems="center" wrap="nowrap" spacing={3}>
        <Grid item xs>
          <CardActionArea disableRipple disableTouchRipple focusRipple={false}>
            <CardHeader
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="h5" component="h3">
                  {nodeName(item, index)}
                </Typography>
              }
            />
          </CardActionArea>
        </Grid>
        <HasPermission
          permissionKey={
            isTenant ? "edit_tenant" : `edit_configurations_env_${envID}`
          }
          isGlobal={isGlobal}
        >
          <Grid item>
            <CardActions className="animate">
              {itemsLength - 1 !== index && !isTenant && (
                <IconButton onClick={() => swap(index, index + 1)}>
                  <ArrowDropDowIcon />
                </IconButton>
              )}

              {!!index && !isTenant && (
                <IconButton onClick={() => swap(index, index - 1)}>
                  <ArrowDropUpIcon />
                </IconButton>
              )}
              <IconButton onClick={() => remove(index)}>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </CardActions>
          </Grid>
        </HasPermission>
      </Grid>
    </Card>
  );
};

export default EditorFormOrderItem;

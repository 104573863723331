/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import uuidv4 from "uuid/v4";
import React, { useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { cloneDeep, intersectionBy, isEmpty, omit } from "lodash";
import compareVersions from "compare-versions";

import {
  setCurrentPage as setCurrentPageAction,
  showSnackbar,
  setApp,
  setSelectedWidget,
  setSelectedWidgetVersions,
  setCurrRowCol,
  setAppSidebar,
  djslDeleteComponent,
  djslAddComponent,
  clearSelectedWidget,
} from "actions/applicationsActions";
import theme from "AppTheme";
import { registry } from "renderer";
import jp from "jsonpath";
import Ajv, { ValidationError } from "ajv";
import { ReactComponent as EditIcon } from "../../assets/images/ic-edit.svg";
import VfSvgIcon from "../icons/VfSvgIcon";
import CardWidgetImage from "../widgets/CardWidgetImage";
import djrSchema from "../../djr-schema.json";

const GridItemWrapper = ({
  application,
  isDeployment,
  setSidebarItem,
  showSnackBar,
  setWidget,
  setWidgetVersions,
  categoryWidgets,
  children,
  componentJSON,
  clearWidget,
}) => {
  const { currentPage, appInfo } = application;

  const useStyles = makeStyles({
    root: {
      position: "relative",
      "&.Mui-hover": {
        "& .MuiCardContent-root": {
          "&:before": {
            opacity: 1,
            borderColor: theme.palette.grey[100],
          },
        },
        "& .MuiCardActions-root": {
          opacity: 1,
        },
        "&.Mui-selected": {
          "& .MuiCardContent-root": {
            "&:before": {
              borderColor: theme.palette.primary.main,
            },
          },
        },
      },
      "&.Mui-drop": {
        "& .MuiCardContent-root": {
          "&:before": {
            opacity: 1,
            borderColor: theme.palette.primary.main,
            backgroundColor: fade(theme.palette.primary.main, 0.38),
          },
        },
      },
      "&.Mui-selected": {
        "& .MuiCardContent-root": {
          "&:before": {
            opacity: 1,
            borderColor: theme.palette.primary.main,
            backgroundColor: fade(theme.palette.primary.main, 0),
          },
        },
        "& .MuiCardActions-root": {
          opacity: 1,
        },
      },
      "& .MuiCardMedia-root": {
        padding: theme.spacing(2),
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      "& .MuiCardContent-root": {
        padding: theme.spacing(2),
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "&:before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          borderColor: "transparent",
          borderStyle: "dashed",
          borderWidth: 1,
          borderRadius: 6,
          backgroundColor: fade(theme.palette.text.primary, 0.38),
          transition: theme.transitions.create(["opacity", "border-color"], {
            duration: theme.transitions.duration.standard,
          }),
        },
      },
      "& .MuiCardActionArea-root": {
        padding: 0,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
      },
      "& .MuiCardActions-root": {
        padding: 0,
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        opacity: 0,
        transition: theme.transitions.create(["opacity"], {
          duration: theme.transitions.duration.standard,
        }),
        "& .MuiButton-contained": {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          minWidth: theme.spacing(6),
          width: theme.spacing(6),
        },
      },
    },
    widgetPadding: {
      paddingTop: React.Children.count(children) < 2 ? "calc(4 / 11 * 100%)" : "calc(9 / 16 * 100%)",
    },
    listItem: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(null);
  const childJSON = useMemo(() => componentJSON?.__props?.children?.[0] || {}, [componentJSON]);
  const fatWidgetList = useMemo(() => categoryWidgets.flatMap(({ widgets }) => widgets), [
    categoryWidgets,
  ]);

  const handleClick = event => {
    // setSidebarItem("widgetList");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const findWidget = widgetName => appInfo.widgets.find(({ name }) => name === widgetName);

  const selectWidget = (name, instanceId) => {
    const wdgts = categoryWidgets.flatMap(({ widgets }) => widgets);
    const widgetVersions = wdgts
      .filter(w => w.name === name)
      .sort((a, b) => {
        return compareVersions(a.version, b.version);
      })
      .reverse();

    let selectedWdgt = intersectionBy(widgetVersions, appInfo.widgets, "_id");
    if (instanceId) {
      selectedWdgt.instanceId = instanceId;
      selectedWdgt.instanceName = findWidget(name)?.instances.find(inst => inst.uuid === instanceId)?.name;
    }
    selectedWdgt = selectedWdgt.length ? selectedWdgt[0] : widgetVersions[0];
    return { selectedWdgt, widgetVersions };
  };

  const checkEditContent = useMemo(() => {
    if (!registry[componentJSON.__component]) return null;
    const wdgts = categoryWidgets.reduce((total, { widgets }) => [...total, ...widgets], []);

    const widgetVersions = wdgts
      .filter(w => w.name === childJSON.__props?.name)
      .sort((a, b) => {
        return compareVersions(a.version, b.version);
      })
      .reverse();

    const selectedWdgt = intersectionBy(widgetVersions, appInfo.widgets, "_id");
    return selectedWdgt.length ? selectedWdgt[0] : widgetVersions[0];
  }, [categoryWidgets, componentJSON]);

  const findWidgetInstanceName = () => {
    const { __instanceId: instanceId, name: widgetName } = childJSON?.__props || {};
    const instanceName = findWidget(widgetName)?.instances.find(inst => inst.uuid === instanceId)?.name;
    return instanceName || instanceId;
  };

  const addWidgetToApplication = (widget, instanceId, replacedInstanceId = null) => {
    const clonedAppInfo = cloneDeep(appInfo);
    const found = clonedAppInfo.widgets.findIndex(w => w.name === widget.name);
    if (found === -1) {
      clonedAppInfo.widgets.push({
        _id: widget._id,
        name: widget.name,
        version: widget.version,
        occurrences: 1,
        instances: [{ uuid: instanceId }],
      });
    } else {
      const instances = clonedAppInfo.widgets[found].instances.filter(i => i.uuid !== replacedInstanceId);
      const occurrences = clonedAppInfo.widgets[found].occurrences;
      clonedAppInfo.widgets.splice(found, 1, {
        _id: widget._id,
        name: widget.name,
        version: widget.version,
        occurrences: replacedInstanceId ? occurrences : occurrences + 1,
        instances: [...instances, { uuid: instanceId }],
      });
    }
    return { appInfo: clonedAppInfo };
  };

  const removeWidgetFromApplication = fatWidgets => {
    const clonedAppInfo = cloneDeep(appInfo);
    fatWidgets.forEach(widget => {
      const found = clonedAppInfo.widgets.findIndex(w => w.name === widget.__component);
      if (clonedAppInfo.widgets[found].occurrences <= 1) {
        clonedAppInfo.widgets.splice(found, 1);
      } else {
        clonedAppInfo.widgets.splice(found, 1, {
          _id: clonedAppInfo.widgets[found]._id,
          name: widget.__component,
          version: clonedAppInfo.widgets[found].version,
          occurrences: clonedAppInfo.widgets[found].occurrences - 1,
          instances: clonedAppInfo.widgets[found].instances.filter(inst => inst.uuid !== widget.__props.__instanceId),
        });
      }
    });

    return clonedAppInfo;
  };

  const fatWidget = name => fatWidgetList.find(w => w.name === name);

  const containsFatComp = comp =>
    jp
      .query(comp, `$..children`)
      .flatMap(x => x)
      .filter(c => {
        return !!fatWidget(c?.__component);
      });

  const [{ isOver }, drop] = useDrop({
    accept: "widget",
    drop: ({ name }) => {
      const instanceId = uuidv4();
      const comp = selectWidget(name, instanceId);
      const isNotFatComponent = registry[name];
      dispatch(
        djslDeleteComponent({
          id: childJSON?.__props.id,
        })
      );
      dispatch(
        djslAddComponent({
          ...{ name, instanceId, parent: componentJSON.__props.id },
          ...comp,
          ...(isNotFatComponent
            ? {}
            : addWidgetToApplication(comp.selectedWdgt, instanceId, childJSON?.__props?.__instanceId)),
        })
      );
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const { selectedWdgt: widget } = selectWidget(childJSON?.__props?.name, childJSON?.__props?.__instanceId);

  const useImageForFatWidget = !registry[childJSON.__component] && !!widget;

  const ajv = new Ajv({
    useDefaults: true,
    allErrors: true,
    jsonPointers: true,
  });
  const validateDjr = ajv.compile(djrSchema);

  const copyColumn = async () => {
    return navigator.clipboard.writeText(JSON.stringify(childJSON)).then(
      () =>
        showSnackBar({
          show: true,
          message: "Column successfully copied",
          severity: 3,
        }),
      () =>
        showSnackBar({
          show: true,
          message: "Clipboard write failed",
          severity: 0,
        })
    );
  };

  const pasteColumn = async () => {
    const snackBarMsg = {
      show: true,
      message: "Widget was added successfully",
      severity: 3,
    };
    let content;

    try {
      content = await navigator.clipboard.readText();
      content = JSON.parse(content);

      if (!validateDjr(content)) {
        throw new ValidationError();
      }
      const widgetName = content.__props.name;
      const instanceId = uuidv4();
      const selectedWdgt = selectWidget(widgetName, instanceId);
      const isNotFatComponent = registry[widgetName];
      if (childJSON?.__component === "CardHeader") {
        dispatch(
          djslDeleteComponent({
            id: childJSON?.__props.id,
          })
        );
      }
      dispatch(
        djslAddComponent({
          ...{
            restOfProps: omit({ ...content.__props }, ["__instanceId", "id", "__pageId"]),
            name: widgetName,
            instanceId,
            parent: componentJSON.__props.id,
          },
          ...selectedWdgt,
          ...(isNotFatComponent ? {} : addWidgetToApplication(selectedWdgt.selectedWdgt, instanceId)),
        })
      );
    } catch (err) {
      snackBarMsg.severity = 0;

      if (err instanceof DOMException) {
        snackBarMsg.message = "Browser doesn't have the permission to write to your clipboard";
      } else if (err instanceof SyntaxError || err instanceof ValidationError) {
        snackBarMsg.message = "This is not a valid schema!";
      } else {
        snackBarMsg.message = "Some error occurred";
      }
    }

    showSnackBar(snackBarMsg);
  };

  return (
    <Card
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={drop}
      elevation={0}
      className={classNames(classes.root, {
        [classes.widgetPadding]: !useImageForFatWidget,
        "MuiCard-rounded": true,
        "Mui-hover": Boolean(anchorEl) || hover,
        "Mui-drop": isOver,
        "Mui-selected": false,
      })}
    >
      {useImageForFatWidget && <CardWidgetImage widget={widget} />}
      {!!registry[childJSON.__component] && <CardContent>{children}</CardContent>}
      <CardActions disableSpacing>
        <Button
          className={classNames({
            "MuiButton-containedLight": false,
          })}
          variant="contained"
          onClick={handleClick}
        >
          <VfSvgIcon icon="more" viewBox={24} />
        </Button>
        <Menu
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {!isDeployment && !!childJSON.__props?.name && (
            <ListItem
              className={classes.listItem}
              onClick={() => {
                handleClose();
              }}
              key="li-1"
            >
              <Grid container alignItems="center" justify="space-between" key="li-2">
                <Grid item xs={11} key="m-i-1">
                  <Typography variant="body2">{findWidgetInstanceName()}</Typography>
                </Grid>
                <Grid item xs={1} justify="flex-end" key="m-i-2">
                  <IconButton
                    size="small"
                    onClick={() => {
                      const { selectedWdgt } = selectWidget(childJSON.__component, childJSON.__props.__instanceId);
                      setWidget({
                        ...selectedWdgt,
                        id: childJSON.__props.id,
                        instanceId: childJSON.__props.__instanceId,
                      });
                      setSidebarItem("widgetInstanceName");
                    }}
                  >
                    <SvgIcon fontSize="small" component={EditIcon} viewBox="0 0 24 24" />
                  </IconButton>
                </Grid>
                {!!findWidget(componentJSON?.__props?.children?.[0]?.__props.name)?.version && (
                  <>
                    <Grid item xs={11} key="grid-1">
                      <Typography variant="body2" color="secondary">
                        {findWidget(componentJSON?.__props?.children?.[0]?.__props.name)?.version}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} key="grid-2">
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => {
                          const { selectedWdgt, widgetVersions } = selectWidget(
                            childJSON.__component,
                            childJSON.__props.__instanceId
                          );
                          setWidget({
                            ...selectedWdgt,
                            id: childJSON.__props.id,
                            instanceId: childJSON.__props.__instanceId,
                          });
                          setWidgetVersions(widgetVersions);
                          setSidebarItem("widgetVersion");
                        }}
                      >
                        <SvgIcon fontSize="small" component={EditIcon} viewBox="0 0 24 24" />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </ListItem>
          )}
          {checkEditContent?.propsSchema && [
            <Divider />,
            <MenuItem
              key="menu-1"
              onClick={() => {
                handleClose();
                const { selectedWdgt } = selectWidget(childJSON.__component, childJSON.__props.__instanceId);
                setWidget({
                  name: selectedWdgt.name,
                  id: childJSON.__props.id,
                  instanceId: childJSON.__props.__instanceId,
                  propsSchema: selectedWdgt.propsSchema,
                });
                setSidebarItem("widgetEditor");
              }}
            >
              EDIT CONTENT
            </MenuItem>,
          ]}
          <MenuItem
            key="menu-2"
            onClick={() => {
              handleClose();
              setWidget({
                name: "GridItem",
                id: componentJSON.__props.id,
                propsSchema: JSON.stringify({
                  title: "GridItem",
                  type: "object",
                  required: ["xs", "sm", "md"],
                  properties: {
                    xs: {
                      oneOf: [
                        {
                          type: "string",
                        },
                        {
                          type: "boolean",
                        },
                        {
                          type: "number",
                        },
                      ],
                      title: "xs",
                      default: "12",
                      enum: [false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    },
                    sm: {
                      oneOf: [
                        {
                          type: "string",
                        },
                        {
                          type: "boolean",
                        },
                        {
                          type: "number",
                        },
                      ],
                      title: "sm",
                      default: "12",
                      enum: [false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    },
                    md: {
                      oneOf: [
                        {
                          type: "string",
                        },
                        {
                          type: "boolean",
                        },
                        {
                          type: "number",
                        },
                      ],
                      title: "md",
                      default: "12",
                      enum: [false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    },
                  },
                }),
              });
              setSidebarItem("widgetEditor");
            }}
          >
            EDIT COLUMN
          </MenuItem>
          <Divider />
          <MenuItem
            key="menu-3"
            onClick={() => {
              handleClose();
              copyColumn();
            }}
          >
            COPY COLUMN
          </MenuItem>
          <MenuItem
            key="menu-4"
            onClick={() => {
              handleClose();
              pasteColumn();
            }}
          >
            PASTE COLUMN
          </MenuItem>
          {!isDeployment && <Divider />}
          {!isDeployment && (
            <MenuItem
              key="menu-5"
              onClick={() => {
                handleClose();
                clearWidget();
                const isNotFatComponent = registry[componentJSON.__component];
                const fatWidgets = containsFatComp(componentJSON);
                let newAppInfo = {};
                if (typeof fatWidgets !== "undefined" && fatWidgets.length) {
                  newAppInfo = removeWidgetFromApplication(fatWidgets);
                } else if (!isNotFatComponent) {
                  newAppInfo = removeWidgetFromApplication([componentJSON.__props.children?.[0]]);
                }
                dispatch(
                  djslDeleteComponent({
                    id: componentJSON.__props.children?.[0]?.__props.id,
                    ...(!isEmpty(newAppInfo) ? { appInfo: newAppInfo } : {}),
                  })
                );
              }}
            >
              CLEAR COLUMN
            </MenuItem>
          )}
          {!isDeployment && (
            <MenuItem
              key="menu-6"
              onClick={() => {
                handleClose();
                if (
                  jp.parent(currentPage.djr, `$..children[?(@.__props.id == '${componentJSON.__props.id}')]`).length < 2
                ) {
                  showSnackBar({
                    show: true,
                    message: "Error: You cant delete last Column, delete Row instead.",
                    severity: 0,
                  });
                  return 0;
                }
                const isNotFatComponent = registry[componentJSON.__component];
                const fatWidgets = containsFatComp(componentJSON);
                let newAppInfo = {};
                if (typeof fatWidgets !== "undefined" && fatWidgets.length) {
                  newAppInfo = removeWidgetFromApplication(fatWidgets);
                } else if (!isNotFatComponent) {
                  newAppInfo = removeWidgetFromApplication([componentJSON]);
                }
                dispatch(
                  djslDeleteComponent({
                    id: componentJSON.__props.id,
                    ...(!isEmpty(newAppInfo) ? { appInfo: newAppInfo } : {}),
                  })
                );
                return 1;
              }}
            >
              DELETE COLUMN
            </MenuItem>
          )}
        </Menu>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    application: state.applications.current,
    isDeployment: state.applications.current?.appInfo?.isDeployed,
    categoryWidgets: state.nexusWidgets.data,
    currentWidget: state.applications.selectedWidget,
    selectedRow: state.applications.selectedRow,
    selectedColumn: state.applications.selectedColumn,
    parent: state.applications.parent,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  showSnackBar: payload => dispatch(showSnackbar(payload)),
  setApplication: payload => dispatch(setApp(payload)),
  setWidget: payload => dispatch(setSelectedWidget(payload)),
  setWidgetVersions: payload => dispatch(setSelectedWidgetVersions(payload)),
  setRowCol: payload => dispatch(setCurrRowCol(payload)),
  setSidebarItem: payload => dispatch(setAppSidebar(payload)),
  clearWidget: () => dispatch(clearSelectedWidget()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridItemWrapper);

import {
  GET_STORES_REQUEST_SUCCESS,
  GET_ALL_STORES_AND_DEVICES_REQUEST_SUCCESS,
  ADD_STORE_REQUEST,
  ADD_STORE_REQUEST_SUCCESS,
  ADD_STORE_REQUEST_FAIL,
  IMPORT_STORES_REQUEST,
  IMPORT_STORES_REQUEST_SUCCESS,
  IMPORT_STORES_REQUEST_FAIL,
  EXPORT_STORES_REQUEST,
  EXPORT_STORES_REQUEST_SUCCESS,
  EXPORT_STORES_REQUEST_FAIL,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_REQUEST_SUCCESS,
  UPDATE_STORE_REQUEST_FAIL,
  DELETE_STORE_REQUEST,
  DELETE_STORE_REQUEST_SUCCESS,
  DELETE_STORE_REQUEST_FAIL,
  BULK_DELETE_STORES_REQUEST,
  BULK_DELETE_STORES_REQUEST_SUCCESS,
  BULK_DELETE_STORES_REQUEST_FAIL,
  REMOVE_ERROR,
} from "actions/storeActions";

const initialState = {
  loading: false,
  stores: [],
  allStores: [],
  allDevices: [],
  allStoreGroups: [],
  storeItems: 1,
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STORES_REQUEST_SUCCESS: {
      return {
        ...state,
        stores: payload.stores,
        storeItems: payload.count,
      };
    }
    case GET_ALL_STORES_AND_DEVICES_REQUEST_SUCCESS: {
      return {
        ...state,
        allDevices: payload.devices ? payload.devices : [],
        allStores: payload.stores ? payload.stores : [],
        allStoreGroups: payload.storeGroups ? payload.storeGroups : [],
      };
    }
    case ADD_STORE_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_STORE_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? ''};
    }
    case ADD_STORE_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case IMPORT_STORES_REQUEST: {
      return { ...state, loading: true };
    }
    case IMPORT_STORES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case IMPORT_STORES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case EXPORT_STORES_REQUEST: {
      return { ...state, loading: true };
    }
    case EXPORT_STORES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case EXPORT_STORES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case UPDATE_STORE_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_STORE_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case UPDATE_STORE_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case DELETE_STORE_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_STORE_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case DELETE_STORE_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case BULK_DELETE_STORES_REQUEST: {
      return { ...state, loading: true };
    }
    case BULK_DELETE_STORES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case BULK_DELETE_STORES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case REMOVE_ERROR: {
      return { ...state, error: '' };
    }
    default:
      return state;
  }
};

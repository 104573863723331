/* eslint-disable no-underscore-dangle */
import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";

import VfSvgIcon from "../icons/VfSvgIcon";
import CardWidgetList from "../widgets/CardWidgetList";
import CardWidgetGrid from "../widgets/CardWidgetGrid";

export default function PaperTranslationsPerEnv({
  env,
  grid,
  envType,
  threshold,
  thresholdPerEnv,
  translationWidgets,
  onShowMore,
  handleClickWidget,
  envId,
}) {
  const renderItems = ({ widget }) => {
    return grid === "list" ? (
      <Grid key={widget.name} item xs={12}>
        <CardWidgetList
          widget={widget}
          cardAction={() => {
            handleClickWidget(widget, envId, env);
          }}
        />
      </Grid>
    ) : (
      <Grid key={widget.name} item xs={12} sm={6} md={4}>
        <CardWidgetGrid
          widget={widget}
          cardAction={() => {
            handleClickWidget(widget, envId, env);
          }}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={9}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <VfSvgIcon icon={env.toLowerCase()} viewBox={56} />
              </Grid>
              <Grid item xs="auto">
                <Typography component="h5" variant="body1">
                  {env}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {translationWidgets.length ? (
              <Grid container spacing={2}>
                {translationWidgets
                  .filter((x, index) => {
                    return index < thresholdPerEnv[envType];
                  })
                  .map(widgetI => renderItems({ widget: widgetI }))}
                <Grid item xs={12}>
                  {translationWidgets.length - threshold > 0 &&
                    thresholdPerEnv[envType] <= threshold && (
                      <Button onClick={() => onShowMore(envType)}>
                        <Typography
                          style={{ textDecoration: "underline" }}
                          component="span"
                          variant="body2"
                        >
                          {`View
                  ${translationWidgets.length - threshold}
                  more`}
                        </Typography>
                      </Button>
                    )}
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body1" component="h1">
                No translations
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import Reporting from "components/portal/Reporting";

const PortalReportingPage = () => {
  const {reportingInfo} = useSelector(
    state => state.tenants.current,
    shallowEqual
  );

  return (
    reportingInfo?.url ? <Reporting reportingInfo={reportingInfo} /> : null
  );
};

export default PortalReportingPage;

/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  MEDIA_REQUEST,
  MEDIA_REQUEST_SUCCESS,
  MEDIA_REQUEST_FAIL,
  FILTER_MEDIA_REQUEST,
  FILTER_MEDIA_REQUEST_SUCCESS,
  FILTER_MEDIA_REQUEST_FAIL,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_REQUEST_SUCCESS,
  UPLOAD_MEDIA_REQUEST_FAIL,
  UPDATE_MEDIA_REQUEST,
  UPDATE_MEDIA_REQUEST_SUCCESS,
  UPDATE_MEDIA_REQUEST_FAIL,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_REQUEST_SUCCESS,
  DELETE_MEDIA_REQUEST_FAIL,
} from "actions/mediaActions";
import MediaService from "services/MediaService";
import getErrorMessage from "../utils/status-code-error-messages";

function* fetchMediaWorker({ payload }) {
  const { page } = payload;
  try {
    const data = yield call(MediaService.fetchMedia, payload);
    if (data.isAxiosError) {
      getErrorMessage(data);
    }
    yield put({
      type: MEDIA_REQUEST_SUCCESS,
      payload: { data, page },
    });
  } catch (error) {
    yield put({ type: MEDIA_REQUEST_FAIL, error: error.message });
  }
}

function* fetchMediaWatcher() {
  yield takeLatest(MEDIA_REQUEST, fetchMediaWorker);
}

function* fetchFilterMediaWorker({ payload }) {
  const { page } = payload;
  try {
    const data = yield call(MediaService.fetchMedia, payload);
    if (data.isAxiosError) {
      getErrorMessage(data);
    }
    yield put({
      type: FILTER_MEDIA_REQUEST_SUCCESS,
      payload: { data, page },
    });
  } catch (error) {
    yield put({ type: FILTER_MEDIA_REQUEST_FAIL, error: error.message });
  }
}

function* fetchFilterMediaWatcher() {
  yield takeLatest(FILTER_MEDIA_REQUEST, fetchFilterMediaWorker);
}

function* uploadMediaWorker({ payload }) {
  const { access_token, id_token, filters, searchText } = payload;
  try {
    const data = yield call(MediaService.uploadMedia, payload);
    if (data.isAxiosError) {
      getErrorMessage(data);
    }
    yield put({
      type: UPLOAD_MEDIA_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: FILTER_MEDIA_REQUEST,
      payload: {
        access_token,
        id_token,
        page: 0,
        limit: 10,
        filters,
        searchText,
      },
    });
  } catch (error) {
    yield put({ type: UPLOAD_MEDIA_REQUEST_FAIL, error: error.message });
  }
}

function* uploadMediaWatcher() {
  yield takeLatest(UPLOAD_MEDIA_REQUEST, uploadMediaWorker);
}

function* updateMediaWorker({ payload }) {
  const { access_token, id_token, filters, searchText } = payload;
  try {
    const data = yield call(MediaService.updateMedia, payload);
    if (data.isAxiosError) {
      getErrorMessage(data);
    }
    yield put({
      type: UPDATE_MEDIA_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: FILTER_MEDIA_REQUEST,
      payload: {
        access_token,
        id_token,
        page: 0,
        limit: 10,
        filters,
        searchText,
      },
    });
  } catch (error) {
    yield put({ type: UPDATE_MEDIA_REQUEST_FAIL, error: error.message });
  }
}

function* updateMediaWatcher() {
  yield takeLatest(UPDATE_MEDIA_REQUEST, updateMediaWorker);
}

function* deleteMediaWorker({ payload }) {
  const { access_token, id_token, filters, searchText } = payload;
  try {
    const data = yield call(MediaService.deleteMedia, payload);
    if (data.isAxiosError) {
      getErrorMessage(data);
    }
    yield put({
      type: DELETE_MEDIA_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: FILTER_MEDIA_REQUEST,
      payload: {
        access_token,
        id_token,
        page: 0,
        limit: 10,
        filters,
        searchText,
      },
    });
  } catch (error) {
    yield put({ type: DELETE_MEDIA_REQUEST_FAIL, error: error.message });
  }
}

function* deleteMediaWatcher() {
  yield takeLatest(DELETE_MEDIA_REQUEST, deleteMediaWorker);
}

const mediaSaga = [
  fetchMediaWatcher(),
  fetchFilterMediaWatcher(),
  uploadMediaWatcher(),
  updateMediaWatcher(),
  deleteMediaWatcher(),
];

export default mediaSaga;

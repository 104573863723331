/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_DEVICES_REQUEST,
  ADD_DEVICE_REQUEST,
  IMPORT_DEVICES_REQUEST,
  UPDATE_DEVICE_REQUEST,
  DELETE_DEVICE_REQUEST,
  EXPORT_DEVICES_REQUEST,
  BULK_DELETE_DEVICES_REQUEST,
  fetchDevicesSuccess,
  fetchDevicesFail,
  addDeviceSuccess,
  addDeviceFail,
  importDevicesSuccess,
  importDevicesFail,
  updateDeviceSuccess,
  updateDeviceFail,
  deleteDeviceSuccess,
  deleteDeviceFail,
  bulkDeleteDevicesSuccess,
  bulkDeleteDevicesFail,
  exportDevicesSuccess,
  exportDevicesFail,
} from "actions/deviceActions";
import DeviceService from "services/DeviceService";

function* fetchDevicesWorker({ payload }) {
  try {
    const data = yield call(DeviceService.fetch, payload);
    yield put(fetchDevicesSuccess(data));
  } catch (error) {
    yield put(fetchDevicesFail(error));
  }
}

function* fetchDevicesWatcher() {
  yield takeLatest(GET_DEVICES_REQUEST, fetchDevicesWorker);
}

function* addDeviceWorker({ payload }) {
  try {
    const data = yield call(DeviceService.add, payload);
    yield put(addDeviceSuccess(data));
  } catch (error) {
    yield put(addDeviceFail(error));
  }
}

function* addDeviceWatcher() {
  yield takeLatest(ADD_DEVICE_REQUEST, addDeviceWorker);
}

function* importDevicesWorker({ payload }) {
  try {
    const data = yield call(DeviceService.import, payload);
    yield put(importDevicesSuccess(data));
  } catch (error) {
    yield put(importDevicesFail(error));
  }
}

function* importDevicesWatcher() {
  yield takeLatest(IMPORT_DEVICES_REQUEST, importDevicesWorker);
}

function* updateDeviceWorker({ payload }) {
  try {
    const data = yield call(DeviceService.update, payload);
    yield put(updateDeviceSuccess(data));
  } catch (error) {
    yield put(updateDeviceFail(error));
  }
}

function* updateDeviceWatcher() {
  yield takeLatest(UPDATE_DEVICE_REQUEST, updateDeviceWorker);
}

function* deleteDeviceWorker({ payload }) {
  try {
    const data = yield call(DeviceService.delete, payload);
    yield put(deleteDeviceSuccess(data));
  } catch (error) {
    yield put(deleteDeviceFail(error));
  }
}

function* deleteDeviceWatcher() {
  yield takeLatest(DELETE_DEVICE_REQUEST, deleteDeviceWorker);
}

function* bulkDeleteDevicesWorker({ payload }) {
  try {
    const data = yield call(DeviceService.bulkDelete, payload);
    yield put(bulkDeleteDevicesSuccess(payload));
  } catch (error) {
    yield put(bulkDeleteDevicesFail(error));
  }
}

function* bulkDeleteDeviceWatcher() {
  yield takeLatest(BULK_DELETE_DEVICES_REQUEST, bulkDeleteDevicesWorker);
}

function* exportDevicesWorker({ payload }) {
  try {
    const data = yield call(DeviceService.export, payload);
    yield put(exportDevicesSuccess(data));
  } catch (error) {
    yield put(exportDevicesFail(error));
  }
}

function* exportDevicesWatcher() {
  yield takeLatest(EXPORT_DEVICES_REQUEST, exportDevicesWorker);
}

const deviceSaga = [
  fetchDevicesWatcher(),
  addDeviceWatcher(),
  importDevicesWatcher(),
  updateDeviceWatcher(),
  deleteDeviceWatcher(),
  bulkDeleteDeviceWatcher(),
  exportDevicesWatcher(),
];

export default deviceSaga;

/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
	GET_GALLERY_MEDIA_REQUEST,
	UPLOAD_GALLERY_MEDIA_REQUEST,
  DELETE_GALLERY_MEDIA_REQUEST,
  getGalleryMediaSuccess,
  getGalleryMediaFail,
  uploadGalleryMediaSuccess,
  uploadGalleryMediaFail,
  deleteMediaGallerySuccess,
  deleteMediaGalleryFail,
} from "actions/galleryMediaActions";
import GalleryMediaService from "services/GalleryMediaService";

function* getGalleryMediaWorker({ payload }) {
  try {
    const data = yield call(GalleryMediaService.fetch, payload);
    yield put(getGalleryMediaSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getGalleryMediaFail(error));
  }
}

function* getGalleryMediaWatcher() {
  yield takeLatest(GET_GALLERY_MEDIA_REQUEST, getGalleryMediaWorker);
}

function* uploadGalleryMediaWorker({ payload }) {
  try {
    const data = yield call(GalleryMediaService.upload, payload);
    yield put(uploadGalleryMediaSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(uploadGalleryMediaFail(error));
  }
}

function* uploadGalleryMediaWatcher() {
  yield takeLatest(UPLOAD_GALLERY_MEDIA_REQUEST, uploadGalleryMediaWorker);
}

function* deleteGalleryMediaWorker({ payload }) {
  try {
    const data = yield call(GalleryMediaService.delete, payload);
    yield put(deleteMediaGallerySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(deleteMediaGalleryFail(error));
  }
}

function* deleteGalleryMediaWatcher() {
  yield takeLatest(DELETE_GALLERY_MEDIA_REQUEST, deleteGalleryMediaWorker);
}

const galleryMediaSaga = [
	getGalleryMediaWatcher(),
  uploadGalleryMediaWatcher(),
  deleteGalleryMediaWatcher(),
];

export default galleryMediaSaga;

import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import VfDialog from "../../vf/VfDialog";
import CardUpload from "../../media/CardUpload";
import { importDevices } from "actions/deviceActions";

const DialogDeviceImport = ({ open, setOpen }) => {
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;
  const dispatch = useDispatch();
  return (
    <VfDialog
      title="Bulk device import"
      subTitle="Supported file format: CSV"
      openDialog={open}
      buttonCloseAction={() => setOpen(false)}
      maxWidth="sm"
    >
      <CardUpload
        postMedia={req => {
          dispatch(importDevices(req));
          setOpen(!open);
        }}
        access_token={access_token}
        id_token={id_token}
        wide
      />
    </VfDialog>
  );
};

export default DialogDeviceImport;

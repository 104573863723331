import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import VfSvgIcon from "../../icons/VfSvgIcon";
import VfDialogConfirm from "../../vf/VfDialogConfirm";
import theme from "AppTheme";
import DialogStoreGroups from "../dialogs/DialogStoreGroup";
import { deleteStoreGroup } from "actions/storeGroupActions.js";
const useStyles = makeStyles({
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
});

const CardGroup = ({ title, group, id, stores, action, backAction, moreActions, bulkSelect, selected }) => {
  const [openMenuMore, setOpenMenuMore] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [showDialogStoreGroup, setShowDialogStoreGroup] = useState(false);
  const auth = useSelector(state => state.authentication, shallowEqual);
  const dispatch = useDispatch();
  const styles = useStyles();
  const { access_token, id_token } = auth;

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-primary": selected && bulkSelect,
        "Mui-selected": selected && bulkSelect,
        animate: true,
      })}
    >
      <Grid container wrap="nowrap" alignItems="center">
        {backAction && (
          <Grid item xs="auto">
            <CardActions className="MuiCardActions-start">
              <IconButton edge="end" onClick={backAction}>
                <VfSvgIcon icon="arrowLeft" />
              </IconButton>
            </CardActions>
          </Grid>
        )}
        <Grid item xs>
          <CardActionArea onClick={() => action && action()}>
            <CardContent className={styles.cardContent}>
              <Grid container spacing={3} justify="space-between" alignItems="center">
                <Grid item xs="auto">
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1">{title}</Typography>
                        </Grid>
                        {id && (
                          <Grid item xs="auto">
                            <Typography variant="body1" color="secondary">
                              {id}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {group && (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          Store group:
                          {group}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs="auto">
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs="auto">
                      <Typography variant="body2">{stores || "0 Stores"}</Typography>
                    </Grid>
                    {action && !moreActions && (
                      <Grid item xs="auto">
                        <Box pl={2.5} mr={-0.5}>
                          <VfSvgIcon icon="chevronRight" />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Grid>
        {moreActions && (
          <Fade in={!bulkSelect}>
            <Grid item xs="auto">
              <CardActions disableSpacing>
                <IconButton
                  aria-label="add to favorites"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={e => setOpenMenuMore(e.currentTarget)}
                >
                  <VfSvgIcon icon="more" viewBox={24} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  elevation={0}
                  anchorEl={openMenuMore}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  open={Boolean(openMenuMore)}
                  onClose={() => setOpenMenuMore(null)}
                >
                  <MenuItem
                    button
                    onClick={() => {
                      setShowDialogStoreGroup(true);
                      setOpenMenuMore(false);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    button
                    onClick={() => {
                      setOpenDialogDelete(true);
                      setOpenMenuMore(false);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>

                {showDialogStoreGroup && (
                  <DialogStoreGroups
                    storeGroup={{ _id: id, name: title }}
                    open={showDialogStoreGroup}
                    setOpen={setShowDialogStoreGroup}
                  />
                )}
                <VfDialogConfirm
                  openDialog={openDialogDelete}
                  maxWidth="xs"
                  buttonCancelText="Cancel"
                  buttonCancelAction={() => setOpenDialogDelete(false)}
                  buttonConfirmText="Delete"
                  buttonConfirmAction={() => {
                    dispatch(
                      deleteStoreGroup({
                        access_token,
                        id_token,
                        ids: [id],
                      })
                    );
                    setOpenDialogDelete(false);
                  }}
                >
                  <Typography variant="body1" align="center">
                    Are you sure you want to delete from menu
                    <br />
                    <strong>{title}</strong>
                    <br />
                    permanently?
                  </Typography>
                </VfDialogConfirm>
              </CardActions>
            </Grid>
          </Fade>
        )}
      </Grid>
    </Card>
  );
};

export default CardGroup;

/* eslint-disable no-underscore-dangle */
import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";

import VfSvgIcon from "../icons/VfSvgIcon";
import CardAppList from "../apps/CardAppList";
import CardAppsGrid from "../apps/CardAppsGrid";

const PaperConfiguratorPerEnv = ({
  env,
  envId,
  envType,
  isGlobal,
  auth,
  grid,
  appsList,
  onShowMore,
  thresholdPerEnv,
  threshold,
  handleClickApp,
}) => {
  const renderItems = ({ app }) => {
    return grid === "list" ? (
      <Grid key={app.title + app.version} item xs={12}>
        <CardAppList
          app={app}
          auth={auth}
          env={env}
          clickAction={() => handleClickApp(app, envId, env, envType,isGlobal)}
        />
      </Grid>
    ) : (
      <Grid key={app.title + app.version} item xs={12} sm={6} md={4}>
        <CardAppsGrid
          app={app}
          auth={auth}
          env={env}
          clickAction={() => handleClickApp(app, envId, env, envType,isGlobal)}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={9}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <VfSvgIcon icon={env.toLowerCase()} viewBox={56} />
              </Grid>
              <Grid item xs="auto">
                <Typography component="h5" variant="body1">
                  {env}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {appsList.length ? (
              <Grid container spacing={2}>
                {appsList
                  .filter((x, index) => {
                    return index < thresholdPerEnv[envType];
                  })
                  .map(app => renderItems({ app }))}
                <Grid item xs={12}>
                  {appsList.length - threshold > 0 &&
                    thresholdPerEnv[envType] <= threshold && (
                      <Button onClick={() => onShowMore(envType)}>
                        <Typography
                          style={{ textDecoration: "underline" }}
                          component="span"
                          variant="body2"
                        >
                          {`View${appsList.length - threshold} more`}
                        </Typography>
                      </Button>
                    )}
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body1" component="h1">
                No Applications
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaperConfiguratorPerEnv;

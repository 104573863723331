/* eslint-disable camelcase */
import axios from "axios";

class TranslationsService {
  fetchlanguages = async () => {
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/settings",
        headers: {
          "content-type": "application/json",
        },
      });
      return data;
    } catch (err) {
      console.log("Error in languages service:", err);
      return err;
    }
  };

  fetchWidgetLanguages = async ({ envID, widget, access_token, id_token }) => {
    const params = {
      widget,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/translation/translations",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetchTranslations service:", err);
      return err;
    }
  };

  // Never used???
  // fetchTranslation = async ({
  //   envID,
  //   access_token,
  //   id_token,
  //   widget,
  //   language,
  // }) => {
  //   const params = {
  //     widget,
  //     language,
  //   };
  //   try {
  //     const data = await axios({
  //       method: "get",
  //       url: "/api/translation",
  //       params,
  //       headers: {
  //         "content-type": "application/json",
  //         Authorization: `Bearer ${access_token}`,
  //         idtoken: id_token,
  //         dbname: envID,
  //       },
  //     });
  //     return data;
  //   } catch (err) {
  //     return console.log("Error in fetchTranslation service:", err);
  //   }
  // };

  saveTranslations = async ({
    envID,
    access_token,
    id_token,
    widget,
    data,
  }) => {
    const params = {
      widget,
      data,
    };
    try {
      const response = await axios({
        method: "post",
        url: "/api/appbuilder/translation/translations",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID,
        },
        data: params,
      });
      return response;
    } catch (err) {
      console.log("Error in config saveTranslations service:", err);
      return err;
    }
  };

  fetchTranslationWidget = async ({
    envID,
    access_token,
    id_token,
    searchText,
    theme,
    category,
  }) => {
    const params = {
      searchText,
      theme,
      category,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/translation/widget",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID || "",
        },
      });
      return data;
    } catch (err) {
      console.log("Error in settings service:", err);
      return err;
    }
  };

  promoteTranslations = async ({
    access_token,
    id_token,
    widgetId,
    sourceEnv,
    destinationEnv,
  }) => {
    const params = {
      widgetId,
      sourceEnv,
      destinationEnv,
    };
    try {
      const response = await axios({
        method: "post",
        url: "/api/appbuilder/translation/promote",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: params,
      });
      return response;
    } catch (err) {
      console.log("Error in config promote Translations service:", err);
      return err;
    }
  };
}

const inst = new TranslationsService();
export default inst;

/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import getInputType from "utils/getTypes";

const TextWidget = ({
  id,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  inputProps,
}) => {
  const _onChange = ({ target: { value } }) =>
    onChange(value === "" ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);
  const type = getInputType(schema.type);
  return (
    <FormControl fullWidth required={required}>
      <Tooltip title={value || ""}>
        <TextField
          id={id}
          variant="outlined"
          label={label || schema.title}
          autoFocus={autofocus}
          required={required}
          disabled={disabled || readonly}
          type={type}
          placeholder={
            schema.placeholder ||
            `Insert ${schema.title ? schema.title : "value"}`
          }
          // eslint-disable-next-line no-unneeded-ternary
          value={value === undefined ? "" : value}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          InputProps={inputProps}
        />
      </Tooltip>
    </FormControl>
  );
};

export default TextWidget;

/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
import React, { useState, useReducer, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  Snackbar,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { uniqBy } from "lodash";

import {
  saveApi,
  clearApiDetails,
  deleteVersionApi,
} from "actions/apisActions";
import theme from "AppTheme";
import VfDialogFull from "../vf/VfDialogFull";
import VfAlert from "../vf/VfAlert";
import CardPlain from "./CardPlain";
import SwaggerCard from "./SwaggerCard";
import AddSwaggerDialog from "./AddSwaggerDialog";
import SelectEnvsDialog from "./SelectEnvsDialog";
import VfSvgIcon from "../icons/VfSvgIcon";
import Loader from "../loader/Loader";
import { reducer, initialState } from "./ApiReducer";

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(6, 3, 4, 3),
  },
  slider: {
    paddingLeft: theme.spacing(3),
    "& .slick-slide": {
      paddingRight: theme.spacing(3),
    },
  },
});

const AddApiDialog = ({ show, showDialog, envs }) => {
  const classes = useStyles();

  const settings = {
    className: classes.slider,
    centerMode: false,
    infinite: false,
    slidesToShow: 3,
    speed: 500,
    arrows: false,
  };

  const [showAddSwaggerDialog, setShowAddSwaggerDialog] = useState(false);
  const [showSelectEnvsDialog, setShowSelectEnvsDialog] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [swaggerFile, setSwaggerFile] = useState(null);
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const [apiInfo, dispatch] = useReducer(reducer, initialState);

  const reduxDispatch = useDispatch();
  const postApi = payload => reduxDispatch(saveApi(payload));
  const cleanApiDetails = () => reduxDispatch(clearApiDetails());
  const deleteVersion = payload => reduxDispatch(deleteVersionApi(payload));

  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );
  const { loading, error, currentApi } = useSelector(
    state => state.apis,
    shallowEqual
  );

  useEffect(() => {
    const expectedFilesNumber =
      uniqBy(apiInfo.files, "fileId").length *
      envs.filter(e => !e.isGlobal).length;
    const showWarning = apiInfo.files.length < expectedFilesNumber;
    setShowWarningMessage(showWarning);
  }, [apiInfo]);

  useEffect(() => {
    if (currentApi) {
      dispatch({ type: "setApiInfo", payload: currentApi });
    }
  }, [currentApi]);

  const onSaveAction = () => {
    postApi({ access_token, id_token, body: apiInfo });
    if (currentApi) cleanApiDetails();
  };

  return (
    <VfDialogFull
      appBar={
        <Grid container spacing={3} justify="space-between">
          <Grid item xs="auto">
            <Typography variant="body1" component="h5">
              Add API
            </Typography>
          </Grid>
        </Grid>
      }
      buttonConfirmText="Save"
      hasValidations
      buttonConfirmDisabled={!apiInfo.name.length}
      buttonConfirmAction
      saveAction={onSaveAction}
      buttonCloseAction={() => {
        showDialog(false);
        if (currentApi) cleanApiDetails();
      }}
      openDialog={show}
    >
      {loading ? (
        <Grid item xs={12}>
          <Loader size={theme.spacing(12)} />
        </Grid>
      ) : (
        <>
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={4}>
              <CardPlain title="Enter API's name">
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    variant="outlined"
                    label="Name"
                    type="text"
                    placeholder="Tenant API"
                    value={apiInfo.name}
                    onChange={e =>
                      dispatch({ type: "setName", payload: e.target.value })
                    }
                  />
                </FormControl>
              </CardPlain>
            </Grid>
            <Grid item xs={8}>
              <CardPlain title="Add Tags (Optional)">
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    options={[]}
                    freeSolo
                    name="tags"
                    closeIcon={<VfSvgIcon icon="close" fontSize="small" />}
                    onChange={(event, values) => {
                      dispatch({ type: "setTags", payload: values });
                    }}
                    value={apiInfo.tags}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        placeholder="Tag..."
                      />
                    )}
                  />
                </FormControl>
              </CardPlain>
            </Grid>
            {showWarningMessage && (
              <Grid item xs={8}>
                <VfAlert
                  severity="warning"
                  message={
                    <Typography variant="body1" component="h5">
                      Some versions are not available in all environments and
                      wont be able to add themon apps
                    </Typography>
                  }
                />
              </Grid>
            )}
          </Grid>
          <Slider {...settings}>
            {envs
              .sort((a, b) => a.order - b.order)
              .map(
                env =>
                  !env.isGlobal && (
                    <>
                      <SwaggerCard
                        env={env}
                        setShowAddSwaggerDialog={setShowAddSwaggerDialog}
                        setSelectedEnv={setSelectedEnv}
                        deleteVersion={deleteVersion}
                        auth={{ accessToken: access_token, idToken: id_token }}
                        apiInfo={apiInfo}
                        files={apiInfo.files.filter(
                          file => file.env === env._id
                        )}
                        dispatch={dispatch}
                      />
                    </>
                  )
              )}
          </Slider>
          {error && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={error}
              onClose={() => {}}
            >
              <VfAlert severity="error" message={error.message} />
            </Snackbar>
          )}
          {showAddSwaggerDialog && (
            <AddSwaggerDialog
              show={showAddSwaggerDialog}
              setShow={setShowAddSwaggerDialog}
              setShowSelectEnvsDialog={setShowSelectEnvsDialog}
              dispatch={dispatch}
              swaggerFile={swaggerFile}
              setSwaggerFile={setSwaggerFile}
              selectedEnv={selectedEnv}
              envs={envs}
            />
          )}
          {showSelectEnvsDialog && (
            <SelectEnvsDialog
              show={showSelectEnvsDialog}
              setShow={setShowSelectEnvsDialog}
              envs={envs}
              selectedEnv={selectedEnv}
              dispatch={dispatch}
              swaggerFile={swaggerFile}
              setSwaggerFile={setSwaggerFile}
              apiInfo={apiInfo}
            />
          )}
        </>
      )}
    </VfDialogFull>
  );
};

export default AddApiDialog;

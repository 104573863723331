const GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST";
const GET_CAMPAIGNS_REQUEST_SUCCESS = "GET_CAMPAIGNS_REQUEST_SUCCESS";
const GET_CAMPAIGNS_REQUEST_FAIL = "GET_CAMPAIGNS_REQUEST_FAIL";
const ADD_CAMPAIGN_REQUEST = "ADD_CAMPAIGN_REQUEST";
const ADD_CAMPAIGN_REQUEST_SUCCESS = "ADD_CAMPAIGN_REQUEST_SUCCESS";
const ADD_CAMPAIGN_REQUEST_FAIL = "ADD_CAMPAIGN_REQUEST_FAIL";
const UPDATE_CAMPAIGNS_REQUEST = "UPDATE_CAMPAIGNS_REQUEST";
const UPDATE_CAMPAIGNS_REQUEST_SUCCESS = "UPDATE_CAMPAIGNS_REQUEST_SUCCESS";
const UPDATE_CAMPAIGNS_REQUEST_FAIL = "UPDATE_CAMPAIGNS_REQUEST_FAIL";
const DELETE_CAMPAIGNS_REQUEST = "DELETE_CAMPAIGNS_REQUEST";
const DELETE_CAMPAIGNS_REQUEST_SUCCESS = "DELETE_CAMPAIGNS_REQUEST_SUCCESS";
const DELETE_CAMPAIGNS_REQUEST_FAIL = "DELETE_CAMPAIGNS_REQUEST_FAIL";
const BULK_DELETE_CAMPAIGNS_REQUEST = "BULK_DELETE_CAMPAIGNS_REQUEST";
const BULK_DELETE_CAMPAIGNS_REQUEST_SUCCESS = "BULK_DELETE_CAMPAIGNS_REQUEST_SUCCESS";
const BULK_DELETE_CAMPAIGNS_REQUEST_FAIL = "BULK_DELETE_CAMPAIGNS_REQUEST_FAIL";
const REMOVE_ERROR = "REMOVE_ERROR";

const fetchCampaigns = payload => ({
	type: GET_CAMPAIGNS_REQUEST,
	payload,
});

const fetchCampaignsSuccess = payload => ({
  type: GET_CAMPAIGNS_REQUEST_SUCCESS,
  payload,
});

const fetchCampaignsFail = payload => ({
  type: GET_CAMPAIGNS_REQUEST_FAIL,
  error: payload
});

const addCampaign = payload => ({
  type: ADD_CAMPAIGN_REQUEST,
  payload,
});

const addCampaignSuccess = payload => ({
  type: ADD_CAMPAIGN_REQUEST_SUCCESS,
  payload,
});

const addCampaignFail = payload => ({
  type: ADD_CAMPAIGN_REQUEST_FAIL,
  error: payload
});

const updateCampaigns = payload => ({
  type: UPDATE_CAMPAIGNS_REQUEST,
  payload,
});

const updateCampaignsSuccess = payload => ({
  type: UPDATE_CAMPAIGNS_REQUEST_SUCCESS,
  payload,
});

const updateCampaignsFail = payload => ({
  type: UPDATE_CAMPAIGNS_REQUEST_FAIL,
  error: payload
});
  
const deleteCampaign = payload => ({
  type: DELETE_CAMPAIGNS_REQUEST,
  payload,
});

const deleteCampaignSuccess = payload => ({
  type: DELETE_CAMPAIGNS_REQUEST_SUCCESS,
  payload,
});

const deleteCampaignFail = payload => ({
  type: DELETE_CAMPAIGNS_REQUEST_FAIL,
  error: payload
});

const bulkDeleteCampaigns = payload => ({
  type: BULK_DELETE_CAMPAIGNS_REQUEST,
  payload,
});

const bulkDeleteCampaignsSuccess = payload => ({
  type: BULK_DELETE_CAMPAIGNS_REQUEST_SUCCESS,
  payload,
});

const bulkDeleteCampaignsFail = payload => ({
  type: BULK_DELETE_CAMPAIGNS_REQUEST_FAIL,
  error: payload,
});

const removeError = () => ({
  type: REMOVE_ERROR,
});

export {
	GET_CAMPAIGNS_REQUEST,
	GET_CAMPAIGNS_REQUEST_SUCCESS,
  GET_CAMPAIGNS_REQUEST_FAIL,
  ADD_CAMPAIGN_REQUEST,
	ADD_CAMPAIGN_REQUEST_SUCCESS,
  ADD_CAMPAIGN_REQUEST_FAIL,
  UPDATE_CAMPAIGNS_REQUEST,
	UPDATE_CAMPAIGNS_REQUEST_SUCCESS,
  UPDATE_CAMPAIGNS_REQUEST_FAIL,
  DELETE_CAMPAIGNS_REQUEST,
	DELETE_CAMPAIGNS_REQUEST_SUCCESS,
  DELETE_CAMPAIGNS_REQUEST_FAIL,
  BULK_DELETE_CAMPAIGNS_REQUEST,
  BULK_DELETE_CAMPAIGNS_REQUEST_SUCCESS,
  BULK_DELETE_CAMPAIGNS_REQUEST_FAIL,
  REMOVE_ERROR,
  fetchCampaigns,
  fetchCampaignsSuccess,
  fetchCampaignsFail,
	addCampaign,
  addCampaignSuccess,
  addCampaignFail,
  updateCampaigns,
  updateCampaignsSuccess,
  updateCampaignsFail,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFail,
  bulkDeleteCampaigns,
  bulkDeleteCampaignsSuccess,
  bulkDeleteCampaignsFail,
  removeError,
};

import React, { useRef } from "react";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import { debounce } from "lodash";

const AppEditCode = ({ appBarHeight, toolBarHeight, djr, setDjr }) => {
  const inputEl = useRef(null);
  const onChange = newValue => {
    setDjr(newValue);
  };
  const debouncedOnChange = debounce(onChange, 800);

  return (
    <AceEditor
      style={{
        height: `calc(100vh - ${appBarHeight}px - ${toolBarHeight}px)`,
        width: "100%",
      }}
      mode="json"
      theme="tomorrow"
      onChange={debouncedOnChange}
      value={djr}
      name="json-editor"
      ref={inputEl}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
      }}
    />
  );
};

export default AppEditCode;

import React, { useState } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";

import DateFnsUtils from "@date-io/date-fns";
import VfSvgIcon from "../icons/VfSvgIcon";

const FilterDateInput = ({ fullWidth, label, selectedDate, setSelectedDate }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <KeyboardDatePicker
            fullWidth={fullWidth}
            size="small"
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={label}
            format="dd/MM/yyyy"
            value={selectedDate}
            InputAdornmentProps={{ position: "end" }}
            KeyboardButtonProps={{ size: "small" }}
            keyboardIcon={<VfSvgIcon icon="calendar" />}
            leftArrowIcon={<VfSvgIcon icon="chevronLeft" />}
            rightArrowIcon={<VfSvgIcon icon="chevronRight" />}
            onChange={date => setSelectedDate(date)}
            disableToolbar
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FilterDateInput;

const GET_STORES_REQUEST = "GET_STORES_REQUEST";
const GET_STORES_REQUEST_SUCCESS = "GET_STORES_REQUEST_SUCCESS";
const GET_STORES_REQUEST_FAIL = "GET_STORES_REQUEST_FAIL";
const GET_ALL_STORES_AND_DEVICES_REQUEST = "FETCH_ALL_STORES_AND_DEVICES_REQUEST";
const GET_ALL_STORES_AND_DEVICES_REQUEST_SUCCESS = "FETCH_ALL_STORES_AND_DEVICES_REQUEST_SUCCESS";
const GET_ALL_STORES_AND_DEVICES_REQUEST_FAIL = "FETCH_ALL_STORES_AND_DEVICES_REQUEST_FAIL";
const ADD_STORE_REQUEST = "ADD_STORE_REQUEST";
const ADD_STORE_REQUEST_SUCCESS = "ADD_STORE_REQUEST_SUCCESS";
const ADD_STORE_REQUEST_FAIL = "ADD_STORE_REQUEST_FAIL";
const IMPORT_STORES_REQUEST = "  IMPORT_STORES_REQUEST";
const IMPORT_STORES_REQUEST_SUCCESS = "  IMPORT_STORES_REQUEST_SUCCESS";
const IMPORT_STORES_REQUEST_FAIL = "  IMPORT_STORES_REQUEST_FAIL";
const EXPORT_STORES_REQUEST = "  EXPORT_STORES_REQUEST";
const EXPORT_STORES_REQUEST_SUCCESS = "  EXPORT_STORES_REQUEST_SUCCESS";
const EXPORT_STORES_REQUEST_FAIL = "  EXPORT_STORES_REQUEST_FAIL";
const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
const UPDATE_STORE_REQUEST_SUCCESS = "UPDATE_STORE_REQUEST_SUCCESS";
const UPDATE_STORE_REQUEST_FAIL = "UPDATE_STORE_REQUEST_FAIL";
const DELETE_STORE_REQUEST = "DELETE_STORE_REQUEST";
const DELETE_STORE_REQUEST_SUCCESS = "DELETE_STORE_REQUEST_SUCCESS";
const DELETE_STORE_REQUEST_FAIL = "DELETE_STORE_REQUEST_FAIL";
const BULK_DELETE_STORES_REQUEST = "BULK_DELETE_STORES_REQUEST";
const BULK_DELETE_STORES_REQUEST_SUCCESS = "BULK_DELETE_STORES_REQUEST_SUCCESS";
const BULK_DELETE_STORES_REQUEST_FAIL = "BULK_DELETE_STORES_REQUEST_FAIL";
const REMOVE_ERROR = "REMOVE_ERROR";

const fetchStores = payload => ({
  type: GET_STORES_REQUEST,
  payload,
});

const fetchStoresSuccess = payload => ({
  type: GET_STORES_REQUEST_SUCCESS,
  payload,
});
const fetchStoresFail = error => ({
  type: GET_STORES_REQUEST_FAIL,
  error,
});

const fetchAllStoresAndDevices = payload => ({
  type: GET_ALL_STORES_AND_DEVICES_REQUEST,
  payload,
});

const fetchAllStoresAndDevicesSuccess = payload => ({
  type: GET_ALL_STORES_AND_DEVICES_REQUEST_SUCCESS,
  payload,
});

const fetchAllStoresAndDevicesFail = error => ({
  type: GET_ALL_STORES_AND_DEVICES_REQUEST_FAIL,
  error,
});

const addStore = payload => ({
  type: ADD_STORE_REQUEST,
  payload,
});

const addStoreSuccess = payload => ({
  type: ADD_STORE_REQUEST_SUCCESS,
  payload,
});

const addStoreFail = error => ({
  type: ADD_STORE_REQUEST_FAIL,
  error,
});

const importStores = payload => ({
  type: IMPORT_STORES_REQUEST,
  payload,
});

const importStoresSuccess = payload => ({
  type: IMPORT_STORES_REQUEST_SUCCESS,
  payload,
});

const importStoresFail = error => ({
  type: IMPORT_STORES_REQUEST_FAIL,
  error,
});

const exportStores = payload => ({
  type: EXPORT_STORES_REQUEST,
  payload,
});

const exportStoresSuccess = payload => ({
  type: EXPORT_STORES_REQUEST_SUCCESS,
  payload,
});

const exportStoresFail = error => ({
  type: EXPORT_STORES_REQUEST_FAIL,
  error,
});

const updateStore = payload => ({
  type: UPDATE_STORE_REQUEST,
  payload,
});

const updateStoreSuccess = payload => ({
  type: UPDATE_STORE_REQUEST_SUCCESS,
  payload,
});

const updateStoreFail = error => ({
  type: UPDATE_STORE_REQUEST_FAIL,
  error,
});

const deleteStore = payload => ({
  type: DELETE_STORE_REQUEST,
  payload,
});

const deleteStoreSuccess = payload => ({
  type: DELETE_STORE_REQUEST_SUCCESS,
  payload,
});

const deleteStoreFail = error => ({
  type: DELETE_STORE_REQUEST_FAIL,
  error,
});

const bulkDeleteStores = payload => ({
  type: BULK_DELETE_STORES_REQUEST,
  payload,
});

const bulkDeleteStoresSuccess = payload => ({
  type: BULK_DELETE_STORES_REQUEST,
  payload,
});

const bulkDeleteStoresFail = error => ({
  type: BULK_DELETE_STORES_REQUEST,
  error,
});

const removeError = () => ({
  type: REMOVE_ERROR,
});

export {
  GET_STORES_REQUEST,
  GET_STORES_REQUEST_SUCCESS,
  GET_STORES_REQUEST_FAIL,
  GET_ALL_STORES_AND_DEVICES_REQUEST,
  GET_ALL_STORES_AND_DEVICES_REQUEST_FAIL,
  GET_ALL_STORES_AND_DEVICES_REQUEST_SUCCESS,
  ADD_STORE_REQUEST,
  ADD_STORE_REQUEST_FAIL,
  ADD_STORE_REQUEST_SUCCESS,
  IMPORT_STORES_REQUEST,
  IMPORT_STORES_REQUEST_FAIL,
  IMPORT_STORES_REQUEST_SUCCESS,
  EXPORT_STORES_REQUEST,
  EXPORT_STORES_REQUEST_FAIL,
  EXPORT_STORES_REQUEST_SUCCESS,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_REQUEST_FAIL,
  UPDATE_STORE_REQUEST_SUCCESS,
  DELETE_STORE_REQUEST,
  DELETE_STORE_REQUEST_FAIL,
  DELETE_STORE_REQUEST_SUCCESS,
  BULK_DELETE_STORES_REQUEST,
  BULK_DELETE_STORES_REQUEST_FAIL,
  BULK_DELETE_STORES_REQUEST_SUCCESS,
  REMOVE_ERROR,
  fetchStores,
  fetchStoresSuccess,
  fetchStoresFail,
  fetchAllStoresAndDevices,
  fetchAllStoresAndDevicesSuccess,
  fetchAllStoresAndDevicesFail,
  importStores,
  importStoresSuccess,
  importStoresFail,
  exportStores,
  exportStoresSuccess,
  exportStoresFail,
  addStore,
  addStoreSuccess,
  addStoreFail,
  updateStore,
  updateStoreSuccess,
  updateStoreFail,
  deleteStore,
  deleteStoreSuccess,
  deleteStoreFail,
  bulkDeleteStores,
  bulkDeleteStoresSuccess,
  bulkDeleteStoresFail,
  removeError,
};

import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import VfDialog from "../../vf/VfDialog";
import CardUpload from "../../media/CardUpload";
import { importStores } from "actions/storeActions";

const DialogStoreImport = ({ open, setOpen }) => {
  const auth = useSelector(state => state.authentication, shallowEqual);
  const dispatch = useDispatch();
  const { access_token, id_token } = auth;

  return (
    <VfDialog
      title="Bulk store import"
      subTitle="Supported file format: CSV"
      openDialog={open}
      buttonCloseAction={() => setOpen(false)}
      maxWidth="sm"
    >
      <CardUpload
        postMedia={req => {
          dispatch(importStores(req));
          setOpen(!open);
        }}
        access_token={access_token}
        id_token={id_token}
        wide
      />
    </VfDialog>
  );
};

export default DialogStoreImport;

const globalReduxSchema = {
  title: "State",
  type: "object",
  properties: {
    authentication: {
      title: "authentication",
      type: "object",
      properties: {
        access_token: {
          title: "access_token",
          type: "string",
        },
        id_token: {
          title: "id_token",
          type: "string",
        },
        ctry: {
          title: "ctry",
          type: "string",
        },
      },
    },
    config: {
      title: "config",
      type: "object",
      properties: {
        data: {
          title: "data",
          type: "object",
          properties: {
            global: {
              title: "global",
              type: "object",
              properties: {
                app: {
                  type: "string",
                  title: "App name",
                  default: "Web1",
                  readOnly: true,
                },
                versionName: {
                  type: "string",
                  title: "Version name",
                  default: "1.0.0",
                  readOnly: true,
                },
                versionCode: {
                  type: "integer",
                  title: "Version code",
                  default: 1,
                  readOnly: true,
                },
                tags: {
                  type: "string",
                  title: "Tags",
                },
                TTL: {
                  title: "TTL",
                  type: "integer",
                  default: 600,
                  minimum: 0,
                  maximum: 3600,
                },
                isPushable: {
                  title: "Is Pushable",
                  type: "boolean",
                  default: false,
                },
                hideHeader: {
                  title: "Hide Header",
                  type: "boolean",
                  default: false,
                },
                config_acl: {
                  type: "array",
                  title: "ACL",
                  items: {
                    type: "object",
                    title: "ACL roles",
                    properties: {
                      role: {
                        type: "string",
                        enum: ["ADMIN", "DEVOPS"],
                      },
                      accessLevels: {
                        type: "array",
                        items: {
                          type: "string",
                          enum: ["CREATE", "READ", "UPDATE", "DELETE"],
                        },
                      },
                    },
                  },
                },
                screenLockTimeout: {
                  type: "string",
                  title: "Screen Lock Timeout",
                  default: 600,
                  minimum: 60,
                  maximum: 3600,
                },
                readMe: {
                  type: "string",
                  title: "Read me",
                },
                contactEmail: {
                  type: "string",
                  title: "Contact email",
                },
                refDoc: {
                  type: "string",
                  title: "Reference documentation",
                },
              },
            },
            widgets: { title: "widgets", type: "object" },
          },
        },
      },
    },
  },
};

const EventSchema = {
  title: "Event Schema",
  type: "object",
  properties: {
    instanceId: {
      title: "instanceId",
      type: "string",
    },
    type: {
      title: "type",
      type: "string",
    },
    payload: {
      title: "payload",
      type: "object",
      properties: {},
    },
  },
};

const CallApiSchema = {
  title: "Call Api Schema",
  type: "object",
  properties: {
    ok: {
      title: "ok",
      type: "boolean",
    },
    status: {
      title: "status",
      type: "integer",
    },
    headers: {
      title: "headers",
      type: "object",
      properties: {
        "content-type": {
          title: "content-type",
          type: "string",
        },
      },
    },
    body: {
      title: "body",
      type: "object",
      properties: {},
    },
  },
};

export { globalReduxSchema, EventSchema, CallApiSchema };

/* eslint-disable no-nested-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-shadow */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  AppBar,
  Box,
  Collapse,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
} from "@material-ui/core";
import { isEmpty, uniqBy } from "lodash";
import compareVersions from "compare-versions";

import {
  setSelectedWidget,
  clearSelectedWidget,
  setSelectedWidgetVersions,
  clearSelectedWidgetVersions,
  setApp,
  clearCurrRowCol,
} from "actions/applicationsActions";
import theme from "AppTheme";

import widgets from "react-jsonschema-form/lib/components/widgets";
import DisplayType from "../displaytype/DisplayType";
import SearchBar from "../searchbar/SearchBar";
import CardWidgetList from "./CardWidgetList";
import CardWidgetTile from "./CardWidgetTile";
import VfDialog from "../vf/VfDialog";
import AppsPages from "./AppsPages";
import AppsMenus from "./AppsMenus";
import CardWidgetVersion from "./CardWidgetVersion";
import VfSvgIcon from "../icons/VfSvgIcon";
import WidgetProps from "./WidgetProps";
import AppsSidebarList from "./AppsSidebarList";
import WidgetInstanceNameForm from "./WidgetInstanceNameForm";

const appBarHeight = theme.spacing(43);
const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "calc(100vh - 76px)",
    overflowX: "hidden",
    paddingTop: theme.spacing(6),
    top: 0,
    ["& .MuiBox-searchBar"]: {
      position: "absolute",
      left: theme.spacing(3),
      right: theme.spacing(3),
      top: "50%",
      transform: "translateY(-50%)",
      ["& .MuiIconButton-root"]: {
        padding: theme.spacing(1),
      },
      ["& .MuiCard-search"]: {
        width: 42,
        ["&.MuiCard-search--open"]: {
          width: "100%",
        },
      },
    },
    ["& .MuiExpansionPanelSummary-root"]: {
      ["& .MuiExpansionPanelSummary-content"]: {
        ["&.Mui-expanded"]: {
          marginTop: theme.spacing(1.5),
          marginBottom: theme.spacing(1.5),
          color: theme.palette.secondary.main,
        },
      },
      ["& .MuiExpansionPanelSummary-expandIcon"]: {
        transform: "rotate(270deg)",
        ["&.Mui-expanded"]: {
          transform: "rotate(0deg)",
          ["& .MuiSvgIcon-root"]: {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
  },
  btnClose: {
    position: "absolute",
    top: 0,
    right: -theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    borderBottomColor: theme.palette.grey[100],
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  menuPages: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    width: `calc(100% - ${theme.spacing(3)}px)`,
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
    "& .MuiButton-endIcon": {
      marginRight: 0,
    },
  },
  sepItem: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[100],
  },
  tabs: {
    "& .MuiTab-labelIcon": {
      minHeight: "auto",
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "& .MuiTab-wrapper": {
        flexDirection: "row",
        "& .MuiSvgIcon-root": {
          marginBottom: 0,
          marginRight: theme.spacing(1),
        },
      },
    },
  },
  tabsSecondary: {
    "& .MuiTab-root": {
      paddingLeft: 6,
      paddingRight: 6,
      backgroundColor: theme.palette.grey[100],
      fontWeight: 400,
      minHeight: 48,
      "&.Mui-selected": {
        backgroundColor: theme.palette.common.white,
        fontWeight: 700,
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  propsTab: {
    padding: theme.spacing(3),
    minHeight: `calc(100vh - ${appBarHeight}px)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiCardMedia-root": {
      height: theme.spacing(9),
      maxWidth: theme.spacing(38),
      width: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      opacity: 0.5,
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(11),
      },
    },
    "& .MuiCardContent-root": {
      maxWidth: theme.spacing(48),
      textAlign: "center",
    },
    "& .MuiCardActions-root": {
      padding: theme.spacing(2),
    },
  },
});

const AppsSidebar = ({
  setWidget,
  clearWidget,
  clearWidgetVersions,
  widgetVersions,
  categoryWidgets,
  auth,
  gridListView,
  setGridListView,
  sidebarItem,
  setSidebarItem,
  application,
  showPages,
  dispatchShowPages,
  setApplication,
  selectedWdgt,
  setAppSection,
  setDialogLogic,
  setSagaId,
  handleRemoveSaga,
  setDialogApi,
  setApiId,
  setDialogApiVersion,
  handleRemoveApi,
  setDialogPackage,
  setPackageId,
  setDialogPackageVersion,
  handleRemovePackage,
  setDialogSnippet,
  setSnippetId,
  handleRemoveSnippet,
  isLogicApiPckSnipSelected,
}) => {
  const classes = useStyles();
  const { access_token, id_token } = auth;
  const { appInfo } = application;
  const [expanded, setExpanded] = useState(null);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const widgetSearchText = (widget, text) => {
    const result = widget.keywords
      ? widget.keywords
          .join(" ")
          .concat(widget.description)
          .toLowerCase()
          .includes(text.toLowerCase())
      : widget.description.toLowerCase().includes(text.toLowerCase());
    return result;
  };

  const widgetShowBoolean = (widget, text) => {
    return (
      widget.appFramework &&
      widget.appFramework.theme &&
      widget.appFramework.theme.includes(appInfo.theming) &&
      widgetSearchText(widget, text)
    );
  };
  const uniqWidgets = unfilteredWidgets => {
    return uniqBy(
      unfilteredWidgets
        .sort((a, b) => {
          return compareVersions(a.version, b.version);
        })
        .reverse(),
      "name"
    );
  };

  const sidebarItemToAppSection = sbItem => {
    switch (sbItem) {
      case "menuList":
        return "menu";
      case "javascript":
      case "appLogicList":
      case "apiList":
      case "packagesList":
        return "logic";
      default:
        return "design";
    }
  };

  return (
    <>
      <Box className={classes.root}>
        {appInfo?.isDeployed ? (
          <AppBar elevation={0} position="absolute" className={classes.appBar}>
            <Tabs className={classes.tabs} variant="fullWidth">
              <Tab
                icon={<VfSvgIcon icon="designer" />}
                /* eslint-disable */
                label={
                  <Typography variant="body2" color="textPrimary">
                    Props
                  </Typography>
                }
              />
            </Tabs>
          </AppBar>
        ) : (
          <AppBar elevation={0} position="absolute" className={classes.appBar}>
            <Tabs
              className={classes.tabs}
              variant="fullWidth"
              value={sidebarItem}
              onChange={(event, newValue) => {
                setAppSection(sidebarItemToAppSection(newValue));
                setSidebarItem(newValue);
              }}
            >
              <Tab
                icon={<VfSvgIcon icon="designer" />}
                /* eslint-disable */
                label={
                  <Typography variant="body2" color="textPrimary">
                    Design
                  </Typography>
                }
                /* eslint-disable no-underscore-dangle */
                value={
                  ["widgetVersion", "widgetEditor", "widgetList"].includes(
                    sidebarItem
                  )
                    ? sidebarItem
                    : "widgetList"
                }
              />
              <Tab
                icon={<VfSvgIcon icon="logic" />}
                /* eslint-disable */
                label={
                  <Typography variant="body2" color="textPrimary">
                    Logic
                  </Typography>
                }
                /* eslint-enable */
                /* eslint-disable no-underscore-dangle */
                value={
                  [
                    "appLogicList",
                    "apiList",
                    "packagesList",
                    "javascript",
                  ].includes(sidebarItem)
                    ? sidebarItem
                    : "appLogicList"
                }
              />
              <Tab
                icon={<VfSvgIcon icon="breakdown" />}
                /* eslint-disable */
                label={
                  <Typography variant="body2" color="textPrimary">
                    Menu
                  </Typography>
                }
                /* eslint-enable */
                /* eslint-disable no-underscore-dangle */
                value="menuList"
              />
            </Tabs>
          </AppBar>
        )}
        <Collapse mountOnEnter unmountOnExit in={sidebarItem === "widgetList"}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                mt={3}
                px={3}
                className="MuiBox-searchOverlay"
                position="relative"
              >
                <DisplayType
                  grid={gridListView}
                  handleGridView={setGridListView}
                />

                <SearchBar
                  /* eslint-disable no-shadow */
                  callback={({ searchText }) => setSearchText(searchText)}
                  externalState={setSearchExpanded}
                  /* eslint-enable */
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {categoryWidgets.map(({ _id, widgets: widgetList, type }) => {
                  if (
                    uniqWidgets(widgetList).filter(widget =>
                      widgetShowBoolean(widget, searchText)
                    ).length === 0
                  ) {
                    return;
                  }
                  return (
                    <Grid item xs={12} key={_id}>
                      <Accordion
                        square
                        elevation={0}
                        expanded={expanded === (_id || "default")}
                        onChange={handleChange(_id || "default")}
                      >
                        <AccordionSummary
                          IconButtonProps={{ edge: false }}
                          expandIcon={
                            <VfSvgIcon icon="chevronDown" viewBox={24} />
                          }
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography variant="body1">
                            {_id || "Uncategorized"}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {expanded === (_id || "default") && (
                            <Grid container spacing={3}>
                              {uniqWidgets(widgetList)
                                .filter(widget =>
                                  widgetShowBoolean(widget, searchText)
                                )
                                .map(widget => {
                                  return gridListView === "list" ? (
                                    <Grid
                                      item
                                      xs={12}
                                      key={widget.name + widget.version}
                                    >
                                      <CardWidgetList
                                        widget={{ ...widget, type }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={12}
                                      key={widget.name + widget.version}
                                    >
                                      <CardWidgetTile
                                        widget={{ ...widget, type }}
                                      />
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        {widgetVersions && selectedWdgt && (
          <Collapse
            mountOnEnter
            unmountOnExit
            in={sidebarItem === "widgetVersion"}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  mt={3}
                  px={3}
                  className="MuiBox-searchOverlay"
                  position="relative"
                >
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          setSidebarItem("widgetList");
                          clearWidget();
                          clearWidgetVersions();
                        }}
                      >
                        <VfSvgIcon icon="close" viewBox={24} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography className="bold" variant="h5" component="h3">
                        Widget Version
                      </Typography>
                    </Grid>
                  </Grid>
                  <SearchBar />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box py={3} px={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1">Select version</Typography>
                    </Grid>
                    {widgetVersions.map(widget => (
                      <Grid item xs={12} key={widget._id}>
                        <CardWidgetVersion
                          appInfo={appInfo}
                          setApplication={setApplication}
                          setWidget={setWidget}
                          widget={widget}
                          selected={widget._id === selectedWdgt._id}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        )}
        {
          <Collapse
            mountOnEnter
            unmountOnExit
            in={sidebarItem === "widgetEditor"}
          >
            {!selectedWdgt ? (
              <Grid item xs={12}>
                <Box py={3} px={3}>
                  <Grid container spacing={3}>
                    <Card
                      square
                      className={classNames(classes.propsTab)}
                      elevation={0}
                    >
                      <CardContent>
                        <Typography
                          className="bold"
                          variant="h5"
                          component="h2"
                        >
                          Select a widget first
                        </Typography>
                        <Typography variant="body1" component="p">
                          Then configure the props of the selected widget
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              <WidgetProps
                setSidebarItem={setSidebarItem}
                selectedWdgt={selectedWdgt}
              />
            )}
          </Collapse>
        }
        {selectedWdgt && (
          <Collapse
            mountOnEnter
            unmountOnExit
            in={sidebarItem === "widgetInstanceName"}
          >
            <WidgetInstanceNameForm
              setSidebarItem={setSidebarItem}
              selectedWdgt={selectedWdgt}
              application={application}
            />
          </Collapse>
        )}
        {
          <Collapse mountOnEnter unmountOnExit in={sidebarItem === "pageList"}>
            <AppsPages
              setSidebarItem={setSidebarItem}
              setAppSection={setAppSection}
            />
          </Collapse>
        }
        {/* <Collapse mountOnEnter unmountOnExit in={sidebarItem === "appLogicEditor"}>
          <AppsSidebarLogicEditor />
        </Collapse> */}
        <Collapse
          mountOnEnter
          unmountOnExit
          in={[
            "appLogicList",
            "apiList",
            "packagesList",
            "javascript",
          ].includes(sidebarItem)}
        >
          <Tabs
            className={classes.tabsSecondary}
            variant="fullWidth"
            value={sidebarItem}
            onChange={(event, newValue) => {
              setAppSection(sidebarItemToAppSection(newValue));
              setSidebarItem(newValue);
            }}
          >
            <Tab
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2" color="textPrimary">
                  APIS
                </Typography>
              }
              value="apiList"
            />
            <Tab
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2" color="textPrimary">
                  FLOWS
                </Typography>
              }
              value="appLogicList"
            />
            <Tab
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2" color="textPrimary">
                  PCKGS
                </Typography>
              }
              value="packagesList"
            />
            <Tab
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2" color="textPrimary">
                  SNIPS
                </Typography>
              }
              value="javascript"
            />
          </Tabs>
          {sidebarItem === "appLogicList" && (
            <AppsSidebarList
              isLogicApiPckSnipSelected={isLogicApiPckSnipSelected}
              selector="applications.current.appInfo.sagas"
              dictionary={{
                items: "Logics",
                addFirst: "Add the first logic",
                addFirstSub: "Add a logic and start building it",
                add: "Add logic",
                edit: "Edit logic",
                design: "Design logic",
                delete: "Delete logic",
              }}
              setSidebarItem={() => setSidebarItem("appLogicList")}
              setDialogFlag={setDialogLogic}
              setTheId={setSagaId}
              handleRemoveItem={handleRemoveSaga}
              onOpenAddDialog={() => {
                setSagaId(null);
                setDialogLogic(true);
              }}
            />
          )}
          {sidebarItem === "apiList" && (
            <AppsSidebarList
              isLogicApiPckSnipSelected={isLogicApiPckSnipSelected}
              selector="applications.current.appInfo.apis"
              dictionary={{
                items: "APIs",
                addFirst: "Add the first API",
                addFirstSub: "Add an API to use it in flows",
                add: "Add API",
                edit: "Edit API",
                design: "Configure",
                delete: "Delete API",
              }}
              sidebarItem={sidebarItem}
              setSidebarItem={() => setSidebarItem("apiList")}
              setDialogFlag={setDialogApiVersion}
              setTheId={setApiId}
              handleRemoveItem={handleRemoveApi}
              onOpenAddDialog={() => {
                setDialogApi(true);
              }}
            />
          )}
          {sidebarItem === "packagesList" && (
            <AppsSidebarList
              isLogicApiPckSnipSelected={isLogicApiPckSnipSelected}
              selector="applications.current.appInfo.packages"
              dictionary={{
                items: "Packages",
                addFirst: "Add the first Package",
                addFirstSub: "Add a Package to use it in flows",
                add: "Add Package",
                edit: "Edit Package",
                design: "View Package",
                delete: "Delete Package",
              }}
              sidebarItem={sidebarItem}
              setSidebarItem={() => setSidebarItem("packagesList")}
              setDialogFlag={setDialogPackageVersion}
              setTheId={setPackageId}
              handleRemoveItem={handleRemovePackage}
              onOpenAddDialog={() => {
                setDialogPackage(true);
              }}
            />
          )}
          {sidebarItem === "javascript" && (
            <AppsSidebarList
              isLogicApiPckSnipSelected={isLogicApiPckSnipSelected}
              selector="applications.current.appInfo.snippets"
              dictionary={{
                items: "Snippets",
                addFirst: "Add the first snippet",
                addFirstSub: "Add a snippet and start coding it",
                add: "Add snippet",
                edit: "Configure",
                design: "Design snippet",
                delete: "Delete snippet",
              }}
              setSidebarItem={() => setSidebarItem("javascript")}
              setDialogFlag={setDialogSnippet}
              setTheId={setSnippetId}
              handleRemoveItem={handleRemoveSnippet}
              onOpenAddDialog={() => {
                setSnippetId(null);
                setDialogSnippet(true);
              }}
            />
          )}
        </Collapse>
        <Collapse mountOnEnter unmountOnExit in={sidebarItem === "menuList"}>
          <AppsPages
            setSidebarItem={setSidebarItem}
            setAppSection={setAppSection}
          />
        </Collapse>
      </Box>
      <VfDialog
        title="Manage Pages & Menu"
        openDialog={showPages}
        maxWidth="md"
        buttonCloseAction={() => dispatchShowPages(false)}
      >
        <Grid container spacing={8} wrap="nowrap">
          <Grid item xs={6}>
            <AppsPages dispatchShowPages={dispatchShowPages} />
          </Grid>
          <Divider flexItem orientation="vertical" />
          <Grid item xs={6}>
            <AppsMenus />
          </Grid>
        </Grid>
      </VfDialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    categoryWidgets: state.nexusWidgets.data,
    gridListView: state.nexusWidgets.view,
    showPages: state.applications.current.showPages,
    widgetVersions: state.applications.selectedWidgetVersions,
    selectedWdgt: state.applications.selectedWidget,
  };
};

const mapDispatchToProps = dispatch => ({
  setApplication: payload => dispatch(setApp(payload)),
  setWidget: payload => dispatch(setSelectedWidget(payload)),
  clearWidget: () => dispatch(clearSelectedWidget()),
  setWidgetVersions: payload => dispatch(setSelectedWidgetVersions(payload)),
  clearWidgetVersions: () => dispatch(clearSelectedWidgetVersions()),
  clearRowCol: () => dispatch(clearCurrRowCol()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppsSidebar);

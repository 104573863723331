import React, { useState, useRef, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import { debounce } from "lodash";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-end",
    pointerEvents: "none",
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
  },
  inputgroup: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  input: {
    flexGrow: "1",
  },
  button: {
    minWidth: "190px",
  },
  flex: {
    flexGrow: "1",
  },
}));

const SearchBarWrapper = ({
  allwaysOpen = false,
  expanded,
  setExpanded,
  hasFocus = false,
  callback,
  callbackParams = {},
  externalState,
  setSearchText,
  children,
}) => {
  const classes = useStyles();
  const [hasText, setHasText] = useState("");
  const focusRef = useRef(null);

  useEffect(() => {
    if (externalState) externalState(expanded);
    if (hasFocus) focusRef.current.children[0].focus();
    if (allwaysOpen) setExpanded(true);
  }, [expanded]);

  const onExpandClick = () => {
    setExpanded(!expanded);
    focusRef.current.children[0].focus();
  };

  const onClickAway = () => {
    if (!hasText) {
      setExpanded(false);
    }
  };

  const onInputChange = useRef(
    debounce((val, cbParams) => {
      return callback && callback({ ...cbParams, searchText: val });
    }, 1000)
  ).current;

  return (
    <Box py={0.25} position="relative">
      <ClickAwayListener onClickAway={onClickAway}>
        <Box>
          <Box className={classNames(classes.root, "MuiBox-searchBar")}>
            <Card
              elevation={0}
              className={classNames({
                "MuiCard-search": true,
                "MuiCard-search--hidden": true,
                "MuiCard-search--open": expanded,
                animate: true,
              })}
            >
              <CardContent>
                <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
                  <Grid item xs="auto">
                    <IconButton onClick={onExpandClick}>
                      <VfSvgIcon icon="search" viewBox={24} />
                    </IconButton>
                  </Grid>
                  <Grid item xs className="collapse animate">
                    <Box className={classes.inputgroup}>
                      <InputBase
                        value={hasText}
                        ref={focusRef}
                        className={classes.input}
                        placeholder="Search"
                        inputProps={{ "aria-label": "search google maps" }}
                        onChange={e => {
                          setHasText(e.target.value);
                          onInputChange(e.target.value, callbackParams);
                          if (setSearchText) setSearchText(e.target.value);
                        }}
                      />
                      {hasText && (
                        <IconButton
                          onClick={() => {
                            setHasText("");
                            onInputChange("", callbackParams);
                            if (setSearchText) setSearchText("");
                          }}
                        >
                          <VfSvgIcon icon="close" viewBox={24} />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          {children}
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default SearchBarWrapper;

import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withTheme } from "react-jsonschema-form";
import { cloneDeep } from "lodash";

import schemaToTree from "utils/schema-to-tree";
import FormTheme from "components/custom-mui-components/FormTheme";

const jp = require("jsonpath");

const Form = withTheme(FormTheme);

const AddItemWidgetForm = ({
  newItemSchema,
  newItemData,
  configForm,
  updateConfigData,
  setConfigTree,
  setAddItem,
}) => {
  const scalarSchema = cloneDeep(newItemSchema);
  if (scalarSchema.properties) {
    Object.entries(scalarSchema.properties).forEach(entry => {
      const [key, value] = entry;
      if (value.type === "array" || value.type === "object")
        delete scalarSchema.properties[key];
    });
  }

  return (
    <Form
      schema={scalarSchema}
      formData={newItemData}
      showErrorList={false}
      liveValidate
      noHtml5Validate
      onSubmit={formData => {
        const { schema } = configForm;
        const cloneData = cloneDeep(configForm.data);
        if (!jp.query(cloneData, configForm.selectedTreeItem.id).length) {
          const splitPath = configForm.selectedTreeItem.id.split(".");
          jp.apply(
            cloneData,
            splitPath.slice(0, splitPath.length - 1).join("."),
            value => {
              return {
                ...value,
                [splitPath[splitPath.length - 1]]: [],
              };
            }
          );
        }
        jp.apply(cloneData, configForm.selectedTreeItem.id, value => {
          return [...value, formData.formData];
        });
        updateConfigData(cloneData);
        const treeData = schemaToTree(undefined, "$", schema, cloneData);
        setConfigTree(treeData);
        setAddItem(false);
      }}
    >
      <Box pt={2}>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          <Grid item xs="auto">
            <Button variant="outlined" onClick={() => setAddItem(false)}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button type="submit" variant="outlined" color="secondary">
              Save Item
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};

export default AddItemWidgetForm;

import {
  USER_PERMISSIONS,
  USER_PERMISSIONS_ERROR,
  USER_PERMISSIONS_MSG,
  CLEAR_USER_PERMISSIONS_MSG,
} from "actions/permissionsActions";

const initialState = {
  enablePermissions: false,
  permissions: [],
  accessDeniedMsg: "",
  error: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case USER_PERMISSIONS: {
      localStorage.setItem("permissions", JSON.stringify(payload));
      return { ...state, ...payload };
    }
    case USER_PERMISSIONS_ERROR: {
      return { ...state, error };
    }
    case USER_PERMISSIONS_MSG: {
      return { ...state, accessDeniedMsg: payload };
    }
    case CLEAR_USER_PERMISSIONS_MSG: {
      return { ...state, accessDeniedMsg: "" };
    }
    default:
      return state;
  }
};

export default reducer;

const GET_GALLERY_MEDIA_REQUEST = "GET_GALLERY_MEDIA_REQUEST";
const GET_GALLERY_MEDIA_REQUEST_SUCCESS = "GET_GALLERY_MEDIA_REQUEST_SUCCESS";
const GET_GALLERY_MEDIA_REQUEST_FAIL = "GET_GALLERY_MEDIA_REQUEST_FAIL";
const UPLOAD_GALLERY_MEDIA_REQUEST = "UPLOAD_GALLERY_MEDIA_REQUEST";
const UPLOAD_GALLERY_MEDIA_REQUEST_SUCCESS = "UPLOAD_GALLERY_MEDIA_REQUEST_SUCCESS";
const UPLOAD_GALLERY_MEDIA_REQUEST_FAIL = "UPLOAD_GALLERY_MEDIA_REQUEST_FAIL";
const DELETE_GALLERY_MEDIA_REQUEST = "DELETE_GALLERY_MEDIA_REQUEST";
const DELETE_GALLERY_MEDIA_REQUEST_SUCCESS = "DELETE_GALLERY_MEDIA_REQUEST_SUCCESS";
const DELETE_GALLERY_MEDIA_REQUEST_FAIL = "DELETE_GALLERY_MEDIA_REQUEST_FAIL";

const getGalleryMedia = payload => ({
  type: GET_GALLERY_MEDIA_REQUEST,
  payload,
});

const getGalleryMediaSuccess = payload => ({
  type: GET_GALLERY_MEDIA_REQUEST_SUCCESS,
  payload,
});

const getGalleryMediaFail = payload => ({
  type: GET_GALLERY_MEDIA_REQUEST_FAIL,
  error: payload
});

const uploadGalleryMedia = payload => ({
  type: UPLOAD_GALLERY_MEDIA_REQUEST,
  payload,
});

const uploadGalleryMediaSuccess = payload => ({
  type: UPLOAD_GALLERY_MEDIA_REQUEST_SUCCESS,
  payload,
});

const uploadGalleryMediaFail = payload => ({
  type: UPLOAD_GALLERY_MEDIA_REQUEST_FAIL,
  error: payload
});

const deleteMediaGallery = payload => ({
  type: DELETE_GALLERY_MEDIA_REQUEST,
  payload,
});

const deleteMediaGallerySuccess = payload => ({
  type: DELETE_GALLERY_MEDIA_REQUEST_SUCCESS,
  payload,
});

const deleteMediaGalleryFail = payload => ({
  type: DELETE_GALLERY_MEDIA_REQUEST_FAIL,
  error: payload
});

export {
  GET_GALLERY_MEDIA_REQUEST,
  GET_GALLERY_MEDIA_REQUEST_SUCCESS,
  GET_GALLERY_MEDIA_REQUEST_FAIL,
  UPLOAD_GALLERY_MEDIA_REQUEST,
  UPLOAD_GALLERY_MEDIA_REQUEST_SUCCESS,
  UPLOAD_GALLERY_MEDIA_REQUEST_FAIL,
  DELETE_GALLERY_MEDIA_REQUEST,
  DELETE_GALLERY_MEDIA_REQUEST_SUCCESS,
  DELETE_GALLERY_MEDIA_REQUEST_FAIL,
  getGalleryMedia,
  getGalleryMediaSuccess,
  getGalleryMediaFail,
  uploadGalleryMedia,
  uploadGalleryMediaSuccess,
  uploadGalleryMediaFail,
  deleteMediaGallery,
  deleteMediaGallerySuccess,
  deleteMediaGalleryFail,
};

/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  updateTranslationData as updTranslationData,
  setSelectedTreeItem as settingSelectedTreeItem,
} from "actions/translationsActions";
import HasPermission from "components/has-permissions/HasPermissions";
import classNames from "classnames";

import TranslationsForm from "./TranslationsForm";
import TranslationsKeys from "./TranslationsKeys";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfDialog from "../vf/VfDialog";
import CardTranslationLanguageItem from "./CardTranslationLanguageItem";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  root: {
    "& .MuiTabs-root": {
      "& .MuiIconButton-root": {
        margin: "11px 12px",
      },
    },
  },
  tabsContainer: {
    overflow: "hidden",
    borderBottomColor: theme.palette.grey[100],
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    paddingRight: theme.spacing(1.5),
  },
  dialogActions: {
    justifyContent: "space-between",
  },
});

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Box>
  );
};

const TranslationsTabs = ({
  envID,
  locales,
  selectedTreeItem,
  translations,
  updateTranslationData,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [newLanguage, setNewLanguage] = useState("");
  const [open, setOpen] = useState(false);

  const [localTranslations, setLocalTranslations] = useState(translations);

  useEffect(() => {
    if (!open) setLocalTranslations(translations);
  }, [open]);

  const saveLanguages = () => {
    updateTranslationData([...localTranslations]);
    setOpen(false);
    setValue(0);
  };

  const addNewLanguage = () => {
    if (newLanguage) {
      setLocalTranslations([
        ...localTranslations,
        {
          data: localTranslations && {
            ...localTranslations.find(l => l.language === "en").data,
          },
          language: newLanguage,
        },
      ]);
      setNewLanguage("");
    }
  };

  const deleteLanguage = code => {
    const newTranslations = localTranslations.filter(t => t.language !== code);
    setLocalTranslations(newTranslations);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!translations || !locales) return null;

  return (
    <Box p={3} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs="auto">
              {selectedTreeItem && selectedTreeItem.name && (
                <Typography className="bold" variant="h5" component="h1">
                  {selectedTreeItem.name}
                </Typography>
              )}
            </Grid>
            {/* <Grid item xs="auto">
              <Grid container spacing={3} alignItems="center">
                <Grid item xs="auto">
                  <Button
                    size="small"
                    variant="outlined"
                    className={classes.button}
                    onClick={() => {
                      history.push(`/${locale}/translations/${env}`);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <HasPermission permissionKey={`edit_translations_env_${envID}`}>
                  <Grid item xs="auto">
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={save}
                    >
                      Save
                    </Button>
                  </Grid>
                </HasPermission>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className="MuiCard-rounded MuiCard-white MuiCard-shadow">
            <Box className={classes.tabsContainer}>
              <Grid container spacing={3} wrap="nowrap" alignItems="center">
                <Grid item xs>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="English" key="en" />
                    {translations &&
                      locales &&
                      translations
                        .filter(l => l.language !== "en")
                        .map(l => (
                          <Tab
                            label={
                              locales
                                .filter(locale => locale.code === l.language)
                                .reduce((a, c) => c, { language: "English" })
                                .language
                            }
                            key={l.language}
                          />
                        ))}
                  </Tabs>
                </Grid>
                <HasPermission permissionKey={`edit_translations_env_${envID}`}>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs="auto">
                    <IconButton onClick={() => setOpen(true)}>
                      <VfSvgIcon icon="settings" />
                    </IconButton>
                  </Grid>
                </HasPermission>
              </Grid>
            </Box>
            <TabPanel value={value} index={0}>
              <TranslationsKeys
                envID={envID}
                original={selectedTreeItem && selectedTreeItem.schema}
              />
            </TabPanel>
            {translations &&
              locales &&
              translations
                .filter(l => l.language !== "en")
                .map((l, i) => (
                  <TabPanel key={l.language} value={value} index={i + 1}>
                    <TranslationsForm
                      envID={envID}
                      original={selectedTreeItem && selectedTreeItem.schema}
                      language={
                        locales
                          .filter(locale => locale.code === l.language)
                          .reduce((a, c) => c, { language: "English" }).language
                      }
                      languageCode={
                        locales.find(locale => locale.code === l.language).code
                      }
                    />
                  </TabPanel>
                ))}
          </Card>
        </Grid>
      </Grid>
      <HasPermission permissionKey={`edit_translations_env_${envID}`}>
        {open && (
          <VfDialog
            title="Manage languages"
            maxWidth="xs"
            openDialog={open}
            buttonConfirmText="Save"
            buttonConfirmAction={() => saveLanguages()}
            buttonCloseAction={() => setOpen(false)}
            buttonSecondaryText="Add language"
            buttonSecondaryAction={addNewLanguage}
            buttonSecondaryDisabled={
              locales &&
              localTranslations &&
              !locales
                .filter(l => l.code !== "en")
                .filter(
                  l => !localTranslations.some(t => t.language === l.code)
                ).length
            }
            classDialogActions={classes.dialogActions}
          >
            <Grid container spacing={3}>
              {locales &&
                localTranslations &&
                locales
                  .filter(l =>
                    localTranslations.some(t => t.language === l.code)
                  )
                  .map(l => (
                    <Grid item xs={12} key={l.code}>
                      <CardTranslationLanguageItem
                        language={l}
                        deleteAction={deleteLanguage}
                      />
                    </Grid>
                  ))}
              <Grid item xs={12}>
                <TextField
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    },
                  }}
                  label="Select"
                  onChange={e => setNewLanguage(e.target.value)}
                  value={newLanguage}
                  variant="filled"
                  color="secondary"
                  fullWidth
                  disabled={
                    locales &&
                    localTranslations &&
                    !locales
                      .filter(l => l.code !== "en")
                      .filter(
                        l => !localTranslations.some(t => t.language === l.code)
                      ).length
                  }
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {locales &&
                    translations &&
                    locales
                      .filter(l => l.code !== "en")
                      .filter(
                        l => !localTranslations.some(t => t.language === l.code)
                      )
                      .map(l => (
                        <MenuItem key={l.code} value={l.code}>
                          {l.language}
                        </MenuItem>
                      ))}
                </TextField>
              </Grid>
            </Grid>
          </VfDialog>
        )}
      </HasPermission>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    selectedTreeItem: state.translations.selectedTreeItem,
    translations: state.translations.translations,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateTranslationData: data => dispatch(updTranslationData(data)),
    setSelectedTreeItem: data => dispatch(settingSelectedTreeItem(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationsTabs);

/* eslint-disable no-param-reassign */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";

import { loggedIn, loginError } from "actions/authenticationActions";
import { userPermissions, userPermissionsError } from "actions/permissionsActions";
import { showTenantSelectionDialog } from "actions/applicationsActions";
import { fetchEnvsByLocale } from "actions/envsActions";
import { fetchTenantBasicInfo } from "actions/tenantActions";

const OauthCode = ({
  location,
  history,
  dispatchloggedIn,
  dispatchloginError,
  dispatchUserPermissions,
  dispatchuserPermissionsError,
  dispatchShowTenantSelectionDialog,
  getEnvsByLocale,
  getTenantBasicInfo,
}) => {
  const params = new URLSearchParams(location.search);

  const code = params.get("code");
  const error = params.get("error");

  if (code) {
    const data = {
      code,
    };

    fetch("/api/appbuilder/token", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(ret => ret.json())
      .then(json => {
        const decoded = jwt.decode(json.id_token);
        json.name = decoded.name;
        json.family_name = decoded.family_name;
        json.given_name = decoded.given_name;
        json.email = decoded.email;
        json.original_country = decoded.original_country;
        json.ctry = decoded.ctry;
        json.allowedMarkets = decoded.allowed_markets;
        json.selectedMarket = decoded.allowed_markets.find(m => m.code === json.ctry);
        dispatchloggedIn(json);
        fetch("/api/appbuilder/permissions/getUserPermissions", {
          method: "get",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${json.access_token}`,
            idtoken: json.id_token,
          },
        })
          .then(ret => ret.json())
          .then(res => {
            dispatchUserPermissions(res);
            getEnvsByLocale({
              locale: json.ctry.toLowerCase(),
              access_token: json.access_token,
              id_token: json.id_token,
            });
            getTenantBasicInfo({
              locale: json.ctry.toLowerCase(),
              access_token: json.access_token,
              id_token: json.id_token,
            });
            history.push(`/${json.ctry}/landing`);
            if (json.allowedMarkets.length > 1) dispatchShowTenantSelectionDialog(true);
          })
          .catch(e => {
            dispatchuserPermissionsError({ error: e.message });
            history.push("/");
          });
      })
      .catch(e => {
        dispatchloginError({ error: e.message });
        history.push("/");
      });
  } else if (error) {
    console.log("Got error", error);
    history.push("/");
  }

  return null;
};

const mapDispatchToProps = dispatch => ({
  dispatchloggedIn: data => dispatch(loggedIn(data)),
  dispatchloginError: data => dispatch(loginError(data)),
  dispatchUserPermissions: data => dispatch(userPermissions(data)),
  dispatchuserPermissionsError: data => dispatch(userPermissionsError(data)),
  dispatchShowTenantSelectionDialog: data => dispatch(showTenantSelectionDialog(data)),
  getEnvsByLocale: params => dispatch(fetchEnvsByLocale(params)),
  getTenantBasicInfo: params => dispatch(fetchTenantBasicInfo(params)),
});

export default connect(null, mapDispatchToProps)(withRouter(OauthCode));

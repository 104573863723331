/* eslint-disable no-underscore-dangle */
import React, { useState, useRef } from "react";
import {
  AppBar,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
// import uuidv4 from "uuid/v4";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, cloneDeep } from "lodash";
import jp from "jsonpath";
import compareVersions from "compare-versions";

import { djrToLayout } from "utils/djr-to-layout";
import { shallowEqual, useSelector } from "react-redux";
import VfSvgIcon from "../icons/VfSvgIcon";
import theme from "../../AppTheme";
import { runLiveEditorDJSLValidators } from "../../utils/djsl-utils";
import {
  hasDuplicates,
  invalidStatePaths,
  invalidActionProps,
} from "../../utils/djsl-validators";

const useStyles = makeStyles({
  appBar: {
    backgroundColor: theme.palette.common.white,
    borderBottomColor: theme.palette.grey[100],
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    zIndex: theme.zIndex.appBar - 10,
    minHeight: theme.spacing(6),
  },
});

const AppsDialogSectionAppBar = ({
  sidebarItem,
  application,
  clearWidget,
  clearRowCol,
  dispatchshowSideBar,
  setSidebarItem,
  setCurrentPage,
  setShowAddPage,
  setAppSection,
  dispatchShowAddTemplate,
  setApplication,
  categoryWidgets,
  showSnackBar,
  sagaName,
  setSagaId,
  apiName,
  setApiId,
  snippetName,
  setSnippetId,
  setDjr,
  djr,
  dispatchShowCreatePage,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMoreMenu, setAnchorMoreMenu] = useState(null);
  const appWidgets = useSelector(
    state => state.applications.current.appInfo.widgets,
    shallowEqual
  );

  const [searchText, setSearchText] = useState("");
  const filterRef = useRef();

  const exportToJson = objectData => {
    const filename = "djr.json";
    const contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(objectData, null, 2)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.download = filename;
      a.href = `data:${contentType},${encodeURIComponent(
        JSON.stringify(objectData, null, 2)
      )}`;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const fatWidgetList = categoryWidgets.flatMap(({ widgets }) =>
    widgets.filter(w => w.images.length > 0)
  );

  const fatWidgetsUsingState = categoryWidgets
    .filter(
      ({ _id }) =>
        _id !== "Protons" && _id !== "Atoms" && _id !== "Custom Widgets"
    )
    .flatMap(({ widgets }) =>
      widgets.filter(({ _id }) => appWidgets.some(w => w._id === _id))
    )
    .filter(({ appFramework }) => appFramework.actions);

  const addWidgetToApplication = fatWidgets => {
    const { appInfo } = application;
    const clonedAppInfo = cloneDeep(appInfo);
    fatWidgets.forEach(widget => {
      const found = clonedAppInfo.widgets.findIndex(
        w => w.name === widget.__component
      );
      if (found === -1) {
        const widgetVersions = fatWidgetList
          .filter(w => w.name === widget.__component)
          .sort((a, b) => {
            return compareVersions(a.version, b.version);
          })
          .reverse();
        clonedAppInfo.widgets.push({
          _id: widgetVersions[0]._id,
          name: widgetVersions[0].name,
          version: widgetVersions[0].version,
          occurrences: 1,
          instances: [{ uuid: widget.__props.__instanceId }],
        });
      } else {
        clonedAppInfo.widgets.splice(found, 1, {
          _id: clonedAppInfo.widgets[found]._id,
          name: clonedAppInfo.widgets[found].name,
          version: clonedAppInfo.widgets[found].version,
          occurrences: clonedAppInfo.widgets[found].occurrences + 1,
          instances: [
            ...(clonedAppInfo.widgets[found].instances || []),
            { uuid: widget.__props.__instanceId },
          ],
        });
      }
    });
    setApplication(clonedAppInfo);
  };

  const removeWidgetFromApplication = fatWidgets => {
    const { appInfo } = application;
    const clonedAppInfo = cloneDeep(appInfo);
    fatWidgets.forEach(widget => {
      const found = clonedAppInfo.widgets.findIndex(
        w => w.name === widget.__component
      );
      if (clonedAppInfo.widgets[found].occurrences <= 1) {
        clonedAppInfo.widgets.splice(found, 1);
      } else {
        clonedAppInfo.widgets.splice(found, 1, {
          _id: clonedAppInfo.widgets[found]._id,
          name: widget.__component,
          version: clonedAppInfo.widgets[found].version,
          occurrences: clonedAppInfo.widgets[found].occurrences - 1,
          instances: clonedAppInfo.widgets[found].instances.filter(
            inst => inst.uuid !== widget.__props.__instanceId
          ),
        });
      }
    });

    setApplication(clonedAppInfo);
  };

  const fatWidget = name => fatWidgetList.find(w => w.name === name);

  const containsFatComp = comp => {
    const propPath = `$..children`;
    return jp
      .query(comp, propPath)
      .flatMap(x => x)
      .filter(c => {
        return c?.__component && !!fatWidget(c.__component);
      });
  };

  const stopPropagation = e => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };
  const moveFocusToInput = e => {
    e.stopPropagation();
    e.preventDefault();
    filterRef.current.focus();
  };

  const codeViewerActionHandler = (appInfo, appSection, currentPage, pages) => {
    if (appSection === "design") {
      setDjr(JSON.stringify(currentPage.djr, null, 2));
      setAppSection("code");
      return;
    }
    const objDjr = JSON.parse(djr);

    const validators = [
      () => hasDuplicates(objDjr, pages, currentPage.pageInfo._id),
      () =>
        invalidStatePaths(appInfo, objDjr, fatWidgetsUsingState, appWidgets),
      () => invalidActionProps(appInfo, objDjr),
    ];

    const validatedDJSL = runLiveEditorDJSLValidators(validators);

    if (validatedDJSL.isValid) {
      const fatWidgetsInPage = containsFatComp(objDjr);
      addWidgetToApplication(fatWidgetsInPage);
      setCurrentPage({
        ...currentPage,
        djr: objDjr,
      });
      setDjr(null);
      setAppSection("design");
    } else {
      showSnackBar({
        show: true,
        message: validatedDJSL.messages.join("\n"),
        severity: 0,
      });
    }
  };

  const renderSectionAppBar = () => {
    const { appSection, currentPage, pages } = application;
    if (
      (appSection === "code" || appSection === "design") &&
      application.showAddPage &&
      application.showAddTemplate
    ) {
      return (
        <AppBar elevation={0} position="sticky" className={classes.appBar}>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={application?.appInfo?.isDeployed ? 6 : 4}>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item xs="auto">
                  <IconButton
                    onClick={() => {
                      dispatchshowSideBar(!application.showSidebar);
                    }}
                  >
                    <VfSvgIcon icon="preview" viewBox={24} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={application?.appInfo?.isDeployed ? 6 : 4}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={application?.appInfo?.isDeployed ? 12 : "auto"}>
                  <Button
                    size="large"
                    className={classes.menuPages}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={event => setAnchorEl(event.currentTarget)}
                    endIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
                  >
                    {!isEmpty(application.currentPage)
                      ? application.currentPage.pageInfo.title
                      : ``}
                  </Button>
                  <Menu
                    id="simple-menu"
                    elevation={0}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                    disableAutoFocus
                  >
                    <MenuItem button={false} onKeyDown={moveFocusToInput}>
                      <TextField
                        inputRef={filterRef}
                        value={searchText}
                        placeholder="Search"
                        InputProps={{ disableUnderline: true }}
                        onKeyDown={stopPropagation}
                        onChange={e => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </MenuItem>
                    <Divider />
                    {pages
                      .filter(p => {
                        if (searchText)
                          return p.title
                            .toLowerCase()
                            .includes(searchText.toLowerCase());
                        return p;
                      })
                      .map(mPage => {
                        return (
                          <MenuItem
                            key={mPage._id}
                            {...(!isEmpty(application.currentPage) &&
                            application.currentPage.pageInfo.title ===
                              mPage.title
                              ? { selected: true }
                              : {})}
                            onClick={() => {
                              setAnchorEl(null);
                              clearRowCol();
                              clearWidget();

                              const { data, ...rest } = mPage;

                              const changedPage =
                                application.changedPages[mPage._id];
                              const currPage = changedPage
                                ? {
                                    ...changedPage,
                                    rows: djrToLayout(changedPage.djr)?.rows,
                                  }
                                : {
                                    djr: data,
                                    pageInfo: rest,
                                    spacing: 3,
                                    rows: djrToLayout(data)?.rows,
                                  };

                              setCurrentPage(currPage);
                              setDjr(JSON.stringify(currPage.djr, null, 2));
                              setShowAddPage(true);
                              dispatchShowAddTemplate(true);
                            }}
                          >
                            {mPage.title}
                          </MenuItem>
                        );
                      })}
                    {!application?.appInfo?.isDeployed && <Divider />}
                    {!application?.appInfo?.isDeployed && (
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          setSidebarItem("pageList");
                          clearRowCol();
                        }}
                      >
                        <Typography variant="body2" color="secondary">
                          Manage pages
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            {!application?.appInfo?.isDeployed && (
              <Grid item xs={4}>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item xs="auto">
                    <IconButton
                      onClick={() =>
                        codeViewerActionHandler(
                          application.appInfo,
                          appSection,
                          currentPage,
                          pages
                        )
                      }
                    >
                      <VfSvgIcon icon="code" viewBox={24} />
                    </IconButton>
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton
                      onClick={event => setAnchorMoreMenu(event.currentTarget)}
                    >
                      <VfSvgIcon icon="more" viewBox={24} />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      elevation={0}
                      anchorEl={anchorMoreMenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      getContentAnchorEl={null}
                      keepMounted
                      open={Boolean(anchorMoreMenu)}
                      onClose={() => setAnchorMoreMenu(null)}
                      TransitionComponent={Fade}
                      disableAutoFocus
                    >
                      <MenuItem
                        onClick={() => {
                          setAnchorMoreMenu(null);
                          setSidebarItem("pageList");
                          dispatchShowCreatePage(true);
                        }}
                      >
                        Page Settings
                      </MenuItem>
                      {/* <MenuItem
                        onClick={() => {
                          setAnchorMoreMenu(null);
                        }}
                      >
                        Save as building block
                      </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          setAppSection("code");
                          const fatWidgetsInPage = containsFatComp(
                            currentPage.djr
                          );
                          removeWidgetFromApplication(fatWidgetsInPage);
                          setCurrentPage({
                            ...currentPage,
                            djr: {},
                          });
                          setAnchorMoreMenu(null);
                        }}
                      >
                        Import DJR
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          exportToJson(currentPage.djr);
                          setAnchorMoreMenu(null);
                        }}
                      >
                        Export DJR
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          const fatWidgetsInPage = containsFatComp(
                            currentPage.djr
                          );
                          removeWidgetFromApplication(fatWidgetsInPage);
                          setCurrentPage({
                            ...currentPage,
                            djr: {
                              __component: "Fragment",
                              __props: {
                                id: "root",
                                children: [],
                              },
                            },
                          });

                          setAnchorMoreMenu(null);
                        }}
                      >
                        Clear content
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AppBar>
      );
    }
    if (appSection === "logic")
      switch (sidebarItem) {
        case "appLogicList":
          return (
            <AppBar elevation={0} position="sticky" className={classes.appBar}>
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="center"
              >
                <Grid item xs="auto">
                  <Button
                    size="large"
                    className={classes.menuPages}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={event => setAnchorEl(event.currentTarget)}
                    endIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
                  >
                    {!isEmpty(sagaName) ? sagaName : ``}
                  </Button>
                  <Menu
                    id="simple-menu"
                    elevation={0}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                  >
                    <MenuItem button={false} onKeyDown={moveFocusToInput}>
                      <TextField
                        onChange={e => {
                          setSearchText(e.target.value);
                        }}
                        inputRef={filterRef}
                        placeholder="Search"
                        InputProps={{ disableUnderline: true }}
                        onKeyDown={stopPropagation}
                        value={searchText}
                      />
                    </MenuItem>
                    <Divider />
                    {application.appInfo.sagas
                      .filter(p => {
                        if (searchText)
                          return p.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase());
                        return p;
                      })
                      .map(s => {
                        return (
                          <MenuItem
                            key={s._id || s.id}
                            onClick={() => {
                              setAnchorEl(null);
                              setSagaId(s.id);
                              setSidebarItem("appLogicList");
                            }}
                          >
                            {s.name}
                          </MenuItem>
                        );
                      })}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setSidebarItem("appLogicList");
                      }}
                    >
                      <Typography variant="body2" color="secondary">
                        Manage sagas
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </AppBar>
          );
        case "apiList":
          return (
            <AppBar elevation={0} position="sticky" className={classes.appBar}>
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="center"
              >
                <Grid item xs="auto">
                  <Button
                    size="large"
                    className={classes.menuPages}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={event => setAnchorEl(event.currentTarget)}
                    endIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
                  >
                    {!isEmpty(apiName) ? apiName : ``}
                  </Button>
                  <Menu
                    id="simple-menu"
                    elevation={0}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                  >
                    <MenuItem button={false} onKeyDown={moveFocusToInput}>
                      <TextField
                        onChange={e => {
                          setSearchText(e.target.value);
                        }}
                        inputRef={filterRef}
                        placeholder="Search"
                        InputProps={{ disableUnderline: true }}
                        onKeyDown={stopPropagation}
                        value={searchText}
                      />
                    </MenuItem>
                    <Divider />
                    {(application.appInfo.apis ?? [])
                      .filter(p => {
                        if (searchText)
                          return p.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase());
                        return p;
                      })
                      .map(s => {
                        return (
                          <MenuItem
                            key={s._id || s.id}
                            onClick={() => {
                              setAnchorEl(null);
                              setApiId(s._id);
                              setSidebarItem("apiList");
                            }}
                          >
                            {s.name}
                          </MenuItem>
                        );
                      })}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setSidebarItem("apiList");
                      }}
                    >
                      <Typography variant="body2" color="secondary">
                        Manage APIs
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </AppBar>
          );
        case "javascript":
          return (
            <AppBar elevation={0} position="sticky" className={classes.appBar}>
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="center"
              >
                <Grid item xs="auto">
                  <Button
                    size="large"
                    className={classes.menuPages}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={event => setAnchorEl(event.currentTarget)}
                    endIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
                  >
                    {!isEmpty(snippetName) ? snippetName : ``}
                  </Button>
                  <Menu
                    id="simple-menu"
                    elevation={0}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                  >
                    <MenuItem button={false} onKeyDown={moveFocusToInput}>
                      <TextField
                        onChange={e => {
                          setSearchText(e.target.value);
                        }}
                        inputRef={filterRef}
                        placeholder="Search"
                        InputProps={{ disableUnderline: true }}
                        onKeyDown={stopPropagation}
                        value={searchText}
                      />
                    </MenuItem>
                    <Divider />
                    {application.appInfo.snippets
                      .filter(p => {
                        if (searchText)
                          return p.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase());
                        return p;
                      })
                      .map(s => {
                        return (
                          <MenuItem
                            key={s._id || s.id}
                            onClick={() => {
                              setAnchorEl(null);
                              setSnippetId(s.id);
                              setSidebarItem("javascript");
                            }}
                          >
                            {s.name}
                          </MenuItem>
                        );
                      })}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setSidebarItem("javascript");
                      }}
                    >
                      <Typography variant="body2" color="secondary">
                        Manage snippets
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </AppBar>
          );
        default:
          return null;
      }
    if (appSection === "menu")
      return (
        <AppBar elevation={0} position="sticky" className={classes.appBar} />
      );
    return null;
  };
  return renderSectionAppBar();
};

export default AppsDialogSectionAppBar;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  dialogContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogActions: {
    paddingBottom: theme.spacing(4),
    justifyContent: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      "& .MuiButton-root": {
        width: "100%",
      },
      "& > :not(:first-child)": {
        marginLeft: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VfDialogConfirm = ({
  buttonConfirmAction,
  buttonConfirmText,
  buttonConfirmDisabled,
  buttonCancelAction,
  buttonCancelText,
  buttonCancelDisabled,
  openDialog,
  maxWidth = "xs",
  children,
}) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={openDialog}
      maxWidth={maxWidth}
    >
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {buttonCancelText && (
          <Button
            variant="outlined"
            disabled={buttonCancelDisabled}
            onClick={() => {
              buttonCancelAction();
            }}
          >
            {buttonCancelText}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          disabled={buttonConfirmDisabled}
          onClick={() => {
            buttonConfirmAction();
          }}
        >
          {buttonConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VfDialogConfirm;

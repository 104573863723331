/* eslint-disable no-underscore-dangle,react/jsx-curly-newline */
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import classNames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import tenantTypes from "enums/tenantTypes";

const AppDialogDetailsSelector = ({
  isValidName,
  title,
  description,
  bgColor,
  unmanaged,
  onTitleChange,
  onTitleBlur,
  onUnmanagedChanged,
  onConfirm,
  workbenchApplicationList,
  unmanagedWidgets,
  workbenchApplicationInitial,
  selectedUnmanagedWidget,
  unmanagedVisible,
  unmanagedEditable,
  tenantType,
}) => {
  const [localTitle, setLocalTitle] = useState(title);
  const [localDescription, setLocalDescription] = useState(description);
  const [localUnmanaged, setLocalUnmanaged] = useState(unmanaged);
  const [localBgColor, setLocalBgColor] = useState(bgColor);
  const [workbenchApplication, setWorkbenchApplication] = useState(workbenchApplicationInitial);
  const [localSelectedUnmanagedWidget, setLocalSelectedUnmanagedWidget] = useState(selectedUnmanagedWidget);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item xs="auto">
            <Typography variant="body1">Add an App Name</Typography>
          </Grid>
          {tenantType !== tenantTypes.SPAIN && unmanagedVisible && (
            <Grid item xs="auto">
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    disabled={unmanagedEditable}
                    name="unmanaged"
                    color="primary"
                    checked={localUnmanaged}
                    onChange={e => {
                      setLocalUnmanaged(e.target.checked);
                      onUnmanagedChanged(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Unmanaged"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            name="title"
            variant="outlined"
            label="Name"
            type="text"
            onChange={e => {
              setLocalTitle(e.target.value);
              onTitleChange(e.target.value);
            }}
            onBlur={e => onTitleBlur(e.target.value)}
            value={localTitle}
            {...(!isValidName && localTitle
              ? {
                  error: true,
                  helperText: "Please provide a unique Application Name.",
                }
              : {})}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            multiline
            name="description"
            variant="outlined"
            label="Description (Optional)"
            type="text"
            onChange={e => setLocalDescription(e.target.value)}
            value={localDescription}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            name="bgColor"
            label="App Background Color (Optional)"
            type="color"
            onChange={e => setLocalBgColor(e.target.value)}
            value={localBgColor}
            margin="normal"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {!!workbenchApplicationList && !!workbenchApplicationList.length && (
          <TextField
            select
            SelectProps={{
              renderValue: () => {
                return workbenchApplication?.name;
              },
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            onChange={e => {
              setWorkbenchApplication(e.target.value);
            }}
            label="Select Workbench Application"
            value={workbenchApplication?.name}
            variant="filled"
            color="primary"
            fullWidth
            disabled={false}
            InputProps={{
              disableUnderline: true,
              className: classNames("MuiFilledInput-disableUnderline"),
            }}
          >
            {workbenchApplicationList &&
              workbenchApplicationList.map(a => (
                <MenuItem key={a.id} value={a}>
                  <Typography component="div" variant="h5">
                    {a?.name}
                  </Typography>
                </MenuItem>
              ))}
          </TextField>
        )}
        {localUnmanaged && (
          <TextField
            select
            SelectProps={{
              renderValue: () => {
                return `${localSelectedUnmanagedWidget?.name} ${localSelectedUnmanagedWidget.version}`;
              },
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            onChange={e => {
              setLocalSelectedUnmanagedWidget(e.target.value);
            }}
            label="Select Unmanaged Widget"
            value={localSelectedUnmanagedWidget?.name}
            variant="filled"
            color="primary"
            fullWidth
            disabled={false}
            InputProps={{
              disableUnderline: true,
              className: classNames("MuiFilledInput-disableUnderline"),
            }}
          >
            {unmanagedWidgets &&
              unmanagedWidgets.map(a => (
                <MenuItem key={a._id} value={a}>
                  <ListItemText primary={a.name} secondary={a.version} />
                </MenuItem>
              ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              disabled={
                !!workbenchApplicationList && !!workbenchApplicationList.length
                  ? !(!!localTitle && !!isValidName && !!workbenchApplication)
                  : localUnmanaged
                  ? !(!!localTitle && !!isValidName && !!localSelectedUnmanagedWidget)
                  : !(!!localTitle && !!isValidName)
              }
              onClick={() =>
                onConfirm(
                  localTitle,
                  localDescription,
                  localBgColor,
                  localUnmanaged,
                  workbenchApplication,
                  localSelectedUnmanagedWidget
                )
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppDialogDetailsSelector;

/* eslint-disable camelcase */
import axios from "axios";

class GalleryMediaService {
  fetch = async ({ access_token, id_token, search }) => {
    const params = { search };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/galleryMedia/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in fetch gallery media service:", err);
      return err;
    }
  };

  delete = async ({ access_token, id_token, keys }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/galleryMedia`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: { keys },
      });
      return data;
    } catch (err) {
      console.log("Error in delete gallery media service:", err);
      return err;
    }
  };

  upload = async ({ access_token, id_token, payload }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/portal/galleryMedia/upload`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: payload,
      });
      return data;
    } catch (err) {
      console.log("Error in upload gallery media service:", err);
      return err;
    }
  };
}

export default new GalleryMediaService();
import { call, put, takeLatest } from "redux-saga/effects";

import {
  NEXUS_WIDGETS_REQUEST,
  NEXUS_WIDGETS_REQUEST_SUCCESS,
  NEXUS_WIDGETS_REQUEST_FAIL,
  NEXUS_WIDGETS_CATEGORIES_REQUEST,
  NEXUS_WIDGETS_CATEGORIES_REQUEST_SUCCESS,
  NEXUS_WIDGETS_CATEGORIES_REQUEST_FAIL,
  NEXUS_WIDGETS_VERSIONS_REQUEST,
  NEXUS_WIDGETS_VERSIONS_REQUEST_SUCCESS,
  NEXUS_WIDGETS_VERSIONS_REQUEST_FAIL,
  GET_NEXUS_WIDGET_BY_ID_REQUEST,
  GET_NEXUS_WIDGET_BY_ID_REQUEST_SUCCESS,
  GET_NEXUS_WIDGET_BY_ID_REQUEST_FAIL,
  UPDATE_NEXUS_WIDGET_REQUEST_SUCCESS,
  UPDATE_NEXUS_WIDGET_REQUEST_FAIL,
  UPDATE_NEXUS_WIDGET_REQUEST,
  CLEAR_NEXUS_WIDGETS_LIST,
} from "actions/nexusWidgetsActions";
import widgetsService from "services/NexusWidgetsService";
import { SHOW_SNACKBAR } from "../actions/applicationsActions";

function* nexusWidgetsWorker({ payload }) {
  try {
    const { data } = yield call(widgetsService.fetchNexusWidgets, payload);
    yield put({
      type: NEXUS_WIDGETS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: NEXUS_WIDGETS_REQUEST_FAIL, error });
  }
}

function* nexusWidgetsWatcher() {
  yield takeLatest(NEXUS_WIDGETS_REQUEST, nexusWidgetsWorker);
}

function* nexusWidgetsCategoriesWorker({ payload }) {
  try {
    const { data } = yield call(
      widgetsService.fetchNexusWidgetsCategories,
      payload
    );
    yield put({
      type: NEXUS_WIDGETS_CATEGORIES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: NEXUS_WIDGETS_CATEGORIES_REQUEST_FAIL, error });
  }
}

function* nexusWidgetsCategoriesWatcher() {
  yield takeLatest(
    NEXUS_WIDGETS_CATEGORIES_REQUEST,
    nexusWidgetsCategoriesWorker
  );
}
function* nexusWidgetsVersionsWorker({ payload }) {
  try {
    const { data } = yield call(
      widgetsService.fetchNexusWidgetsVersions,
      payload
    );
    yield put({
      type: NEXUS_WIDGETS_VERSIONS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: NEXUS_WIDGETS_VERSIONS_REQUEST_FAIL, error });
  }
}

function* nexusWidgetsVersionsWatcher() {
  yield takeLatest(NEXUS_WIDGETS_VERSIONS_REQUEST, nexusWidgetsVersionsWorker);
}

function* nexusWidgetDetailsWorker({ payload }) {
  try {
    const { data } = yield call(
      widgetsService.fetchNexusWidgetDetails,
      payload
    );
    yield put({
      type: GET_NEXUS_WIDGET_BY_ID_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_NEXUS_WIDGET_BY_ID_REQUEST_FAIL, error });
  }
}

function* nexusWidgetDetailsWatcher() {
  yield takeLatest(GET_NEXUS_WIDGET_BY_ID_REQUEST, nexusWidgetDetailsWorker);
}

function* updateNexusWidgetWorker({ payload }) {
  try {
    const { data } = yield call(widgetsService.updateNexusWidget, payload);
    yield put({
      type: UPDATE_NEXUS_WIDGET_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: CLEAR_NEXUS_WIDGETS_LIST,
    });
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: "Widget was successfully updated.",
        severity: 3,
      },
    });
  } catch (error) {
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: error.message,
        severity: 0,
      },
    });
    yield put({ type: UPDATE_NEXUS_WIDGET_REQUEST_FAIL, error });
  }
}

function* updateNexusWidgetWatcher() {
  yield takeLatest(UPDATE_NEXUS_WIDGET_REQUEST, updateNexusWidgetWorker);
}

const nexusWidgetsSaga = [
  nexusWidgetsWatcher(),
  nexusWidgetsCategoriesWatcher(),
  nexusWidgetsVersionsWatcher(),
  nexusWidgetDetailsWatcher(),
  updateNexusWidgetWatcher(),
];

export default nexusWidgetsSaga;

/* eslint-disable camelcase */
import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Card, CardContent, CardActionArea } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import VfSvgIcon from "../../icons/VfSvgIcon";
import { uploadGalleryMedia } from "actions/galleryMediaActions";

const CardMediaUpload = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-dropzone": true,
        "MuiCard-media": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-rounded": true,
      })}
    >
      <Dropzone
        multiple
        onDrop={acceptedFiles => {
          const data = new FormData();
          acceptedFiles.forEach(m => {
            data.append("files", m);
          });
          console.log(data);
          dispatch(uploadGalleryMedia({ payload: data, access_token, id_token }));
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <CardActionArea {...getRootProps()}>
            <CardContent>
              <input {...getInputProps()} />
              <VfSvgIcon className="MuiSvgIcon-fontSizeXLarge" icon="upload" viewBox={56} fontSize="large" />
              <Typography variant="body2">Click or drop files here</Typography>
            </CardContent>
          </CardActionArea>
        )}
      </Dropzone>
    </Card>
  );
};

export default CardMediaUpload;

import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Badge, Card, CardActionArea, CardActions, CardContent, Grid, Fade, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import VfSvgIcon from "../../icons/VfSvgIcon";
import VfDialogConfirm from "../../vf/VfDialogConfirm";
import theme from "../../../AppTheme";
import DialogCampaign from "../dialogs/DialogCampaign";
import { deleteCampaign } from "actions/campaignActions";

const useStyles = makeStyles({
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "none",
      position: "relative",
    },
  },
});

const CardDetails = ({title, gallery, date, priority}) => {
  const styles = useStyles();

  return (
    <Grid container spacing={3} justify="space-between" alignItems="center">
      <Grid item xs="auto">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">{title}</Typography>
          </Grid>
          {gallery && (
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Gallery:
                {gallery}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs="auto">
            <Typography variant="body2">{new Date(date).toDateString()}</Typography>
          </Grid>
          <Grid item xs="auto">
            <Badge
              className={styles.badge}
              badgeContent={priority}
              color={priority === 1 ? "secondary" : "default"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MoreActions = ({bulkSelect, openMenuMore, setOpenMenuMore, setShowDialogCampaign, setOpenDialogDelete}) => (
  <Fade in={!bulkSelect}>
    <Grid item xs="auto">
      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={e => setOpenMenuMore(e.currentTarget)}
        >
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={openMenuMore}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(openMenuMore)}
          onClose={() => setOpenMenuMore(null)}
        >
          <MenuItem
            button
            onClick={() => {
              setShowDialogCampaign(true);
              setOpenMenuMore(false);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            button
            onClick={() => {
              setOpenDialogDelete(true);
              setOpenMenuMore(false);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </CardActions>
    </Grid>
  </Fade>
);

const CardCampaign = ({ campaign, title, gallery, priority, date, action, moreActions, bulkSelect, selected }) => {
  const styles = useStyles();
  const [showDialogCampaign, setShowDialogCampaign] = useState(false);
  const [openMenuMore, setOpenMenuMore] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-primary": selected && bulkSelect,
        "Mui-selected": selected && bulkSelect,
        animate: true,
      })}
    >
      <Grid container wrap="nowrap" alignItems="center">
        <Grid item xs>
          <CardActionArea onClick={() => action && action()}>
            <CardContent className={styles.cardContent}>
              <CardDetails title={title} gallery={gallery} date={date} priority={priority} />
            </CardContent>
          </CardActionArea>
        </Grid>
        {
          moreActions && 
            <MoreActions 
              bulkSelect={bulkSelect}
              openMenuMore={openMenuMore}
              setOpenMenuMore={setOpenMenuMore}
              setShowDialogCampaign={setShowDialogCampaign}
              setOpenDialogDelete={setOpenDialogDelete}
            />
        }
        {showDialogCampaign && <DialogCampaign campaign={campaign} open={showDialogCampaign} setOpen={setShowDialogCampaign} />}
        <VfDialogConfirm
          openDialog={openDialogDelete}
          maxWidth="xs"
          buttonCancelText="Cancel"
          buttonCancelAction={() => setOpenDialogDelete(false)}
          buttonConfirmText="Delete"
          buttonConfirmAction={() => {
            dispatch(
              deleteCampaign({
                access_token,
                id_token,
                campaign: campaign._id,
              })
            );
            setOpenDialogDelete(false);
          }}
        >
          <Typography variant="body1" align="center">
            Are you sure you want to delete from menu
            <br />
            <strong>{title}</strong>
            <br />
            permanently?
          </Typography>
        </VfDialogConfirm>
      </Grid>
    </Card>
  );
};

export default CardCampaign;

import React, { useState } from "react";
import { Card, CardActionArea, CardActions, CardContent, Grid, Fade, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import VfSvgIcon from "../../icons/VfSvgIcon";
import VfDialogConfirm from "../../vf/VfDialogConfirm";
import theme from "AppTheme";
import { deleteGallery } from "actions/galleryActions";

const useStyles = makeStyles({
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "none",
      position: "relative",
    },
  },
});

const MoreActions = ({bulkSelect, openMenuMore, setOpenMenuMore, setOpenDialogDelete, action}) => (
  <Fade in={!bulkSelect}>
    <Grid item xs="auto">
      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={e => setOpenMenuMore(e.currentTarget)}
        >
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={openMenuMore}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(openMenuMore)}
          onClose={() => setOpenMenuMore(null)}
        >
          <MenuItem
            button
            onClick={() => {
              setOpenMenuMore(false);
              if (action) action();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            button
            onClick={() => {
              setOpenDialogDelete(true);
              setOpenMenuMore(false);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </CardActions>
    </Grid>
  </Fade>
);

const CardGallery = ({ gallery, action, moreActions, bulkSelect, selected, setSelectedGallery }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { access_token, id_token } = auth;
  const [openMenuMore, setOpenMenuMore] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-primary": selected && bulkSelect,
        "Mui-selected": selected && bulkSelect,
        animate: true,
      })}
    >
      <Grid container wrap="nowrap" alignItems="center">
        <Grid item xs>
          <CardActionArea onClick={() => action && action()}>
            <CardContent className={styles.cardContent}>
              <Grid container spacing={3} justify="space-between" alignItems="center">
                <Grid item xs="auto">
                  <Typography variant="body1">{gallery?.title}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Grid>
        {
          moreActions &&
            <MoreActions
              bulkSelect={bulkSelect}
              openMenuMore={openMenuMore}
              setOpenMenuMore={setOpenMenuMore}
              setOpenDialogDelete={setOpenDialogDelete}
              action={action}
            />
        }
        <VfDialogConfirm
          openDialog={openDialogDelete}
          maxWidth="xs"
          buttonCancelText="Cancel"
          buttonCancelAction={() => setOpenDialogDelete(false)}
          buttonConfirmText="Delete"
          buttonConfirmAction={() => {
            dispatch(
              deleteGallery({
                access_token,
                id_token,
                gallery: gallery._id,
              })
            );
            setOpenDialogDelete(false);
          }}
        >
          <Typography variant="body1" align="center">
            Are you sure you want to delete from menu
            <br />
            <strong>{gallery?.title}</strong>
            <br />
            permanently?
          </Typography>
        </VfDialogConfirm>
      </Grid>
    </Card>
  );
};

export default CardGallery;

import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Box, Button, Collapse, Container, Fab, Fade, Grid, IconButton, Typography, Snackbar } from "@material-ui/core";
import Loader from "../loader/Loader";
import Heading from "../headings/Heading";
import theme from "AppTheme";
import FilterMenuSorting from "../filters/FilterMenuSorting";
import FilterBulkSelect from "../filters/FilterBulckSelect";
import FilterSelectAll from "../filters/FilterSelectAll";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import VfAlert from "../vf/VfAlert";
import SearchBarWrapper from "../searchbar/SearchBarWrapper";
import VfSvgIcon from "../icons/VfSvgIcon";
import CardGroup from "./cards/CardGroup";
import FilterPager from "../filters/FilterPager";
import DialogStoreGroups from "./dialogs/DialogStoreGroup";
import { fetchAllStoresAndDevices } from "actions/storeActions";
import { fetchStoreGroups, bulkDeleteStoreGroups, removeError } from "actions/storeGroupActions";
import StoreGroupService from "services/StoreGroupService";
import { CSVLink } from "react-csv";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 100,
    },
  },
  fab: {
    position: "absolute",
    right: theme.spacing(3),
    top: -theme.spacing(7),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
});

const StoreGroups = () => {
  const styles = useStyles();
  const { locale } = useParams();
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogStoreGroup, setShowDialogStoreGroup] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [select, setSelect] = useState([]);
  const [pager, setPager] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name");
  const [downloadData, setDownloadData] = useState([]);
  const { allStores } = useSelector(state => state.storeReducer, shallowEqual);
  const { storeGroups, storeGroupsItems, loading, error } = useSelector(state => state.storeGroupReducer, shallowEqual);
  const auth = useSelector(state => state.authentication, shallowEqual);
  const dispatch = useDispatch();
  const csvLink = useRef();
  const { access_token, id_token } = auth;
  let calling = false;

  useEffect(() => {
    if (loading) {
      return;
    }
    dispatch(
      fetchStoreGroups({
        search,
        pageSize,
        page: pager ? pager : 1,
        sort,
        access_token,
        id_token,
      })
    );
  }, [loading, pager, sort, pageSize]);

  useEffect(() => {
    if (!loading) {
      if (pager === 1) {
        dispatch(
          fetchStoreGroups({
            search,
            pageSize,
            page: pager ? pager : 1,
            sort,
            access_token,
            id_token,
          })
        );
      }
      setPager(1);
    }
  }, [search]);

  useEffect(() => {
    if (loading) return;
    dispatch(
      fetchAllStoresAndDevices({
        access_token,
        id_token,
      })
    );
  }, [loading]);

  useEffect(() => {
    setSelectAll(select.every(value => value));
  }, [select]);

  const storeGroupsPages = useMemo(() => Math.ceil(storeGroupsItems / pageSize), [storeGroupsItems, pageSize]);

  const handleDownload = () => {
    if (calling) return;
    if (!calling) {
      calling = true;
      StoreGroupService.export({
        search,
        sort,
        access_token,
        id_token,
      })
        .then(({ storeGroups }) => {
          calling = false;
          setDownloadData(
            storeGroups?.map(x => ({
              _id: x?._id,
              name: x?.name,
            }))
          );
          csvLink.current.link.click();
          calling = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const sortOptions = [
    { title: "Sort by name", value: "name" },
    { title: "Sort by Id", value: "_id" },
  ];

  const selectAction = index => {
    setSelect(
      select.map((x, y) => {
        if (y !== index) return x;
        return !x;
      })
    );
  };

  const handleDelete = () => {
    const groups = select
      .map((item, index) => {
        if (item) return storeGroups[index]._id;
        return null;
      })
      .filter(x => x);
    setBulkSelect(false);
    setSelectAll(false);
    dispatch(
      bulkDeleteStoreGroups({
        access_token,
        id_token,
        groups: groups,
      })
    );
    setShowDialogDelete(false);
  };

  const onChangePageSize = number => {
    setPageSize(number);
    setPager(1);
  };

  return (
    <main className={styles.root}>
      <Heading title="Store groups" backUrl={`/${locale}/landing`}>
        <Grid container spacing={3} wrap="nowrap" justify="space-between" alignItems="center">
          <Grid item xs="auto">
            <Grid container spacing={3} wrap="nowrap" justify="flex-start" alignItems="center">
              <Grid item xs="auto">
                <FilterMenuSorting options={sortOptions} handleFilters={value => setSort(value)} />
              </Grid>
              <Grid item xs="auto">
                <FilterBulkSelect
                  value={bulkSelect}
                  action={() => {
                    setSelect(storeGroups.map(() => false));
                    setSelectAll(false);
                    setBulkSelect(!bulkSelect);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3} wrap="nowrap" justify="flex-end" alignItems="center">
              <Fade in={bulkSelect}>
                <Grid item sm={6}>
                  <FilterSelectAll
                    fullWidth
                    value={selectAll}
                    action={() => {
                      setSelect(storeGroups.map(() => !selectAll));
                      setSelectAll(value => !value);
                    }}
                  />
                </Grid>
              </Fade>
              <Fade in={bulkSelect}>
                <Grid item xs="auto">
                  <Button size="small" onClick={() => setShowDialogDelete(true)}>
                    Delete selected
                  </Button>
                  <VfDialogConfirm
                    openDialog={showDialogDelete}
                    maxWidth="xs"
                    buttonCancelText="Cancel"
                    buttonCancelAction={() => setShowDialogDelete(false)}
                    buttonConfirmText="Delete"
                    buttonConfirmAction={() => {
                      handleDelete();
                    }}
                  >
                    <Typography variant="body1" align="center">
                      Are you sure you want to delete from menu
                      <br />
                      <strong>{select?.filter(x => x).length + " StoreGroups"}</strong>
                      <br />
                      permanently?
                    </Typography>
                  </VfDialogConfirm>
                </Grid>
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      </Heading>
      <Box pt={8} pb={4}>
        <Container className={styles.container} maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Collapse in={!bulkSelect}>
                <SearchBarWrapper setSearchText={setSearch} expanded={openSearch} setExpanded={setOpenSearch}>
                  <Grid container alignItems="center" justify="flex-end">
                    <Grid item xs="auto">
                      <IconButton onClick={handleDownload}>
                        <VfSvgIcon icon="download" />
                      </IconButton>
                    </Grid>
                    <Grid item xs="auto">
                      <IconButton
                        onClick={() => {
                          setOpenSearch(true);
                        }}
                      >
                        <VfSvgIcon icon="search" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </SearchBarWrapper>
              </Collapse>
            </Grid>
            {!loading ? (
              storeGroups &&
              storeGroups.map((storeGroup, index) => (
                <Grid key={storeGroup._id} item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <CardGroup
                        key={storeGroup._id}
                        title={storeGroup.name}
                        id={storeGroup._id}
                        stores={allStores.filter(store => store.storeGroup?._id === storeGroup._id).length + " Stores"}
                        moreActions
                        bulkSelect={bulkSelect}
                        selected={select[index]}
                        action={() => {
                          // eslint-disable-next-line no-unused-expressions
                          if (bulkSelect) selectAction(index);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
            {!loading && (
              <Fade in={!bulkSelect}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item xs="auto">
                      <FilterPager
                        count={storeGroupsPages ? storeGroupsPages : 1}
                        page={pager}
                        pageSize={pageSize}
                        action={setPager}
                        actionPageSize={onChangePageSize}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
          <Box className={styles.fab}>
            <Fade in={!bulkSelect}>
              <Grid container spacing={3}>
                <Grid item xs="auto">
                  <Fab color="secondary" onClick={() => setShowDialogStoreGroup(true)}>
                    <VfSvgIcon icon="plus" viewBox={24} />
                  </Fab>
                </Grid>
              </Grid>
            </Fade>
          </Box>
        </Container>
      </Box>
      <CSVLink
        data={downloadData}
        filename="storegroups.csv"
        separator={";"}
        className="hidden"
        ref={csvLink}
        target="_blank"
      ></CSVLink>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(error)}
        onClose={() => {
          dispatch(removeError());
        }}
      >
        <VfAlert severity={0} message={error} />
      </Snackbar>
      {showDialogStoreGroup && <DialogStoreGroups open={showDialogStoreGroup} setOpen={setShowDialogStoreGroup} />}
    </main>
  );
};

export default StoreGroups;

import React, { useState } from "react";
import { Box, Typography, Menu, MenuItem, Button } from '@material-ui/core';
import { Pagination } from "@material-ui/lab";

const sizes = [5, 10, 20, 50, 100];

const FilterPager = ({ count, page, pageSize, action, actionPageSize }) => {
  const [open, setOpen] = useState(false);

  const onChangePageSize = (number) => {
    if (number !== pageSize) {
      actionPageSize(number);
      setOpen(false);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Pagination
        count={count}
        page={page}
        siblingCount={0}
        boundaryCount={1}
        color="primary"
        shape="rounded"
        onChange={(event, value) => action(value)}
      />
      <Button aria-controls="page-size" aria-haspopup="true" onClick={(e) => setOpen(e.currentTarget)}>
        <Typography variant="h5" color="textPrimary">
          {pageSize} / page
        </Typography>
      </Button>
      <Menu
        id="page-size"
        elevation={0}
        anchorEl={open}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(open)}
        onClose={() => setOpen(false)}
      >
        {
          sizes.map((size) => (
            <MenuItem key={size} onClick={() => onChangePageSize(size)}>
              {size}
            </MenuItem>
          ))
        }
      </Menu>
    </Box>
  );
};

FilterPager.defaultProps = {
  pageSize: 10,
  actionPageSize: () => void 0
};

export default FilterPager;

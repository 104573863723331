const TENANT_SCHEMA_REQUEST = "TENANT_SCHEMA_REQUEST";
const TENANT_SCHEMA_REQUEST_SUCCESS = "TENANT_SCHEMA_REQUEST_SUCCESS";
const TENANT_SCHEMA_REQUEST_FAIL = "TENANT_SCHEMA_REQUEST_FAIL";
const SAVE_TENANT_REQUEST = "SAVE_TENANT_REQUEST";
const SAVE_TENANT_REQUEST_SUCCESS = "SAVE_TENANT_REQUEST_SUCCESS";
const SAVE_TENANT_REQUEST_FAIL = "SAVE_TENANT_REQUEST_FAIL";
const GET_TENANTS_REQUEST = "GET_TENANTS_REQUEST";
const GET_TENANTS_REQUEST_SUCCESS = "GET_TENANTS_REQUEST_SUCCESS";
const GET_TENANTS_REQUEST_FAIL = "GET_TENANTS_REQUEST_FAIL";
const SET_CURRENT_TENANT = "SET_CURRENT_TENANT";
const SHOW_TENANT_DIALOG = "SHOW_TENANT_DIALOG";
const TENANT_VALIDATIONS_REQUEST = "TENANT_VALIDATIONS_REQUEST";
const TENANT_VALIDATIONS_REQUEST_SUCCESS = "TENANT_VALIDATIONS_REQUEST_SUCCESS";
const TENANT_VALIDATIONS_REQUEST_FAIL = "TENANT_VALIDATIONS_REQUEST_FAIL";
const UPDATE_TENANT_REQUEST = "UPDATE_TENANT_REQUEST";
const UPDATE_TENANT_REQUEST_SUCCESS = "UPDATE_TENANT_REQUEST_SUCCESS";
const UPDATE_TENANT_REQUEST_FAIL = "UPDATE_TENANT_REQUEST_FAIL";
const DELETE_ENV_FROM_TENANT = "DELETE_ENV_FROM_TENANT";
const DELETE_TENANT_REQUEST = "DELETE_TENANT_REQUEST";
const DELETE_TENANT_REQUEST_SUCCESS = "DELETE_TENANT_REQUEST_SUCCESS";
const DELETE_TENANT_REQUEST_FAIL = "DELETE_TENANT_REQUEST_FAIL";
const FETCH_TENANT_BASIC_INFO_REQUEST = "FETCH_TENANT_BASIC_INFO_REQUEST";
const FETCH_TENANT_BASIC_INFO_REQUEST_SUCCESS = "FETCH_TENANT_BASIC_INFO_REQUEST_SUCCESS";
const FETCH_TENANT_BASIC_INFO_REQUEST_FAIL = "FETCH_TENANT_BASIC_INFO_REQUEST_FAIL";
const UPDATE_TENANT_TOKEN = "UPDATE_TENANT_TOKEN";

const fetchTenantSchema = payload => ({
  type: TENANT_SCHEMA_REQUEST,
  payload,
});

const saveTenantData = payload => ({
  type: SAVE_TENANT_REQUEST,
  payload,
});

const fetchTenants = payload => ({
  type: GET_TENANTS_REQUEST,
  payload,
});

const setCurrentTenant = payload => ({
  type: SET_CURRENT_TENANT,
  payload,
});

const setShowTenantDialog = () => ({
  type: SHOW_TENANT_DIALOG,
});

const tenantValidions = payload => ({
  type: TENANT_VALIDATIONS_REQUEST,
  payload,
});

const updateTenant = payload => ({
  type: UPDATE_TENANT_REQUEST,
  payload,
});

const deleteEnvFromTenant = payload => ({
  type: DELETE_ENV_FROM_TENANT,
  payload,
});

const deleteTenant = payload => ({
  type: DELETE_TENANT_REQUEST,
  payload,
});

const fetchTenantBasicInfo = payload => ({
  type: FETCH_TENANT_BASIC_INFO_REQUEST,
  payload,
});

const updateTenantToken = payload => ({
  type: UPDATE_TENANT_TOKEN,
  payload,
});

export {
  TENANT_SCHEMA_REQUEST,
  TENANT_SCHEMA_REQUEST_SUCCESS,
  TENANT_SCHEMA_REQUEST_FAIL,
  SAVE_TENANT_REQUEST_SUCCESS,
  SAVE_TENANT_REQUEST_FAIL,
  SAVE_TENANT_REQUEST,
  GET_TENANTS_REQUEST,
  GET_TENANTS_REQUEST_SUCCESS,
  GET_TENANTS_REQUEST_FAIL,
  SET_CURRENT_TENANT,
  SHOW_TENANT_DIALOG,
  TENANT_VALIDATIONS_REQUEST,
  TENANT_VALIDATIONS_REQUEST_SUCCESS,
  TENANT_VALIDATIONS_REQUEST_FAIL,
  UPDATE_TENANT_REQUEST,
  UPDATE_TENANT_REQUEST_SUCCESS,
  UPDATE_TENANT_REQUEST_FAIL,
  DELETE_ENV_FROM_TENANT,
  DELETE_TENANT_REQUEST,
  DELETE_TENANT_REQUEST_SUCCESS,
  DELETE_TENANT_REQUEST_FAIL,
  FETCH_TENANT_BASIC_INFO_REQUEST,
  FETCH_TENANT_BASIC_INFO_REQUEST_SUCCESS,
  FETCH_TENANT_BASIC_INFO_REQUEST_FAIL,
  UPDATE_TENANT_TOKEN,
  fetchTenantSchema,
  saveTenantData,
  fetchTenants,
  setCurrentTenant,
  setShowTenantDialog,
  tenantValidions,
  updateTenant,
  deleteEnvFromTenant,
  deleteTenant,
  fetchTenantBasicInfo,
  updateTenantToken,
};

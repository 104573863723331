/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_STORES_REQUEST,
  GET_ALL_STORES_AND_DEVICES_REQUEST,
  ADD_STORE_REQUEST,
  IMPORT_STORES_REQUEST,
  EXPORT_STORES_REQUEST,
  UPDATE_STORE_REQUEST,
  DELETE_STORE_REQUEST,
  BULK_DELETE_STORES_REQUEST,
  fetchStoresSuccess,
  fetchStoresFail,
  fetchAllStoresAndDevicesSuccess,
  fetchAllStoresAndDevicesFail,
  importStoresSuccess,
  importStoresFail,
  exportStoresSuccess,
  exportStoresFail,
  addStoreSuccess,
  addStoreFail,
  updateStoreSuccess,
  updateStoreFail,
  deleteStoreSuccess,
  deleteStoreFail,
  bulkDeleteStoresSuccess,
  bulkDeleteStoresFail,
} from "actions/storeActions";
import StoreService from "services/StoreService";

function* fetchStoresWorker({ payload }) {
  try {
    const data = yield call(StoreService.fetch, payload);
    yield put(fetchStoresSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(fetchStoresFail(error));
  }
}

function* fetchStoresWatcher() {
  yield takeLatest(GET_STORES_REQUEST, fetchStoresWorker);
}

function* fetchAllStoresAndDevicesWorker({ payload }) {
  try {
    const data = yield call(StoreService.fetchAll, payload);
    yield put(fetchAllStoresAndDevicesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(fetchAllStoresAndDevicesFail(error));
  }
}

function* fetchAllStoresAndDevicesWatcher() {
  yield takeLatest(GET_ALL_STORES_AND_DEVICES_REQUEST, fetchAllStoresAndDevicesWorker);
}

function* addStoreWorker({ payload }) {
  try {
    const data = yield call(StoreService.add, payload);
    yield put(addStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addStoreFail(error));
  }
}

function* addStoreWatcher() {
  yield takeLatest(ADD_STORE_REQUEST, addStoreWorker);
}

function* updateStoreWorker({ payload }) {
  try {
    const data = yield call(StoreService.update, payload);
    yield put(updateStoreSuccess(data));
  } catch (error) {
    yield put(updateStoreFail(error));
  }
}

function* updateStoreWatcher() {
  yield takeLatest(UPDATE_STORE_REQUEST, updateStoreWorker);
}

function* deleteStoreWorker({ payload }) {
  try {
    const data = yield call(StoreService.delete, payload);
    yield put(deleteStoreSuccess(data));
  } catch (error) {
    yield put(deleteStoreFail(error));
  }
}

function* deleteStoreWatcher() {
  yield takeLatest(DELETE_STORE_REQUEST, deleteStoreWorker);
}

function* bulkDeleteStoresWorker({ payload }) {
  try {
    const data = yield call(StoreService.bulkDelete, payload);
    yield put(bulkDeleteStoresSuccess(data));
  } catch (error) {
    yield put(bulkDeleteStoresFail(error));
  }
}

function* bulkDeleteStoresWatcher() {
  yield takeLatest(BULK_DELETE_STORES_REQUEST, bulkDeleteStoresWorker);
}

function* importStoresWorker({ payload }) {
  try {
    const data = yield call(StoreService.import, payload);
    yield put(importStoresSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(importStoresFail(error));
  }
}

function* importStoresWatcher() {
  yield takeLatest(IMPORT_STORES_REQUEST, importStoresWorker);
}

function* exportStoresWorker({ payload }) {
  try {
    const data = yield call(StoreService.export, payload);
    yield put(exportStoresSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(exportStoresFail(error));
  }
}

function* exportStoresWatcher() {
  yield takeLatest(EXPORT_STORES_REQUEST, exportStoresWorker);
}

const storeSaga = [
  fetchStoresWatcher(),
  fetchAllStoresAndDevicesWatcher(),
  addStoreWatcher(),
  importStoresWatcher(),
  exportStoresWatcher(),
  updateStoreWatcher(),
  deleteStoreWatcher(),
  bulkDeleteStoresWatcher(),
];

export default storeSaga;

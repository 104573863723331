/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";

import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    minHeight: "55vh",
    overflowX: "hidden",
    ["& .MuiTreeView-root"]: {
      ["& > .MuiTreeItem-root"]: {
        ["&:hover"]: {
          ["& > .MuiTreeItem-content"]: {
            // backgroundColor: fade(theme.palette.secondary.main,.16)
          },
        },
        ["&.Mui-expanded"]: {
          ["& > .MuiTreeItem-content"]: {
            color: theme.palette.secondary.main,
            ["& .MuiSvgIcon-root"]: {
              ["& path"]: {
                fill: theme.palette.secondary.main,
              },
            },
            ["& .MuiBadge-root"]: {
              ["& .MuiBadge-colorSecondary"]: {
                backgroundColor: theme.palette.common.white,
              },
            },
          },
        },
        ["& > .MuiTreeItem-content"]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(1.5),
          paddingTop: theme.spacing(1.5),
          flexDirection: "row-reverse",
          ["& > .MuiTreeItem-iconContainer"]: {
            marginRight: 0,
            marginLeft: theme.spacing(0.5),
          },
          ["& > .MuiTreeItem-label"]: {
            paddingLeft: 0,
          },
          ["& .MuiTreeItem-iconContainer"]: {
            ["& svg"]: {
              fontSize: 24,
            },
          },
        },
        ["& .Mui-expanded"]: {
          ["& > .MuiTreeItem-content"]: {
            ["& .MuiTreeItem-iconContainer"]: {
              color: theme.palette.secondary.main,
            },
            ["& .MuiTreeItem-label"]: {
              fontWeight: "bold",
            },
          },
        },
        ["& .Mui-selected"]: {
          ["& > .MuiTreeItem-content"]: {
            ["& .MuiTreeItem-label"]: {
              fontWeight: "bold",
            },
          },
        },
        ["& .MuiTreeItem-content"]: {
          paddingBottom: theme.spacing(0.5),
          paddingTop: theme.spacing(0.5),
        },
      },
    },
    ["& .MuiTreeItem-root"]: {
      ["&.Mui-selected"]: {
        ["& .MuiTreeItem-content"]: {
          ["& .MuiTreeItem-label"]: {
            backgroundColor: "transparent",
          },
        },
      },
      ["& .MuiTreeItem-root"]: {
        ["& .MuiTreeItem-content"]: {
          "& .MuiTreeItem-label": {
            lineHeight: 1.2,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
          },
        },
      },
    },
    ["& .MuiCollapse-container"]: {
      ["& .MuiCollapse-wrapper"]: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        ["& .MuiCollapse-wrapperInner"]: {
          borderLeftWidth: 2,
          borderLeftStyle: "solid",
          borderLeftColor: theme.palette.grey["300"],
          ["& .MuiTreeItem-root"]: {
            ["&[aria-expanded]"]: {
              backgroundColor: theme.palette.common.white,
              paddingLeft: theme.spacing(2),
              marginLeft: -theme.spacing(2),
              ["& > .MuiTreeItem-content"]: {
                ["& > .MuiTreeItem-iconContainer"]: {
                  marginLeft: -theme.spacing(1),
                },
                ["& > .MuiTreeItem-label"]: {
                  paddingLeft: theme.spacing(1),
                },
              },
            },
          },
        },
      },
    },
    ["& .MuiTreeItem-iconContainer"]: {
      marginRight: 0,

      ["& svg"]: {
        fontSize: 16,
      },
    },
    ["& .MuiTypography-root"]: {},
    ["& .MuiTreeItem-label"]: {
      backgroundColor: "transparent",
      paddingLeft: 0,
    },
    ["& .MuiBox-searchBar"]: {
      position: "absolute",
      left: theme.spacing(2),
      right: theme.spacing(2),
      top: "50%",
      transform: "translateY(-50%)",
      ["& .MuiIconButton-root"]: {
        padding: theme.spacing(1),
      },
      ["& .MuiCard-search"]: {
        width: 42,
        ["&.MuiCard-search--open"]: {
          width: "100%",
        },
      },
    },
    ["& .MuiBadge-root"]: {
      ["& .MuiBadge-badge"]: {
        right: -theme.spacing(1),
        top: -1,
      },
    },
  },
});

const schemaSearch = (node, parentData, searchValue) => {
  if (node && node.__schema__ && node.__schema__.properties) {
    let clean = false;
    Object.entries(node.__schema__.properties).forEach(([k, value]) => {
      if (clean) return;
      if (k.indexOf(searchValue) > -1) {
        parentData.push({
          id: node.id,
          __schema__: node.__schema__,
          name: node.name,
        });
        clean = true;
      } else {
        Object.entries(value).forEach(([key, val]) => {
          if (
            key === "title" &&
            val.toUpperCase().indexOf(searchValue.toUpperCase()) > -1
          ) {
            parentData.push({
              id: node.id,
              __schema__: node.__schema__,
              name: node.name,
            });
            clean = true;
          }
        });
      }
    });
  }

  if (node.children) {
    node.children.map(childNode => {
      return schemaSearch(childNode, parentData, searchValue);
    });
  }
  return parentData;
};

const WidgetFormTree = ({ data, setSelectedTreeItem }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(["$"]);

  const renderTree = node => {
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        label={
          node.name?.charAt(0).toUpperCase() + node.name?.slice(1) ||
          "Properties"
        }
        onClick={() => setSelectedTreeItem(node)}
      >
        {Array.isArray(node.children)
          ? node.children.map(n => renderTree(n))
          : null}
      </TreeItem>
    );
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TreeView
            defaultCollapseIcon={<VfSvgIcon icon="chevronDown" />}
            defaultExpandIcon={<VfSvgIcon icon="chevronRight" />}
            expanded={expanded}
            onNodeToggle={(e, value) => setExpanded(value)}
          >
            {Array.isArray(data) ? data.map(node => renderTree(node)) : null}
          </TreeView>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WidgetFormTree;

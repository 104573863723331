/* eslint-disable camelcase */
import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
import VfDialog from "components/vf/VfDialog";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TextWidget from "../custom-mui-components/TextWidget";
import VfSvgIcon from "../icons/VfSvgIcon";
import MediaBox from "../media/MediaBox";
import MenuUrlBox from "../media/MenuUrlBox";
import theme from "../../AppTheme";
import {
  deleteMedia,
  fetchMedia,
  filterFetchMedia,
  updateMedia,
  uploadMedia,
  resetMedia as clearMedia,
} from "../../actions/mediaActions";
import { ReactComponent as imagePlaceholder } from "../../assets/images/ic-image@56.svg";
import { ReactComponent as videoPlaceholder } from "../../assets/images/ic-video@56.svg";
import { ReactComponent as audioPlaceholder } from "../../assets/images/ic-audio@56.svg";
import { ReactComponent as filePlaceholder } from "../../assets/images/ic-read-me@56.svg";

const mediaBoxClasses = makeStyles({
  root: {
    "& .MuiBox-searchBar": {
      position: "absolute",
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  searchWrapper: {
    minHeight: theme.spacing(6) + 2,
  },
});

const initialFilterTypes = [
  { type: "image", icon: "image", placeholder: imagePlaceholder },
  { type: "video", icon: "video", placeholder: videoPlaceholder },
  { type: "audio", icon: "audio", placeholder: audioPlaceholder },
  { type: "other", icon: "readme", placeholder: filePlaceholder },
];

const createInputForMedia = (mediaType, maximumRequestBodySize) =>
  // React.memo(props => {
  props => {
    const { onChange } = props;
    const { access_token, id_token } = useSelector(
      state => state.authentication,
      shallowEqual
    );
    const media = useSelector(state => state.media.list, shallowEqual);
    const page = useSelector(state => state.media.page, shallowEqual);
    const limit = useSelector(state => state.media.limit, shallowEqual);
    const hasMore = useSelector(state => state.media.hasMore, shallowEqual);
    const loading = useSelector(state => state.media.loading, shallowEqual);
    const scrollLoading = useSelector(
      state => state.media.scrollLoading,
      shallowEqual
    );
    const dispatch = useDispatch();
    const getMedia = payload => dispatch(fetchMedia(payload));
    const getFilterMedia = payload => dispatch(filterFetchMedia(payload));
    const postMedia = payload => dispatch(uploadMedia(payload));
    const putMedia = payload => dispatch(updateMedia(payload));
    const eraseMedia = payload => dispatch(deleteMedia(payload));
    const resetMedia = () => dispatch(clearMedia());

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [selected, setSelected] = useState({});

    const mediaBoxProps = {
      classes: mediaBoxClasses(),
      getFilterMedia,
      access_token,
      id_token,
      limit,
      loading,
      getMedia,
      page,
      hasMore,
      postMedia,
      media,
      filterTypes: initialFilterTypes,
      putMedia,
      eraseMedia,
      scrollLoading,
      allwaysOpen: true,
      hasFocus: true,
      bulkItems: [],
      bulkSelect: false,
      filters: mediaType,
      inModal: true,
      selected,
      setSelected,
      maximumRequestBodySize,
    };

    const inputProps = {
      endAdornment: (
        <IconButton
          onClick={() => {
            getFilterMedia({
              access_token,
              id_token,
              page,
              limit,
              filters: mediaType,
            });

            setDialogIsOpen(true);
          }}
        >
          <VfSvgIcon
            icon={
              ["video", "image", "audio"].includes(mediaType)
                ? mediaType
                : "readme"
            }
          />
        </IconButton>
      ),
    };

    return (
      <>
        <TextWidget inputProps={inputProps} {...props} />
        <VfDialog
          title={`Select ${mediaType || "media"}`}
          openDialog={dialogIsOpen}
          buttonConfirmAction={() => {
            setDialogIsOpen(false);
            onChange(selected.url);
            resetMedia();
          }}
          buttonConfirmDisabled={!Object.keys(selected).length}
          buttonCloseAction={() => {
            setDialogIsOpen(false);
            setSelected({});
            resetMedia();
          }}
          buttonConfirmText="Select"
          maxHeight={650}
        >
          <MediaBox {...mediaBoxProps} />
        </VfDialog>
      </>
    );
  };

const createInputForMenuUrl = () => props => {
  const [inputValue, setInputValue] = useState({});
  const { onChange } = props;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const menuUrlBoxProps = {
    allwaysOpen: true,
    hasFocus: true,
    inModal: true,
    onChange,
    setDialogIsOpen,
    inputValue,
    setInputValue,
  };

  const inputProps = {
    endAdornment: (
      <IconButton
        onClick={() => {
          setDialogIsOpen(true);
        }}
      >
        <VfSvgIcon icon="readme" />
      </IconButton>
    ),
  };

  return (
    <>
      <TextWidget inputProps={inputProps} {...props} />
      <VfDialog
        title="Select URL"
        openDialog={dialogIsOpen}
        buttonCloseAction={() => {
          setDialogIsOpen(false);
        }}
        maxHeight="80vh"
        maxWidth="md"
      >
        <MenuUrlBox {...menuUrlBoxProps} />
      </VfDialog>
    </>
  );
};

const createInputForRole = (role, maximumRequestBodySize) => {
  switch (role) {
    case "media":
      return createInputForMedia("", maximumRequestBodySize);
    case "image":
    case "video":
    case "audio":
    case "other":
      return createInputForMedia(role, maximumRequestBodySize);
    case "menuUrl":
      return createInputForMenuUrl();
    default:
      return null;
  }
};

export default createInputForRole;

import {
  GET_DEVICES_REQUEST_SUCCESS,
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_REQUEST_FAIL,
  ADD_DEVICE_REQUEST_SUCCESS,
  IMPORT_DEVICES_REQUEST,
  IMPORT_DEVICES_REQUEST_SUCCESS,
  IMPORT_DEVICES_REQUEST_FAIL,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_REQUEST_SUCCESS,
  UPDATE_DEVICE_REQUEST_FAIL,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_REQUEST_SUCCESS,
  DELETE_DEVICE_REQUEST_FAIL,
  BULK_DELETE_DEVICES_REQUEST,
  BULK_DELETE_DEVICES_REQUEST_SUCCESS,
  BULK_DELETE_DEVICES_REQUEST_FAIL,
  EXPORT_DEVICES_REQUEST,
  EXPORT_DEVICES_REQUEST_SUCCESS,
  EXPORT_DEVICES_REQUEST_FAIL,
  REMOVE_ERROR,
} from "actions/deviceActions";

const initialState = {
  loading: false,
  devices: [],
  devicesItems: 1,
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DEVICES_REQUEST_SUCCESS: {
      return {
        ...state,
        devices: payload.devices,
        devicesItems: payload.count,
      };
    }
    case ADD_DEVICE_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_DEVICE_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case ADD_DEVICE_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case IMPORT_DEVICES_REQUEST: {
      return { ...state, loading: true };
    }
    case IMPORT_DEVICES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case IMPORT_DEVICES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case UPDATE_DEVICE_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_DEVICE_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case UPDATE_DEVICE_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case DELETE_DEVICE_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_DEVICE_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case DELETE_DEVICE_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case BULK_DELETE_DEVICES_REQUEST: {
      return { ...state, loading: true };
    }
    case BULK_DELETE_DEVICES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case BULK_DELETE_DEVICES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case EXPORT_DEVICES_REQUEST: {
      return { ...state, loading: true };
    }
    case EXPORT_DEVICES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case EXPORT_DEVICES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case REMOVE_ERROR: {
      return { ...state, error: '' };
    }
    default:
      return state;
  }
};

import React, { Fragment, useState } from "react";
import theme from "AppTheme";
import { Typography, Grid, FormControl, TextField, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
});

const AppDialogCredentials = ({ values, onComplete }) => {
  const [credentials, setCredentials] = useState(values.length > 0 ? values : [{ code: "", scope: "" }]);
  const classes = useStyles();

  const onChangeField = (value, type, idx) => {
    setCredentials(credentials =>
      credentials.map((credential, index) => ({
        ...credential,
        [type]: index === idx ? value : credential[type],
      }))
    );
  };

  const onRemove = () => {
    setCredentials(credentials => {
      return credentials.filter((credential, index) => index !== credentials.length - 1);
    });
  };

  const onAdd = () => {
    setCredentials(credentials => {
      return [...credentials, { code: "", scope: "" }];
    });
  };

  const isValid = credentials.every(({ code, scope }) => code !== "" && scope !== "");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs="auto">
            <Typography variant="body1">Add credentials</Typography>
          </Grid>
          {credentials.map(({ code, scope }, index) => (
            <Fragment key={index}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="title"
                    variant="outlined"
                    label="Code"
                    type="text"
                    onChange={e => {
                      onChangeField(e.target.value, "code", index);
                    }}
                    value={code}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="title"
                    variant="outlined"
                    label="Scope"
                    type="text"
                    onChange={e => {
                      onChangeField(e.target.value, "scope", index);
                    }}
                    value={scope}
                  />
                </FormControl>
              </Grid>
            </Fragment>
          ))}
          <Grid item xs={12}>
            <Grid container spacing={3} justify="space-between">
              <Box display="flex" alignItems="center" className={classes.marginLeft}>
                <Grid item xs="auto">
                  <Button variant="contained" disabled={credentials.length === 1} onClick={onRemove}>
                    Remove
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={false}
                    onClick={onAdd}
                    className={classes.marginLeft}
                  >
                    Add
                  </Button>
                </Grid>
              </Box>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!isValid}
                  onClick={() => onComplete(credentials)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppDialogCredentials;

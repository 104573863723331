import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {
  setSelectedTreeItem as settingSelectedTreeItem,
  updateTranslationData as updTranslationData,
} from "actions/translationsActions";
import HasPermission from "components/has-permissions/HasPermissions";

import { get, set } from "lodash";

const useStyles = makeStyles({
  root: {},
});

const TranslationsKeys = ({
  selectedTreeItem,
  setSelectedTreeItem,
  translations,
  updateTranslationData,
  envID,
}) => {
  const classes = useStyles();

  /* eslint-disable no-nested-ternary */
  const path =
    selectedTreeItem && selectedTreeItem.path
      ? selectedTreeItem.path === "$."
        ? "data"
        : `data.${selectedTreeItem.path}`.replace("$.", "")
      : "";
  const [translated, setTranslated] = React.useState(
    get(
      translations.find(l => l.language === "en"),
      path,
      {}
    )
  );
  const setValue = (k, v) => {
    translations.map(t => {
      if (t.language !== "en") return t;
      set(t, `${path}.${k}`, v);
      setTranslated(get(t, path, {}));
      return t;
    });
    updateTranslationData(translations);
    setSelectedTreeItem({
      ...selectedTreeItem,
    });
  };

  React.useEffect(() => {
    setTranslated(
      get(
        translations.find(l => l.language === "en"),
        path,
        {}
      )
    );
  });

  if (!translated) return null;

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            Original
          </Typography>
        </Grid>
        {Object.keys(translated).map(
          k =>
            typeof translated[k] !== "object" && (
              <Grid key={k} item xs={12}>
                <HasPermission
                  permissionKey={`edit_translations_env_${envID}`}
                  disabled
                >
                  <TextField
                    label={k}
                    variant="outlined"
                    value={translated && translated[k]}
                    onChange={e => setValue(k, e.target.value)}
                    rowsMax="3"
                    rows="1"
                    multiline
                    fullWidth
                  />
                </HasPermission>
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    translations: state.translations.translations,
    selectedTreeItem: state.translations.selectedTreeItem,
  };
};

const mapDispatchToProps = dispatch => ({
  setSelectedTreeItem: data => dispatch(settingSelectedTreeItem(data)),
  updateTranslationData: data => dispatch(updTranslationData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationsKeys);

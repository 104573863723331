import React, { useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardSelectorItems from "./CardSelectorItems";

const CardSelector = ({
  items,
  title,
  selected,
  editable,
  onSelect,
  onConfirm,
}) => {
  const initialValue = useRef(selected).current;
  const Title = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
    );
  };

  const ActionButton = () => {
    if (editable && !initialValue) {
      return null;
    }
    if (!onConfirm) {
      return null;
    }
    return (
      <Grid item xs={12} container spacing={3}>
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onConfirm()}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Cards = () => {
    return (
      <Grid item xs={12} container spacing={3}>
        {items.map(item => {
          return (
            <Grid item xs={12} sm={3} md={3} key={item.value}>
              <CardSelectorItems
                title={item.title}
                value={item.value}
                imageIcon={item.value}
                selected={selected === item.value}
                disabled={!editable && !!selected && selected !== item.value}
                onSelect={onSelect}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };
  return (
    <Grid container spacing={3}>
      <Title />
      <Cards />
      <ActionButton />
    </Grid>
  );
};

export default CardSelector;

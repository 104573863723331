/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from "react";
import {
  Grid,
  Button,
  IconButton,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  DialogActions,
} from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalContent: {
    textAlign: "center",
    "& p": {
      marginBottom: 0,
    },
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  modalActions: {
    justifyContent: "center",
  },
});

export default function CardApi({
  api,
  auth,
  removeApi,
  deleteApiFailData,
  getApiDetails,
  toggleDeleteApiIsUsedPopUp,
}) {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { access_token, id_token } = auth;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialogDelete = () => {
    setOpenDialogDelete(!openDialogDelete);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-widget": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": false,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <CardActionArea
            onClick={() => {
              getApiDetails({
                access_token,
                id_token,
                apiId: api._id,
              });
            }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs>
                <CardContent>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={12} sm="auto">
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1" component="h5">
                            {api.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      {!!api.tags.length && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {api.tags ? api.tags.join(", ") : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
        <Grid item xs="auto">
          <CardActions disableSpacing>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <IconButton
                  aria-label="add to favorites"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <VfSvgIcon icon="more" viewBox={24} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  elevation={0}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      getApiDetails({
                        access_token,
                        id_token,
                        apiId: api._id,
                      });
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      handleOpenDialogDelete();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </CardActions>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={openDialogDelete}
        onClose={handleClose}
        maxWidth="xs"
      >
        <DialogContent className={classes.modalContent}>
          <DialogContentText gutterBottom>
            Are you sure you want to delete
          </DialogContentText>
          <DialogContentText>
            <strong>{api.name}</strong>
          </DialogContentText>
          <DialogContentText>API permanently?</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            color="default"
            variant="outlined"
            onClick={() => handleOpenDialogDelete()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleOpenDialogDelete();
              removeApi({
                access_token,
                id_token,
                apiId: api._id,
              });
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={deleteApiFailData?.popUpBool}
        maxWidth="xs"
      >
        <DialogContent className={classes.modalContent}>
          <DialogContentText gutterBottom>
            {`This API ${
              deleteApiFailData.deleteVersion ? "version " : ""
            }is currently being used by ${deleteApiFailData?.usedBy}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => toggleDeleteApiIsUsedPopUp()}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

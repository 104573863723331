import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    background: "white",
    height: "80px",
    boxShadow: "0 -3px 6px rgba(0,0,0,.16)",
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
  },
  button: {
    minWidth: "190px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "120px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "auto",
    },
  },
  flex: {
    flexGrow: "1",
  },
}));

const ToolbarBottom = ({
  widgets,
  resetChanges,
  editor,
  cancelAction,
  deployChanges,
  envTitle,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const [saveAndDeploy, setSaveAndDeploy] = useState(false);

  const changeModeClick = mode => {
    setSaveAndDeploy(mode);
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container alignItems="center" justify="space-between" spacing={3}>
      <Grid item>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          {widgets && (
            <Grid item>
              <Button
                onClick={resetChanges}
                variant="outlined"
                className={classes.button}
              >
                Reset changes
              </Button>
            </Grid>
          )}
          {editor && (
            <Grid item>
              <Button
                onClick={cancelAction}
                variant="outlined"
                className={classes.button}
              >
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          {editor && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Preview
              </Button>
            </Grid>
          )}
          {editor && (
            <Grid item>
              <ButtonGroup
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                <Button type="submit" className={classes.flex}>
                  {saveAndDeploy ? "Save & Deploy" : "Save"}
                </Button>
                <Button
                  size="small"
                  aria-label="add to favorites"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Menu
                id="simple-menu"
                elevation={0}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={e => {
                    changeModeClick(true);
                    handleClose(e);
                  }}
                >
                  SAVE AND DEPLOY
                </MenuItem>
                <MenuItem
                  onClick={e => {
                    changeModeClick(false);
                    handleClose(e);
                  }}
                >
                  SAVE AND REVIEW
                </MenuItem>
              </Menu>
            </Grid>
          )}
          {widgets && (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => deployChanges()}
              >
                {`Deploy to ${envTitle}`}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToolbarBottom;

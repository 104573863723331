import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Box, Button, Card, CardContent, Container, Divider, Grid, InputBase, TextField, Typography, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import VfDialogFull from "../../vf/VfDialogFull";
import theme from "AppTheme";
import CardStoreList from "../cards/CardStoreList";
import { addStoreGroup, updateStoreGroup } from "actions/storeGroupActions";

const useStyles = makeStyles({
  cardContent: {
    padding: theme.spacing(3),
  },
});

const DialogStoreGroups = ({ open, setOpen, storeGroup }) => {
  const [name, setName] = useState(storeGroup ? storeGroup?.name : "");
  const [storeArray, setStoreArray] = useState([]);
  const [selected, setSelected] = useState({});
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { allStores } = useSelector(state => state.storeReducer, shallowEqual);

  const dispatch = useDispatch();
  const styles = useStyles();

  const { access_token, id_token } = auth;

  const handleCompletion = () => {
    if (name) {
      if (storeGroup?._id) {
        dispatch(
          updateStoreGroup({
            name,
            stores: storeArray,
            access_token,
            id_token,
            _id: storeGroup._id,
          })
        );
      } else {
        dispatch(
          addStoreGroup({
            name,
            stores: storeArray,
            access_token,
            id_token,
          })
        );
      }
      setOpen(false);
      setStoreArray([]);
    }
  };
  useEffect(() => {
    if (open) {
      setStoreArray(
        allStores.filter(x => {
          if (!x?.storeGroup?._id) {
            return false;
          }
          return x?.storeGroup?._id === storeGroup?._id;
        })
      );
    }
  }, [open]);

  return (
    <VfDialogFull
      title="Dialog"
      openDialog={open}
      buttonCloseAction={() => setOpen(false)}
      buttonConfirmAction={() => setOpen(false)}
      buttonConfirmText="Save"
      buttonConfirmDisabled={!name}
      saveAction={handleCompletion}
      /* eslint-disable */
      appBar={
        <Grid container alignItems="center" spacing={6} justify="space-between">
          <Grid item xs="auto">
            <Typography variant="body1" component="h5">
              {storeGroup ? storeGroup?.name : "New Store Group"}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="body2">{storeArray?.length} Stores</Typography>
          </Grid>
        </Grid>
      }
      /* eslint-enable */
    >
      <Box py={6}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Card elevation={0}>
                <CardContent className={styles.cardContent}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1">Enter group’s name</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Name"
                        value={name}
                        onChange={e => {
                          setName(e.target.value);
                        }}
                        variant="outlined"
                        color="primary"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Card elevation={0}>
                <CardContent className={styles.cardContent}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1">Select stores</Typography>
                    </Grid>
                    {storeArray &&
                      storeArray.map(store => (
                        <Grid key={store._id} item xs={12}>
                          <CardStoreList
                            name={store.name}
                            code={store.storeId}
                            deleteAction={storeId => {
                              setStoreArray(storeArray.filter(x => x.storeId !== storeId));
                            }}
                          />
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <TextField
                        select
                        value={selected}
                        label="Select store"
                        variant="filled"
                        color="primary"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: classNames("MuiFilledInput-disableUnderline"),
                        }}
                      >
                        <MenuItem value="search" button={false}>
                          <InputBase placeholder="Search store name or ID" fullWidth />
                        </MenuItem>
                        <Divider orientation="horizontal" />
                        {allStores &&
                          allStores
                            .filter(x => !storeArray.find(y => y._id === x._id))
                            .map(store => (
                              <MenuItem key={store._id} value={store} onClick={() => setSelected(store)}>
                                <Grid container spacing={1} alignItems="center" justify="space-between" wrap="nowrap">
                                  <Grid item xs="auto">
                                    <Grid container spacing={1} alignItems="center">
                                      <Grid item xs="auto">
                                        <Typography variant="body1">{store.name}</Typography>
                                      </Grid>
                                      <Grid item xs="auto">
                                        <Typography variant="body1" color="secondary">
                                          {store.storeId}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {!!store?.storeGroup?._id && (
                                    <Grid item xs="auto">
                                      <Typography variant="body2" color="secondary">
                                        {"Already in " + store?.storeGroup?.name}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </MenuItem>
                            ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        disabled={!selected?.name}
                        onClick={() => setStoreArray(storeArray.concat([selected]))}
                      >
                        Add item
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </VfDialogFull>
  );
};

export default DialogStoreGroups;

/* eslint-disable camelcase */
import axios from "axios";

class DeviceService {
  fetch = async ({ access_token, id_token, search, page, pageSize, sort, store }) => {
    const params = { search, page, pageSize, sort, store };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/device/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in fetch devices service:", err);
      return err;
    }
  };

  add = async ({ name, store, code, type, passcodes, access_token, id_token }) => {
    const params = { name, store, code, type, passcodes };
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/portal/device/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: params,
      });
      return data;
    } catch (err) {
      console.log("Error in add device service:", err);
      return err;
    }
  };

  import = async ({ payload, access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/portal/device/import`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: payload,
      });
      return data;
    } catch (err) {
      console.log("Error in add many devices service:", err);
      return err;
    }
  };

  update = async ({ name, store, code, type, passcodes, _id, access_token, id_token }) => {
    const params = { name, store, code, type, passcodes };
    try {
      const { data } = await axios({
        method: "patch",
        url: `/api/appbuilder/portal/device/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: params,
      });
      return data;
    } catch (err) {
      console.log("Error in update device service:", err);
      return err;
    }
  };

  delete = async ({ access_token, id_token, _id }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/device/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in delete device service:", err);
      return err;
    }
  };

  bulkDelete = async ({ access_token, id_token, devices }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/device/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: devices,
      });
      return data;
    } catch (err) {
      console.log("Error in bulk Delete devices service:", err);
      return err;
    }
  };

  export = async ({ access_token, id_token, search, sort, store }) => {
    const params = { search, sort, store };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/device/export`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in export devices service:", err);
      return err;
    }
  };
}

export default new DeviceService();

/* eslint-disable camelcase */
import axios from "axios";

class CampaignService {
  fetch = async ({
    id_token,
    access_token,
    search,
    page,
    pageSize,
    sort,
    priority,
    dateFrom,
    dateTo,
    selectedStore,
    selectedStoreType,
  }) => {
    try {
      const params = {
        search,
        page,
        pageSize,
        sort,
        priority,
        dateFrom,
        dateTo,
        selectedStore,
        selectedStoreType,
      };
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/campaign/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in fetch campaign service:", err);
      return err;
    }
  };

  add = async ({ campaign, id_token, access_token }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/portal/campaign/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: campaign,
      });
      return data;
    } catch (err) {
      console.log("Error in add campaign service:", err);
      return err;
    }
  };

  update = async ({ campaign, id_token, access_token }) => {
    const {_id} = campaign;
    try {
      const { data } = await axios({
        method: "patch",
        url: `/api/appbuilder/portal/campaign/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: campaign,
      });
      return data;
    } catch (err) {
      console.log("Error in update campaign service:", err);
      return err;
    }
  };

  delete = async ({ access_token, id_token, campaign }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/portal/campaign/${campaign}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        }
      });
      return data;
    } catch (err) {
      console.log("Error in delete campaign service:", err);
      return err;
    }
  };

  bulkDelete = async ({ access_token, id_token, campaigns }) => {
    try {
      await axios({
        method: "delete",
        url: `/api/appbuilder/portal/campaign/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: campaigns,
      });
    } catch (err) {
      console.log("Error in bulk delete gallery service:", err);
      return err;
    }
  };

  export = async ({
    id_token,
    access_token,
    search,
    sort,
    priority,
    dateFrom,
    dateTo,
    selectedStore,
    selectedStoreType,
  }) => {
    try {
      const params = {
        search,
        sort,
        priority,
        dateFrom,
        dateTo,
        selectedStore,
        selectedStoreType,
      };
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/campaign/export`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in export campaign service:", err);
      return err;
    }
  };
}

export default new CampaignService();

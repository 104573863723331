import React from "react";
import { Card, CardContent, Grid, Typography, Box } from "@material-ui/core";
import { capitalizeFirstLetter } from "../dialogs/DialogDevice";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../AppTheme";

const useStyles = makeStyles({
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  device: {
    borderBottom: '1px solid #ccc'
  },
  prText: {
    paddingRight: theme.spacing(1)
  }
});

const CardPasscode = ({storeId, name, devices}) => {
  const styles = useStyles();

  return (
    <Card elevation={0} className="MuiCard-white MuiCard-outlined animate">
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CardContent className={styles.cardContent}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1">{name}</Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography variant="body1" color="secondary">
                            {storeId}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {devices.map(({name, code, passcodes}) => (
                      <>
                      <Grid item xs={12} className={styles.device}>
                        <Grid container spacing={1} >
                          <Grid item xs="auto">
                            <Typography variant="body2" color="textSecondary">{name}</Typography>
                          </Grid>
                          <Grid item xs="auto">
                            <Typography variant="body2" color="secondary">{code}</Typography>
                          </Grid>
                          {passcodes.map(({name, password}) => (
                            <>
                              <Grid item xs="auto">
                                <Typography variant="body2">{capitalizeFirstLetter(name)}:</Typography>
                              </Grid>
                              <Grid item xs="auto">
                                <Typography variant="body2" color="secondary">
                                  {password}
                                </Typography>
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      </Grid>
                      <br/>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardPasscode;

import React, { useEffect, useState, useMemo, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Box, Button, Container, Collapse, Fade, Grid, IconButton, Typography } from "@material-ui/core";
import CardStore from "../cards/CardStore";
import VfDialogFull from "../../vf/VfDialogFull";
import VfSvgIcon from "../../icons/VfSvgIcon";
import SearchBarWrapper from "../../searchbar/SearchBarWrapper";
import FilterMenuSorting from "../../filters/FilterMenuSorting";
import FilterBulkSelect from "../../filters/FilterBulckSelect";
import FilterSelectAll from "../../filters/FilterSelectAll";
import Loader from "../../loader/Loader";
import theme from "AppTheme";
import FilterPager from "../../filters/FilterPager";
import CardDevice from "../cards/CardDevice";
import VfDialogConfirm from "../../vf/VfDialogConfirm";
import DialogDevice from "./DialogDevice";
import { fetchDevices, deleteDevice, bulkDeleteDevices } from "actions/deviceActions";
import DeviceService from "services/DeviceService";
import { CSVLink } from "react-csv";

const DialogDevices = ({ open, setOpen, store, devicesText }) => {
  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogDevice, setShowDialogDevice] = useState(false);
  const [select, setSelect] = useState([]);
  const [pager, setPager] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name");
  const [downloadData, setDownloadData] = useState([]);
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { devices, devicesItems, loading } = useSelector(state => state.deviceReducer, shallowEqual);
  const dispatch = useDispatch();
  const csvLink = useRef();
  const { access_token, id_token } = auth;
  let calling = false;

  useEffect(() => {
    if (!loading && open)
      dispatch(
        fetchDevices({
          search,
          page: pager,
          pageSize,
          sort,
          store: store._id,
          access_token,
          id_token,
        })
      );
  }, [open, pager, pageSize, search, loading, sort]);

  useEffect(() => {
    setSelectAll(select.every(value => value));
  }, [select]);

  const devicesPages = useMemo(() => Math.ceil(devicesItems / pageSize), [devicesItems, pageSize]);

  const sortOptions = [
    { title: "Sort by name", value: "name" },
    { title: "Sort by Id", value: "code" },
  ];

  const handleDownload = () => {
    if (calling) return;
    if (!calling) {
      calling = true;
      DeviceService.export({
        search,
        sort,
        store: store._id,
        access_token,
        id_token,
      })
        .then(({ devices }) => {
          calling = false;
          console.log(devices);

          setDownloadData(
            devices?.map(x => ({
              name: x?.name,
              code: x?.code,
              type: x?.type,
              _id: x?._id,
            }))
          );
          csvLink.current.link.click();
          calling = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleDelete = () => {
    const idsArray = select
      .map((item, index) => {
        if (item) return devices[index]?._id;
        return null;
      })
      .filter(x => x);
    setBulkSelect(false);
    dispatch(bulkDeleteDevices({ access_token, id_token, devices: idsArray }));
  };

  const selectAction = index => {
    setSelect(
      select.map((x, y) => {
        if (y !== index) return x;
        return !x;
      })
    );
  };

  const onChangePageSize = number => {
    setPageSize(number);
    setPager(1);
  };

  return (
    <VfDialogFull title="Dialog" openDialog={open} buttonCloseAction={() => setOpen(false)}>
      <Box py={6}>
        <Container maxWidth="md">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <CardStore store={store} devices={devicesText} backAction={() => setOpen(false)} />
            </Grid>
            <Grid item xs={12}>
              <SearchBarWrapper expanded={openSearch} setSearchText={setSearch} setExpanded={setOpenSearch}>
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                  <Grid item xs="auto">
                    <Grid container spacing={3} wrap="nowrap" justify="flex-start" alignItems="center">
                      <Grid item xs="auto">
                        <FilterMenuSorting
                          options={sortOptions}
                          handleFilters={value => {
                            setSort(value);
                          }}
                          color="white"
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <FilterBulkSelect
                          color="white"
                          value={bulkSelect}
                          action={() => {
                            setSelect(devices.map(() => false));
                            setSelectAll(false);
                            setBulkSelect(!bulkSelect);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Collapse in={bulkSelect}>
                      <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
                        <Grid item sm={6}>
                          <FilterSelectAll
                            fullWidth
                            value={selectAll}
                            action={() => {
                              setSelect(devices.map(() => !selectAll));
                              setSelectAll(value => !value);
                            }}
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <Button size="small" onClick={() => setShowDialogDelete(true)}>
                            Delete selected
                          </Button>
                          <VfDialogConfirm
                            openDialog={showDialogDelete}
                            maxWidth="xs"
                            buttonCancelText="Cancel"
                            buttonCancelAction={() => {
                              setShowDialogDelete(false);
                            }}
                            buttonConfirmText="Delete"
                            buttonConfirmAction={() => {
                              handleDelete();
                              setShowDialogDelete(false);
                            }}
                          >
                            <Typography variant="body1" align="center">
                              Are you sure you want to delete from menu
                              <br />
                              <strong>{select.filter(x => x).length + " devices"}</strong>
                              <br />
                              permanently?
                            </Typography>
                          </VfDialogConfirm>
                        </Grid>
                      </Grid>
                    </Collapse>
                    <Collapse in={!bulkSelect}>
                      <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
                        <Grid item xs="auto">
                          <IconButton onClick={() => setShowDialogDevice(true)}>
                            <VfSvgIcon icon="plus" />
                          </IconButton>
                          {showDialogDevice && (
                            <DialogDevice open={showDialogDevice} store={store} setOpen={setShowDialogDevice} />
                          )}
                        </Grid>
                        <Grid item xs="auto">
                          <IconButton onClick={handleDownload}>
                            <VfSvgIcon icon="download" />
                          </IconButton>
                        </Grid>
                        <Grid item xs="auto">
                          <IconButton onClick={() => setOpenSearch(true)}>
                            <VfSvgIcon icon="search" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </SearchBarWrapper>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {!loading ? (
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      {devices &&
                        devices.map((device, index) => (
                          <Grid key={device._id} item xs={12}>
                            <CardDevice
                              device={device}
                              moreActions
                              bulkSelect={bulkSelect}
                              selected={select[index]}
                              action={() => {
                                // eslint-disable-next-line no-unused-expressions
                                bulkSelect ? selectAction(index) : null;
                              }}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Loader size={theme.spacing(12)} />
                  </Grid>
                )}
                {!loading && (
                  <Fade in={!bulkSelect}>
                    <Grid item xs={12}>
                      <Grid container justify="flex-end">
                        <Grid item xs="auto">
                          <FilterPager
                            count={devicesPages ? devicesPages : 1}
                            page={pager}
                            pageSize={pageSize}
                            action={setPager}
                            actionPageSize={onChangePageSize}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fade>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <CSVLink
          data={downloadData}
          separator={";"}
          filename="devices.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        ></CSVLink>
      </Box>
    </VfDialogFull>
  );
};

export default DialogDevices;

import React from "react";

import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { setShowEditApp, setApp } from "../../../actions/applicationsActions";

const EditAppMenuItem = ({ application, showCreateApp, setApplication, onClick }) => {
  return (
    <MenuItem
      onClick={() => {
        onClick();
        setApplication({
          ...application,
        });
        showCreateApp(true);
      }}
    >
      Edit
    </MenuItem>
  );
};

const mapDispatchToProps = dispatch => ({
  showCreateApp: payload => dispatch(setShowEditApp(payload)),
  setApplication: payload => dispatch(setApp(payload)),
});

export default connect(null, mapDispatchToProps)(EditAppMenuItem);

import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  Container,
  Collapse,
  Divider,
  Fab,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Loader from "../loader/Loader";
import Heading from "../headings/Heading";
import SearchBarWrapper from "../searchbar/SearchBarWrapper";
import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfDialogFull from "../vf/VfDialogFull";
import VfAlert from "../vf/VfAlert";
import CardStore from "./cards/CardStore";
import FilterBulkSelect from "../filters/FilterBulckSelect";
import FilterSelectAll from "../filters/FilterSelectAll";
import FilterPager from "../filters/FilterPager";
import DialogDevice from "./dialogs/DialogDevice";
import DialogDeviceImport from "./dialogs/DialogDeviceImport";
import DialogDevices from "./dialogs/DialogDevices";
import DialogApps from "./dialogs/DialogApps";
import DialogStore from "./dialogs/DialogStore";
import DialogStoreImport from "./dialogs/DialogStoreImport";
import FilterMenuSorting from "../filters/FilterMenuSorting";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import { fetchAllStoresAndDevices, fetchStores, bulkDeleteStores, removeError as removeErrorStore } from "actions/storeActions";
import { removeError as removeErrorDevice } from "actions/storeActions";
import StoreService from "services/StoreService";
import { CSVLink } from "react-csv";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 100,
    },
  },
  fab: {
    position: "absolute",
    right: theme.spacing(3),
    top: -theme.spacing(7),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
  menuItemIcon: {
    marginLeft: theme.spacing(1),
  },
});
const StoreDevices = () => {
  const styles = useStyles();
  const { locale } = useParams();
  const allStore = { name: "All Devices" };
  const [showMenuAdd, setShowMenuAdd] = useState(null);
  const [selectedStore, setSelectedStore] = useState({});
  const [showDialogStores, setShowDialogStores] = useState(false);
  const [showDialogStore, setShowDialogStore] = useState(false);
  const [showDialogStoreImport, setShowDialogStoreImport] = useState(false);
  const [showDialogDevice, setShowDialogDevice] = useState(false);
  const [showDialogDevices, setShowDialogDevices] = useState(false);
  const [showDialogDeviceImport, setShowDialogDeviceImport] = useState(false);
  const [showDialogApps, setShowDialogApps] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name");
  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [select, setSelect] = useState([]);
  const [pager, setPager] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [downloadData, setDownloadData] = useState([]);

  const { stores, storeItems, allDevices, error: errorStore, loading } = useSelector(state => state.storeReducer, shallowEqual);
  const { error: errorDevice } = useSelector(state => state.deviceReducer, shallowEqual);
  const auth = useSelector(state => state.authentication, shallowEqual);
  const dispatch = useDispatch();
  const csvLink = useRef();

  let calling = false;

  useEffect(() => {
    dispatch(
      fetchAllStoresAndDevices({
        access_token,
        id_token,
      })
    );
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      dispatch(
        fetchStores({
          search,
          pageSize,
          page: pager ? pager : 1,
          sort,
          access_token,
          id_token,
        })
      );
    }
  }, [loading, pager, sort, pageSize]);

  useEffect(() => {
    if (!loading) {
      if (pager === 1) {
        dispatch(
          fetchStores({
            search,
            pageSize,
            page: pager ? pager : 1,
            sort,
            access_token,
            id_token,
          })
        );
      }
      setPager(1);
    }
  }, [search]);

  useEffect(() => {
    setSelectAll(select.every(value => value));
  }, [select]);

  const openDeviceAppsDialog = showDialogApps => {
    setShowDialogApps(showDialogApps);
  };

  const storePages = useMemo(() => Math.ceil(storeItems / pageSize), [storeItems, pageSize]);

  const handleDownload = () => {
    if (calling) return;
    if (!calling) {
      calling = true;
      StoreService.export({
        search,
        sort,
        access_token,
        id_token,
      })
        .then(({ stores }) => {
          calling = false;
          setDownloadData(
            stores?.map(x => ({
              _id: x?._id,
              name: x?.name,
              storeId: x?.storeId,
              storeGroup: x?.storeGroup?._id,
              devices: x?.devices?.map(item => item._id).join(","),
            }))
          );
          csvLink.current.link.click();
          calling = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  const { access_token, id_token } = auth;
  const sortOptions = [
    { title: "Sort by name", value: "name" },
    { title: "Sort by Id", value: "storeId" },
  ];

  const selectAction = index => {
    setSelect(
      select.map((selectOption, i) => {
        if (i !== index) return selectOption;
        return !selectOption;
      })
    );
  };

  const handleDelete = () => {
    const storeIds = select
      .map((item, index) => {
        if (item) return stores[index]._id;
        return null;
      })
      .filter(x => x);
    setBulkSelect(false);
    dispatch(bulkDeleteStores({ access_token, id_token, stores: storeIds }));
    setShowDialogDelete(false);
  };

  const onChangePageSize = number => {
    setPageSize(number);
    setPager(1);
  };

  return (
    <main className={styles.root}>
      <Heading title="Stores & devices" backUrl={`/${locale}/landing`}>
        <Grid container spacing={3} wrap="nowrap" justify="space-between" alignItems="center">
          <Grid item xs="auto">
            <Grid container spacing={3} wrap="nowrap" justify="flex-start" alignItems="center">
              <Grid item xs="auto">
                <FilterMenuSorting options={sortOptions} handleFilters={sortValue => setSort(sortValue)} />
              </Grid>
              <Grid item xs="auto">
                <FilterBulkSelect
                  value={bulkSelect}
                  action={() => {
                    setSelect(stores.map(() => false));
                    setSelectAll(false);
                    setBulkSelect(!bulkSelect);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3} wrap="nowrap" justify="flex-end" alignItems="center">
              <Fade in={bulkSelect}>
                <Grid item sm={6}>
                  <FilterSelectAll
                    fullWidth
                    value={selectAll}
                    action={() => {
                      setSelect(stores.map(() => !selectAll));
                      setSelectAll(value => !value);
                    }}
                  />
                </Grid>
              </Fade>
              <Fade in={bulkSelect}>
                <Grid item xs="auto">
                  <Button size="small" onClick={() => setShowDialogDelete(true)}>
                    Delete selected
                  </Button>
                  <VfDialogConfirm
                    openDialog={showDialogDelete}
                    maxWidth="xs"
                    buttonCancelText="Cancel"
                    buttonCancelAction={() => setShowDialogDelete(false)}
                    buttonConfirmText="Delete"
                    buttonConfirmAction={() => handleDelete()}
                  >
                    <Typography variant="body1" align="center">
                      Are you sure you want to delete from menu
                      <br />
                      <strong>{select.filter(x => x).length + "stores"}</strong>
                      <br />
                      permanently?
                    </Typography>
                  </VfDialogConfirm>
                </Grid>
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      </Heading>
      <Box pt={8} pb={4}>
        <Container className={styles.container} maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Collapse in={!bulkSelect}>
                <SearchBarWrapper setSearchText={setSearch} expanded={openSearch} setExpanded={setOpenSearch}>
                  <Grid container alignItems="center" justify="flex-end">
                    <Grid item xs="auto">
                      <IconButton onClick={handleDownload}>
                        <VfSvgIcon icon="download" />
                      </IconButton>
                    </Grid>
                    <Grid item xs="auto">
                      <IconButton
                        onClick={() => {
                          setOpenSearch(true);
                        }}
                      >
                        <VfSvgIcon icon="search" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </SearchBarWrapper>
              </Collapse>
            </Grid>
            {!loading ? (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Collapse in={!bulkSelect} addEndListener={() => {}}>
                      <CardStore
                        store={allStore}
                        devices={allDevices.length + " Devices"}
                        action={() => {
                          setSelectedStore(allStore);
                          setShowDialogDevices(true);
                        }}
                      />
                    </Collapse>
                  </Grid>
                  {stores &&
                    stores.map((store, index) => (
                      <Grid item xs={12} key={store._id}>
                        <CardStore
                          store={store}
                          group={store?.storeGroup?.name ? store.storeGroup.name : ""}
                          devices={(store.devices ? store.devices.length : 0) + " Devices"}
                          moreActions
                          bulkSelect={bulkSelect}
                          selected={select[index]}
                          action={() => {
                            if (bulkSelect) {
                              selectAction(index);
                            } else {
                              setShowDialogDevices(true);
                              setSelectedStore(store);
                            }
                            // eslint-disable-next-line no-unused-expressions
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
            {!loading && (
              <Fade in={!bulkSelect}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item xs="auto">
                      <FilterPager
                        count={storePages ? storePages : 1}
                        page={pager}
                        pageSize={pageSize}
                        action={setPager}
                        actionPageSize={onChangePageSize}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
          <Box className={styles.fab}>
            <Fade in={!bulkSelect}>
              <Grid container spacing={3}>
                <Grid item xs="auto">
                  <Fab color="secondary" onClick={e => setShowMenuAdd(e.currentTarget)}>
                    <VfSvgIcon icon="plus" viewBox={24} />
                  </Fab>
                  <Menu
                    id="simple-menu"
                    elevation={0}
                    anchorEl={showMenuAdd}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(showMenuAdd)}
                    onClose={() => setShowMenuAdd(null)}
                  >
                    <MenuItem
                      button
                      onClick={() => {
                        setShowMenuAdd(null);
                        setShowDialogStore(true);
                      }}
                    >
                      Add a store
                    </MenuItem>
                    <MenuItem
                      button
                      onClick={() => {
                        setShowMenuAdd(null);
                        setShowDialogStoreImport(true);
                      }}
                    >
                      Bulk store import
                      <VfSvgIcon className={styles.menuItemIcon} icon="upload" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      button
                      onClick={() => {
                        setShowMenuAdd(null);
                        setShowDialogDevice(true);
                      }}
                    >
                      Add a device
                    </MenuItem>
                    <MenuItem
                      button
                      onClick={() => {
                        setShowMenuAdd(null);
                        setShowDialogDeviceImport(true);
                      }}
                    >
                      Bulk device import
                      <VfSvgIcon className={styles.menuItemIcon} icon="upload" />
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Fade>
          </Box>
        </Container>
      </Box>
      {showDialogStore && <DialogStore open={showDialogStore} setOpen={setShowDialogStore} />}
      {showDialogStoreImport && <DialogStoreImport open={showDialogStoreImport} setOpen={setShowDialogStoreImport} />}
      {showDialogDevice && <DialogDevice open={showDialogDevice} setOpen={setShowDialogDevice} />}
      {showDialogDeviceImport && (
        <DialogDeviceImport open={showDialogDeviceImport} setOpen={setShowDialogDeviceImport} />
      )}
      {/*<DialogReceipt open={showDialogReceipt} setOpen={setShowDialogReceipt} />*/}
      {showDialogStores && (
        <VfDialogFull
          title="Dialog"
          openDialog={showDialogStores}
          buttonCloseAction={() => setShowDialogStores(false)}
        >
          Dialog stores
        </VfDialogFull>
      )}
      <DialogDevices
        open={showDialogDevices}
        setOpen={setShowDialogDevices}
        setOpenApps={openDeviceAppsDialog}
        devicesText={selectedStore?._id ? selectedStore.devices.length + " Devices" : allDevices.length + " Devices"}
        store={selectedStore}
      />
      <CSVLink
        data={downloadData}
        separator={";"}
        filename="stores.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      ></CSVLink>
      <DialogApps open={showDialogApps} setOpen={openDeviceAppsDialog} />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(errorStore) || Boolean(errorDevice)}
        onClose={() => {
          dispatch(removeErrorStore());
          dispatch(removeErrorDevice());
        }}
      >
        <VfAlert severity={0} message={errorStore || errorDevice} />
      </Snackbar>
    </main>
  );
};

export default StoreDevices;

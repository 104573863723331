import React, { useState } from "react";
import {
  Button,
  Collapse,
  Grid,
  Stepper,
  Step,
  StepConnector,
  StepButton,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VfSvgIcon from "../../icons/VfSvgIcon";
import theme from "AppTheme";
import VfDialog from "../../vf/VfDialog";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { addStore, updateStore } from "actions/storeActions";

const useStyles = makeStyles({
  stepFirst: {
    marginTop: -theme.spacing(4),
    "& .MuiStepConnector-line": {
      minHeight: theme.spacing(5),
    },
  },
  stepLast: {
    marginBottom: -theme.spacing(4),
    "& .MuiStepConnector-line": {
      minHeight: theme.spacing(5),
    },
  },
});

const DialogStore = ({ open, setOpen, store }) => {
  const [name, setName] = useState(store ? store.name : "");
  const [storeId, setStoreId] = useState(store ? store.storeId : "");
  const { allStoreGroups } = useSelector(state => state.storeReducer, shallowEqual);
  const auth = useSelector(state => state.authentication, shallowEqual);
  const [storeGroup, setStoreGroup] = useState(
    store ? allStoreGroups?.find(({ _id }) => _id === store?.storeGroup) : {}
  );
  const [activeStep, setStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [steps] = useState([
    { name: "ID", completed: false },
    { name: "Name", completed: false },
    { name: "Store group", completed: false },
  ]);
  const dispatch = useDispatch();
  const styles = useStyles();
  const { access_token, id_token } = auth;

  const handleStep = step => () => {
    setStep(step);
    let newCompleted = {};
    Object.entries(completed).forEach((item, index) => {
      newCompleted = index >= step ? { ...newCompleted, [index]: false } : { ...newCompleted, [index]: true };
    });

    setCompleted(newCompleted);
  };

  const handleStepExpanded = index => {
    return index === activeStep;
  };

  const handleStepCompletion = () => {
    if (name && storeId && storeGroup._id) {
      if (store) {
        dispatch(
          updateStore({
            name,
            storeId,
            storeGroup: storeGroup._id,
            access_token,
            _id: store._id,
            id_token,
          })
        );
      } else {
        dispatch(
          addStore({
            name,
            storeId,
            storeGroup: storeGroup._id,
            access_token,
            id_token,
          })
        );
      }

      setOpen(false);
    } else {
      handleStep(1);
    }
  };

  const stepIcon = index => {
    if (activeStep === index) return "circleSelected";
    if (steps[index].completed) return "circleDone";
    return "circle";
  };

  return (
    <VfDialog
      title={store ? "Edit Store" : "Add store"}
      openDialog={open}
      buttonCloseAction={() => setOpen(false)}
      maxWidth="md"
    >
      {
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <StepConnector className={styles.stepFirst} />
              {steps.map((stp, index) => (
                <Step key={stp.name}>
                  <StepButton
                    onClick={handleStep(index)}
                    completed={steps[index].completed}
                    /* eslint-disable */
                    icon={<VfSvgIcon icon={stepIcon(index)} viewBox={24} />}
                    /* eslint-enable */
                  >
                    <Typography component="span" variant="body1">
                      {stp.name}
                    </Typography>
                  </StepButton>
                </Step>
              ))}
              <StepConnector className={styles.stepLast} />
            </Stepper>
          </Grid>
          <Grid item xs={9}>
            <Collapse in={handleStepExpanded(0)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Enter store ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={storeId}
                    onChange={e => {
                      setStoreId(e.target.value);
                    }}
                    label="ID"
                    color="primary"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs="auto">
                      <Button onClick={handleStep(1)} color="secondary" variant="contained">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={handleStepExpanded(1)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Enter a name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                    label="Name"
                    color="primary"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs="auto">
                      <Button onClick={handleStep(2)} color="secondary" variant="contained">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={handleStepExpanded(2)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Select a store group</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={allStoreGroups}
                    label="Store group name"
                    getOptionLabel={option => option?.name ?? ""}
                    value={storeGroup}
                    onChange={(e, v) => setStoreGroup(v)}
                    getOptionSelected={(option, value) => option?._id === value?._id}
                    renderInput={params => (
                      <TextField {...params} label="Combo box" variant="outlined" color="primary" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs="auto">
                      <Button onClick={handleStepCompletion} color="secondary" variant="contained">
                        Complete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      }
    </VfDialog>
  );
};

export default DialogStore;

/* eslint-disable camelcase */
import axios from "axios";

class RolesService {
  fetchRoles = async ({ access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/roles`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch applications service:", err);
      return err;
    }
  };

  saveRole = async ({ access_token, id_token, data: payloadData }) => {
    const body = {
      ...payloadData,
    };
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/roles`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in create application service:", err);
      return err;
    }
  };

  deleteRole = async ({ access_token, id_token, id }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/roles/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in create application service:", err);
      return err;
    }
  };
}

const inst = new RolesService();
export default inst;

/* eslint-disable camelcase */
import axios from "axios";

class PermissionsService {
  fetchPermissions = async ({ access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/permissions`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch applications service:", err);
      return err;
    }
  };

  fetchUserPermissions = async ({ access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/permissions/getUserPermissions`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch user permissions:", err);
      throw err;
    }
  };
}

const inst = new PermissionsService();
export default inst;

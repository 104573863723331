/* eslint no-underscore-dangle:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Fragment } from "react";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import CardHeader from "@material-ui/core/CardHeader";
import { Card, Grid as MaterialGrid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import * as DCL from "@vf-dcl/dcl-components";
import atomics from "vf-comps/build/components";
import createDynamicJourneyRenderer from "@vf-djr/renderer";
import mapStateToPropsPlugin from "@vf-djr-plugins/map-state-to-props/lib";
import componentRenderingPlugin from "@vf-djr-plugins/component-rendering";
import { isEmpty, cloneDeep, intersectionBy } from "lodash";
import compareVersions from "compare-versions";
import jp from "jsonpath";
import { makeStyles } from "@material-ui/core/styles";
import styleTheme from "AppTheme";
import CardWidgetImage from "./components/widgets/CardWidgetImage";
import AtomicComponentWrapper from "./AtomicComponentWrapper";
import {
  djslDeleteComponent,
  djslAddComponent,
  djslEditComponent,
} from "./actions/applicationsActions";
import { MVA10 } from "./utils/constants";
import { htmlTags } from "./utils/djr-to-layout";

const useStyles = makeStyles({
  card: {
    position: "relative",
    "& .MuiCardMedia-root": {
      padding: styleTheme.spacing(2),
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
});

const theme = "MVA10";
const Grid =
  theme === MVA10 ? props => <MaterialGrid container {...props} /> : DCL.Grid;
const GridItem =
  theme === MVA10 ? props => <MaterialGrid item {...props} /> : DCL.Grid.Item;
const atomicsRegistry = Object.fromEntries(
  atomics.flatMap(c => c.widgets).map(w => [w.name, w.component])
);
export const inlineComponents = [];
export const componentsWithPadding = ["GridItem"];

const registry = {
  ...atomicsRegistry,
  Link,
  Grid,
  GridItem,
  Fragment,
  CardHeader,
};

const wrapWithAtomicComponentWrapper = (componentJSON, Element) => {
  const AtomicWrapper = props => {
    const categoryWidgets = useSelector(state => state.nexusWidgets.data);
    const appInfo = useSelector(
      state => state.applications.current.appInfo,
      shallowEqual
    );
    const dispatch = useDispatch();
    const classes = useStyles();

    const fatWidgetList = categoryWidgets.flatMap(({ widgets }) =>
      widgets.filter(w => w.images.length > 0)
    );

    const addWidgetToApplication = (widget, instanceId) => {
      const clonedAppInfo = cloneDeep(appInfo);
      const found = clonedAppInfo.widgets.findIndex(
        w => w.name === widget.name
      );
      if (found === -1) {
        clonedAppInfo.widgets.push({
          _id: widget._id,
          name: widget.name,
          version: widget.version,
          occurrences: 1,
          instances: [{ uuid: instanceId }],
        });
      } else {
        clonedAppInfo.widgets.splice(found, 1, {
          _id: widget._id,
          name: widget.name,
          version: widget.version,
          occurrences: clonedAppInfo.widgets[found].occurrences + 1,
          instances: [
            ...(clonedAppInfo.widgets[found].instances || []),
            { uuid: instanceId },
          ],
        });
      }
      return { appInfo: clonedAppInfo };
    };

    const selectWidget = (name, instanceId) => {
      const wdgts = categoryWidgets.flatMap(({ widgets }) => widgets);
      let widgetVersions = wdgts.filter(w => w.name === name);
      widgetVersions = widgetVersions
        .sort((a, b) => {
          return compareVersions(a.version, b.version);
        })
        .reverse();
      let selectedWdgt = intersectionBy(widgetVersions, appInfo.widgets, "_id");
      if (instanceId) {
        selectedWdgt.instanceId = instanceId;
        selectedWdgt.instanceName = appInfo.widgets
          .find(wdgt => wdgt.name === name)
          ?.instances.find(inst => inst.uuid === instanceId)?.name;
      }
      selectedWdgt = selectedWdgt.length ? selectedWdgt[0] : widgetVersions[0];
      return { selectedWdgt, widgetVersions };
    };

    const getFatWidget = name => fatWidgetList.find(w => w.name === name);

    const removeWidgetFromApplication = fatWidgets => {
      const clonedAppInfo = cloneDeep(appInfo);
      fatWidgets.forEach(widget => {
        const found = clonedAppInfo.widgets.findIndex(
          w => w.name === widget.__component
        );
        if (clonedAppInfo.widgets[found].occurrences <= 1) {
          clonedAppInfo.widgets.splice(found, 1);
        } else {
          clonedAppInfo.widgets.splice(found, 1, {
            _id: clonedAppInfo.widgets[found]._id,
            name: widget.__component,
            version: clonedAppInfo.widgets[found].version,
            occurrences: clonedAppInfo.widgets[found].occurrences - 1,
            instances: clonedAppInfo.widgets[found].instances.filter(
              inst => inst.uuid !== widget.__props.__instanceId
            ),
          });
        }
      });

      return clonedAppInfo;
    };

    const containsFatComp = comp => {
      const propPath = `$..children`;
      return jp
        .query(comp, propPath)
        .flatMap(x => x)
        .filter(c => c?.__component && !!getFatWidget(c.__component));
    };

    const displayUnrecognisedTagOrComponent = () => {
      const fatWidget = getFatWidget(componentJSON.__component);

      return fatWidget ? (
        <Card
          className={classes.card}
          style={{ height: "100%", width: "100%" }}
        >
          <CardWidgetImage widget={fatWidget} />
          &nbsp;
        </Card>
      ) : (
        <div>
          {componentJSON.__component}
          {props?.children}
        </div>
      );
    };

    return (
      <AtomicComponentWrapper
        isInline={inlineComponents.includes(componentJSON.__component)}
        hasPadding={componentsWithPadding.includes(componentJSON.__component)}
        label={
          getFatWidget(componentJSON.__component)?.description ??
          componentJSON.__component
        }
        parentComponent={
          componentJSON.__id || componentJSON.__props.id || "root"
        }
        onDelete={id => {
          const isNotFatComponent = registry[componentJSON.__component];
          const fatWidgets = containsFatComp(componentJSON);
          let newAppInfo = {};
          if (typeof fatWidgets !== "undefined" && fatWidgets.length) {
            newAppInfo = removeWidgetFromApplication(fatWidgets);
          } else if (!isNotFatComponent) {
            newAppInfo = removeWidgetFromApplication([
              {
                __component: componentJSON.__component,
                __props: {
                  instanceId: componentJSON.__props.instanceId,
                },
              },
            ]);
          }
          dispatch(
            djslDeleteComponent({
              id,
              ...(!isEmpty(newAppInfo) ? { appInfo: newAppInfo } : {}),
            })
          );
        }}
        onDrop={payload => {
          const comp = selectWidget(payload.name, payload.instanceId);
          const isNotFatComponent = registry[payload.name];
          dispatch(
            djslAddComponent({
              ...payload,
              ...comp,
              ...(isNotFatComponent
                ? {}
                : addWidgetToApplication(
                    comp.selectedWdgt,
                    payload.instanceId
                  )),
            })
          );
        }}
        onEdit={payload => dispatch(djslEditComponent(payload))}
        component={selectWidget(componentJSON.__component)}
        componentProps={Object.fromEntries(
          Object.entries(componentJSON).filter(
            ([key, value]) =>
              !["__id", "__component", "__returnFunction"].includes(key) &&
              typeof value === "object"
          )
        )}
        componentJSON={componentJSON}
      >
        {[...htmlTags, ...Object.keys(registry)].includes(
          componentJSON.__component
        ) ? (
          <Element {...props} />
        ) : (
          displayUnrecognisedTagOrComponent()
        )}
      </AtomicComponentWrapper>
    );
  };
  return AtomicWrapper;
};

const render = createDynamicJourneyRenderer({
  registry,
  plugins: {
    initialSetup: {
      connect,
    },
    preRender: {
      __component: wrapWithAtomicComponentWrapper,
      __mapStateToProps: mapStateToPropsPlugin,
    },
    propsPlugin: componentRenderingPlugin,
  },
});

export { render, registry };

/* eslint-disable camelcase */
import React, { useState } from "react";
import { Box, Badge, Card, CardMedia as MuiCardMedia, CardActionArea, CardContent, CardActions, IconButton, Typography, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import VfDialogConfirm from "../../vf/VfDialogConfirm";
import VfSvgIcon from "../../icons/VfSvgIcon";
import theme from "../../../AppTheme";

const useStyles = makeStyles({
  typography: {
    color: theme.palette.common.white,
  },
  badge: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    "& .MuiBadge-badge": {
      right: theme.spacing(2.5),
      top: theme.spacing(2.5),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      transform: "none",
      borderRadius: theme.spacing(3),
      opacity: 1,
      width: theme.spacing(3),
      height: theme.spacing(3),
      transition: theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.standard,
      }),
      "&.MuiBadge-invisible": {
        opacity: 0,
      },
    },
  },
  badgeHide: {
    "& .MuiBadge-badge": {
      opacity: 0,
    },
  },
});

const CardMedia = ({ item, isSelected, cardAction, isForBulkSelect, eraseMedia }) => {
  const styles = useStyles();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <Card
      elevation={0}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classNames({
        "MuiCard-media": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-primary": isSelected,
        "Mui-selected": isSelected,
        "Mui-forSelect": isForBulkSelect,
        "MuiCard-rounded": true,
        "MuiCard-grid": true,
        "Mui-hover": hover,
      })}
      onClick={() => {
        cardAction();
      }}
    >
      <CardActions disableSpacing className={styles.cardActions}>
        {eraseMedia && (
          <IconButton
            className="MuiIconButton-colorWhite"
            color="secondary"
            aria-label="add to favorites"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => {
              setHover(false);
              setDeleteDialog(true);
            }}
          >
            <VfSvgIcon icon="delete" viewBox={24} />
          </IconButton>
        )}
        <Typography className={styles.typography} variant="body2">
          {item.info}
        </Typography>
      </CardActions>
      <CardActionArea onClick={() => {}}>
        <Badge
          {...(item.pos > 0 && { badgeContent: item.pos })}
          className={classNames({
            [styles.badge]: true,
            [styles.badgeHide]: hover,
          })}
        >
          <MuiCardMedia image={item.url} />
        </Badge>
        <CardContent>
          <Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">{item.title}</Typography>
            {item.type === "video" && <VfSvgIcon icon="video" fontSize="small" viewBox={24} />}
          </Box>
        </CardContent>
      </CardActionArea>
      <VfDialogConfirm
        buttonConfirmAction={() => {
          setDeleteDialog(false);
          /* todo erase */
          eraseMedia();
        }}
        openDialog={deleteDialog}
        buttonCancelAction={() => setDeleteDialog(false)}
        buttonCancelText="Cancel"
        buttonConfirmText="Delete"
      >
        <Typography variant="body1" align="center">
          Are you sure you want to delete
          <br />
          <strong>{item.title}</strong>
          <br />
          permanently?
        </Typography>
      </VfDialogConfirm>
    </Card>
  );
};

export default CardMedia;

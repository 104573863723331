import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Container, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import VfSvgIcon from "../../icons/VfSvgIcon";
import VfDialogFull from "../../vf/VfDialogFull";
import SearchBarWrapper from "../../searchbar/SearchBarWrapper";
import DisplayType from "../../displaytype/DisplayType";
import CardAppsGrid from "../../apps/CardAppsGrid";
import CardAppList from "../../apps/CardAppList";

const DialogApps = ({ open, setOpen, auth }) => {
  const [openSearch, setOpenSearch] = useState(false);
  const [grid, setGrid] = useState("list");
  const app = {
    _id: { $oid: "5f6311a71cfde3346d9a66b8" },
    __v: 0,
    appType: "desktop",
    authProvider: "Azure AD",
    createdAt: { $date: "2020-09-17T07:35:03.330Z" },
    description: "test",
    hasAuth: true,
    imageBase64: "",
    platform: "web",
    tags: ["Test AD"],
    theming: "MVA10",
    title: "Test AD",
    touchpoint: "web",
    unmanaged: true,
    updatedAt: { $date: "2020-09-17T07:35:03.330Z" },
    version: "1.0.0",
    widgets: [],
  };
  return (
    <VfDialogFull
      title="Dialog"
      openDialog={open}
      buttonCloseAction={() => setOpen(false)}
      buttonConfirmAction={() => setOpen(false)}
      buttonConfirmText="Save"
      saveAction={() => {}}
      /* eslint-disable */
      appBar={
        <Grid container alignItems="center" spacing={6}>
          <Grid item xs={12} sm="auto">
            <VfSvgIcon icon="vodafone" viewBox={24} />
          </Grid>
          <Divider flexItem orientation="vertical" variant="fullWidth" />
          <Grid item xs={12} sm>
            <Grid container spacing={1}>
              <Grid item xs="auto">
                <Typography variant="body1" component="h5">
                  Device Name
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography variant="body1" color="secondary">
                  01234
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Store: Store name VF313
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      /* eslint-enable */
    >
      <Box py={6}>
        <Container maxWidth="md">
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <SearchBarWrapper expanded={openSearch} setExpanded={setOpenSearch}>
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                  <Grid item xs="auto">
                    <Grid container spacing={3} wrap="nowrap" justify="flex-start" alignItems="center">
                      <Grid item xs="auto">
                        <DisplayType grid={grid} handleGridView={setGrid} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs="auto">
                    <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
                      <Grid item xs="auto">
                        <IconButton onClick={() => setOpenSearch(true)}>
                          <VfSvgIcon icon="search" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchBarWrapper>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {grid === "list" ? (
                  <Grid item xs={12}>
                    <CardAppList auth={auth} app={app} showStatus />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} md={4}>
                    <CardAppsGrid auth={auth} app={app} showStatus />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </VfDialogFull>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
  };
};

export default connect(mapStateToProps)(DialogApps);

/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_STOREGROUPS_REQUEST,
  ADD_STOREGROUP_REQUEST,
  UPDATE_STOREGROUP_REQUEST,
  DELETE_STOREGROUP_REQUEST,
  BULK_DELETE_STOREGROUPS_REQUEST,
  EXPORT_STOREGROUPS_REQUEST,
  fetchStoreGroupsSuccess,
  fetchStoreGroupsFail,
  addStoreGroupSuccess,
  addStoreGroupFail,
  updateStoreGroupSuccess,
  updateStoreGroupFail,
  deleteStoreGroupSuccess,
  deleteStoreGroupFail,
  bulkDeleteStoreGroupsSuccess,
  bulkDeleteStoreGroupsFail,
} from "actions/storeGroupActions";
import StoreGroupService from "services/StoreGroupService";
import { exportStoresFail, exportStoresSuccess } from "actions/storeActions";

function* fetchStoreGroupsWorker({ payload }) {
  try {
    const data = yield call(StoreGroupService.fetch, payload);
    yield put(fetchStoreGroupsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(fetchStoreGroupsFail(error));
  }
}

function* fetchStoreGroupsWatcher() {
  yield takeLatest(GET_STOREGROUPS_REQUEST, fetchStoreGroupsWorker);
}

function* addStoreGroupWorker({ payload }) {
  try {
    const data = yield call(StoreGroupService.add, payload);
    yield put(addStoreGroupSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addStoreGroupFail(error));
  }
}

function* addStoreGroupWatcher() {
  yield takeLatest(ADD_STOREGROUP_REQUEST, addStoreGroupWorker);
}

function* updateStoreGroupWorker({ payload }) {
  try {
    const data = yield call(StoreGroupService.update, payload);
    yield put(updateStoreGroupSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(updateStoreGroupFail(error));
  }
}

function* updateStoreGroupWatcher() {
  yield takeLatest(UPDATE_STOREGROUP_REQUEST, updateStoreGroupWorker);
}

function* deleteStoreGroupWorker({ payload }) {
  try {
    const data = yield call(StoreGroupService.delete, payload);
    yield put(deleteStoreGroupSuccess(data));
  } catch (error) {
    yield put(deleteStoreGroupFail(error));
  }
}

function* deleteStoreGroupWatcher() {
  yield takeLatest(DELETE_STOREGROUP_REQUEST, deleteStoreGroupWorker);
}

function* bulkDeleteStoreGroupsWorker({ payload }) {
  try {
    const data = yield call(StoreGroupService.bulkDelete, payload);
    yield put(bulkDeleteStoreGroupsSuccess(data));
  } catch (error) {
    yield put(bulkDeleteStoreGroupsFail(error));
  }
}

function* bulkDeleteStoreGroupsWatcher() {
  yield takeLatest(BULK_DELETE_STOREGROUPS_REQUEST, bulkDeleteStoreGroupsWorker);
}

function* exportStoreGroupWorker({ payload }) {
  try {
    const data = yield call(StoreGroupService.export, payload);
    yield put(exportStoreGroupsSuccess(data));
  } catch (error) {
    yield put(exportStoreGroupsFail(error));
  }
}

function* exportStoreGroupWatcher() {
  yield takeLatest(EXPORT_STOREGROUPS_REQUEST, exportStoreGroupWorker);
}

const storeGroupSaga = [
  fetchStoreGroupsWatcher(),
  addStoreGroupWatcher(),
  updateStoreGroupWatcher(),
  deleteStoreGroupWatcher(),
  bulkDeleteStoreGroupsWatcher(),
  exportStoreGroupWatcher(),
];

export default storeGroupSaga;

/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { fetchWidgetLanguages } from "actions/translationsActions";
import theme from "AppTheme";
import TranslationsTabs from "./TranslationsTabs";
import TranslationsTree from "./TranslationsTree";

const useStyles = makeStyles({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(11)}px)`,
  },
});

const Translations = ({
  env,
  envID,
  auth,
  locales,
  translations,
  selectedTreeItem,
  currentWidget,
  getWidgetLanguages,
  widgetDescription,
}) => {
  const classes = useStyles();
  const { access_token, id_token } = auth;
  useEffect(() => {
    if (currentWidget)
      getWidgetLanguages({
        envID,
        access_token,
        id_token,
        widget: currentWidget,
      });
  }, [currentWidget]);

  if (!translations || !locales) return null;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={3} lg={3}>
        <TranslationsTree
          widgetDescription={widgetDescription}
          locales={locales}
          translations={translations}
          selectedTreeItem={selectedTreeItem}
        />
      </Grid>
      <Grid item xs={12} md={9} lg={9}>
        <TranslationsTabs
          currentWidget={currentWidget}
          env={env}
          envID={envID}
          locales={locales}
          selectedTreeItem={selectedTreeItem}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  auth: state.authentication,
  envID: state.translations.current.envId,
  env: state.envs.current,
  locales: state.settings.languages,
  translations: state.translations.translations,
  widgetDescription: state.translations.current.description,
  selectedTreeItem: state.translations.selectedTreeItem,
  currentWidget: state.translations.current._id,
});

const mapDispatchToProps = dispatch => ({
  getWidgetLanguages: params => dispatch(fetchWidgetLanguages(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Translations);

/* eslint-disable camelcase */
import axios from "axios";

class ApisService {
  fetchApis = async ({ access_token, id_token, searchText }) => {
    const params = {
      searchText,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/apis`,
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch apis service:", err);
      throw err;
    }
  };

  fetchApiVersionFileData = async ({
    access_token,
    id_token,
    apiId,
    version,
  }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/apis/${apiId}/${version}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch api swagger data service:", err);
      throw err;
    }
  };

  fetchApiDetails = async ({ access_token, id_token, apiId }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/apis/${apiId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch api details service:", err);
      throw err;
    }
  };

  saveApi = async ({ access_token, id_token, body }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/apis`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in save api service:", err);
      throw err;
    }
  };

  deleteApi = async ({ access_token, id_token, apiId }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/apis/${apiId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });

      return data;
    } catch (err) {
      console.log("Error in delete api service:", err);
      throw err;
    }
  };

  deleteVersionApi = async ({
    access_token,
    id_token,
    apiId,
    envId,
    versionName,
  }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/apis/${apiId}/${envId}/${versionName}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });

      return data;
    } catch (err) {
      console.log("Error in deleteVersion api service:", err);
      throw err;
    }
  };
}

const inst = new ApisService();
export default inst;

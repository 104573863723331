/* eslint-disable no-underscore-dangle */
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const FreeTextSelector = ({
  optional,
  title,
  placeholder,
  label,
  name,
  values,
  options,
  onComplete,
  onChange,
  disabled,
}) => {
  const Title = () => {
    if (!title) {
      return null;
    }
    return (
      <Grid item xs={12}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
    );
  };

  const ActionButton = () => {
    if (!onComplete) {
      return null;
    }

    return (
      <Grid item xs={12}>
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              disabled={!optional && values.length === 0}
              onClick={() => onComplete()}
            >
              {optional && values.length === 0 ? "Skip" : "Next"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      <Title />
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            value={values}
            options={options}
            filterSelectedOptions
            freeSolo
            name={name}
            onChange={onChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                placeholder={placeholder}
              />
            )}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
      <ActionButton />
    </Grid>
  );
};

export default FreeTextSelector;

/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from "react";
import {
  Grid,
  Chip,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Divider,
  CardMedia,
  Typography,
  IconButton,
} from "@material-ui/core";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfTooltip from "../vf/VfTooltip";
import AppActionsMenu from "./AppActionMenu/AppActionMenu";

const CardAppList = ({
  app,
  showApp,
  setApplication,
  env,
  setApplicationEnv,
  auth,
  getAppPages,
  backAction,
  moreActions,
  showEnv,
  envTitle,
  envType,
  clickAction,
}) => {
  const { access_token, id_token } = auth;

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-apps": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": false,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {backAction && (
          <Grid item xs="auto">
            <CardActions className="MuiCardActions-start">
              <IconButton edge="end" onClick={() => backAction()}>
                <VfSvgIcon icon="arrowLeft" />
              </IconButton>
            </CardActions>
          </Grid>
        )}
        <Grid item xs>
        <CardActionArea
            onClick={() => {
              if (clickAction) {
                clickAction();
              } else if (!app.unmanaged) {
                showApp(true);
                setApplication({
                  ...app,
                  isDeployed: !!(env && !env.isGlobal),
                });
                setApplicationEnv(env);
                getAppPages({
                  access_token,
                  id_token,
                  application: app._id,
                  envId: env.id,
                });
              }
            }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs>
                <CardContent>
                  <Grid container alignItems="center" spacing={6}>
                    <Grid item xs="auto">
                      {app.iconBase64 && <CardMedia image={app.iconBase64} />}
                      {!app.iconBase64 && (
                        <VfSvgIcon icon="vodafone" viewBox={24} />
                      )}
                    </Grid>
                    <Divider
                      flexItem
                      orientation="vertical"
                      variant="fullWidth"
                    />
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1" component="h5">
                            {app.title}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography
                            variant="body1"
                            component="span"
                            color="secondary"
                          >
                            {app.version}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs="auto">
                          <VfSvgIcon
                            icon={app.appType}
                            viewBox={24}
                            className="MuiSvgIcon-fontSizeXSmall"
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <VfSvgIcon
                            icon={app.platform}
                            viewBox={24}
                            className="MuiSvgIcon-fontSizeXSmall"
                          />
                        </Grid>
                        {app.hasAuth && (
                          <Grid item xs="auto">
                            <VfTooltip title={app.authProvider}>
                              <VfSvgIcon
                                icon="lock"
                                viewBox={24}
                                className="MuiSvgIcon-fontSizeXSmall"
                              />
                            </VfTooltip>
                          </Grid>
                        )}
                        <Grid item xs="auto">
                          <Typography variant="body2" color="textSecondary">
                            {app.theming === "MVA10" ? "WS10" : app.theming}
                          </Typography>
                        </Grid>
                        {app.unmanaged && (
                          <Grid item xs="auto">
                            <Typography variant="body2" color="secondary">
                              Unmanaged
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {app.tags.length > 0 && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {app.tags ? app.tags.join(", ") : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {app.status && (
                      <Grid item xs="auto">
                        <Typography variant="body2" component="div">
                          {app.status}
                        </Typography>
                      </Grid>
                    )}
                    {showEnv && (
                      <Grid item xs="auto">
                        <Chip
                          className={classNames({
                            [`MuiChip-${envTitle.toLowerCase()}`]: true,
                            "MuiChip-selected": true,
                          })}
                          icon={
                            <VfSvgIcon
                              className="MuiChip-leftIcon"
                              icon={envType}
                              fontSize="small"
                            />
                          }
                          label={envTitle}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
        <>
          {moreActions && (
            <AppActionsMenu app={app} env={env} envType={envType} />
          )}
        </>
      </Grid>
    </Card>
  );
};

export default CardAppList;

/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { fetchApps } from "actions/applicationsActions";
import { fetchRoles } from "actions/rolesActions";
import { fetchPermissions } from "actions/permissionsActions";
import { setEnv, clearEnv } from "actions/envsActions";
import { fetchTenants, updateTenantToken } from "actions/tenantActions";
import { setSelectedMarket } from "actions/authenticationActions";
import logo from "assets/images/logo.svg";
import avatar from "assets/images/avatar.png";
import HasPermission from "components/has-permissions/HasPermissions";
import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    minHeight: "48px",
  },
  logo: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  mid: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiSelect-select:focus": { backgroundColor: "#fff" },
  },
  right: {
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  environmentButton: {
    fontSize: 14,
    "&.MuiButton-development": {
      backgroundColor: theme.palette.grey[700],
      "&:hover": {
        backgroundColor: theme.palette.grey[700],
      },
    },
    "&.MuiButton-testing": {
      backgroundColor: theme.palette.warning.main,
      "&:hover": {
        backgroundColor: theme.palette.warning.main,
      },
    },
    "&.MuiButton-production": {
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        backgroundColor: theme.palette.success.main,
      },
    },
    "&.MuiButton-all": {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.secondary,
      "& .MuiSvgIcon-root": {
        "& path": {
          fill: theme.palette.text.secondary,
        },
        "& rect": {
          fill: theme.palette.text.secondary,
        },
        "& circle": {
          fill: theme.palette.text.secondary,
        },
        "& polygon": {
          fill: theme.palette.text.secondary,
        },
      },
      "&:hover": {
        // backgroundColor: theme.palette.secondary.main,
      },
    },
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
  environmentMenu: {
    "& .MuiListItem-root": {
      fontSize: 14,
      "&.MuiListItem-development": {
        "&:hover": {
          backgroundColor: theme.palette.grey[600],
        },
      },
      "&.MuiListItem-testing": {
        "&:hover": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.warning.main,
        },
      },
      "&.MuiListItem-production": {
        "&:hover": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.success.main,
        },
      },
      "&:hover": {
        "& .MuiTypography-root": {
          color: theme.palette.common.white,
        },
        "& .MuiSvgIcon-root": {
          "& rect": {
            fill: theme.palette.common.white,
          },
          "& path": {
            fill: theme.palette.common.white,
          },
          "& circle": {
            fill: theme.palette.common.white,
          },
          "& polygon": {
            fill: theme.palette.common.white,
          },
        },
      },
    },
  },
  drawerMenu: {
    width: 280,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    "& .MuiListItem-button": {
      "&.Mui-selected": {
        backgroundColor: theme.palette.grey[200],
        "& .MuiSvgIcon-root": {
          "& path": {
            fill: theme.palette.secondary.main,
          },
          "& rect": {
            fill: theme.palette.secondary.main,
          },
        },
        "& .MuiTypography-root": {
          color: theme.palette.secondary.main,
        },
        "& .MuiTypography-root + .MuiSvgIcon-root": {
          transform: "rotate(90deg)",
        },
      },
      "&:hover": {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[100],
        "& .MuiSvgIcon-root": {
          "& path": {
            fill: theme.palette.secondary.main,
          },
          "& rect": {
            fill: theme.palette.secondary.main,
          },
        },
        "& .MuiTypography-root": {
          color: theme.palette.text.secondary,
        },
      },
    },
    "& .MuiListItem-root": {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(3),
      },
      "& .MuiTypography-root": {
        flexGrow: 1,
      },
      "& .MuiTypography-root + .MuiSvgIcon-root": {
        marginLeft: theme.spacing(3),
        marginRight: 0,
        transition: theme.transitions.create(["transform"], {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
    "& .MuiCollapse-container": {
      "& .MuiListItem-root": {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(9),
        "& .MuiTypography-root": {
          fontSize: 14,
        },
      },
    },
  },
  subMenu: {
    paddingLeft: theme.spacing(6),
    fontSize: 14,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const Header = ({
  authentication,
  clearEnvironment,
  getApplications,
  getRoles,
  getPermissions,
  getTenants,
  settings,
  showEdit,
  show,
  dispatchSetSelectedMarket,
  selectedMarketCode,
  dispatchUpdateTenantToken,
}) => {
  const classes = useStyles();
  const anchor = "left";
  const isLoogedIn = !isEmpty(authentication) && !authentication.error;
  const { ctry, access_token, id_token, allowedMarkets } = authentication;
  const mappedMarketOptions = allowedMarkets?.map(market => (
    <MenuItem value={market.code} key={market.code}>
      <Typography component="h1" variant="h5">
        {`Vodafone ${market.title}`}
      </Typography>
    </MenuItem>
  ));
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownPortal, setOpenDropdownPortal] = useState(false);
  const { tenantId, logoutRedirectUri } = settings;

  const toggleDrawer = open => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenDropdown(open);
  };

  const history = useHistory();
  const {
    location: { pathname },
  } = useHistory();

  const handleTenantSelectionChange = event => {
    const { value: selectedTenant } = event.target;
    dispatchSetSelectedMarket(allowedMarkets.find(m => m.code.toLowerCase() === selectedTenant.toLowerCase()));
    dispatchUpdateTenantToken({ access_token, id_token, selectedTenant });
    history.push(`/${selectedTenant}/landing`);
  };

  if (show || showEdit) return null;

  return (
    isLoogedIn && (
      <React.Fragment key={anchor}>
        <AppBar position="fixed" className={classes.root} color="inherit">
          <Toolbar className={classes.toolbar}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs="auto">
                <Grid container alignItems="center">
                  <Grid item xs="auto">
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      onClick={toggleDrawer(true)}
                      color="inherit"
                      aria-label="menu"
                    >
                      <VfSvgIcon icon="menu" viewBox={24} />
                    </IconButton>
                  </Grid>
                  <Grid item xs="auto">
                    <Box className={classes.mid} display="flex">
                      <img className={classes.logo} src={logo} alt="Vodafone" />
                      {allowedMarkets.length > 1 ? (
                        <FormControl variant="outlined" fullWidth>
                          <Select value={selectedMarketCode} onChange={handleTenantSelectionChange}>
                            {mappedMarketOptions}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography component="h1" variant="h5">
                          {selectedMarketCode ? `Vodafone ${selectedMarketCode}` : "Vodafone"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="auto">
                <Box className={classes.right} display="flex">
                  <IconButton color="inherit">
                    <VfSvgIcon icon="notification" viewBox={24} />
                  </IconButton>
                  <IconButton color="inherit">
                    <a
                      href={`https://login.windows.net/${tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${logoutRedirectUri}`}
                      rel="noopener noreferrer"
                    >
                      <Avatar alt={authentication && authentication.name} className={classes.avatar} src={avatar} />
                    </a>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer className={classes.drawer} anchor={anchor} open={openDropdown} onClose={toggleDrawer(false)}>
          <List className={classes.drawerMenu}>
            <HasPermission permissionKey="view_apps" disabled>
              <ListItem
                {...(pathname.indexOf("apps") > -1 ? { selected: true } : null)}
                button
                key="Apps"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownPortal(false);
                  clearEnvironment();
                  history.push(`/${ctry}/apps`);
                  getApplications({ access_token, id_token });
                }}
              >
                <VfSvgIcon icon="apps" viewBox={24} />
                <Typography variant="body1">Apps</Typography>
              </ListItem>
            </HasPermission>
            <HasPermission permissionKey="view_configurations" disabled>
              <ListItem
                {...(pathname.indexOf("env") > -1 ? { selected: true } : null)}
                button
                key="Configurator"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownPortal(false);
                  clearEnvironment();
                  history.push(`/${ctry}/configurator`);
                }}
              >
                <VfSvgIcon icon="config" viewBox={24} />
                <Typography variant="body1">Configurator</Typography>
              </ListItem>
            </HasPermission>
            <HasPermission permissionKey="view_translations" disabled>
              <ListItem
                {...(pathname.indexOf("translations") > -1 ? { selected: true } : null)}
                button
                key="Translator"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownPortal(false);
                  clearEnvironment();
                  history.push(`/${ctry}/translations`);
                }}
              >
                <VfSvgIcon icon="translate" viewBox={24} />
                <Typography variant="body1">Translator</Typography>
              </ListItem>
            </HasPermission>
            <HasPermission permissionKey="view_portal" disabled>
              <ListItem
                selected={openDropdownPortal}
                button
                key="Portal"
                onClick={() => {
                  if (openDropdownPortal) {
                    setOpenDropdown(false);
                    setOpenDropdownPortal(!openDropdownPortal);
                    history.push(`/${ctry}/portal`);
                  } else {
                    setOpenDropdownPortal(!openDropdownPortal);
                  }
                }}
              >
                <VfSvgIcon icon="portal" viewBox={24} />
                <Typography variant="body1">Portal</Typography>
                <VfSvgIcon icon="chevronRight" viewBox={24} />
              </ListItem>
            </HasPermission>
            <Collapse in={openDropdownPortal}>
              <HasPermission permissionKey="view_reporting" disabled>
                <ListItem
                  button
                  onClick={() => {
                    setOpenDropdown(false);
                    history.push(`/${ctry}/portal/reporting`);
                  }}
                  {...(pathname.indexOf(`/${ctry}/portal/reporting`) > -1 ? { selected: true } : null)}
                >
                  <Typography className={classes.subMenu} variant="body1">
                    Reporting
                  </Typography>
                </ListItem>
              </HasPermission>
              <HasPermission permissionKey="view_campaigns" disabled>
                <ListItem
                  button
                  onClick={() => {
                    setOpenDropdown(false);
                    history.push(`/${ctry}/portal/campaigns`);
                  }}
                  {...(pathname.indexOf(`/${ctry}/portal/campaigns`) > -1 ? { selected: true } : null)}
                >
                  <Typography className={classes.subMenu} variant="body1">
                    Campaigns
                  </Typography>
                </ListItem>
              </HasPermission>
              <HasPermission permissionKey="view_galleries" disabled>
                <ListItem
                  button
                  onClick={() => {
                    setOpenDropdown(false);
                    history.push(`/${ctry}/portal/galleries`);
                  }}
                  {...(pathname.indexOf(`/${ctry}/portal/galleries`) > -1 ? { selected: true } : null)}
                >
                  <Typography className={classes.subMenu} variant="body1">
                    Galleries
                  </Typography>
                </ListItem>
              </HasPermission>
              <HasPermission permissionKey="view_store_groups" disabled>
                <ListItem
                  button
                  onClick={() => {
                    setOpenDropdown(false);
                    history.push(`/${ctry}/portal/store-groups`);
                  }}
                  {...(pathname.indexOf(`/${ctry}/portal/store-groups`) > -1 ? { selected: true } : null)}
                >
                  <Typography className={classes.subMenu} variant="body1">
                    Store groups
                  </Typography>
                </ListItem>
              </HasPermission>
              <HasPermission permissionKey="view_store_devices" disabled>
                <ListItem
                  button
                  onClick={() => {
                    setOpenDropdown(false);
                    history.push(`/${ctry}/portal/store-devices`);
                  }}
                  {...(pathname.indexOf(`/${ctry}/portal/store-devices`) > -1 ? { selected: true } : null)}
                >
                  <Typography className={classes.subMenu} variant="body1">
                    Stores & Devices
                  </Typography>
                </ListItem>
              </HasPermission>
              <HasPermission permissionKey="view_passcodes" disabled>
                <ListItem
                  button
                  onClick={() => {
                    setOpenDropdown(false);
                    history.push(`/${ctry}/portal/passcodes`);
                  }}
                  {...(pathname.indexOf(`/${ctry}/portal/passcodes`) > -1 ? { selected: true } : null)}
                >
                  <Typography className={classes.subMenu} variant="body1">
                    Passcodes
                  </Typography>
                </ListItem>
              </HasPermission>
            </Collapse>
            <Divider className={classes.divider} />
            <HasPermission permissionKey="view_media" disabled>
              <ListItem
                {...(pathname.indexOf("media") > -1 ? { selected: true } : null)}
                button
                key="Media"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownPortal(false);
                  clearEnvironment();
                  history.push(`/${ctry}/media`);
                }}
              >
                <VfSvgIcon icon="image" viewBox={24} />
                <Typography variant="body1">Media library</Typography>
              </ListItem>
            </HasPermission>
            <HasPermission permissionKey="view_roles" disabled>
              <ListItem
                {...(pathname.indexOf("roles") > -1 ? { selected: true } : null)}
                button
                key="Roles"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownPortal(false);
                  clearEnvironment();
                  history.push(`/${ctry}/roles`);
                  getRoles({ access_token, id_token });
                  getPermissions({ access_token, id_token });
                }}
              >
                <VfSvgIcon icon="roles" viewBox={24} />
                <Typography variant="body1">Roles & Permissions</Typography>
              </ListItem>
            </HasPermission>
            <HasPermission permissionKey="view_tenant" disabled>
              <ListItem
                {...(pathname.indexOf("tenant") > -1 ? { selected: true } : null)}
                button
                key="Tenant"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownPortal(false);
                  history.push(`/tenant`);
                  getTenants({ access_token, id_token });
                }}
              >
                <VfSvgIcon icon="buildings" viewBox={24} />
                <Typography variant="body1">Tenants Management</Typography>
              </ListItem>
            </HasPermission>
            <HasPermission permissionKey="view_apis" disabled>
              <ListItem
                {...(pathname.indexOf("apis") > -1 ? { selected: true } : null)}
                button
                key="Apis"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownPortal(false);
                  history.push(`/${ctry}/apis`);
                  getTenants({ access_token, id_token });
                }}
              >
                <VfSvgIcon icon="apis" viewBox={24} />
                <Typography variant="body1">Api Management</Typography>
              </ListItem>
            </HasPermission>
          </List>
        </Drawer>
      </React.Fragment>
    )
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    envs: state.envs.list,
    settings: state.settings,
    show: state.applications.show,
    showEdit: state.applications.showEdit,
    selectedMarketCode: state.authentication?.selectedMarket?.code,
  };
};

const mapDispatchToProps = dispatch => ({
  setEnvironment: parmas => dispatch(setEnv(parmas)),
  getApplications: params => dispatch(fetchApps(params)),
  getRoles: params => dispatch(fetchRoles(params)),
  getPermissions: params => dispatch(fetchPermissions(params)),
  clearEnvironment: () => dispatch(clearEnv()),
  getTenants: params => dispatch(fetchTenants(params)),
  dispatchSetSelectedMarket: data => dispatch(setSelectedMarket(data)),
  dispatchUpdateTenantToken: data => dispatch(updateTenantToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

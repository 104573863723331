/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Box, Container, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { clearEnv, setEnv } from "actions/envsActions";
import {
  fetchConfigApps,
  setConfigApp,
  clearConfigApp,
  fetchConfigListWidget,
  clearConfigWidgetList,
  resetConfigListWidget,
  deployAndFetchWidgetConfig,
} from "actions/configurationActions";
import Heading from "../headings/Heading";
import SearchBar from "../searchbar/SearchBar";
import Loader from "../loader/Loader";
import theme from "../../AppTheme";
import DisplayType from "../displaytype/DisplayType";
import FilterGroupPlatforms from "../filters/FilterGroupPlatforms";
import FilterGroupAppTypes from "../filters/FilterGroupAppTypes";
import FilterMenuThemes from "../filters/FilterMenuThemes";
import FilterMenuEnvironments from "../filters/FilterMenuEnvironments";
import DialogConfiguratorApp from "./DialogConfiguratorApp";
import DialogConfiguratorWidget from "./DialogConfiguratorWidget";
import PaperConfiguratorPerEnv from "./PaperConfiguratorPerEnv";

const useStyles = makeStyles({
  root: {
    paddingTop: theme.spacing(6),
    "& .MuiBox-searchBar": {
      position: "absolute",
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  searchWrapper: {
    minHeight: theme.spacing(6) + 2,
  },
});

const THRESHOLD = 3;

const PaperConfigurator = () => {
  const auth = useSelector(state => state.authentication, shallowEqual);
  const envs = useSelector(state => state.envs, shallowEqual);
  const configApps = useSelector(
    state => state.configuration.appList,
    shallowEqual
  );
  const loading = useSelector(
    state => state.configuration.loading,
    shallowEqual
  );
  const currentApp = useSelector(
    state => state.configuration.currentApp,
    shallowEqual
  );

  const dispatch = useDispatch();
  const clearEnvironment = () => dispatch(clearEnv());
  const setEnvironment = parmas => dispatch(setEnv(parmas));
  const fetchAppList = parmas => dispatch(fetchConfigApps(parmas));
  const setCurrentApp = parmas => dispatch(setConfigApp(parmas));
  const clearApp = () => dispatch(clearConfigApp());
  const clearWidgetList = () => dispatch(clearConfigWidgetList());
  const fetchWidgetList = parmas => dispatch(fetchConfigListWidget(parmas));
  const resetConfig = parmas => dispatch(resetConfigListWidget(parmas));
  const deployAndFetchConfig = parmas =>
    dispatch(deployAndFetchWidgetConfig(parmas));

  const { id: envID } = envs;
  const { access_token, id_token } = auth;
  const { locale } = useParams();
  const classes = useStyles();

  const [thresholdPerEnv, setThresholdPerEnv] = useState({});
  const [searchText, setSearchText] = useState(null);
  const [grid, setGrid] = useState("list");
  const [dialogWidget, setDialogWidget] = useState(false);
  const [dialogApp, setDialogApp] = useState(false);

  const [filters, setFilters] = useState({
    theme: null,
    appType: [],
    platform: [],
  });

  useEffect(() => {
    fetchAppList({ envID, access_token, id_token, searchText, ...filters });
  }, [filters, envID]);

  useEffect(() => {
    const defaultThresholdPerEnv = {};
    configApps.forEach(x => {
      const [type] = Object.entries(x)[0];
      defaultThresholdPerEnv[type] = THRESHOLD;
    });
    setThresholdPerEnv(defaultThresholdPerEnv);
  }, [configApps]);

  const handleFilters = e => {
    const { name, value, remove } = e.currentTarget.dataset;
    if (name !== "theme") {
      if (remove) {
        setFilters({
          ...filters,
          [name]: filters[name].filter(v => v !== value),
        });
      } else {
        setFilters({
          ...filters,
          [name]: [...filters[name], value],
        });
      }
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const handleClickApp = (application, envId, env, envType,isGlobal) => {
    setDialogApp(true);
    setCurrentApp({ ...application, envId, envTitle: env, envType, isGlobal });
    fetchWidgetList({
      application: application._id,
      access_token,
      id_token,
      envID: envId,
    });
  };

  const onShowMore = value => {
    const copyThresholdPerEnv = { ...thresholdPerEnv };
    copyThresholdPerEnv[value] = 1000000;
    setThresholdPerEnv(copyThresholdPerEnv);
  };

  const deployChanges = componentId =>
    deployAndFetchConfig({
      envID: currentApp.envId,
      access_token,
      application: currentApp._id,
      componentId,
      id_token,
    });

  const resetChanges = async () => {
    await resetConfig({
      envID: currentApp.envId,
      application: currentApp._id,
      id_token,
      access_token,
    });
    fetchWidgetList({
      application: currentApp._id,
      access_token,
      id_token,
      envID: currentApp.envId,
    });
  };

  return (
    <main className={classes.root}>
      <Heading title="Configurator" backUrl={`/${locale}/landing`}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs="auto">
            <FilterGroupPlatforms handleFilters={handleFilters} />
          </Grid>
          <Grid item xs="auto">
            <FilterGroupAppTypes handleFilters={handleFilters} />
          </Grid>
          <Grid item xs="auto">
            <FilterMenuThemes handleFilters={handleFilters} />
          </Grid>
          <Grid item xs>
            <Grid container spacing={3} justify="flex-end" alignItems="center">
              <Grid item xs="auto">
                <FilterMenuEnvironments
                  envs={envs}
                  clearEnvironment={clearEnvironment}
                  setEnvironment={setEnvironment}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Heading>
      <Box pt={8} pb={4} className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box className={classes.searchWrapper}>
                <Box
                  py={0.25}
                  className="MuiBox-searchOverlay"
                  position="relative"
                >
                  <DisplayType grid={grid} handleGridView={setGrid} />
                  <SearchBar
                    callback={fetchAppList}
                    callbackParams={{
                      ...filters,
                      access_token,
                      id_token,
                      envID,
                    }}
                    setSearchText={setSearchText}
                  />
                </Box>
              </Box>
            </Grid>
            {!loading ? (
              <Grid item xs={12}>
                {configApps.map(env => {
                  const [type, envData] = Object.entries(env)[0];
                  return (
                    <PaperConfiguratorPerEnv
                      key={type}
                      env={envData.title}
                      isGlobal={envData.isGlobal}
                      envType={type}
                      envId={envData.envId}
                      auth={auth}
                      grid={grid}
                      appsList={envData.data}
                      threshold={THRESHOLD}
                      thresholdPerEnv={thresholdPerEnv}
                      onShowMore={onShowMore}
                      handleClickApp={handleClickApp}
                    />
                  );
                })}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      {currentApp && dialogApp && (
        <DialogConfiguratorApp
          loading={loading}
          app={currentApp}
          dialogApp={dialogApp}
          setDialogApp={setDialogApp}
          setDialogWidget={setDialogWidget}
          clearApp={clearApp}
          clearWidgetList={clearWidgetList}
          resetChanges={resetChanges}
          deployChanges={deployChanges}
        />
      )}
      {currentApp && dialogWidget && (
        <DialogConfiguratorWidget
          app={currentApp}
          dialogWidget={dialogWidget}
          setDialogWidget={setDialogWidget}
        />
      )}
    </main>
  );
};

export default PaperConfigurator;

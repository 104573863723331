/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import theme from "AppTheme";
import { setSelectedTreeItem as settingSelectedTreeItem } from "actions/translationsActions";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    ["& .MuiTreeView-root"]: {
      ["& > .MuiTreeItem-root"]: {
        ["&:hover"]: {
          ["& > .MuiTreeItem-content"]: {},
        },
        ["&.Mui-expanded"]: {
          ["& > .MuiTreeItem-content"]: {
            color: theme.palette.secondary.main,
            ["& .MuiSvgIcon-root"]: {
              ["& path"]: {
                fill: theme.palette.secondary.main,
              },
            },
            ["& .MuiBadge-root"]: {
              ["& .MuiBadge-colorSecondary"]: {
                backgroundColor: theme.palette.common.white,
              },
            },
          },
        },
        ["&.Mui-selected"]: {
          ["& > .MuiTreeItem-content"]: {
            color: theme.palette.secondary.main,
            ["& .MuiSvgIcon-root"]: {
              "& path": {
                fill: theme.palette.secondary.main,
              },
            },
            ["& .MuiBadge-root"]: {
              ["& .MuiBadge-colorSecondary"]: {
                backgroundColor: theme.palette.common.white,
              },
            },
          },
        },
        ["& > .MuiTreeItem-content"]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(1.5),
          paddingTop: theme.spacing(1.5),
          flexDirection: "row-reverse",
          ["& > .MuiTreeItem-iconContainer"]: {
            marginRight: 0,
            marginLeft: theme.spacing(0.5),
          },
          ["& > .MuiTreeItem-label"]: {
            paddingLeft: 0,
          },
          ["& .MuiTreeItem-iconContainer"]: {
            ["& svg"]: {
              fontSize: 24,
            },
          },
        },
        ["& .Mui-expanded"]: {
          ["& > .MuiTreeItem-content"]: {
            ["& .MuiTreeItem-iconContainer"]: {
              color: theme.palette.secondary.main,
            },
            ["& .MuiTreeItem-label"]: {
              fontWeight: "bold",
            },
          },
        },
        ["& .Mui-selected"]: {
          ["& > .MuiTreeItem-content"]: {
            ["& .MuiTreeItem-label"]: {
              fontWeight: "bold",
            },
          },
        },
      },
    },
    ["& .MuiTreeItem-root"]: {
      ["&.Mui-selected"]: {
        ["& .MuiTreeItem-content"]: {
          ["& .MuiTreeItem-label"]: {
            backgroundColor: "transparent",
          },
        },
      },
    },
    ["& .MuiTreeItem-content"]: {
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
    ["& .MuiCollapse-container"]: {
      ["& .MuiCollapse-wrapper"]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        ["& .MuiCollapse-wrapperInner"]: {
          borderLeftWidth: 2,
          borderLeftStyle: "solid",
          borderLeftColor: theme.palette.grey["300"],
          ["& .MuiTreeItem-root"]: {
            ["&[aria-expanded]"]: {
              backgroundColor: theme.palette.common.white,
              paddingLeft: theme.spacing(2),
              marginLeft: -theme.spacing(2),
              ["& > .MuiTreeItem-content"]: {
                ["& > .MuiTreeItem-iconContainer"]: {
                  marginLeft: -theme.spacing(1),
                },
                ["& > .MuiTreeItem-label"]: {
                  paddingLeft: theme.spacing(1),
                },
              },
            },
          },
        },
      },
    },
    ["& .MuiTreeItem-iconContainer"]: {
      marginRight: 0,

      ["& svg"]: {
        fontSize: 16,
      },
    },
    ["& .MuiTypography-root"]: {},
    ["& .MuiTreeItem-label"]: {
      backgroundColor: "transparent",
      paddingLeft: 0,
    },
    ["& .MuiBox-searchBar"]: {
      position: "absolute",
      left: theme.spacing(2),
      right: theme.spacing(2),
      top: "50%",
      transform: "translateY(-50%)",
      ["& .MuiIconButton-root"]: {
        padding: theme.spacing(1),
      },
      ["& .MuiCard-search"]: {
        width: 42,
        ["&.MuiCard-search--open"]: {
          width: "100%",
        },
      },
    },
    ["& .MuiBadge-root"]: {
      ["& .MuiBadge-badge"]: {
        right: -theme.spacing(1),
        top: -1,
      },
    },
  },
});

const getBranch = (data, path) => {
  let _path = path;
  if (_path === "$.") _path = "$";
  /* eslint-disable  */
  return Object.keys(data)
    .map(b =>
      typeof data[b] === "object"
        ? {
            id: _path + "." + b,
            name: b,
            path: _path + "." + b,
            children: getBranch(data[b], _path + "." + b),
            schema: data[b],
          }
        : null
    )
    .filter(n => n);
  /* eslint-enable  */
};

const generateTree = original => [
  {
    id: "$.",
    name: "Root",
    changes: false,
    path: "$.",
    schema: original,
    children: getBranch(original, "$."),
  },
];

const TranslationsTree = ({
  translations,
  setSelectedTreeItem,
  widgetDescription,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(["$."]);
  React.useEffect(() => {
    setSelectedTreeItem({
      id: "$.",
      name: widgetDescription,
      changes: false,
      path: "$.",
      schema: translations.find(l => l.language === "en").data,
    });
  }, [translations]);

  const renderTree = node => (
    <TreeItem
      className={classnames({ "Mui-changes": node.changes })}
      key={node.id}
      nodeId={node.id}
      label={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          {node.name}
          {node.changes && <Badge variant="dot" color="secondary" />}
        </>
      }
      onClick={() => {
        setSelectedTreeItem({
          schema: node.schema,
          id: node.id,
          path: node.path,
          name: node.name === "Root" ? widgetDescription : node.name,
          isArray: node.showArray,
        });
      }}
    >
      {Array.isArray(node.children)
        ? node.children.map(n => renderTree(n))
        : null}
    </TreeItem>
  );
  if (!translations) return null;

  return (
    <Box py={3} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box px={2} position="relative">
            <Typography className="bold" variant="h5" component="h2">
              Sections
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TreeView
            defaultCollapseIcon={
              <VfSvgIcon icon="chevronDown" fontSize="small" />
            }
            defaultExpandIcon={
              <VfSvgIcon icon="chevronRight" fontSize="small" />
            }
            expanded={expanded}
            onNodeToggle={(e, value) => setExpanded(value)}
          >
            {/* eslint-disable */}
            {Array.isArray(
              generateTree(translations.find(l => l.language === "en").data)
            )
              ? generateTree(
                  translations.find(l => l.language === "en").data
                ).map(n => renderTree(n))
              : null}
            {/* eslint-enable */}
          </TreeView>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = dispatch => ({
  setSelectedTreeItem: data => dispatch(settingSelectedTreeItem(data)),
});

export default connect(null, mapDispatchToProps)(TranslationsTree);

/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_CAMPAIGNS_REQUEST,
  ADD_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGNS_REQUEST,
  DELETE_CAMPAIGNS_REQUEST,
  BULK_DELETE_CAMPAIGNS_REQUEST,
  fetchCampaignsSuccess,
  fetchCampaignsFail,
  addCampaignSuccess,
  addCampaignFail,
  updateCampaignsSuccess,
  updateCampaignsFail,
  deleteCampaignSuccess,
  deleteCampaignFail,
  bulkDeleteCampaignsSuccess,
  bulkDeleteCampaignsFail,
} from "actions/campaignActions";
import CampaignService from "services/CampaignService";

function* getCampaignsWorker({ payload }) {
  try {
    const data = yield call(CampaignService.fetch, payload);
    yield put(fetchCampaignsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(fetchCampaignsFail(error));
  }
}

function* getCampaignsWatcher() {
  yield takeLatest(GET_CAMPAIGNS_REQUEST, getCampaignsWorker);
}

function* addCampaignWorker({ payload }) {
  try {
    const data = yield call(CampaignService.add, payload);
    yield put(addCampaignSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addCampaignFail(error));
  }
}

function* addCampaignWatcher() {
  yield takeLatest(ADD_CAMPAIGN_REQUEST, addCampaignWorker);
}

function* updateCampaignWorker({ payload }) {
  try {
    const data = yield call(CampaignService.update, payload);
    yield put(updateCampaignsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(updateCampaignsFail(error));
  }
}

function* updateCampaignWatcher() {
  yield takeLatest(UPDATE_CAMPAIGNS_REQUEST, updateCampaignWorker);
}

function* deleteCampaignWorker({ payload }) {
  try {
    const data = yield call(CampaignService.delete, payload);
    yield put(deleteCampaignSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(deleteCampaignFail(error));
  }
}

function* deleteCampaignWatcher() {
  yield takeLatest(DELETE_CAMPAIGNS_REQUEST, deleteCampaignWorker);
}

function* bulkDeleteCampaignsWorker({ payload }) {
  try {
    const data = yield call(CampaignService.bulkDelete, payload);
    yield put(bulkDeleteCampaignsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(bulkDeleteCampaignsFail(error));
  }
}

function* bulkDeleteCampaignsWatcher() {
  yield takeLatest(BULK_DELETE_CAMPAIGNS_REQUEST, bulkDeleteCampaignsWorker);
}

const campaignSaga = [
  getCampaignsWatcher(),
  addCampaignWatcher(),
  updateCampaignWatcher(),
  deleteCampaignWatcher(),
  bulkDeleteCampaignsWatcher(),
];

export default campaignSaga;

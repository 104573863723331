/* eslint-disable camelcase */
import axios from "axios";

class TenantService {
  fetchSchema = async ({ access_token, id_token }) => {
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/tenant/schema",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in tenant schema service:", err);
      return err;
    }
  };

  saveTenant = async payload => {
    const { access_token, id_token } = payload;
    try {
      const response = await axios({
        method: "post",
        url: `/api/appbuilder/tenant/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: payload.data,
      });
      return response.data;
    } catch (err) {
      console.log("Error in save Tenant service:", err);
      return err;
    }
  };

  fetchTenants = async payload => {
    const { access_token, id_token } = payload;
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/tenant",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in get tenants service:", err);
      return err;
    }
  };

  tenantValidations = async payload => {
    const { access_token, id_token, body } = payload;
    try {
      const response = await axios({
        method: "post",
        url: `/api/appbuilder/tenant/validations`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: body,
      });
      return response;
    } catch (err) {
      console.log("Error in validate tenant service:", err);
      return err;
    }
  };

  updateTenant = async payload => {
    const { access_token, id_token } = payload;
    try {
      const response = await axios({
        method: "post",
        url: `/api/appbuilder/tenant/update`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: payload.data,
      });
      return response.data;
    } catch (err) {
      console.log("Error in update tenant service:", err);
      return err;
    }
  };

  deleteTenant = async ({ access_token, id_token, id }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/tenant/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in delete tenant service:", err);
      return err;
    }
  };

  fetchTenantBasicInfo = async ({ access_token, id_token, locale }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/tenant/basicInfo/${locale}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in get tenant basic info service:", err);
      return err;
    }
  };

  updateTenantToken = async ({ access_token, id_token, selectedTenant }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: "/api/appbuilder/getMarketToken",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: { ctry: selectedTenant },
      });
      return data;
    } catch (err) {
      console.log("Error in updating tenant token:", err);
      throw err;
    }
  };
}

const inst = new TenantService();
export default inst;

import { call, put, takeLatest } from "redux-saga/effects";

import {
  CONFIG_SCHEMA_REQUEST,
  CONFIG_SCHEMA_REQUEST_SUCCESS,
  CONFIG_SCHEMA_REQUEST_FAIL,
  CONFIG_DATA_REQUEST,
  CONFIG_DATA_REQUEST_SUCCESS,
  CONFIG_DATA_REQUEST_FAIL,
  CONFIG_DATA_POST_REQUEST,
  CONFIG_DATA_POST_REQUEST_SUCCESS,
  CONFIG_DATA_POST_REQUEST_FAIL,
} from "actions/configFormActions";
import { CONFIG_LIST_WIDGET_REQUEST } from "actions/configurationActions";
import configFormService from "services/ConfigFormService";

function* configSchemaWorker({ payload }) {
  try {
    const { schema } = yield call(configFormService.fetchConfigSchema, payload);
    yield put({
      type: CONFIG_SCHEMA_REQUEST_SUCCESS,
      payload: schema,
    });
  } catch (error) {
    yield put({ type: CONFIG_SCHEMA_REQUEST_FAIL, error });
  }
}

function* configDataWorker({ payload }) {
  try {
    const { data } = yield call(configFormService.fetchConfigData, payload);
    yield put({
      type: CONFIG_DATA_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: CONFIG_DATA_REQUEST_FAIL, error });
  }
}

function* configPostDataWorker({ payload }) {
  try {
    const data = yield call(configFormService.postConfigData, payload);
    if (data.isAxiosError) {
      throw data;
    }

    yield put({
      type: CONFIG_DATA_POST_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: CONFIG_LIST_WIDGET_REQUEST,
      payload: { ...payload, application: payload.app },
    });
  } catch (error) {
    yield put({
      type: CONFIG_DATA_POST_REQUEST_FAIL,
      error: error.response.data.error,
    });
  }
}

function* configSchemaWatcher() {
  yield takeLatest(CONFIG_SCHEMA_REQUEST, configSchemaWorker);
}

function* configDataWatcher() {
  yield takeLatest(CONFIG_DATA_REQUEST, configDataWorker);
}

function* configPostDataWatcher() {
  yield takeLatest(CONFIG_DATA_POST_REQUEST, configPostDataWorker);
}

const configFormSaga = [
  configSchemaWatcher(),
  configDataWatcher(),
  configPostDataWatcher(),
];

export default configFormSaga;

/* eslint-disable no-param-reassign */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  TENANT_SCHEMA_REQUEST,
  TENANT_SCHEMA_REQUEST_SUCCESS,
  TENANT_SCHEMA_REQUEST_FAIL,
  SAVE_TENANT_REQUEST_SUCCESS,
  SAVE_TENANT_REQUEST_FAIL,
  SAVE_TENANT_REQUEST,
  GET_TENANTS_REQUEST,
  GET_TENANTS_REQUEST_SUCCESS,
  GET_TENANTS_REQUEST_FAIL,
  SHOW_TENANT_DIALOG,
  TENANT_VALIDATIONS_REQUEST,
  TENANT_VALIDATIONS_REQUEST_SUCCESS,
  TENANT_VALIDATIONS_REQUEST_FAIL,
  SET_CURRENT_TENANT,
  UPDATE_TENANT_REQUEST,
  UPDATE_TENANT_REQUEST_SUCCESS,
  UPDATE_TENANT_REQUEST_FAIL,
  DELETE_TENANT_REQUEST,
  DELETE_TENANT_REQUEST_SUCCESS,
  DELETE_TENANT_REQUEST_FAIL,
  FETCH_TENANT_BASIC_INFO_REQUEST,
  FETCH_TENANT_BASIC_INFO_REQUEST_SUCCESS,
  FETCH_TENANT_BASIC_INFO_REQUEST_FAIL,
  UPDATE_TENANT_TOKEN,
} from "actions/tenantActions";
import { USER_PERMISSIONS, USER_PERMISSIONS_ERROR } from "actions/permissionsActions";
import { UPDATE_AUTH_ID_TOKEN } from "actions/authenticationActions";
import tenantService from "services/TenantService";
import PermissionsService from "services/PermissionsService";
import jwt from "jsonwebtoken";

function removeTrailingSlashFromData(data) {
  const workbenchApi = data?.data?.locale?.integrationInfo?.workbenchApi;
  if (workbenchApi) {
    data.data.locale.integrationInfo.workbenchApi = workbenchApi.replace(/\/+$/gi, "");
  }

  const jenkinsApi = data?.data?.locale?.integrationInfo?.jenkinsApi;
  if (data?.data?.locale?.integrationInfo?.jenkinsApi) {
    data.data.locale.integrationInfo.jenkinsApi = jenkinsApi.replace(/\/+$/gi, "");
  }

  return data;
}

function* tenantSchemaWorker({ payload }) {
  try {
    const { data } = yield call(tenantService.fetchSchema, payload);
    yield put({
      type: TENANT_SCHEMA_REQUEST_SUCCESS,
      payload: data.schema,
    });
  } catch (error) {
    yield put({ type: TENANT_SCHEMA_REQUEST_FAIL, error });
  }
}

function* tenantSchemaWatcher() {
  yield takeLatest(TENANT_SCHEMA_REQUEST, tenantSchemaWorker);
}

function* saveTenantWorker({ payload }) {
  try {
    const trimmedPayload = removeTrailingSlashFromData(payload);
    const data = yield call(tenantService.saveTenant, trimmedPayload);
    if (data.isAxiosError) {
      throw data;
    }
    yield put({
      type: SAVE_TENANT_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: SAVE_TENANT_REQUEST_FAIL,
      error: error.response.data.error,
    });
  }
}

function* saveTenantWatcher() {
  yield takeLatest(SAVE_TENANT_REQUEST, saveTenantWorker);
}

function* fetchTenantsWorker({ payload }) {
  try {
    const { data } = yield call(tenantService.fetchTenants, payload);
    yield put({
      type: GET_TENANTS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_TENANTS_REQUEST_FAIL, error });
  }
}

function* fetchTenantsWatcher() {
  yield takeLatest(GET_TENANTS_REQUEST, fetchTenantsWorker);
}

function* tenantValidationsWorker({ payload }) {
  try {
    const { data } = yield call(tenantService.tenantValidations, payload);
    yield put({
      type: TENANT_VALIDATIONS_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: SET_CURRENT_TENANT,
      payload: data,
    });
    yield put({
      type: SHOW_TENANT_DIALOG,
    });
  } catch (error) {
    yield put({ type: TENANT_VALIDATIONS_REQUEST_FAIL, error });
  }
}

function* tenantValidationsWatcher() {
  yield takeLatest(TENANT_VALIDATIONS_REQUEST, tenantValidationsWorker);
}

function* updateTenantWorker({ payload }) {
  try {
    const trimmedPayload = removeTrailingSlashFromData(payload);
    const data = yield call(tenantService.updateTenant, trimmedPayload);
    if (data.isAxiosError) {
      throw data;
    }
    yield put({
      type: UPDATE_TENANT_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: UPDATE_TENANT_REQUEST_FAIL,
      error: error.response.data.error,
    });
  }
}

function* updateTenantWatcher() {
  yield takeLatest(UPDATE_TENANT_REQUEST, updateTenantWorker);
}

function* deleteTenantWorker({ payload }) {
  try {
    const data = yield call(tenantService.deleteTenant, payload);
    if (data.isAxiosError) {
      throw data;
    }
    yield put({
      type: DELETE_TENANT_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: GET_TENANTS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    yield put({
      type: DELETE_TENANT_REQUEST_FAIL,
      error: error.response.data.error,
    });
  }
}

function* deleteTenantWatcher() {
  yield takeLatest(DELETE_TENANT_REQUEST, deleteTenantWorker);
}

function* fetchTenantBasicInfoWorker({ payload }) {
  try {
    const { data, error } = yield call(tenantService.fetchTenantBasicInfo, payload);
    if (error) yield put({ type: FETCH_TENANT_BASIC_INFO_REQUEST_FAIL, error });
    yield put({
      type: FETCH_TENANT_BASIC_INFO_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: FETCH_TENANT_BASIC_INFO_REQUEST_FAIL, error });
  }
}

function* fetchTenantBasicInfoWatcher() {
  yield takeLatest(FETCH_TENANT_BASIC_INFO_REQUEST, fetchTenantBasicInfoWorker);
}

function* updateTenantTokenWorker({ payload }) {
  try {
    const data = yield call(tenantService.updateTenantToken, payload);

    const decoded = jwt.decode(data.id_token);
    data.ctry = decoded.ctry;
    data.selectedMarket = decoded.allowed_markets.find(m => m.code === data.ctry);

    yield put({ type: UPDATE_AUTH_ID_TOKEN, payload: { id_token: data.id_token, ctry: data.ctry } });

    const permissionsData = yield call(PermissionsService.fetchUserPermissions, {
      access_token: payload.access_token,
      id_token: data.id_token,
    });

    yield put({ type: USER_PERMISSIONS, payload: permissionsData });
    yield put({
      type: "ENVS_REQUEST",
      payload: { locale: data.ctry.toLowerCase(), access_token: payload.access_token, id_token: data.id_token },
    });
    yield put({
      type: "FETCH_TENANT_BASIC_INFO_REQUEST",
      payload: { locale: data.ctry.toLowerCase(), access_token: payload.access_token, id_token: data.id_token },
    });
  } catch (error) {
    yield put({ type: USER_PERMISSIONS_ERROR, error });
  }
}

function* updateTenantTokenWatcher() {
  yield takeLatest(UPDATE_TENANT_TOKEN, updateTenantTokenWorker);
}

const tenantSaga = [
  tenantSchemaWatcher(),
  saveTenantWatcher(),
  fetchTenantsWatcher(),
  tenantValidationsWatcher(),
  updateTenantWatcher(),
  deleteTenantWatcher(),
  fetchTenantBasicInfoWatcher(),
  updateTenantTokenWatcher(),
];

export default tenantSaga;

/* eslint-disable camelcase */
import axios from "axios";

class CompareVersionsService {
  fetchVersions = async ({ app, id_token, access_token }) => {
    const params = {
      sourceAppId: app.sourceAppId,
    };
    try {
      const { data, status } = await axios({
        method: "get",
        url: "/api/appbuilder/compareVersions",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return { data, status };
    } catch (err) {
      console.log("Error in config fetch versions service:", err);
      return err;
    }
  };

  fetchVersionsDetails = async ({
    versionFrom,
    versionTo,
    id_token,
    access_token,
  }) => {
    const params = {
      versionFrom,
      versionTo,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/compareVersions/details",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });

      return data;
    } catch (err) {
      console.log("Error in config fetch versions details service:", err);
      return err;
    }
  };
}

const inst = new CompareVersionsService();
export default inst;

/* eslint-disable camelcase */
import axios from "axios";
import { handleErrorResponse } from "./ErrorHandling";

class NexusWidgetsService {
  fetchNexusWidgets = async ({ access_token, searchText, id_token }) => {
    const params = {
      searchText,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/repository/widgets",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: "",
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget list service:", err);
      return err;
    }
  };

  fetchUnmanagedWidgets = async ({ envID, access_token, id_token }) => {
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/repository/widgets/unmanaged",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in unmanaged widgets service:", err);
      return err;
    }
  };

  fetchNexusWidgetsCategories = async ({ access_token, id_token }) => {
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/widget/categories",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in widgets categories service:", err);
      return err;
    }
  };

  fetchNexusWidgetsVersions = async ({ access_token, id_token, name, envId }) => {
    try {
      const data = await axios({
        method: "get",
        url: `/api/appbuilder/widget/translation/versions/${name}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envId,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in widgets versions service:", err);
      return err;
    }
  };

  fetchNexusWidgetDetails = async ({ access_token, id_token, id }) => {
    try {
      const data = await axios({
        method: "get",
        url: `/api/appbuilder/widget/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in widgets details service:", err);
      return err;
    }
  };

  // eslint-disable-next-line consistent-return
  updateNexusWidget = async ({ localeId, widgetName, widgetVersion }) => {
    try {
      const data = await axios({
        method: "post",
        url: "/api/appbuilder/repository/widgets",
        headers: {
          "content-type": "application/json",
        },
        data: {
          localMarket: localeId,
          name: widgetName,
          version: widgetVersion,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in updating widget:", err);
      handleErrorResponse(err, "Widget could not be updated.");
    }
  };
}

const inst = new NexusWidgetsService();
export default inst;

const GET_DEVICES_REQUEST = "GET_DEVICES_REQUEST";
const GET_DEVICES_REQUEST_SUCCESS = "GET_DEVICES_REQUEST_SUCCESS";
const GET_DEVICES_REQUEST_FAIL = "GET_DEVICES_REQUEST_FAIL";
const ADD_DEVICE_REQUEST = "ADD_DEVICE_REQUEST";
const ADD_DEVICE_REQUEST_SUCCESS = "ADD_DEVICE_REQUEST_SUCCESS";
const ADD_DEVICE_REQUEST_FAIL = "ADD_DEVICE_REQUEST_FAIL";
const IMPORT_DEVICES_REQUEST = "IMPORT_DEVICES_REQUEST";
const IMPORT_DEVICES_REQUEST_SUCCESS = "IMPORT_DEVICES_REQUEST_SUCCESS";
const IMPORT_DEVICES_REQUEST_FAIL = "IMPORT_DEVICES_REQUEST_FAIL";
const UPDATE_DEVICE_REQUEST = "UPDATE_DEVICE_REQUEST";
const UPDATE_DEVICE_REQUEST_SUCCESS = "UPDATE_DEVICE_REQUEST_SUCCESS";
const UPDATE_DEVICE_REQUEST_FAIL = "UPDATE_DEVICE_REQUEST_FAIL";
const DELETE_DEVICE_REQUEST = "DELETE_DEVICE_REQUEST";
const DELETE_DEVICE_REQUEST_SUCCESS = "DELETE_DEVICE_REQUEST_SUCCESS";
const DELETE_DEVICE_REQUEST_FAIL = "DELETE_DEVICE_REQUEST_FAIL";
const BULK_DELETE_DEVICES_REQUEST = "BULK_DELETE_DEVICES_REQUEST";
const BULK_DELETE_DEVICES_REQUEST_SUCCESS = "BULK_DELETE_DEVICES_REQUEST_SUCCESS";
const BULK_DELETE_DEVICES_REQUEST_FAIL = "BULK_DELETE_DEVICES_REQUEST_FAIL";
const EXPORT_DEVICES_REQUEST = "EXPORT_DEVICES_REQUEST";
const EXPORT_DEVICES_REQUEST_SUCCESS = "EXPORT_DEVICES_REQUEST_SUCCESS";
const EXPORT_DEVICES_REQUEST_FAIL = "EXPORT_DEVICES_REQUEST_FAIL";
const REMOVE_ERROR = "REMOVE_ERROR";

const fetchDevices = payload => ({
  type: GET_DEVICES_REQUEST,
  payload,
});

const fetchDevicesSuccess = payload => ({
  type: GET_DEVICES_REQUEST_SUCCESS,
  payload,
});

const fetchDevicesFail = error => ({
  type: GET_DEVICES_REQUEST_FAIL,
  error,
});

const addDevice = payload => ({
  type: ADD_DEVICE_REQUEST,
  payload,
});

const addDeviceSuccess = payload => ({
  type: ADD_DEVICE_REQUEST_SUCCESS,
  payload,
});

const addDeviceFail = error => ({
  type: ADD_DEVICE_REQUEST_FAIL,
  error,
});

const importDevices = payload => ({
  type: IMPORT_DEVICES_REQUEST,
  payload,
});

const importDevicesSuccess = payload => ({
  type: IMPORT_DEVICES_REQUEST_SUCCESS,
  payload,
});

const importDevicesFail = error => ({
  type: IMPORT_DEVICES_REQUEST_FAIL,
  error,
});

const updateDevice = payload => ({
  type: UPDATE_DEVICE_REQUEST,
  payload,
});

const updateDeviceSuccess = payload => ({
  type: UPDATE_DEVICE_REQUEST_SUCCESS,
  payload,
});

const updateDeviceFail = error => ({
  type: UPDATE_DEVICE_REQUEST_FAIL,
  error,
});

const deleteDevice = payload => ({
  type: DELETE_DEVICE_REQUEST,
  payload,
});

const deleteDeviceSuccess = payload => ({
  type: DELETE_DEVICE_REQUEST_SUCCESS,
  payload,
});

const deleteDeviceFail = error => ({
  type: DELETE_DEVICE_REQUEST_FAIL,
  error,
});

const bulkDeleteDevices = payload => ({
  type: BULK_DELETE_DEVICES_REQUEST,
  payload,
});

const bulkDeleteDevicesSuccess = payload => ({
  type: BULK_DELETE_DEVICES_REQUEST_SUCCESS,
  payload,
});

const bulkDeleteDevicesFail = error => ({
  type: BULK_DELETE_DEVICES_REQUEST_FAIL,
  error,
});

const exportDevices = payload => ({
  type: EXPORT_DEVICES_REQUEST,
  payload,
});

const exportDevicesSuccess = payload => ({
  type: EXPORT_DEVICES_REQUEST_SUCCESS,
  payload,
});

const exportDevicesFail = error => ({
  type: EXPORT_DEVICES_REQUEST_FAIL,
  error,
});

const removeError = () => ({
  type: REMOVE_ERROR,
});

export {
  GET_DEVICES_REQUEST,
  GET_DEVICES_REQUEST_FAIL,
  GET_DEVICES_REQUEST_SUCCESS,
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_REQUEST_FAIL,
  ADD_DEVICE_REQUEST_SUCCESS,
  IMPORT_DEVICES_REQUEST,
  IMPORT_DEVICES_REQUEST_FAIL,
  IMPORT_DEVICES_REQUEST_SUCCESS,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_REQUEST_SUCCESS,
  UPDATE_DEVICE_REQUEST_FAIL,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_REQUEST_FAIL,
  DELETE_DEVICE_REQUEST_SUCCESS,
  BULK_DELETE_DEVICES_REQUEST,
  BULK_DELETE_DEVICES_REQUEST_FAIL,
  BULK_DELETE_DEVICES_REQUEST_SUCCESS,
  EXPORT_DEVICES_REQUEST,
  EXPORT_DEVICES_REQUEST_FAIL,
  EXPORT_DEVICES_REQUEST_SUCCESS,
  REMOVE_ERROR,
  fetchDevices,
  fetchDevicesSuccess,
  fetchDevicesFail,
  addDevice,
  addDeviceSuccess,
  addDeviceFail,
  importDevices,
  importDevicesSuccess,
  importDevicesFail,
  updateDevice,
  updateDeviceSuccess,
  updateDeviceFail,
  deleteDevice,
  deleteDeviceSuccess,
  deleteDeviceFail,
  bulkDeleteDevices,
  bulkDeleteDevicesSuccess,
  bulkDeleteDevicesFail,
  exportDevices,
  exportDevicesSuccess,
  exportDevicesFail,
  removeError,
};

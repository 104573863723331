import {
  GET_CAMPAIGNS_REQUEST_SUCCESS,
  ADD_CAMPAIGN_REQUEST,
  ADD_CAMPAIGN_REQUEST_SUCCESS,
  ADD_CAMPAIGN_REQUEST_FAIL,
  UPDATE_CAMPAIGNS_REQUEST_SUCCESS,
  UPDATE_CAMPAIGNS_REQUEST,
  UPDATE_CAMPAIGNS_REQUEST_FAIL,
  DELETE_CAMPAIGNS_REQUEST_SUCCESS,
  DELETE_CAMPAIGNS_REQUEST_FAIL,
  DELETE_CAMPAIGNS_REQUEST,
  BULK_DELETE_CAMPAIGNS_REQUEST,
  BULK_DELETE_CAMPAIGNS_REQUEST_SUCCESS,
  BULK_DELETE_CAMPAIGNS_REQUEST_FAIL,
  REMOVE_ERROR,
} from "actions/campaignActions";

const initialState = {
  loading: false,
  campaigns: [],
  campaignItems: 1,
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload, } = action;
  switch (type) {
    case GET_CAMPAIGNS_REQUEST_SUCCESS: {
      return {
        ...state,
        campaigns: payload.campaigns,
        campaignItems: payload.count,
      };
    }
    case ADD_CAMPAIGN_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_CAMPAIGN_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case ADD_CAMPAIGN_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case UPDATE_CAMPAIGNS_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_CAMPAIGNS_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case UPDATE_CAMPAIGNS_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case DELETE_CAMPAIGNS_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_CAMPAIGNS_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case DELETE_CAMPAIGNS_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case BULK_DELETE_CAMPAIGNS_REQUEST: {
      return { ...state, loading: true };
    }
    case BULK_DELETE_CAMPAIGNS_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case BULK_DELETE_CAMPAIGNS_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case REMOVE_ERROR: {
      return { ...state, error: '' };
    }
    default:
      return state;
  }
};

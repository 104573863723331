import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { addGallery, updateGallery } from "actions/galleryActions";
import { getGalleryMedia, deleteMediaGallery } from "actions/galleryMediaActions";
import { Box, Card, CardContent, Container, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VfDialogFull from "../../vf/VfDialogFull";
import theme from "AppTheme";
import CardMedia from "../cards/CardMedia";
import CardMediaAdd from "../cards/CardMediaAdd";
import VfDialog from "../../vf/VfDialog";
import SearchBar from "../../searchbar/SearchBar";
import CardMediaUpload from "../cards/CardMediaUpload";
import Loader from "../../loader/Loader";

const useStyles = makeStyles({
  cardContent: {
    padding: theme.spacing(3),
  },
});

const AppBar = () => (
  <Grid container alignItems="center" spacing={6} justify="space-between">
    <Grid item xs="auto">
      <Typography variant="body1" component="h5">
        Add gallery
      </Typography>
    </Grid>
  </Grid>
);

const CardDetails = ({name, setName}) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="body1">Enter gallery’s name</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
        label="Name"
        variant="outlined"
        color="primary"
        fullWidth
      />
    </Grid>
  </Grid>
);

const DialogGallery = ({ open, setOpen, gallery }) => {
  const styles = useStyles();
  const [dialogAdd, setDialogAdd] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { loading, media } = useSelector(state => state.galleryMediaReducer, shallowEqual);
  const [selectedList, setSelectedList] = useState([]);
  const { access_token, id_token } = auth;
  const [mediaList, setMediaList] = useState([]);

  useEffect(() => {
    setName(gallery?.title || "");
    setMediaList(gallery?.media || []);
  }, [open]);

  useEffect(() => {
    if (!loading) {
      dispatch(getGalleryMedia({ access_token, id_token, searchText }));
    }
  }, [loading]);

  return (
    <VfDialogFull
      title="Dialog"
      openDialog={open}
      buttonCloseAction={() => {
        setName("");
        setMediaList([]);
        setOpen(false);
      }}
      buttonConfirmAction={() => setOpen(false)}
      buttonConfirmText="Save"
      saveAction={() => {
        if (gallery._id) {
          dispatch(
            updateGallery({
              access_token,
              id_token,
              gallery: { title: name, media: mediaList, _id: gallery._id },
            })
          );
        } else {
          dispatch(
            addGallery({
              access_token,
              id_token,
              gallery: { title: name, media: mediaList },
            })
          );
        }
        setName("");
        setMediaList([]);
        setOpen(false);
      }}
      /* eslint-disable */
      appBar={<AppBar />}
      /* eslint-enable */
    >
      <Box py={6}>
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardContent className={styles.cardContent}>
                  <CardDetails name={name} setName={setName} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {media &&
                  media
                    .filter(x => mediaList.find(y => x._id === y._id))
                    .map(item => (
                      <Grid item xs={6} sm={3} key={item._id}>
                        <CardMedia
                          item={item}
                          cardAction={() => {}}
                          eraseMedia={() => setMediaList(mediaList.filter(x => x._id !== item._id))}
                        />
                      </Grid>
                    ))}

                <Grid item xs={6} sm={3}>
                  <CardMediaAdd addAction={() => setDialogAdd(true)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <VfDialog
        title="Select media"
        openDialog={dialogAdd}
        buttonCloseAction={() => setDialogAdd(false)}
        buttonConfirmText="Select"
        buttonConfirmAction={() => {
          setMediaList(mediaList.concat(selectedList));
          setSelectedList([]);
          setDialogAdd(false);
        }}
      >
        {!loading ? (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box py={0.25} className="MuiBox-searchOverlay" position="relative">
                <SearchBar allwaysOpen setSearchText={setSearchText} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <CardMediaUpload />
                </Grid>
                {media &&
                  media
                    .filter(x => !mediaList.find(y => x._id === y._id))
                    .map(item => (
                      <Grid item xs={6} sm={4} key={item._id}>
                        <CardMedia
                          eraseMedia={() => {
                            dispatch(
                              deleteMediaGallery({
                                keys: [item.key],
                                access_token,
                                id_token,
                              })
                            );
                          }}
                          item={item}
                          isSelected={selectedList.find(x => x._id === item._id)}
                          cardAction={() => {
                            if (selectedList.find(x => x._id === item._id)) {
                              setSelectedList(selectedList.filter(x => x._id !== item._id));
                            } else {
                              setSelectedList(selectedList.concat([item]));
                            }
                          }}
                        />
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Loader size={theme.spacing(12)} />
          </Grid>
        )}
      </VfDialog>
    </VfDialogFull>
  );
};

export default DialogGallery;

/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Box,
  Card,
  Container,
  CardContent,
  CardActionArea,
  Grid,
  SvgIcon,
  Typography,
  IconButton,
} from "@material-ui/core";

import theme from "AppTheme";
import CardWidgetPlaceholder from "./CardWidgetPlaceholder";
import GridColTemplateBuilder from "./GridColTemplateBuilder";
import { ReactComponent as CloseIcon } from "../../assets/images/ic-close.svg";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  content: {
    minHeight: theme.spacing(19),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  closeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[100],
    borderRadius: 6,
    opacity: 0,
    pointerEvents: "none",
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  overlayShow: {
    opacity: 1,
    pointerEvents: "all",
  },
});

const CardGridCreator = () => {
  const classes = useStyles();

  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Card
      elevation={0}
      className={classNames(classes.root, {
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-dashed": true,
        "MuiCard-rounded": true,
      })}
    >
      <CardActionArea onClick={() => setShowOverlay(true)}>
        <CardContent className={classes.content}>
          <Typography variant="body1" align="center">
            Add section
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardContent
        className={classNames(classes.overlay, {
          [classes.overlayShow]: showOverlay,
        })}
      >
        <Container className={classes.container} maxWidth="md">
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} md={2} />
            <Grid item xs={6} md={2}>
              <CardWidgetPlaceholder
                cols={1}
                handleShowOverlay={setShowOverlay}
                size="sm"
              >
                <GridColTemplateBuilder columns={1} size="md" />
              </CardWidgetPlaceholder>
            </Grid>
            <Grid item xs={6} md={2}>
              <CardWidgetPlaceholder
                cols={2}
                handleShowOverlay={setShowOverlay}
                size="sm"
              >
                <GridColTemplateBuilder columns={2} size="md" />
              </CardWidgetPlaceholder>
            </Grid>
            <Grid item xs={6} md={2}>
              <CardWidgetPlaceholder
                cols={3}
                handleShowOverlay={setShowOverlay}
                size="sm"
              >
                <GridColTemplateBuilder columns={3} size="md" />
              </CardWidgetPlaceholder>
            </Grid>
            <Grid item xs={6} md={2}>
              <CardWidgetPlaceholder
                cols={4}
                handleShowOverlay={setShowOverlay}
                size="sm"
              >
                <GridColTemplateBuilder columns={4} size="md" />
              </CardWidgetPlaceholder>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box className={classes.closeBox}>
                <IconButton onClick={() => setShowOverlay(false)}>
                  <VfSvgIcon icon="close" viewBox={24} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
};

export default CardGridCreator;

/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
  Snackbar,
  Divider,
} from "@material-ui/core";
import {
  showCreatePage,
  deletePage,
  setShowAddPage as setShowAddPageAction,
  setCurrentPage as setCurrentPageAction,
  setShowAddTemplate,
  setApplicationHomepage,
  setApp,
} from "actions/applicationsActions";
import VfAlert from "../vf/VfAlert";
import { ReactComponent as PlusIcon } from "../../assets/images/ic-plus.svg";
import CardPageListItem from "./CardPageListItem";
import theme from "../../AppTheme";
import CardMenuListItem from "./CardMenuListItem";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {},
  box: {
    minHeight: theme.spacing(7),
    display: "flex",
    alignItems: "center",
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiCardActions-root": {
      padding: 0,
    },
  },
});

const AppsPages = ({
  dispatchShowCreatePage,
  dispatchshowSideBar,
  setSidebarItem,
  dispatchDeletePage,
  dispatchShowAddTemplate,
  setCurrentPage,
  setShowAddPage,
  pages,
  auth,
  setAppHomepage,
  setApplication,
  application,
  setAppSection,
}) => {
  const classes = useStyles();
  const { currentPage, appInfo } = application;

  const [snackBar, setSnackBar] = useState({
    show: false,
    message: "",
    severity: 0,
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={3} px={3} className={classes.box}>
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className="bold" variant="h5">
                  Pages
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    dispatchShowCreatePage(true);
                    setShowAddPage(false);
                    dispatchShowAddTemplate(false);
                    setCurrentPage({});
                  }}
                >
                  <VfSvgIcon icon="plus" viewBox={24} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box py={3} px={3}>
            <Grid container spacing={3}>
              {pages.map(page => {
                return (
                  <Grid item xs={12} key={page._id}>
                    <CardPageListItem
                      setAppHomepage={setAppHomepage}
                      deletePage={dispatchDeletePage}
                      auth={auth}
                      page={page}
                      dispatchShowAddTemplate={dispatchShowAddTemplate}
                      setCurrentPage={setCurrentPage}
                      dispatchShowCreatePage={dispatchShowCreatePage}
                      setShowAddPage={setShowAddPage}
                      setSidebarItem={setSidebarItem}
                      dispatchshowSideBar={dispatchshowSideBar}
                      currentPage={currentPage}
                      showSnackBar={setSnackBar}
                      setApplication={setApplication}
                      appInfo={appInfo}
                      setAppSection={setAppSection}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackBar.show}
          onClose={() => setSnackBar({ ...snackBar, show: false })}
        >
          <VfAlert severity={snackBar.severity} message={snackBar.message} />
        </Snackbar>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    pages: state.applications.current.pages,
    application: state.applications.current,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchShowCreatePage: payload => dispatch(showCreatePage(payload)),
  dispatchDeletePage: payload => dispatch(deletePage(payload)),
  dispatchShowAddTemplate: payload => dispatch(setShowAddTemplate(payload)),
  setShowAddPage: payload => dispatch(setShowAddPageAction(payload)),
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  setAppHomepage: payload => dispatch(setApplicationHomepage(payload)),
  setApplication: payload => dispatch(setApp(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppsPages);

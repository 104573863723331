/* eslint-disable camelcase */
import axios from "axios";

class EnvsService {
  fetchEnvsByLocale = async ({ locale, access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/environments/${locale}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch envs service:", err);
      return err;
    }
  };

  fetchURLsAndResourcesPerEnv = async ({
    localeID,
    environmentID,
    access_token,
    id_token,
  }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/environments/${localeID}/${environmentID}/urls-resources`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch envs service:", err);
      return err;
    }
  };
}

const inst = new EnvsService();
export default inst;

import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import ConfigurationForm from "./ConfigurationForm";
import EditorFormJson from "./EditorFormJson";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Box>
  );
};

const EditorFormTabs = ({ form, isTenant, currentTenant, isGlobal }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                spacing={3}
              >
                <Grid item>
                  <Typography className="bold" variant="h5" component="h1">
                    {form &&
                      form.selectedTreeItem &&
                      form.selectedTreeItem.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className="MuiCard-rounded MuiCard-white MuiCard-shadow">
            {!isTenant && (
              <>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="fullWidth"
                >
                  <Tab label="Form" />
                  <Tab label="Json" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <ConfigurationForm isGlobal={isGlobal}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <EditorFormJson />
                </TabPanel>
              </>
            )}
            {isTenant && (
              <TabPanel value={value} index={0}>
                <ConfigurationForm
                  isGlobal={isGlobal}
                  isTenant={isTenant}
                  currentTenant={currentTenant}
                />
              </TabPanel>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditorFormTabs;

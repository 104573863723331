/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_PASSCODES_REQUEST,
  fetchPasscodesSuccess,
  fetchPasscodesFail,
} from "actions/passcodeActions";
import PasscodeService from "services/PasscodeService";

function* fetchPasscodesWorker({ payload }) {
  try {
    const data = yield call(PasscodeService.fetch, payload);
    yield put(fetchPasscodesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(fetchPasscodesFail(error));
  }
}

function* fetchPasscodesWatcher() {
  yield takeLatest(GET_PASSCODES_REQUEST, fetchPasscodesWorker);
}

const passcodeSaga = [
  fetchPasscodesWatcher(),
];

export default passcodeSaga;

/* eslint-disable camelcase */
import React from "react";
import { Card, CardContent, CardActionArea } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";

const CardUpload = ({
  postMedia,
  access_token,
  id_token,
  filters,
  searchText,
}) => {
  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-dropzone": true,
        "MuiCard-media": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-rounded": true,
      })}
    >
      <Dropzone
        multiple
        onDrop={acceptedFiles => {
          const data = new FormData();
          acceptedFiles.forEach(m => {
            data.append("files", m);
          });
          postMedia({
            access_token,
            id_token,
            payload: data,
            filters,
            searchText,
          });
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <CardActionArea {...getRootProps()}>
            <CardContent>
              <input {...getInputProps()} />
              <VfSvgIcon
                className="MuiSvgIcon-fontSizeXLarge"
                icon="upload"
                viewBox={56}
                fontSize="large"
              />
              <Typography variant="body2">Click or drop files here</Typography>
            </CardContent>
          </CardActionArea>
        )}
      </Dropzone>
    </Card>
  );
};

export default CardUpload;

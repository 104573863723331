/* eslint-disable no-useless-computed-key */
import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import VodafoneLight from "./assets/fonts/vodafone-light.woff";
import VodafoneRegular from "./assets/fonts/vodafone-regular.woff";
import VodafoneBold from "./assets/fonts/vodafone-bold.woff";
import VodafoneExtraBold from "./assets/fonts/vodafone-extra-bold.woff";

const vodafoneLight = {
  fontFamily: "Vodafone",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `url(${VodafoneLight}) format('woff')`,
};
const vodafoneRegular = {
  fontFamily: "Vodafone",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `url(${VodafoneRegular}) format('woff')`,
};
const vodafoneBold = {
  fontFamily: "Vodafone",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `url(${VodafoneBold}) format('woff')`,
};
const vodafoneExtraBold = {
  fontFamily: "Vodafone",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `url(${VodafoneExtraBold}) format('woff')`,
};

const palette = {
  common: {
    black: "#000",
    white: "#fff",
  },
  background: {
    paper: "#fff",
    default: "#fafafa",
  },
  primary: {
    light: "#00B0CA",
    main: "#007C92",
    dark: "#007C92",
    contrastText: "#fff",
  },
  secondary: {
    light: "#ff4081",
    main: "#E60000",
    dark: "#990000",
    contrastText: "#fff",
  },
  error: {
    light: "#e57373",
    main: "#e60000",
    dark: "#d32f2f",
    contrastText: "#fff",
  },
  success: {
    light: "#81c784",
    main: "#009900",
    dark: "#388e3c",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  info: {
    light: "#00B0CA",
    main: "#007C92",
    dark: "#1976d2",
    contrastText: "#fff",
  },
  warning: {
    light: "#ffb74d",
    main: "#eb9700",
    dark: "#f57c00",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  grey: {
    50: "#fafafa",
    100: "#f4f4f4",
    200: "#eeeeee",
    300: "#ebebeb",
    400: "#cccccc",
    500: "#999999",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
    A100: "#d5d5d5",
    A200: "#aaaaaa",
    A400: "#333333",
    A700: "#666666",
  },
  text: {
    primary: "#4a4d4e",
    secondary: "#666666",
    disabled: "rgba(51, 51, 51, 0.38)",
    hint: "rgba(51, 51, 51, 0.38)",
  },
  vodafone: {
    aqua: "#00B0CA",
    green: "#009900",
    grey: "#4A4D4E",
    midGrey: "#999999",
    midNight: "#222222",
    maroon: "#990000",
    orange: "#EB9700",
    yellow: "#FECB00",
    violet: "#9C2AA0",
  },
};
const theme = createMuiTheme({
  palette,
  shape: {
    borderRadius: 6,
  },
  zIndex: {
    mobileStepper: 2000,
    speedDial: 2050,
    appBar: 2100,
    drawer: 2200,
    modal: 2300,
    snackbar: 2400,
    tooltip: 2500,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          vodafoneLight,
          vodafoneRegular,
          vodafoneBold,
          vodafoneExtraBold,
        ],
      },
    },
    MuiCard: {
      root: {
        "&.MuiCard-selection": {
          MuiCardContent: {
            padding: 0,
          },
        },
        "&.MuiCard-white": {},
        "&.MuiCard-rounded": {
          "&.MuiCard-rounded-bottom": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
        "&.MuiCard-details": {},
        "&.MuiCard-widget": {},
        "&.MuiCard-touchpoint": {},
        "&.MuiCard-environment": {},
        "&.MuiCard-application": {},
        "&.MuiCard-login": {},
        "&.MuiCard-outlined": {
          borderWidth: "1px",
          borderType: "solid",
          borderColor: "#999999",
          "&:focus": {
            boxShadow: "0 2px 8px rgba(0,0,0,.16)",
          },
          "&:hover": {
            boxShadow: "0 2px 8px rgba(0,0,0,.16)",
          },
        },
        "&.MuiCard-grid": {
          height: "100%",
          "&.MuiCardMedia-root": {
            textAlign: "center",
          },
        },
      },
    },
    MuiCardMedia: {},
    MuiCardContent: {},
    MuiIconButton: {},
    MuiButton: {},
    MuiButtonGroup: {},
    MuiButtonBase: {},
    MuiInputBase: {},
    MuiInputLabel: {},
    MuiOutlinedInput: {},
    MuiPaper: {},
    MuiDialog: {},
    MuiDialogTitle: {},
    MuiDialogContent: {},
    MuiDialogActions: {},
    MuiTab: {},
    MuiTabs: {},
    MuiDivider: {},
    MuiChip: {},
    MuiListItem: {},
    MuiListItemIcon: {},
    MuiSvgIcon: {},
    MuiStepper: {},
    MuiStepIcon: {},
    MuiStepLabel: {},
    MuiStepConnector: {},
    MuiTypography: {},
    MuiCheckbox: {},
    MuiExpansionPanelSummary: {},
    MuiCardActionArea: {},
    MuiPopover: {},
    MuiFormLabel: {},
    MuiFilledInput: {},
    MuiAlert: {},
    MuiTouchRipple: {},
    MuiSwitch: {},
    MuiTreeItem: {},
    MuiFab: {},
    MuiBadge: {},
    MuiAutocomplete: {},
  },
});

const MuiCardMediaContentHeight = theme.spacing(4);

theme.typography.h1 = {
  fontSize: "63px",
  lineHeight: "72px",
  fontWeight: "300",
  [theme.breakpoints.down("md")]: {
    fontSize: "56px",
    lineHeight: "62px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
};
theme.typography.h2 = {
  fontSize: "56px",
  lineHeight: "62px",
  fontWeight: "300",
  [theme.breakpoints.down("md")]: {
    fontSize: "40px",
    lineHeight: "48px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "28px",
    lineHeight: "36px",
  },
};
theme.typography.h3 = {
  fontSize: "40px",
  lineHeight: "48px",
  fontWeight: "300",
  [theme.breakpoints.down("md")]: {
    fontSize: "34px",
    lineHeight: "40px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "24px",
    lineHeight: "30px",
  },
};
theme.typography.h4 = {
  fontSize: "28px",
  lineHeight: "34px",
  fontWeight: "300",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    lineHeight: "30px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
};
theme.typography.h5 = {
  fontSize: "20px",
  lineHeight: "28px",
  fontWeight: "300",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "18px",
    lineHeight: "24px",
  },
  ["&.bold"]: {
    fontWeight: 700,
  },
};
theme.typography.body1 = {
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: "400",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "22px",
  },
};
theme.typography.body2 = {
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: "400",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "22px",
  },
};
theme.overrides.MuiTypography.root = {
  fontFamily: ["Vodafone", "Arial", "sans-serif"].join(","),
  "&.MuiTypography-bold": {
    fontWeight: "bold",
  },
  "&.MuiTypography-development": {
    color: palette.vodafone.midGrey,
  },
  "&.MuiTypography-testing": {
    color: palette.vodafone.grey,
  },
  "&.MuiTypography-production": {
    color: palette.vodafone.aqua,
  },
  "&.MuiTypography-stage": {
    color: palette.vodafone.violet,
  },
};
theme.overrides.MuiTypography.gutterBottom = {
  marginBottom: theme.spacing(1),
};
theme.overrides.MuiExpansionPanelSummary.root = {
  ["&:hover"]: {
    ["& .MuiTypography-root"]: {
      color: theme.palette.secondary.main,
    },
    ["& .MuiExpansionPanelSummary-expandIcon"]: {
      ["& .MuiSvgIcon-root"]: {
        ["& path"]: {
          fill: theme.palette.secondary.main,
        },
        ["& polygon"]: {
          fill: theme.palette.secondary.main,
        },
        ["& rect"]: {
          fill: theme.palette.secondary.main,
        },
        ["& circle"]: {
          fill: theme.palette.secondary.main,
        },
      },
    },
  },
  ["&.Mui-expanded"]: {
    minHeight: theme.spacing(6),
    ["& .MuiExpansionPanelSummary-expandIcon"]: {
      ["& .MuiSvgIcon-root"]: {
        ["& path"]: {
          fill: theme.palette.secondary.main,
        },
        ["& rect"]: {
          fill: theme.palette.secondary.main,
        },
        ["& polygon"]: {
          fill: theme.palette.secondary.main,
        },
        ["& circle"]: {
          fill: theme.palette.secondary.main,
        },
      },
    },
  },
};
theme.overrides.MuiExpansionPanelSummary.content = {
  ["&.Mui-expanded"]: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    color: theme.palette.secondary.main,
    MuiCardActionArea: {},
  },
};
theme.overrides.MuiCardActionArea.root = {
  ["&.Mui-disabled"]: {
    opacity: 0.4,
  },
};
theme.overrides.MuiCheckbox.colorPrimary = {
  color: theme.palette.grey[500],
  "& .MuiSvgIcon-root": {
    "& path": {
      fill: theme.palette.grey[500],
    },
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  },
};
theme.overrides.MuiStepper.root = {
  padding: undefined,
};
theme.overrides.MuiStepConnector.vertical = {
  padding: undefined,
  marginLeft: theme.spacing(1.5),
  marginTop: -theme.spacing(1),
  marginBottom: -theme.spacing(1),
};
theme.overrides.MuiStepConnector.lineVertical = {
  minHeight: theme.spacing(4),
};
theme.overrides.MuiStepConnector.line = {
  borderColor: theme.palette.grey[300],
};
theme.overrides.MuiStepLabel.label = {
  ["&.MuiStepLabel-active"]: {
    fontWeight: 700,
  },
};
theme.overrides.MuiStepLabel.iconContainer = {
  paddingRight: undefined,
  marginRight: theme.spacing(2),
  position: "relative",
  ["& .MuiStepIcon-root"]: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "transparent",
    backgroundColor: "transparent",
    borderRadius: "100%",
    margin: 1,
    "& path": {
      fill: theme.palette.secondary.main,
    },
    ["&.MuiStepIcon-active"]: {
      borderColor: theme.palette.secondary.main,
      padding: 1,
      marginLeft: -1,
      "& path": {
        fill: theme.palette.secondary.main,
      },
      ["& text"]: {
        fill: "transparent",
      },
    },
    ["& text"]: {
      fill: "transparent",
    },
  },
};
theme.overrides.MuiStepIcon.root = {
  color: theme.palette.grey[300],
  width: 14,
  height: 14,
  ["&.MuiStepIcon-active"]: {
    color: theme.palette.secondary.main,
    position: "relative",
  },
  ["&.MuiStepIcon-completed"]: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "100%",
  },
};
theme.overrides.MuiInputLabel.root = {
  ["&.Mui-disabled"]: {
    color: theme.palette.grey[500],
  },
};
theme.overrides.MuiInputLabel.filled = {
  transform: "translate(16px, 20px) scale(1)",
};
theme.overrides.MuiInputLabel.outlined = {
  "&.MuiInputLabel-marginDense": {
    transform: "translate(16px, 9px) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
};
theme.overrides.MuiInputLabel.marginDense = {
  fontSize: 14,
};
theme.overrides.MuiSvgIcon.root = {
  display: "block",
  "&.MuiChip-deleteIcon": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
  "&.MuiSvgIcon-white": {
    "& path": {
      fill: theme.palette.common.white,
    },
    "& rect": {
      fill: theme.palette.common.white,
    },
    "& circle": {
      fill: theme.palette.common.white,
    },
    "& polygon": {
      fill: theme.palette.common.white,
    },
  },
  "&.MuiSvgIcon-colorAction": {
    color: theme.palette.text.primary,
    "& path": {
      fill: theme.palette.text.primary,
    },
    "& rect": {
      fill: theme.palette.text.primary,
    },
    "& circle": {
      fill: theme.palette.text.primary,
    },
    "& polygon": {
      fill: theme.palette.text.primary,
    },
  },
  "&.MuiSvgIcon-red": {
    "& path": {
      fill: theme.palette.secondary.main,
    },
    "& rect": {
      fill: theme.palette.secondary.main,
    },
    "& circle": {
      fill: theme.palette.secondary.main,
    },
    "& polygon": {
      fill: theme.palette.secondary.main,
    },
    "&.MuiSvgIcon-selected": {
      "& circle": {
        fill: theme.palette.grey[300],
      },
    },
  },
  "&.MuiChip-leftIcon": {
    marginLeft: theme.spacing(2),
    marginRight: -theme.spacing(1),
  },
  "&.MuiSvgIcon-fontSizeXSmall": {
    fontSize: theme.spacing(2),
  },
  "&.MuiSvgIcon-fontSizeXLarge": {
    fontSize: theme.spacing(7),
  },
};
theme.overrides.MuiSvgIcon.colorSecondary = {
  ["& path"]: {
    fill: theme.palette.secondary.main,
  },
};
theme.overrides.MuiFormLabel.root = {
  fontFamily: ["Vodafone", "Arial", "sans-serif"].join(","),
  ["&.Mui-focused"]: {
    color: theme.palette.primary.dark,
  },
  ["&.Mui-disabled"]: {
    color: theme.palette.common.black,
  },
};
theme.overrides.MuiListItem.root = {
  "&.MuiMenuItem-root": {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    "&:focus": {
      outline: "none",
    },
  },
};
theme.overrides.MuiListItem.button = {
  "&.MuiListItem-withListIcon": {
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& path": {
          fill: theme.palette.common.white,
        },
        "& rect": {
          fill: theme.palette.common.white,
        },
        "& circle": {
          fill: theme.palette.common.white,
        },
        "& polygon": {
          fill: theme.palette.common.white,
        },
      },
    },
  },
  "&.MuiMenuItem-root": {
    fontSize: theme.typography.body2.fontSize,
    minHeight: theme.spacing(6),
  },
  "&.MuiListItem-development": {
    "&:hover": {
      backgroundColor: palette.vodafone.midGrey,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
    },
  },
  "&.MuiListItem-testing": {
    "&:hover": {
      backgroundColor: palette.vodafone.grey,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
    },
  },
  "&.MuiListItem-stage": {
    "&:hover": {
      backgroundColor: palette.vodafone.violet,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
    },
  },
  "&.MuiListItem-staging": {
    "&:hover": {
      backgroundColor: palette.vodafone.violet,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
    },
  },
  "&.MuiListItem-production": {
    "&:hover": {
      backgroundColor: palette.vodafone.aqua,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
    },
  },
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
  },
};
theme.overrides.MuiListItemIcon.root = {
  minWidth: theme.spacing(3),
  marginRight: theme.spacing(1),
};
theme.overrides.MuiDivider.root = {
  backgroundColor: theme.palette.grey[100],
};
theme.overrides.MuiChip.root = {
  backgroundColor: theme.palette.grey[100],
  height: theme.spacing(4.5),
  "& .MuiChip-label": {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  "&.MuiChip-round": {
    width: theme.spacing(4),
    "& .MuiSvgIcon-root": {
      marginLeft: theme.spacing(0.5),
      marginRight: -theme.spacing(3.5),
    },
  },
  "&.MuiChip-error": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
  "&.MuiChip-success": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
  "&.MuiChip-dark": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
  "&.MuiChip-warning": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
  "&.MuiChip-selected": {
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
  "&.MuiChip-global": {
    backgroundColor: palette.vodafone.midNight,
    color: theme.palette.common.white,
  },
  "&.MuiChip-development": {
    backgroundColor: palette.vodafone.midGrey,
    color: theme.palette.common.white,
  },
  "&.MuiChip-testing": {
    backgroundColor: palette.vodafone.grey,
    color: theme.palette.common.white,
  },
  "&.MuiChip-stage": {
    backgroundColor: palette.vodafone.violet,
    color: theme.palette.common.white,
  },
  "&.MuiChip-staging": {
    backgroundColor: palette.vodafone.violet,
    color: theme.palette.common.white,
  },
  "&.MuiChip-production": {
    backgroundColor: palette.vodafone.aqua,
    color: theme.palette.common.white,
  },
};
theme.overrides.MuiChip.clickable = {
  "&:focus": {
    backgroundColor: undefined,
  },
};
theme.overrides.MuiOutlinedInput.root = {
  ["&.Mui-focused"]: {
    ["& .MuiOutlinedInput-notchedOutline"]: {
      // borderColor: theme.palette.primary.dark,
      borderWidth: 1,
    },
  },
};
theme.overrides.MuiOutlinedInput.notchedOutline = {
  borderColor: theme.palette.grey[500],
};
theme.overrides.MuiOutlinedInput.inputMarginDense = {
  height: 22,
  fontSize: 14,
  paddingTop: 5,
  paddingBottom: 5,
};
theme.overrides.MuiInputBase.root = {
  fontFamily: ["Vodafone", "Arial", "sans-serif"].join(","),
  backgroundColor: theme.palette.common.white,
  ["&.MuiOutlinedInput-root"]: {
    ["&.Mui-disabled"]: {
      color: theme.palette.grey["500"],
      backgroundColor: "transparent",
      ["& .MuiOutlinedInput-notchedOutline"]: {
        borderColor: theme.palette.grey[500],
      },
      ["& fieldset"]: {
        ["& legend"]: {
          color: theme.palette.common.black,
        },
      },
    },
  },
  "& .MuiSwitch-root": {
    marginTop: -4,
    marginBottom: -4,
  },
};
theme.overrides.MuiInputBase.input = {
  ["&.Mui-disabled"]: {
    color: theme.palette.grey["500"],
    borderColor: theme.palette.grey["500"],
  },
};
theme.overrides.MuiFilledInput.root = {
  backgroundColor: theme.palette.grey[100],
  "&.MuiFilledInput-disableUnderline": {
    borderRadius: 6,
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.grey[100],
    "& .MuiSelect-iconFilled": {
      "& path": {
        fill: theme.palette.grey[500],
      },
    },
  },
};
theme.overrides.MuiFilledInput.input = {
  paddingLeft: theme.spacing(2),
};
theme.overrides.MuiDialog.paper = {
  width: "100%",
};
theme.overrides.MuiDialogTitle.root = {
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
};
theme.overrides.MuiDialogContent.root = {
  width: "100%",
  padding: theme.spacing(3),
  ["&:first-child"]: {
    paddingTop: undefined,
  },
  ["&.MuiDialogContent-git"]: {
    fontFamily: ["Vodafone", "Arial", "sans-serif"].join(","),
    ["& h1"]: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
    ["& h2"]: {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
    ["& p"]: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      ["& a"]: {
        color: theme.palette.text.primary,
        ["&:hover"]: {
          color: theme.palette.secondary.main,
        },
      },
    },
    ["& pre"]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[100],
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
    ["& blockquote"]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: theme.spacing(2),
      borderLeftColor: theme.palette.grey[100],
      borderLeftWidth: theme.spacing(0.5),
      borderLeftStyle: "solid",
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      ["& p"]: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
      },
    },
  },
};
theme.overrides.MuiDialogActions.root = {
  padding: theme.spacing(3),
  paddingTop: 0,
};
theme.overrides.MuiTabs.root = {};
theme.overrides.MuiTab.root = {
  minHeight: theme.spacing(7),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    minWidth: undefined,
  },
  "&.Mui-selected": {
    fontWeight: "bold",
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
  },
};
theme.overrides.MuiPaper.root = {
  ["&.MuiPaper-transparent"]: {
    backgroundColor: "transparent",
  },
};
theme.overrides.MuiCard.root = {
  "&.MuiCard-media": {
    position: "relative",
    paddingTop: "56.25%",
    paddingBottom: MuiCardMediaContentHeight,
    "& .MuiCardActions-root": {
      opacity: 0,
      visibility: "hidden",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: "auto",
      background:
        "linear-gradient(to bottom, rgba(51,51,51,0.8) 0%,rgba(51,51,51,0) 100%)",
      transition: theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.standard,
      }),
    },
    "& .MuiCardActionArea-root": {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    "& .MuiCardMedia-root": {
      position: "absolute",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      left: 0,
      top: 0,
      right: 0,
      bottom: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.grey[100],
      "& .MuiSvgIcon-fontSizeLarge": {
        fontSize: theme.spacing(4),
      },
    },
    "& .MuiCardContent-root": {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: MuiCardMediaContentHeight,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      ["& .MuiTypography-root"]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    "&.Mui-hover": {
      "& .MuiCardActions-root": {
        opacity: 1,
        visibility: "visible",
      },
      "&.Mui-forSelect": {
        "& .MuiCardActions-root": {
          opacity: 0,
          visibility: "hidden",
        },
      },
    },
    "&.MuiCard-dropzone": {
      "& .MuiCardContent-root": {
        top: 0,
        height: "auto",
      },
    },
    "&.MuiCard-media-thumb": {
      paddingBottom: 0,
      "& .MuiCardMedia-root": {
        bottom: 0,
      },
    },
  },
  "&.MuiCard-apps": {
    ["& .MuiCardMedia-root"]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    "&.MuiCard-media-wide": {
      paddingTop: "21.48%",
      paddingBottom: 0,
    },
    ["& .MuiCardContent-root"]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    ["& .MuiDivider-vertical"]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  ["&.MuiCard-list"]: {
    ["& .MuiCardContent-root"]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      ["& .MuiListItem-button"]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  },
  ["&.MuiCard-form"]: {
    ["& .MuiCardHeader-root"]: {
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    ["& .MuiCardContent-root"]: {
      padding: theme.spacing(3),
    },
  },
  ["&.MuiCard-widget"]: {
    ["&.MuiCard-grid"]: {
      height: "100%",
      ["& .MuiCardContent-root"]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      ["& .MuiCardActions-root"]: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      ["& .MuiCardMedia-root"]: {
        width: "100%",
        height: theme.spacing(18),
        backgroundSize: "contain",
      },
    },
    "& .MuiCardActions-root": {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      "&.MuiCardActions-start": {
        paddingRight: 0,
      },
      "&.MuiCardActions-end": {
        paddingLeft: 0,
      },
    },
    "& .MuiCardContent-root": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  ["&.MuiCard-grid"]: {
    position: "relative",
    height: "100%",
    ["& .MuiCardActionArea-root"]: {
      zIndex: 1,
      position: "relative",
    },
    ["& .MuiCardActions-root"]: {
      position: "absolute",
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
    },
  },
  ["&.MuiCard-white"]: {
    backgroundColor: theme.palette.common.white,
  },
  ["&.MuiCard-grey"]: {
    backgroundColor: theme.palette.grey[100],
  },
  ["&.MuiCard-shadow"]: {
    boxShadow: `0 1px 3px ${fade(theme.palette.common.black, 0.16)}`,
  },
  ["&.MuiCard-ordering"]: {
    ["& .MuiCardActionArea-focusHighlight"]: {
      display: "none",
    },
    ["& .MuiCardActions-root"]: {
      opacity: 0,
    },
    ["& .MuiCardHeader-root"]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    ["&:hover"]: {
      backgroundColor: fade(theme.palette.primary.dark, 0.07),
      ["& .MuiCardActions-root"]: {
        opacity: 1,
      },
    },
  },
  ["&.MuiCard-selection"]: {
    ["&.MuiCard-selected"]: {
      border: `2px solid ${theme.palette.info.main}`,
    },
    ["&.MuiCard-disabled"]: {
      backgroundColor: theme.palette.grey[100],
      pointerEvents: "none",
      ["& .MuiCardContent-root"]: {
        opacity: 0.2,
      },
    },
    ["& .MuiCardContent-root"]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      ["& .MuiSvgIcon-root"]: {
        marginBottom: theme.spacing(1),
      },
    },
  },
  ["&.MuiCard-dropzone"]: {
    "&.MuiCard-dropzone-media": {
      ["& .MuiCardContent-root"]: {
        height: theme.spacing(15),
      },
    },
    ["& .MuiCardMedia-root"]: {
      paddingTop: "50%",
    },
    ["& .MuiCardContent-root"]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      ["&:focus"]: {
        outline: "none",
      },
      ["& .MuiSvgIcon-root"]: {
        marginBottom: theme.spacing(1),
      },
    },
  },
  ["&.MuiCard-disabled"]: {
    backgroundColor: theme.palette.grey[100],
    pointerEvents: "none",
    ["& .MuiCardContent-root"]: {
      opacity: 0.2,
    },
  },
  ["&.MuiCard-widgetList"]: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    ["& .MuiCardActions-root"]: {
      padding: theme.spacing(2),
      paddingLeft: 0,
    },
  },
  ["&.MuiCard-widgetTile"]: {
    position: "relative",
    ["& .MuiCardActions-root"]: {
      padding: theme.spacing(2),
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      justifyContent: "flex-end",
    },
    ["& .MuiCardMedia-root"]: {
      height: theme.spacing(20),
      position: "relative",
      ["&:before"]: {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background:
          "linear-gradient(to bottom, rgba(51,51,51,0.8) 0%,rgba(51,51,51,0) 100%)",
        height: theme.spacing(10),
      },
    },
  },
  ["&.MuiCard-widgetPopover"]: {
    position: "relative",
    width: theme.spacing(34),
    ["& .MuiCardMedia-root"]: {
      height: theme.spacing(20),
      position: "relative",
      ["&:before"]: {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background:
          "linear-gradient(to bottom, rgba(51,51,51,0.8) 0%,rgba(51,51,51,0) 100%)",
        height: theme.spacing(10),
      },
    },
  },
  ["&.MuiCard-shadowHover"]: {
    "&:hover": {
      boxShadow: "0 2px 8px rgba(0,0,0,.16)",
    },
  },
  ["&.MuiCard-environment"]: {
    "& .MuiCardMedia-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiSvgIcon-fontSizeLarge": {
        fontSize: theme.spacing(7),
      },
    },
  },
  ["&.MuiCard-touchpoint"]: {
    "& .MuiCardMedia-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiSvgIcon-fontSizeLarge": {
        fontSize: theme.spacing(7),
      },
    },
  },
  ["&.MuiCard-outlined"]: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
    ["&.MuiCard-outlined-dashed"]: {
      borderStyle: "dashed",
      transition: theme.transitions.create(["border-color"], {
        duration: theme.transitions.duration.standard,
      }),
      "& .MuiTypography-root": {
        transition: theme.transitions.create(["color"], {
          duration: theme.transitions.duration.standard,
        }),
      },
      "&:hover": {
        borderColor: theme.palette.secondary.main,
        boxShadow: "none",
        "& .MuiTypography-root": {
          color: theme.palette.secondary.main,
        },
      },
    },
    ["&.Mui-selected"]: {
      borderWidth: "2px",
    },
    "&.MuiCard-outlined-grey": {
      borderColor: theme.palette.grey[100],
    },
    "&.MuiCard-outlined-light": {
      borderColor: theme.palette.grey[300],
    },
    "&.MuiCard-outlined-info": {
      borderColor: palette.info.main,
      "& .label": {
        color: palette.info.main,
      },
    },
    "&.MuiCard-outlined-error": {
      borderColor: palette.error.main,
      "& .label": {
        color: palette.error.main,
      },
    },
    "&.MuiCard-outlined-warning": {
      borderColor: palette.warning.main,
      "& .label": {
        color: palette.warning.main,
      },
    },
    "&.MuiCard-outlined-success": {
      borderColor: palette.success.main,
      "& .label": {
        color: palette.success.main,
      },
    },
    "&.MuiCard-outlined-primary": {
      borderColor: palette.primary.main,
      "& .label": {
        color: palette.primary.main,
      },
    },
  },
  "&.MuiCard-search": {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    boxShadow: "none",
    borderRadius: 6,
    width: "50px",
    display: "inline-flex",
    pointerEvents: "all",
    transition: theme.transitions.create(
      ["box-shadow", "border-color", "background-color", "width"],
      {
        duration: theme.transitions.duration.complex,
      }
    ),
    "& .MuiCardContent-root": {
      padding: 0,
      width: "100%",
      "& .collapse ": {
        width: 0,
      },
    },
    "& .MuiCollapse-container": {
      width: "0px",
      transitionProperty: "width",
      transitionDuration: "300ms",
    },
    "& .MuiCollapse-entered": {
      width: "100%",
    },
    "& .MuiCollapse-hidden": {
      width: "0px",
    },
    "&.MuiCard-search--hidden": {
      width: 0,
    },
    "&.MuiCard-search--open": {
      background: theme.palette.common.white,
      borderColor: theme.palette.grey[500],
      width: "100%",
      "& .MuiCardContent-root": {
        "& .collapse": {
          width: "100%",
        },
      },
      "&:hover": {
        boxShadow: `0 2px 8px ${fade(theme.palette.common.black, 0.16)}`,
      },
    },
  },
};
theme.overrides.MuiCardMedia.root = {
  "&.MuiCardMedia-flex": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "&.CardMedia-appIcon": {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  "&.CardMedia-imagePreview": {
    position: "relative",
    width: "100%",
    height: theme.spacing(18),
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      height: theme.spacing(9),
      background:
        "linear-gradient(to bottom, rgba(51,51,51,0.8) 0%,rgba(51,51,51,0) 100%)",
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(22),
    },
  },
};
theme.overrides.MuiCardContent.root = {
  ["&:last-child"]: null,
};
theme.overrides.MuiIconButton.root = {
  color: fade(theme.palette.common.black, 0.6),
  "&.MuiRadio-root.Mui-checked": {
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.primary.main,
      },
      "& rect": {
        fill: theme.palette.primary.main,
      },
      "& circle": {
        fill: theme.palette.primary.main,
      },
      "& polygon": {
        fill: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSvgIcon-root": {
    "& path": {
      fill: fade(theme.palette.common.black, 0.6),
    },
    "& rect": {
      fill: fade(theme.palette.common.black, 0.6),
    },
    "& circle": {
      fill: fade(theme.palette.common.black, 0.6),
    },
    "& polygon": {
      fill: fade(theme.palette.common.black, 0.6),
    },
  },
  "&.MuiIconButton-colorWhite": {
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.04),
    },
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
};
theme.overrides.MuiIconButton.colorSecondary = {
  ["& .MuiSvgIcon-root"]: {
    ["& path"]: {
      fill: theme.palette.secondary.main,
    },
    ["& circle"]: {
      fill: theme.palette.secondary.main,
    },
    ["& rect"]: {
      fill: theme.palette.secondary.main,
    },
    ["& polygon"]: {
      fill: theme.palette.secondary.main,
    },
  },
};
theme.overrides.MuiIconButton.edgeStart = {
  marginLeft: -theme.spacing(1),
};
theme.overrides.MuiIconButton.edgeEnd = {
  marginRight: -theme.spacing(1),
};
theme.overrides.MuiButton.root = {
  padding: "10px 24px",
  fontSize: theme.typography.body1.fontSize,
  fontWeight: undefined,
  lineHeight: "24px",
  borderRadius: "6px",
  textTransform: undefined,
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "20px",
    padding: "10px 12px",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "6px 12px",
  },
  ["&.MuiButton-outlinedPrimary"]: {
    color: "#222222",
    borderColor: "#222222",
    ["&:hover"]: {
      color: "#FFFFFF",
      background: "#222222",
    },
  },
  ["&.MuiButton-outlinedSecondary"]: {
    color: theme.palette.secondary.main,
    borderColor: fade(theme.palette.secondary.main, 0.5),
    ["& path"]: {
      fill: theme.palette.secondary.main,
    },
  },
};
theme.overrides.MuiButton.contained = {
  boxShadow: "none",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.grey[100],
  ["&:hover"]: {
    backgroundColor: theme.palette.grey[300],
    boxShadow: "none",
  },
  ["&:active"]: {
    boxShadow: "none",
  },
  "&.MuiButton-no-round": {
    borderRadius: 0,
  },
  "&.MuiButton-containedLight": {
    backgroundColor: theme.palette.grey[100],
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.text.primary,
      },
      "& rect": {
        fill: theme.palette.text.primary,
      },
      "& circle": {
        fill: theme.palette.text.primary,
      },
      "& polygon": {
        fill: theme.palette.text.primary,
      },
    },
    "& .MuiTouchRipple-ripple": {
      "& .MuiTouchRipple-child": {
        backgroundColor: theme.palette.secondary.primary,
      },
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& path": {
          fill: theme.palette.secondary.main,
        },
        "& rect": {
          fill: theme.palette.secondary.main,
        },
        "& circle": {
          fill: theme.palette.secondary.main,
        },
        "& polygon": {
          fill: theme.palette.secondary.main,
        },
      },
    },
  },
  "&.MuiButton-selected": {
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: theme.palette.common.white,
      },
      "& rect": {
        fill: theme.palette.common.white,
      },
      "& circle": {
        fill: theme.palette.common.white,
      },
      "& polygon": {
        fill: theme.palette.common.white,
      },
    },
  },
  "&.MuiButton-development": {
    backgroundColor: palette.vodafone.midGrey,
    color: theme.palette.common.white,
  },
  "&.MuiButton-testing": {
    backgroundColor: palette.vodafone.grey,
    color: theme.palette.common.white,
  },
  "&.MuiButton-stage": {
    backgroundColor: palette.vodafone.violet,
    color: theme.palette.common.white,
  },
  "&.MuiButton-staging": {
    backgroundColor: palette.vodafone.violet,
    color: theme.palette.common.white,
  },
  "&.MuiButton-production": {
    backgroundColor: palette.vodafone.aqua,
    color: theme.palette.common.white,
  },
};
theme.overrides.MuiButton.containedSizeSmall = {
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 16,
  paddingRight: 16,
};
theme.overrides.MuiButton.containedPrimary = {
  "& .MuiSvgIcon-root": {
    "& path": {
      fill: theme.palette.common.white,
    },
    "& polygon": {
      fill: theme.palette.common.white,
    },
  },
  "& .MuiCheckbox-colorPrimary": {
    "&.Mui-checked": {
      "& .MuiSvgIcon-root": {
        "& path": {
          fill: theme.palette.common.white,
        },
      },
    },
  },
};
theme.overrides.MuiButton.containedSizeSmall = {
  "& .MuiCheckbox-root": {
    padding: 0,
    marginLeft: theme.spacing(5),
  },
};
theme.overrides.MuiButton.textSizeLarge = {
  padding: theme.spacing(1.5),
  fontSize: theme.typography.body2.fontSize,
};
theme.overrides.MuiButton.outlined = {
  background: theme.palette.common.white,
  padding: "9px 24px",
  fontSize: "18px",
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "20px",
    padding: "9px 12px",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "5px 12px",
  },
};
theme.overrides.MuiButtonBase.root = {
  fontFamily: ["Vodafone", "Arial", "sans-serif"].join(","),
  ["& .MuiCardActionArea-focusHighlight"]: {
    backgroundColor: "transparent",
  },
  ["&:hover"]: {
    ["& .MuiCardActionArea-focusHighlight"]: {
      backgroundColor: "transparent",
    },
  },
};
theme.overrides.MuiPopover.paper = {
  boxShadow: `0 12px 23px ${fade(theme.palette.common.black, 0.16)}`,
};
theme.overrides.MuiButtonGroup.contained = {
  boxShadow: "none",
  "& .MuiButton-contained:hover": {
    boxShadow: "none",
  },
};
theme.overrides.MuiAlert.root = {
  fontFamily: ["Vodafone", "Arial", "sans-serif"].join(","),
};

theme.overrides.MuiSwitch.root = {
  width: 42,
  height: 26,
  padding: 0,
  marginTop: 6,
  marginBottom: 6,
  marginLeft: 6,
  marginRight: 6,
};
theme.overrides.MuiSwitch.switchBase = {
  padding: 0,
  left: 1,
  top: 1,
  "&.Mui-checked": {
    transform: "translateX(16px)",
    "& + .MuiSwitch-track": {
      opacity: 1,
    },
  },
  "&.Mui-disabled": {
    "& + .MuiSwitch-track": {
      opacity: 1,
    },
  },
};
theme.overrides.MuiSwitch.track = {
  borderRadius: 26 / 2,
  backgroundColor: theme.palette.grey[500],
  opacity: 1,
  transition: theme.transitions.create(["background-color"]),
  "&.Mui-disabled": {
    backgroundColor: theme.palette.grey[300],
  },
};
theme.overrides.MuiSwitch.thumb = {
  width: 24,
  height: 24,
  backgroundColor: theme.palette.common.white,
  "&.Mui-disabled": {
    backgroundColor: theme.palette.grey[200],
  },
};
theme.overrides.MuiSwitch.colorPrimary = {
  "&.Mui-disabled": {
    "& + .MuiSwitch-track": {
      backgroundColor: theme.palette.grey[300],
    },
  },
};
theme.overrides.MuiSwitch.sizeSmall = {
  width: 30,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    left: 3,
    top: 3,
    "&.Mui-checked": {
      transform: "translateX(8px) !important",
    },
  },
};
theme.overrides.MuiTreeItem.root = {
  "&.Mui-expanded": {
    "& > .MuiTreeItem-content > .MuiTreeItem-iconContainer": {
      "& path": {
        fill: theme.palette.secondary.main,
      },
    },
  },
  "&.Mui-selected > .MuiTreeItem-content": {
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
    "& .MuiTreeItem-label": {
      backgroundColor: "transparent",
      fontWeight: "bold",
      "& .MuiTypography-root": {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
};
theme.overrides.MuiTreeItem.label = {
  "&:hover": {
    backgroundColor: "transparent",
  },
};
theme.overrides.MuiTreeItem.iconContainer = {
  "& svg": {
    fontSize: theme.spacing(1.5),
  },
};
theme.overrides.MuiFab.root = {
  backgroundColor: theme.palette.common.white,
};
theme.overrides.MuiBadge.badge = {
  backgroundColor: theme.palette.text.primary,
  color: theme.palette.common.white,
};
theme.overrides.MuiAutocomplete.popper = {
  fontFamily: ["Vodafone", "Arial", "sans-serif"].join(","),
};

export default theme;

import {
  GET_STOREGROUPS_REQUEST_SUCCESS,
  ADD_STOREGROUP_REQUEST,
  ADD_STOREGROUP_REQUEST_SUCCESS,
  ADD_STOREGROUP_REQUEST_FAIL,
  UPDATE_STOREGROUP_REQUEST,
  UPDATE_STOREGROUP_REQUEST_SUCCESS,
  UPDATE_STOREGROUP_REQUEST_FAIL,
  DELETE_STOREGROUP_REQUEST,
  DELETE_STOREGROUP_REQUEST_SUCCESS,
  DELETE_STOREGROUP_REQUEST_FAIL,
  BULK_DELETE_STOREGROUPS_REQUEST,
  BULK_DELETE_STOREGROUPS_REQUEST_SUCCESS,
  BULK_DELETE_STOREGROUPS_REQUEST_FAIL,
  EXPORT_STOREGROUPS_REQUEST,
  EXPORT_STOREGROUPS_REQUEST_SUCCESS,
  EXPORT_STOREGROUPS_REQUEST_FAIL,
  REMOVE_ERROR,
} from "actions/storeGroupActions";

const initialState = {
  loading: false,
  storeGroups: [],
  storeGroupsItems: 1,
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STOREGROUPS_REQUEST_SUCCESS: {
      return {
        ...state,
        storeGroups: payload.storeGroups,
        storeGroupsItems: payload.count,
      };
    }
    case ADD_STOREGROUP_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_STOREGROUP_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '' };
    }
    case ADD_STOREGROUP_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case UPDATE_STOREGROUP_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_STOREGROUP_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '' };
    }
    case UPDATE_STOREGROUP_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case DELETE_STOREGROUP_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_STOREGROUP_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '' };
    }
    case DELETE_STOREGROUP_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case BULK_DELETE_STOREGROUPS_REQUEST: {
      return { ...state, loading: true };
    }
    case BULK_DELETE_STOREGROUPS_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '' };
    }
    case BULK_DELETE_STOREGROUPS_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case EXPORT_STOREGROUPS_REQUEST: {
      return { ...state, loading: true };
    }
    case EXPORT_STOREGROUPS_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '' };
    }
    case EXPORT_STOREGROUPS_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case REMOVE_ERROR: {
      return { ...state, error: '' };
    }
    default:
      return state;
  }
};

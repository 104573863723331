import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Box, Button, Container, Grid } from "@material-ui/core";
import Loader from "../loader/Loader";
import Heading from "../headings/Heading";
import theme from "../../AppTheme";
import ReportingPopup from "./ReportingPopup";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  iframe: {
    minHeight: "90vh",
    minWidth: "100%",
    padding: 0,
  },
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 100,
    },
  },
  fab: {
    position: "absolute",
    right: theme.spacing(3),
    top: -theme.spacing(7),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
});

const Iframe = ({ ...props }) => {
  const styles = useStyles();
  const { src } = props;
  if (!src) return null;
  return <iframe className={styles.iframe} src={src} frameBorder="0" />;
};

const Reporting = ({reportingInfo}) => {
  const styles = useStyles();
  const { locale } = useParams();
  const popupRef = useRef();
  const [loading, setLoading] = useState(true);
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      popupRef.current.openPopup();
    }, 1000);
  }, [loading]);

  return (
    <main className={styles.root}>
      <Heading
        title={
          <>
            <Container className={styles.container} maxWidth="md">
              <Grid container>
                <Grid item xs={6}>
                  Reporting
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </Container>
          </>
        }
        backUrl={`/${locale}/landing`}
      />
      <ReportingPopup
        ref={popupRef}
        title="Kibana"
        width={"800"}
        height={"600"}
        url={reportingInfo.url}
        onClose={() => setShowIframe(true)}
      />
      <Box pt={8} pb={4}>
        <Container className={styles.container} maxWidth="md">
          <Grid container>
            {!loading ? (
              <Grid item xs={12}>
                {false ? (
                  <Iframe className={styles.root} style={{ minHeight: "100vh" }} src={reportingInfo.url} />
                ) : (
                  <Box pt={8} pb={4}>
                    <Container className={styles.container} maxWidth="md">
                      <Grid container>
                        <Grid item xs={12}>
                          <Button
                            autoFocus
                            color="default"
                            variant="outlined"
                            onClick={() => {
                              popupRef.current.openPopup();
                            }}
                          >
                            {"Open Reporting"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                )}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </main>
  );
};

export default Reporting;

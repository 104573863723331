/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { Box, Container, Grid } from "@material-ui/core";
import ToolbarBottom from "components/toolbarbottom/ToolbarBottom";
import VfDialogFull from "../vf/VfDialogFull";
import CardAppList from "../apps/CardAppList";
import PaperConfiguratorAppsWidgets from "./PaperConfiguratorAppsWidgets";

const DialogConfiguratorApp = ({
  app,
  dialogApp,
  setDialogApp,
  setDialogWidget,
  clearApp,
  clearWidgetList,
  deployChanges,
  resetChanges,
}) => {
  const [searchText, setSearchText] = useState(null);

  return (
    <VfDialogFull
      /* eslint-disable */
      appBarBottom={
        <ToolbarBottom
          envTitle={app.envTitle}
          resetChanges={resetChanges}
          deployChanges={deployChanges}
          widgets
        />
      }
      /* eslint-enable */
      openDialog={dialogApp}
      buttonCloseAction={() => {
        setDialogApp(false);
        clearWidgetList();
        clearApp();
      }}
    >
      <Box py={6}>
        <Container maxWidth="md">
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <CardAppList
                app={app}
                auth={{}}
                env={null}
                envTitle={app.envTitle}
                envType={app.envType}
                backAction={() => {
                  setDialogApp(false);
                  clearWidgetList();
                  clearApp();
                }}
                isConfiguration
                showEnv
              />
            </Grid>
            <Grid item xs={12}>
              <PaperConfiguratorAppsWidgets
                searchText={searchText}
                setSearchText={setSearchText}
                envID={app.envId}
                // eslint-disable-next-line no-underscore-dangle
                application={app._id}
                setDialogWidget={setDialogWidget}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </VfDialogFull>
  );
};

export default DialogConfiguratorApp;

import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Button, Collapse, Grid, Stepper, Step, StepConnector, StepButton, Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import VfSvgIcon from "../../icons/VfSvgIcon";
import theme from "AppTheme";
import VfDialog from "../../vf/VfDialog";
import AppsCardSelector from "../../apps/AppsCardSelector";
import { addDevice, updateDevice } from "actions/deviceActions";

const useStyles = makeStyles({
  stepFirst: {
    marginTop: -theme.spacing(4),
    "& .MuiStepConnector-line": {
      minHeight: theme.spacing(5),
    },
  },
  stepLast: {
    marginBottom: -theme.spacing(4),
    "& .MuiStepConnector-line": {
      minHeight: theme.spacing(5),
    },
  },
});

const generatePassword = () => Math.floor(100000 + Math.random() * 900000);
export const capitalizeFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1));
const findParentstore = (allStores, deviceId) => allStores.find(({ devices }) => devices.includes(deviceId));
const passcodeData = (device) => device.passcodes.map(({name, password}) => ({name, password, label: capitalizeFirstLetter(name)}));

const passcodesDefault = [{
  label: 'Transactions',
  name: 'transactions',
  password: generatePassword()
},
{
  label: 'Parameters',
  name: 'parameters',
  password: generatePassword()
}];

const DialogDevice = ({ open, setOpen, device, store }) => {
  const [activeStep, setStep] = useState(0);
  const [name, setName] = useState(device ? device.name : "");
  const [code, setCode] = useState(device ? device.code : "");
  const [type, setType] = useState(device ? device.type : "");
  const [passcodes, setPasscodes] = useState(() => device ? passcodeData(device) : passcodesDefault);
  const [completed, setCompleted] = useState({});
  const auth = useSelector(state => state.authentication, shallowEqual);
  const { allStores } = useSelector(state => state.storeReducer, shallowEqual);
  const [parentStore, setParentStore] = useState(findParentstore(allStores, device?._id) ?? {});
  const dispatch = useDispatch();
  const styles = useStyles();
  const { access_token, id_token } = auth;

  useEffect(() => {
    if (device) {
      setName(device.name);
      setType(device.type);
      setCode(device.code);
      setPasscodes(passcodeData(device));
    }
    if (store) {
      setParentStore(store);
    }
  }, [open]);

  const [steps] = useState([
    { name: "Type", completed: false },
    { name: "ID", completed: false },
    { name: "Name", completed: false },
    { name: "Store", completed: false },
    { name: "Passcodes", completed: false },
  ]);

  const handleStep = step => () => {
    setStep(step);
    let newCompleted = {};
    Object.entries(completed).forEach((item, index) => {
      newCompleted = index >= step ? { ...newCompleted, [index]: false } : { ...newCompleted, [index]: true };
    });

    setCompleted(newCompleted);
  };

  const handleStepExpanded = index => {
    return index === activeStep;
  };

  const handleStepCompletion = () => {
    handleStep(2);
    if (name && code && type && parentStore._id && passcodes.every(({password}) => `${password}`.length === 6)) {
      const passcodesData = passcodes.map(({name, password}) => ({name, password}));
      if (!device) {
        dispatch(
          addDevice({
            name,
            store: parentStore._id,
            code,
            type,
            passcodes: passcodesData,
            access_token,
            id_token,
          })
        );
      } else {
        dispatch(
          updateDevice({
            name,
            store: parentStore._id,
            code,
            type,
            passcodes: passcodesData,
            _id: device._id,
            access_token,
            id_token,
          })
        );
      }

      setOpen(false);
    } else {
      handleStep(1);
    }
  };

  const stepIcon = index => {
    if (activeStep === index) return "circleSelected";
    if (steps[index].completed) return "circleDone";
    return "circle";
  };

  const onChangePasscodes = (name, password) => {
    setPasscodes((passcodes) => passcodes.map((passcode) => ({
      ...passcode,
      password: passcode.name === name ? password : passcode.password
    })));
  };
  
  return (
    <VfDialog
      title={device ? "Edit Device" : "Add device"}
      openDialog={open}
      buttonCloseAction={() => setOpen(false)}
      maxWidth="md"
    >
      {
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <StepConnector className={styles.stepFirst} />
              {steps.map((stp, index) => (
                <Step key={stp.name}>
                  <StepButton
                    onClick={handleStep(index)}
                    completed={steps[index].completed}
                    /* eslint-disable */
                    icon={<VfSvgIcon icon={stepIcon(index)} viewBox={24} />}
                    /* eslint-enable */
                  >
                    <Typography component="span" variant="body1">
                      {stp.name}
                    </Typography>
                  </StepButton>
                </Step>
              ))}
              <StepConnector className={styles.stepLast} />
            </Stepper>
          </Grid>
          <Grid item xs={9}>
            <Collapse in={handleStepExpanded(0)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Select device type</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} md={3}>
                      <AppsCardSelector
                        title="web"
                        imageIcon="web"
                        selected={type === "web"}
                        setAppType={() => {
                          setType("web");
                        }}
                        handleNext={handleStep(1)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <AppsCardSelector
                        title="android"
                        selected={type === "android"}
                        imageIcon="android"
                        setAppType={() => {
                          setType("android");
                        }}
                        handleNext={handleStep(1)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <AppsCardSelector
                        title="ios"
                        selected={type === "ios"}
                        imageIcon="ios"
                        setAppType={() => {
                          setType("ios");
                        }}
                        handleNext={handleStep(1)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <AppsCardSelector
                        title="kiosk"
                        selected={type === "kiosk"}
                        imageIcon="kiosk"
                        setAppType={() => {
                          setType("kiosk");
                        }}
                        handleNext={handleStep(1)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={handleStepExpanded(1)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Enter device ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={e => {
                      setCode(e.target.value);
                    }}
                    value={code}
                    label="ID"
                    color="primary"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs="auto">
                      <Button onClick={handleStep(2)} color="secondary" variant="contained">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={handleStepExpanded(2)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Enter a name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={e => {
                      setName(e.target.value);
                    }}
                    value={name}
                    label="Name"
                    color="primary"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs="auto">
                      <Button onClick={handleStep(3)} color="secondary" variant="contained">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={handleStepExpanded(3)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Select store</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    label="Select store"
                    options={allStores}
                    getOptionLabel={option => (option.name ? option.name : "")}
                    value={parentStore}
                    onChange={(e, v) => {
                      setParentStore({ _id: v?._id, name: v?.name });
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Combo box" variant="outlined" color="primary" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs="auto">
                      <Button onClick={handleStep(4)} color="secondary" variant="contained">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={handleStepExpanded(4)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Passcodes</Typography>
                </Grid>
                {
                  passcodes.map(({label, name, password}) => (
                    <Grid item xs={6}>
                      <TextField
                        onChange={e => {
                          onChangePasscodes(name, Math.max(0, parseInt(e.target.value)).toString().slice(0, 6));
                        }}
                        value={password}
                        label={label}
                        color="primary"
                        variant="outlined"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  ))
                }
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs="auto">
                      <Button onClick={handleStepCompletion} color="secondary" variant="contained">
                        Complete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      }
    </VfDialog>
  );
};

export default DialogDevice;

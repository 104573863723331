/* eslint-disable no-useless-computed-key */
import React from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import CardActionArea from "@material-ui/core/CardActionArea";
import VfSvgIcon from "../icons/VfSvgIcon";

const AppsCardSelector = ({
  title,
  values,
  imageIcon,
  handleNext,
  setAppType,
  selected,
  disabled,
  setOptionTags,
  property,
}) => {
  return (
    <Card
      className={classNames({
        "MuiCard-selection": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-rounded": true,
        "MuiCard-selected": selected,
        "MuiCard-disabled": disabled,
      })}
      elevation={0}
    >
      <CardActionArea
        onClick={() => {
          if (property === "authProvider" && title !== "No Authentication") {
            setAppType({ ...values, [property]: title, hasAuth: true });
          } else {
            setAppType({ ...values, [property]: title });
          }
          if (property === "appType")
            setOptionTags(oldArray => [...oldArray, title]);
          handleNext();
        }}
      >
        <CardContent>
          <VfSvgIcon icon={imageIcon} viewBox="56" fontSize="large" />
          <Typography variant="body2" component="h5">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AppsCardSelector;

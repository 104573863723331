/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as TranslateIcon24 } from "assets/images/ic-translate.svg";
import { ReactComponent as TranslateIcon56 } from "assets/images/ic-translate@56.svg";
import { ReactComponent as ConfigurationIcon24 } from "../../assets/images/ic-configuration.svg";
import { ReactComponent as ConfigurationIcon56 } from "../../assets/images/ic-configuration@56.svg";
import { ReactComponent as TestingIcon24 } from "../../assets/images/ic-testing.svg";
import { ReactComponent as TestingIcon56 } from "../../assets/images/ic-testing@56.svg";
import { ReactComponent as GlobalIcon24 } from "../../assets/images/ic-global.svg";
import { ReactComponent as GlobalIcon56 } from "../../assets/images/ic-global@56.svg";
import { ReactComponent as AppsIcon24 } from "../../assets/images/ic-apps.svg";
import { ReactComponent as AppsIcon56 } from "../../assets/images/ic-apps@56.svg";
import { ReactComponent as RolesIcon } from "../../assets/images/ic-roles-24.svg";
import { ReactComponent as ProductionIcon24 } from "../../assets/images/ic-production.svg";
import { ReactComponent as ProductionIcon56 } from "../../assets/images/ic-production@56.svg";
import { ReactComponent as PlusIcon } from "../../assets/images/ic-plus.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/ic-close.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/ic-more.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/ic-search.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/ic-upload.svg";
import { ReactComponent as DocumentIcon } from "../../assets/images/document.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/ic-info.svg";
import { ReactComponent as ReadmeIcon } from "../../assets/images/ic-read-me.svg";
import { ReactComponent as GridIcon } from "../../assets/images/ic-grid.svg";
import { ReactComponent as ListIcon } from "../../assets/images/ic-list.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/ic-menu.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/ic-notifications.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/images/ic-chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/images/ic-chevron-right.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/ic-chevron-down.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ic-edit.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/ic-arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/ic-arrow-right.svg";

import { ReactComponent as DevelopmentIcon24 } from "../../assets/images/ic-development.svg";
import { ReactComponent as DevelopmentIcon56 } from "../../assets/images/development.svg";
import { ReactComponent as AndroidIcon24 } from "../../assets/images/ic-android.svg";
import { ReactComponent as AndroidIcon56 } from "../../assets/images/ic-android@56.svg";
import { ReactComponent as KioskIcon24 } from "../../assets/images/ic-kiosk.svg";
import { ReactComponent as KioskIcon56 } from "../../assets/images/ic-kiosk@56.svg";
import { ReactComponent as MobileIcon24 } from "../../assets/images/ic-mobile.svg";
import { ReactComponent as MobileIcon56 } from "../../assets/images/ic-mobile@56.svg";
import { ReactComponent as AppleIcon24 } from "../../assets/images/ic-apple.svg";
import { ReactComponent as AppleIcon56 } from "../../assets/images/ic-apple@56.svg";
import { ReactComponent as WebIcon24 } from "../../assets/images/ic-web.svg";
import { ReactComponent as WebIcon56 } from "../../assets/images/ic-web@56.svg";
import { ReactComponent as TabletIcon24 } from "../../assets/images/ic-tablet.svg";
import { ReactComponent as TabletIcon56 } from "../../assets/images/ic-tablet@56.svg";
import { ReactComponent as TouchpointIcon24 } from "../../assets/images/ic-touchpoint.svg";
import { ReactComponent as TouchpointIcon56 } from "../../assets/images/ic-touchpoint@56.svg";
import { ReactComponent as DesktopIcon24 } from "../../assets/images/ic-desktop.svg";
import { ReactComponent as DesktopIcon56 } from "../../assets/images/ic-desktop@56.svg";
import { ReactComponent as WindowsIcon24 } from "../../assets/images/ic-windows.svg";
import { ReactComponent as WindowsIcon56 } from "../../assets/images/ic-windows@56.svg";
import { ReactComponent as WidgetIcon24 } from "../../assets/images/ic-widget.svg";
import { ReactComponent as WidgetIcon56 } from "../../assets/images/ic-widget@56.svg";
import { ReactComponent as StageIcon24 } from "../../assets/images/ic-staging.svg";
import { ReactComponent as StageIcon56 } from "../../assets/images/ic-staging@56.svg";

import { ReactComponent as Breakdown24 } from "../../assets/images/ic-breakdown.svg";
import { ReactComponent as Settings24 } from "../../assets/images/ic-settings.svg";
import { ReactComponent as Move24 } from "../../assets/images/ic-move.svg";
import { ReactComponent as Home24 } from "../../assets/images/ic-home.svg";
import { ReactComponent as Page24 } from "../../assets/images/ic-page.svg";
import { ReactComponent as Show24 } from "../../assets/images/ic-show.svg";
import { ReactComponent as Hide24 } from "../../assets/images/ic-hide.svg";
import { ReactComponent as Circle24 } from "../../assets/images/ic-circle.svg";
import { ReactComponent as CircleSelected24 } from "../../assets/images/ic-circle-selected.svg";
import { ReactComponent as CircleDone24 } from "../../assets/images/ic-circle-done.svg";
import { ReactComponent as Vodafone24 } from "../../assets/images/ic-logo.svg";
import { ReactComponent as Delete24 } from "../../assets/images/ic-delete.svg";

import { ReactComponent as Image24 } from "../../assets/images/ic-image.svg";
import { ReactComponent as Video24 } from "../../assets/images/ic-video.svg";
import { ReactComponent as Audio24 } from "../../assets/images/ic-audio.svg";
import { ReactComponent as Copy24 } from "../../assets/images/ic-copy.svg";

import { ReactComponent as Warning24 } from "../../assets/images/warning.svg";
import { ReactComponent as Success24 } from "../../assets/images/success.svg";
import { ReactComponent as Lock24 } from "../../assets/images/ic-padlock.svg";
import { ReactComponent as BuildingsIcon } from "../../assets/images/ic-buildings.svg";
import { ReactComponent as DesignerIcon } from "../../assets/images/ic-designer.svg";
import { ReactComponent as LogicIcon } from "../../assets/images/ic-logic.svg";
import { ReactComponent as ExternalLink } from "../../assets/images/ic-external-link.svg";
import { ReactComponent as Preview } from "../../assets/images/ic-preview.svg";
import { ReactComponent as Code } from "../../assets/images/ic-code.svg";
import { ReactComponent as Apis } from "../../assets/images/ic-apis.svg";
import { ReactComponent as Portal } from "../../assets/images/ic-portal.svg";
import { ReactComponent as Calendar } from "../../assets/images/ic-calendar.svg";
import { ReactComponent as Download } from "../../assets/images/ic-download.svg";

const VfSvgIcon = ({ className, viewBox = 24, icon, fontSize, color }) => {
  const findIcon = {
    android: {
      24: AndroidIcon24,
      56: AndroidIcon56,
    },
    apis: {
      24: Apis,
    },
    apps: {
      24: AppsIcon24,
      56: AppsIcon56,
    },
    arrowLeft: {
      24: ArrowLeftIcon,
    },
    audio: {
      24: Audio24,
    },
    breakdown: {
      24: Breakdown24,
    },
    arrowRight: {
      24: ArrowRightIcon,
    },
    calendar: {
      24: Calendar,
    },
    buildings: {
      24: BuildingsIcon,
    },
    chevronLeft: {
      24: ChevronLeftIcon,
    },
    chevronRight: {
      24: ChevronRightIcon,
    },
    chevronDown: {
      24: ChevronDownIcon,
    },
    circle: {
      24: Circle24,
    },
    circleDone: {
      24: CircleDone24,
    },
    circleSelected: {
      24: CircleSelected24,
    },
    close: {
      24: CloseIcon,
    },
    copy: {
      24: Copy24,
    },
    code: {
      24: Code,
    },
    config: {
      24: ConfigurationIcon24,
      56: ConfigurationIcon56,
    },
    delete: {
      24: Delete24,
    },
    desktop: {
      24: DesktopIcon24,
      56: DesktopIcon56,
    },
    designer: {
      24: DesignerIcon,
    },
    document: {
      24: DocumentIcon,
    },
    download: {
      24: Download,
    },
    dev: {
      24: DevelopmentIcon24,
      56: DevelopmentIcon56,
    },
    development: {
      24: DevelopmentIcon24,
      56: DevelopmentIcon56,
    },
    edit: {
      24: EditIcon,
    },
    global: {
      24: GlobalIcon24,
      56: GlobalIcon56,
    },
    Global: {
      24: GlobalIcon24,
      56: GlobalIcon56,
    },
    GLOBAL: {
      24: GlobalIcon24,
      56: GlobalIcon56,
    },
    grid: {
      24: GridIcon,
    },
    hide: {
      24: Hide24,
    },
    home: {
      24: Home24,
    },
    info: {
      24: InfoIcon,
    },
    image: {
      24: Image24,
    },
    ios: {
      24: AppleIcon24,
      56: AppleIcon56,
    },
    kiosk: {
      24: KioskIcon24,
      56: KioskIcon56,
    },
    list: {
      24: ListIcon,
    },
    lock: {
      24: Lock24,
    },
    logic: {
      24: LogicIcon,
    },
    menu: {
      24: MenuIcon,
    },
    mobile: {
      24: MobileIcon24,
      56: MobileIcon56,
    },
    more: {
      24: MoreIcon,
    },
    move: {
      24: Move24,
    },
    notification: {
      24: NotificationIcon,
    },
    page: {
      24: Page24,
    },
    plus: {
      24: PlusIcon,
    },
    portal: {
      24: Portal,
    },
    preview: {
      24: Preview,
    },
    prod: {
      24: ProductionIcon24,
      56: ProductionIcon56,
    },
    production: {
      24: ProductionIcon24,
      56: ProductionIcon56,
    },
    roles: {
      24: RolesIcon,
    },
    readme: {
      24: ReadmeIcon,
    },
    search: {
      24: SearchIcon,
    },
    settings: {
      24: Settings24,
    },
    show: {
      24: Show24,
    },
    stage: {
      24: StageIcon24,
      56: StageIcon56,
    },
    staging: {
      24: StageIcon24,
      56: StageIcon56,
    },
    success: {
      24: Success24,
    },
    tablet: {
      24: TabletIcon24,
      56: TabletIcon56,
    },
    test: {
      24: TestingIcon24,
      56: TestingIcon56,
    },
    testing: {
      24: TestingIcon24,
      56: TestingIcon56,
    },
    translate: {
      24: TranslateIcon24,
      56: TranslateIcon56,
    },
    touchPoint: {
      24: TouchpointIcon24,
      56: TouchpointIcon56,
    },
    upload: {
      56: UploadIcon,
    },
    video: {
      24: Video24,
    },
    vodafone: {
      24: Vodafone24,
    },
    productSelector: {
      24: WidgetIcon24,
      56: WidgetIcon56,
    },
    warning: {
      24: Warning24,
    },
    windows: {
      24: WindowsIcon24,
      56: WindowsIcon56,
    },
    widget: {
      24: WidgetIcon24,
      56: WidgetIcon56,
    },
    web: {
      24: WebIcon24,
      56: WebIcon56,
    },
    externalLink: {
      24: ExternalLink,
    },
  };
  const findViewBox = {
    24: "0 0 24 24",
    56: "0 0 56 56",
  };

  return (
    <>
      {findIcon[icon] !== undefined && findIcon[icon][viewBox] !== undefined && (
        <SvgIcon
          className={className}
          component={findIcon[icon][viewBox]}
          fontSize={fontSize}
          color={color}
          viewBox={findViewBox[viewBox]}
        />
      )}
    </>
  );
};

export default VfSvgIcon;

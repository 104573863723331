/* eslint-disable camelcase */
import axios from "axios";

class PasscodeService {
  fetch = async ({ access_token, id_token, search, page, pageSize}) => {
    const params = {
      search,
      page,
      pageSize,
    };

    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/passcode/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in fetch passcode service:", err);
      return err;
    }
  };
}

const inst = new PasscodeService();
export default inst;

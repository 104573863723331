/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Container, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";

import {
  fetchTranslationWidgetList,
  setTranslationWidget,
  saveWidgetTranslations,
  clearTranslationData,
  promoteTranslation,
} from "actions/translationsActions";
import {
  fetchNexusWidgetsCategories,
  fetchNexusWidgetsVersions,
  clearNexusWidgetsVersions,
  fetchNexusWidgetById,
  clearNexusWidget,
} from "actions/nexusWidgetsActions";
import { clearEnv, setEnv } from "actions/envsActions";
import Heading from "../headings/Heading";
import SearchBar from "../searchbar/SearchBar";
import Loader from "../loader/Loader";
import theme from "../../AppTheme";
import DisplayType from "../displaytype/DisplayType";
import PaperTranslationsHeader from "./PaperTranslationsHeader";
import DialogTranslationsVersions from "./DialogTranslationsVersions";
import DialogTranslationsTranslate from "./DialogTranslationsTranslate";
import PaperTranslationsPerEnv from "./PaperTranslationsPerEnv";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
    "& .MuiBox-searchBar": {
      position: "absolute",
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  searchWrapper: {
    minHeight: theme.spacing(6) + 2,
  },
});

const THRESHOLD = 3;

const PaperTranslations = ({
  translationWidgets,
  fetchWidgetList,
  auth,
  envs,
  clearEnvironment,
  setEnvironment,
  setTranslationWdgt,
  selectedWidget,
  saveTranslations,
  translations,
  getNexusWidgetsCategories,
  widgetsCategories,
  setWidgetVersions,
  widgetsVersions,
  clearWidgetVersions,
  translationsChanged,
  clearTranslations,
  fetchWidgetDetails,
  loading,
  widgetDetails,
  clearWidgetDetails,
  dispatchPromoteTranslation,
  versionsLoading,
}) => {
  const { access_token, id_token } = auth;
  const { id: envID } = envs;

  const [thresholdPerEnv, setThresholdPerEnv] = useState({});

  const onShowMore = value => {
    const copyThresholdPerEnv = { ...thresholdPerEnv };
    copyThresholdPerEnv[value] = 1000000;
    setThresholdPerEnv(copyThresholdPerEnv);
  };

  const { locale } = useParams();
  const classes = useStyles();
  const [grid, setGrid] = useState("list");
  const [dialogWidget, setDialogWidget] = useState(false);
  const [dialogVersion, setDialogVersion] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState({
    theme: null,
    category: null,
  });

  const handleFilters = e => {
    const { name, value } = e.currentTarget.dataset;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleClickWidget = (widget, envId, env) => {
    setDialogVersion(true);
    setWidgetVersions({ name: widget.name, access_token, id_token, envId });
    setTranslationWdgt({ ...widget, envId, envTitle: env });
  };

  const handleMoreInfoClick = id => {
    fetchWidgetDetails({ id, access_token, id_token });
  };

  const handlePromoteClick = (widgetId, sourceEnv, destinationEnv) => {
    dispatchPromoteTranslation({
      widgetId,
      sourceEnv,
      destinationEnv,
      access_token,
      id_token,
    });
  };

  useEffect(() => {
    fetchWidgetList({ envID, access_token, id_token, searchText, ...filters });
  }, [filters, envID]);

  useEffect(() => {
    const defaultThresholdPerEnv = {};
    translationWidgets.forEach(x => {
      const [type] = Object.entries(x)[0];
      defaultThresholdPerEnv[type] = THRESHOLD;
    });
    setThresholdPerEnv(defaultThresholdPerEnv);
  }, [translationWidgets]);

  useEffect(() => {
    getNexusWidgetsCategories({ access_token, id_token });
  }, []);

  return (
    <main className={classes.root}>
      <Heading title="Translate" backUrl={`/${locale}/landing`}>
        <PaperTranslationsHeader
          envs={envs}
          clearEnvironment={clearEnvironment}
          setEnvironment={setEnvironment}
          widgetsCategories={widgetsCategories}
          handleFilters={handleFilters}
        />
      </Heading>
      <Box pt={8} pb={4} className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box className={classes.searchWrapper}>
                <Box
                  py={0.25}
                  className="MuiBox-searchOverlay"
                  position="relative"
                >
                  <DisplayType grid={grid} handleGridView={setGrid} />
                  <SearchBar
                    callback={fetchWidgetList}
                    callbackParams={{
                      ...filters,
                      access_token,
                      id_token,
                      envID,
                    }}
                    setSearchText={setSearchText}
                  />
                </Box>
              </Box>
            </Grid>
            {!loading && !isEmpty(thresholdPerEnv) ? (
              <Grid item xs={12}>
                {translationWidgets.map(env => {
                  const [type, envData] = Object.entries(env)[0];
                  return (
                    <PaperTranslationsPerEnv
                      key={type}
                      env={envData.title}
                      envType={type}
                      envId={envData.envId}
                      setEnvironment={setEnvironment}
                      threshold={THRESHOLD}
                      grid={grid}
                      thresholdPerEnv={thresholdPerEnv}
                      translationWidgets={envData.data}
                      onShowMore={onShowMore}
                      handleClickWidget={handleClickWidget}
                    />
                  );
                })}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      {widgetsVersions && (
        <DialogTranslationsVersions
          versionsLoading={versionsLoading}
          envs={envs.list}
          clearWidgetDetails={clearWidgetDetails}
          widgetDetails={widgetDetails}
          handleMoreInfoClick={handleMoreInfoClick}
          selectedWidget={selectedWidget}
          widgetsVersions={widgetsVersions}
          dialogVersion={dialogVersion}
          setDialogVersion={setDialogVersion}
          setDialogWidget={setDialogWidget}
          clearWidgetVersions={clearWidgetVersions}
          setTranslationWdgt={setTranslationWdgt}
          handlePromoteClick={handlePromoteClick}
        />
      )}
      <DialogTranslationsTranslate
        dialogWidget={dialogWidget}
        setDialogWidget={setDialogWidget}
        selectedWidget={selectedWidget}
        saveTranslations={saveTranslations}
        access_token={access_token}
        id_token={id_token}
        translations={translations}
        translationsChanged={translationsChanged}
        clearTranslations={clearTranslations}
      />
    </main>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    envs: state.envs,
    translationWidgets: state.translations.list,
    selectedWidget: state.translations.current,
    translations: state.translations.translations,
    widgetsCategories: state.nexusWidgets.categories,
    widgetsVersions: state.nexusWidgets.versions,
    widgetDetails: state.nexusWidgets.current,
    versionsLoading: state.nexusWidgets.loading,
    translationsChanged: state.translations.translationsChanged,
    loading: state.translations.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchWidgetList: params => dispatch(fetchTranslationWidgetList(params)),
  clearEnvironment: () => dispatch(clearEnv()),
  setEnvironment: parmas => dispatch(setEnv(parmas)),
  setTranslationWdgt: widget => dispatch(setTranslationWidget(widget)),
  saveTranslations: payload => dispatch(saveWidgetTranslations(payload)),
  getNexusWidgetsCategories: params =>
    dispatch(fetchNexusWidgetsCategories(params)),
  setWidgetVersions: payload => dispatch(fetchNexusWidgetsVersions(payload)),
  clearWidgetVersions: () => dispatch(clearNexusWidgetsVersions()),
  clearTranslations: () => dispatch(clearTranslationData()),
  clearWidgetDetails: () => dispatch(clearNexusWidget()),
  fetchWidgetDetails: payload => dispatch(fetchNexusWidgetById(payload)),
  dispatchPromoteTranslation: payload => dispatch(promoteTranslation(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaperTranslations);

import React from "react";
import { Button, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  white: {
    backgroundColor: theme.palette.common.white,
  },
  fullWidth: {
    justifyContent: "space-between",
  },
});

const FilterBulkSelect = ({ value, action, fullWidth, color = "default" }) => {
  const styles = useStyles();
  return (
    <Button
      fullWidth={fullWidth}
      onClick={() => action()}
      variant="contained"
      className={classNames({
        [styles.white]: color === "white" && !value,
        [styles.fullWidth]: fullWidth,
      })}
      size="small"
      {...(value ? { color: "primary" } : {})}
    >
      Bulk select
      <Checkbox size="small" color="primary" checked={value} onChange={() => action()} />
    </Button>
  );
};

export default FilterBulkSelect;

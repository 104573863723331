/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { MenuItem, Typography, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import DeleteAppDialog from "../DeleteAppDialog";
import { deleteApp } from "../../../actions/applicationsActions";

const useStyles = makeStyles({
  root: {},
  appName: {
    fontWeight: "bold",
  },
  appDialogContainer: {
    textAlign: "center",
  },
});

const DeleteMenuItem = ({ application, onClick, auth, deleteApplication }) => {
  const styles = useStyles();

  const [openDialog, setOpenDialog] = useState(false);

  const { access_token, id_token } = auth;

  const onDelete = () => {
    deleteApplication({
      access_token,
      id_token,
      applicationId: application._id,
    });
    onClick();
  };

  const onCancel = () => setOpenDialog(false);

  return (
    <>
      <MenuItem onClick={() => setOpenDialog(true)}>Delete</MenuItem>

      <DeleteAppDialog
        isOpen={openDialog}
        onCancel={onCancel}
        onDelete={onDelete}
      >
        <Grid container direction="row" className={styles.appDialogContainer}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3">
              Are you sure you want to delete
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              display="inline"
              className={styles.appName}
            >
              {`${application.title} `}
            </Typography>
            <Typography
              variant="h5"
              display="inline"
              className={styles.appName}
              color="secondary"
            >
              {`v${application.version}`}
            </Typography>
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">
                permanently?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DeleteAppDialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteApplication: payload => dispatch(deleteApp(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMenuItem);

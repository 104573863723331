/* eslint-disable camelcase */
import axios from "axios";

class ConfigurationService {
  fetchConfigListApp = async ({
    theme,
    appType,
    platform,
    searchText,
    access_token,
    id_token,
    envID,
  }) => {
    const params = {
      theme,
      appType,
      platform,
      searchText,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/configuration/applications",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config applications service:", err);
      return err;
    }
  };

  fetchConfigListWidget = async ({
    application,
    envID,
    access_token,
    searchText,
    id_token,
  }) => {
    const params = {
      searchText,
    };
    try {
      const data = await axios({
        method: "get",
        url: `/api/appbuilder/configuration/widgets/${application}`,
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget list service:", err);
      return err;
    }
  };

  resetConfigApp = async ({ application, envID, access_token, id_token }) => {
    const params = {
      application,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/configuration/reset",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget reset service:", err);
      return err;
    }
  };

  deployConfigApp = async ({
    application,
    componentId,
    envID,
    access_token,
    id_token,
  }) => {
    const params = {
      application,
      componentId,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/configuration/deploy",
        params,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget deploy service:", err);
      return err;
    }
  };
}

const inst = new ConfigurationService();
export default inst;

/* eslint-disable camelcase */
import axios from "axios";

class GalleryService {
  fetch = async ({ access_token, id_token, search, page, pageSize, sort }) => {
    const params = { search, page, pageSize, sort };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/gallery/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in fetch gallery service:", err);
      return err;
    }
  };

  fetchAll = async ({ id_token, access_token }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/gallery/all`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch all galleries service:", err);
      return err;
    }
  };

  add = async ({ access_token, id_token, gallery }) => {
    try {
      await axios({
        method: "post",
        url: `/api/appbuilder/portal/gallery/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: gallery,
      });
      return gallery;
    } catch (err) {
      console.log("Error in add gallery media service:", err);
      return err;
    }
  };

  update = async ({ access_token, id_token, gallery }) => {
    const {_id} = gallery;
    try {
      await axios({
        method: "patch",
        url: `/api/appbuilder/portal/gallery/${_id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: gallery,
      });
      return gallery;
    } catch (err) {
      console.log("Error in updata gallery service:", err);
      return err;
    }
  };

  delete = async ({ access_token, id_token, gallery }) => {
    try {
      await axios({
        method: "delete",
        url: `/api/appbuilder/portal/gallery/${gallery}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        }
      });
    } catch (err) {
      console.log("Error in delete gallery service:", err);
      return err;
    }
  };

  bulkDelete = async ({ access_token, id_token, galleries }) => {
    try {
      await axios({
        method: "delete",
        url: `/api/appbuilder/portal/gallery/`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        data: galleries,
      });
    } catch (err) {
      console.log("Error in bulk delete gallery service:", err);
      return err;
    }
  };

  export = async ({ access_token, id_token, search, sort }) => {
    const params = { search, sort };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/portal/gallery/export`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          idtoken: id_token,
        },
        params,
      });
      return data;
    } catch (err) {
      console.log("Error in export gallery service:", err);
      return err;
    }
  };
}

export default new GalleryService();

const routesPermissions = [
  {
    label: "locale",
    value: "/:locale",
  },
  {
    label: "landing",
    value: "/:locale/landing/",
  },
  {
    label: "configuration",
    value: "/:locale/env",
    permission: ["view_configurations"],
  },
  {
    label: "configuration",
    value: "/:locale/configurator",
    permission: ["view_configurations"],
  },
  {
    label: "envConfiguration",
    value: "/:locale/env/:env",
    permission: ["view_configurations"],
  },
  {
    label: "touchpointConfiguration",
    value: "/:locale/env/:env/:touchpoint",
    permission: ["view_configurations"],
  },
  {
    label: "appConfiguration",
    value: "/:locale/env/:env/:touchpoint/:app",
    permission: ["view_configurations"],
  },
  {
    label: "formConfiguration",
    value: "/:locale/env/:env/:touchpoint/:app/:widget/form",
    permission: ["view_configurations"],
  },
  {
    label: "translation",
    value: "/:locale/translations",
    permission: ["view_translations"],
  },
  {
    label: "envTranslation",
    value: "/:locale/translations/:env",
    permission: ["view_translations"],
  },
  {
    label: "widgetTranslation",
    value: "/:locale/translations/:env/:widget/:version",
    permission: ["view_translations"],
  },
  {
    label: "roles",
    value: "/:locale/roles/",
    permission: ["view_roles"],
  },
  {
    label: "media",
    value: "/:locale/media/",
    permission: ["view_media"],
  },
  {
    label: "createApp",
    value: "/:locale/apps/",
    permission: ["view_apps"],
  },
  {
    label: "tenant",
    value: "/tenant",
    permission: ["view_tenant"],
  },
  {
    label: "sagas",
    value: "/:locale/apps/sagas/:sagaId",
    permission: ["view_apps"],
  },
  {
    label: "apis",
    value: "/:locale/apis/",
    permission: ["view_apis"],
  },
  {
    label: "portalDevices",
    value: "/:locale/portal/store-devices",
    permission: ["view_store_devices"],
  },
  {
    label: "portalGroups",
    value: "/:locale/portal/store-groups",
    permission: ["view_store_groups"],
  },
  {
    label: "portalReporting",
    value: "/:locale/portal/reporting",
    permission: ["view_reporting"],
  },
  {
    label: "portalCampaigns",
    value: "/:locale/portal/campaigns",
    permission: ["view_campaigns"],
  },
  {
    label: "portalGalleries",
    value: "/:locale/portal/galleries",
    permission: ["view_galleries"],
  },
  {
    label: "portalPasscodes",
    value: "/:locale/portal/passcodes",
    permission: ["view_passcodes"],
  },
  {
    label: "portal",
    value: "/:locale/portal",
    permission: ["view_portal"],
  },
];

export default routesPermissions;

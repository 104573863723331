/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React from "react";

import { ListItem, Box, Typography, Link, IconButton } from "@material-ui/core";
import VfSvgIcon from "../../icons/VfSvgIcon";

const VisitAppMenuItem = ({ application, environment, onClick }) => {
  return (
    <ListItem>
      <Box display="block">
        <Typography variant="body2">
          {environment.urls
            ?.find(url => url._id.toString() === application.url)
            ?.value.substring(0, 25)}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="secondary" onClick={onClick}>
            <Link
              href={
                `https://${
                  environment.urls?.find(
                    url => url._id.toString() === application.url
                  )?.value
                }` || "#"
              }
              rel="noreferrer"
              target="_blank"
              variant="body2"
              color="secondary"
            >
              {`Open ${application.title}`}
            </Link>
          </Typography>
          <Link
            href={
              `https://${
                environment.urls?.find(
                  url => url._id.toString() === application.url
                )?.value
              }` || "#"
            }
            rel="noreferrer"
            target="_blank"
          >
            <IconButton color="secondary" size="small" onClick={onClick}>
              <VfSvgIcon fontSize="small" icon="externalLink" viewBox="24" />
            </IconButton>
          </Link>
        </Box>
      </Box>
    </ListItem>
  );
};

export default VisitAppMenuItem;

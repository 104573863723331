/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React, { useState } from "react";

import { MenuItem, DialogContentText } from "@material-ui/core";
import VfDialog from "../../vf/VfDialog";

const NotesMenuItem = ({ onClick }) => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <MenuItem
        onClick={() => {
          onClick();
          setOpenDialog(true);
        }}
      >
        SEE NOTES
      </MenuItem>

      <VfDialog
        title="Notes"
        openDialog={openDialog}
        buttonCloseAction={() => setOpenDialog(false)}
      >
        <DialogContentText>
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </VfDialog>
    </>
  );
};

export default NotesMenuItem;

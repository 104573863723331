import {
  GET_GALLERIES_REQUEST_SUCCESS,
  GET_ALL_GALLERIES_REQUEST_SUCCESS,
  ADD_GALLERIES_REQUEST,
  ADD_GALLERIES_REQUEST_SUCCESS,
  ADD_GALLERIES_REQUEST_FAIL,
  UPDATE_GALLERIES_REQUEST,
  UPDATE_GALLERIES_REQUEST_SUCCESS,
  UPDATE_GALLERIES_REQUEST_FAIL,
  DELETE_GALLERIES_REQUEST,
  DELETE_GALLERIES_REQUEST_SUCCESS,
  DELETE_GALLERIES_REQUEST_FAIL,
  BULK_DELETE_GALLERIES_REQUEST,
  BULK_DELETE_GALLERIES_REQUEST_SUCCESS,
  BULK_DELETE_GALLERIES_REQUEST_FAIL,
  REMOVE_ERROR,
} from "actions/galleryActions";

const initialState = {
  loading: false,
  galleries: [],
  allGalleries: [],
  media: [],
  galleriesItems: 1,
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GALLERIES_REQUEST_SUCCESS: {
      return {
        ...state,
        galleries: payload.galleries,
        galleriesItems: payload.count,
      };
    }
    case GET_ALL_GALLERIES_REQUEST_SUCCESS: {
      return {
        ...state,
        allGalleries: payload.galleries ? payload.galleries : [],
      }
    }
    case ADD_GALLERIES_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_GALLERIES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case ADD_GALLERIES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case UPDATE_GALLERIES_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_GALLERIES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case UPDATE_GALLERIES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case DELETE_GALLERIES_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_GALLERIES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case DELETE_GALLERIES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case BULK_DELETE_GALLERIES_REQUEST: {
      return { ...state, loading: true };
    }
    case BULK_DELETE_GALLERIES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: payload?.response?.data?.message ?? '', };
    }
    case BULK_DELETE_GALLERIES_REQUEST_FAIL: {
      return { ...state, loading: false };
    }
    case REMOVE_ERROR: {
      return { ...state, error: '' };
    }
    default:
      return state;
  }
};
  